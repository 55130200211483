import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateFuel = {

    SaveChanges:{
        en_US: "Save changes",
        ru_RU: "Сохранить"
    },
    Title:{
        en_US: "Dictionary",
        ru_RU: "Справочник"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    Name:{
        en_US: "Name",
        ru_RU: "Название"
    },
    FuelType:{
        en_US: "Fuels",
        ru_RU: "Виды топлива"
    },
    Values:{
        en_US: "Values",
        ru_RU: "Значения"
    },
    Value:{
        en_US: "Value",
        ru_RU: "Значение"
    },
    Add:{
        en_US: "Add",
        ru_RU: "Добавить"
    },
    TitleAdd:{
        en_US: "Add new value",
        ru_RU: "Добавление значения"
    },
    TitleUpdate:{
        en_US: "Update value",
        ru_RU: "Обновление значения"
    }
};
