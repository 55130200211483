import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateRole = {

    Title:{
        en_US: "System roles",
        ru_RU: "Системные роли"
    },
    Name:{
        en_US: "Name",
        ru_RU: "Название"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    Add:{
        en_US: "Add role",
        ru_RU: "Добавить роль"
    },
    General:{
        en_US: "General",
        ru_RU: "Основная информация"
    },
    Role:{
        en_US: "Role",
        ru_RU: "Роль"
    },
    AccessForModules:{
        en_US: "Access for modules",
        ru_RU: "Права доступа к модулям"
    },
    Module:{
        en_US: "Module",
        ru_RU: "Модуль"
    },
    Read:{
        en_US: "Read",
        ru_RU: "Просмотр"
    },
    Create:{
        en_US: "Create",
        ru_RU: "Добавление"
    },
    Update:{
        en_US: "Update",
        ru_RU: "Обновление"
    },
    Blocking:{
        en_US: "Block",
        ru_RU: "Блокировка"
    },
    Fullaccess:{
        en_US: "Full access",
        ru_RU: "Полный доступ"
    },
    Using:{
        en_US: "Using",
        ru_RU: "Используется"
    },
    NotUsing:{
        en_US: "Not using",
        ru_RU: "Не используется"
    },
    CreateRole:{
        en_US: "Add role",
        ru_RU: "Добавление роли"
    },
    UpdateRole:{
        en_US: "Update role",
        ru_RU: "Обновление роли"
    }
};
