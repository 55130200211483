import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Card, Spinner, Nav} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import './../../../../boxicons.css';
import api from '../../../../utils/axiosInterceptions';

export const LivenkaBs2 = ({smartData, controllerInfo}) => {

    const router = useHistory()
    const params = useParams();
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [showSecondSleeva, setShowSecondSleeve] = useState(false)
    const [fuels, setFuels] = useState([])
    const [loadingBtn, setLoadingBtn] = useState(false)

    const listSubtypes = [
        {label:"КУП-2. КУП-4. КУП-6", value:0},
        {label:"КУП-47", value:1},
        {label:"КУП-7 (м)", value:2},
        {label:"КУП-7 (в)", value:3},
        {label:"КУП-7 (м) 1 рукав без геркона", value:4},
        {label:"КУП-47 без кнопки старт", value:5},
    ]

    const addresses = [
        {label:"1", value:1},
        {label:"2", value:2},
        {label:"3", value:3},
        {label:"4", value:4},
        {label:"5", value:5},
        {label:"6", value:6},
        {label:"7", value:7},
        {label:"8", value:8},
        {label:"9", value:9},
        {label:"10", value:10},
    ]

    const getFuels = () => {
        api.get(`/api/fuels/all`, {
            withCredentials:true
        })
        .then(function (response) { 
            const add = response.data.listDatas.map(item => ({
                ...item,
                value: item.id,
                label: item.name
            }))
            setFuels(add)

            let find_fuel_type = response.data.listDatas.filter((item) => item.id === smartData.fuel_type)
            setValue("FuelType", {label:find_fuel_type[0].name, value:find_fuel_type[0].id})

            if(smartData.fuel_type2 != 0) {
                let find_fuel_type2 = response.data.listDatas.filter((item) => item.id === smartData.fuel_type2)
                setValue("FuelType2", {label:find_fuel_type2[0].name, value:find_fuel_type2[0].id})
            } else {
                let find_fuel_type2 = response.data.listDatas.filter((item) => item.id === 1)
                setValue("FuelType2", {label:find_fuel_type2[0].name, value:find_fuel_type2[0].id})
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        getFuels()

        let find_address = addresses.filter((item) => item.value === smartData.address)
        setValue("Address", find_address[0])

        let find_address2 = addresses.filter((item) => item.value === smartData.address2)
        setValue("Address2", find_address2[0])

        let find_subtype = listSubtypes.filter((item) => item.value === smartData.subtype)
        setValue("Subtype", find_subtype[0])

        if(smartData.subtype == 2) {
            console.log("antonnnn")
            setShowSecondSleeve(true)
        } else {
            setShowSecondSleeve(false)
        }
    }, [])

    const onSubmit = data => {
        setLoadingBtn(true)
        console.log(data)
        api.post('/api/controllers/details/livenka/update', {
            ControllerId: params.id,
            SubType: data.Subtype.value,
            Address: data.Address.value,
            Address2: data.Address2.value,
            FuelType: data.FuelType != undefined ? data.FuelType.id : 1,
            FuelType2: data.FuelType2 != undefined ? data.FuelType2.value : 1,
            withCredentials:true
        })
        .then(function (response) {
            setLoadingBtn(false)
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingBtn(false)
        });
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    
    }

    return(      
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12">
                    <h4 className="text-black">Ливенка</h4>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">Модель колонки </Form.Label>
                            <Controller
                                name="Subtype"                                
                                control={control}
                                rules={{ required: "Обязательное поле" }}
                                render={({ field:{value} }) => (
                                <Select
                                    value={value}
                                    options={listSubtypes}
                                    placeholder="Выберите значение из списка"
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        setValue("Subtype", item)
                                        if(item.value == 2) {
                                            setShowSecondSleeve(true)
                                        } else {
                                            setShowSecondSleeve(false)
                                        }
                                    }}
                                />
                                )}
                            />
                            {errors.Subtype && 
                                <span className="text-danger font-size-13">{errors.Subtype.message}</span>
                            }
                    </Form.Group>

                </div>

                <div className="col-xs-12 col-lg-6 col-md-6">
                    <Card border="light" className="border shadow-none">
                        <Card.Body>
                        <h3 className="text-black benzamat-font-bold">Рукав 1</h3>
                        <div>
                            <Form.Group className="mb-3 mt-3">
                                <Form.Label className="text-black">Адрес</Form.Label>                                
                                <Controller
                                    name="Address"                                
                                    control={control}
                                    rules={{ required: "Обязательное поле" }}
                                    render={({ field: {value} }) => (
                                    <Select                      
                                        isSearchable
                                        options={addresses}
                                        value={value}               
                                        placeholder="Значение"
                                        classNamePrefix="select"
                                        onChange={(item) => setValue("Address", item)}
                                    />
                                    )}
                                />
                                {errors.Address && 
                                    <span className="text-danger font-size-13">{errors.Address.message}</span>
                                } 
                            </Form.Group>

                            <Form.Group className="mb-3 mt-3">
                                <Form.Label className="text-black">Вид топлива</Form.Label>                                
                                <Controller
                                    name="FuelType"                                
                                    control={control}
                                    rules={{ required: "Обязательное поле" }}
                                    render={({ field: {value} }) => (
                                    <Select                      
                                        isSearchable
                                        options={fuels}
                                        value={value}               
                                        placeholder="Значение"
                                        classNamePrefix="select"
                                        onChange={(item) => setValue("FuelType", item)}
                                    />
                                    )}
                                />
                                {errors.FuelType && 
                                    <span className="text-danger font-size-13">{errors.FuelType.message}</span>
                                } 
                            </Form.Group>
                        </div>
                        </Card.Body>
                    </Card>
                </div>
                
                {
                    showSecondSleeva
                    ?
                    <div className="col-xs-12 col-lg-6 col-md-6">
                    <Card border="light" className="border shadow-none">
                        <Card.Body>
                        <h3 className="text-black benzamat-font-bold">Рукав 2</h3>
                        <div>
                            <Form.Group className="mb-3 mt-3">
                                <Form.Label className="text-black">Адрес</Form.Label>                                
                                <Controller
                                    name="Address2"                                
                                    control={control}
                                    render={({ field: {value} }) => (
                                    <Select                      
                                        isSearchable
                                        options={addresses}
                                        value={value}               
                                        placeholder="Значение"
                                        classNamePrefix="select"
                                        onChange={(item) => setValue("Address2", item)}
                                    />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3 mt-3">
                                <Form.Label className="text-black">Вид топлива</Form.Label>                                
                                <Controller
                                    name="FuelType2"                                
                                    control={control}
                                    render={({ field: {value} }) => (
                                    <Select                      
                                        isSearchable
                                        options={fuels}
                                        value={value}               
                                        placeholder="Значение"
                                        classNamePrefix="select"
                                        onChange={(item) => setValue("FuelType2", item)}
                                    />
                                    )}
                                />
                            </Form.Group>
                        </div>
                        </Card.Body>
                    </Card>
                    </div>
                    : null
                }

                <div className="form-group ">
                    <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                        {loadingBtn && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingBtn && <span>Сохранение параметров</span>}
                        {!loadingBtn && <span>Сохранить параметры</span>}
                    </button>
                </div>
            </div>
        </Form>
    );
}