import React, { useState, useEffect, useContext, useMemo, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Form, Spinner, Alert, ProgressBar, Modal} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller, useWatch} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import './../../boxicons.css';
import CurrencyInput from 'react-currency-input-field';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import moment from 'moment/moment';
import { MenuItem } from '@mui/material';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslatePouring } from '../../lang/pourings/translatePouring';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import api from '../../utils/axiosInterceptions';

export const Pouring = () => {

  const router = useHistory();
  const {isAuth} = useContext(AuthContext);
  const { control, register, handleSubmit, reset, setValue, getValues, watch, formState, formState: { errors }} = useForm();
  const [startLevel, setStartLevel] = useState(0)
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [countGsm, setCountGsm] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [listPourings, setListPourings] = useState([])
  const [listStation, setListStation] = useState([])
  const [listCompanies, setListCompanies] = useState([])
  const [listSections, setListSections] = useState([])
  const [btnIsUpdate, setBtnIsUpdate] = useState(false)
  const [issetLevelMeter, setIssetLevelMeter] = useState(false)
  const [sectionIsSelected, setSectionIsSelected] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [startDateTime, setStartDateTime] = useState(new Date())
  const [sectionData, setSectionData] = useState({})
  const [endDateTime, setEndDateTime] = useState(new Date())
  const [invoiceDate, setInvoiceDate] = useState(new Date())
  const [deltaVolume, setDeltaVolume] = useState(0)
  const [deltaMass, setDeltaMass] = useState(0)
  const [deltaLevel, setDeltaLevel] = useState(0)
  const [deltaTemperatur, setDeltaTemperatur] = useState(0)
  const [deltaDensity, setDeltaDensity] = useState(0)
  const [startMass, setStartMass] = useState(0)
  const [lmIndexOnController, setLmIndexOnController] = useState(0)
  const translated = useLittera(TranslatePouring);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [loadingLevelMeterBtn, setLoadingLevelMeterBtn] = useState({
    start:false,
    end:false
  })
  const [selectedCompany, setSelectedCompany] = useState({
    label:'',
    value:0
  })
  const [selectedStation, setSelectedStation] = useState({
    label:'',
    value:0
  })
  const [selectedSection, setSelectedSection] = useState({
    label:'',
    value:0
  })
  const [permission, setPermission] = useState({
    read:false,
    create:false,
    fullAccess:false
  })
  const [loadingData, setLoadingData] = useState({
    grid:false,
    button:false
  });

  const handleShow = () => {

    setModalShow(true)
    setSectionIsSelected(false)
    setBtnIsUpdate(false)

    setValue("InvoiceDate", invoiceDate)
    setValue("StartDateTime", startDateTime)
    setValue("EndDateTime", endDateTime)

    setDeltaVolume(0)
    setDeltaTemperatur(0)
    setDeltaMass(0)
    setDeltaLevel(0)
    setDeltaDensity(0)
    setStartMass(0)

    reset({
        "Price":0,
        "InvoiceNumber": 0,
        "InvoiceVolume": 0,
        "InvoiceDensity": 0,
        "InvoiceTemperature": 0,
        "InvoiceMass": 0,

        "StartLevel": 0,
        "StartVolume": 0,
        "StartDensity": 0,
        "StartTemperature": 0,
        "StartMass":0,

        "EndLevel": 0,
        "EndVolume": 0,
        "EndDensity": 0,
        "EndTemperature": 0,
        "EndMass":0,

        "TankTruckVolume": 0,
        "TankTruckDensity": 0,
        
        "Company": "",
        "Station": "",
        "Section": ""
    })
  };

  const pourings = () => {
    api.get('/api/pourings/all', {
        withCredentials:true
    })
    .then(function (response) {
        console.log("pourings")
        
        setListPourings(response.data.listDatas)
        setCountGsm(response.data.listDatas.length)
        setPermission({
            read: response.data.read,
            create: response.data.create,
            fullAccess: response.data.fullAccess
        })
        setIsLoading(false)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const handleClose = () => setShowFormAdd(false);

  const getLevelMeterData = (is_start) => {
    
    if(is_start) {
        setLoadingLevelMeterBtn({start:true})
    } else {
        setLoadingLevelMeterBtn({end:true})
    }
    api.get('/api/controllers/details/level-meters/group-get-level-meter', {
        headers:{
            "content-type" : "application/json",
            "controllerId":sectionData.levelMeterControllerId
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("level meter controller")
        

        if(response.data.listDatas.length != 0) {

            if(is_start) {

                if(response.data.listDatas[lmIndexOnController].controllerTypeId == 3) 
                {                    
                    setValue("StartLevel", response.data.listDatas[lmIndexOnController].level)
                    setValue("StartVolume", response.data.listDatas[lmIndexOnController].allVolume)
                    setValue("StartDensity", response.data.listDatas[lmIndexOnController].density)
                    setValue("StartTemperature", response.data.listDatas[lmIndexOnController].temperature)
                    setStartMass((response.data.listDatas[lmIndexOnController].allVolume) * (response.data.listDatas[lmIndexOnController].density) / 1000)                    
                } 
                else 
                {                    
                    setValue("StartLevel", response.data.listDatas[lmIndexOnController].level.data * 100)
                    setValue("StartVolume", response.data.listDatas[lmIndexOnController].all_volume.data * 1000)
                    setValue("StartDensity", response.data.listDatas[lmIndexOnController].density.data * 1000)
                    setValue("StartTemperature", response.data.listDatas[lmIndexOnController].temperature.data)
                    setStartMass((response.data.listDatas[lmIndexOnController].all_volume.data * 1000) * (response.data.listDatas[lmIndexOnController].density.data * 1000) / 1000)
                }

            } else {

                if(response.data.listDatas[lmIndexOnController].controllerTypeId == 3) 
                {
                    setValue("EndLevel", response.data.listDatas[lmIndexOnController].level)
                    setValue("EndVolume", response.data.listDatas[lmIndexOnController].allVolume)
                    setValue("EndDensity", response.data.listDatas[lmIndexOnController].density)
                    setValue("EndTemperature", response.data.listDatas[lmIndexOnController].temperature)
                    setValue("EndMass", ((response.data.listDatas[lmIndexOnController].allVolume) * (response.data.listDatas[lmIndexOnController].density) / 1000))

                } 
                else 
                {
                    setValue("EndLevel", response.data.listDatas[lmIndexOnController].level.data * 100)
                    setValue("EndVolume", response.data.listDatas[lmIndexOnController].all_volume.data * 1000)
                    setValue("EndDensity", response.data.listDatas[lmIndexOnController].density.data * 1000)
                    setValue("EndTemperature", response.data.listDatas[lmIndexOnController].temperature.data)
                    setValue("EndMass", ((response.data.listDatas[lmIndexOnController].all_volume.data * 1000) * (response.data.listDatas[lmIndexOnController].density.data * 1000) / 1000))
                }
            }

            let endMass = getValues("EndMass")
            setDeltaMass((endMass - startMass))

            let startLevel = getValues("StartLevel")
            let endLevel = getValues("EndLevel")
            setDeltaLevel((endLevel - startLevel))

            let endVolume = getValues("EndVolume")
            let startVolume = getValues("StartVolume")
            setDeltaVolume((endVolume - startVolume))

            let endTemp = getValues("EndTemperature")
            let startTemp = getValues("StartTemperature")
            setDeltaTemperatur((endTemp - startTemp))

            displayToast(translated.GetDataLevelMeterSuccess, true)
        } else {
            displayToast(translated.NotGetSections, false)
        }

        if(is_start) {
            setLoadingLevelMeterBtn({start:false})
        } else {
            setLoadingLevelMeterBtn({end:false})
        }
    })
    .catch(function (error) {
        console.log(error);
        setLoadingLevelMeterBtn({end:false})
        setLoadingLevelMeterBtn({start:false})
        displayToast(error.response.data.message, false)
    });
  }

  const getSectionData = (id) => {
    api.get('/api/stations/details/sections/details', {
        headers:{
            "content-type" : "application/json",
            "Id":id
        },
        params:{
            Id: id,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("section data")
        console.log(response)
        setSectionData(response.data.dataResult)

        if(response.data.dataResult.levelMeterId != 0) {
            setIssetLevelMeter(true)
            setLmIndexOnController(response.data.dataResult.levelMeterOnController)
        }
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getCompanies = () => {
    api.get('/api/company/all', {
        withCredentials:true
    })
    .then(function (response) {
        console.log("companies")
        

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))

        setListCompanies(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getStations = companyId => {
    api.get('/api/company/details/stations', {
        headers:{
            "content-type" : "application/json",
            "companyId":companyId
        },
        params:{
            companyId: companyId,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("stations of company")
        

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))
        setListStation(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getSections = stationId => {
    console.log(stationId)
    api.get('/api/stations/details/sections', {
        headers:{
            "content-type" : "application/json",
            "StationId":stationId
        },
        params:{
            StationId: stationId,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("sections of station")
        

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))
        setListSections(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const displayToast = (message, status) => {

      if(status){
        toast.success(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });
    
      } else {
        toast.error(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });
      }
  }

  const handleUpdate = (id) => {
    api.get('/api/pourings/details', {
        headers:{
            "content-type" : "application/json",
            "Id":id
        },
        params:{
            Id: id,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("pouring details")
        

        setValue("Company", {label: response.data.dataResult.company.name, value:response.data.dataResult.company.id})
        setValue("Station", {label: response.data.dataResult.station.name, value:response.data.dataResult.station.id})
        setValue("Section", {label: response.data.dataResult.stationSection.name, value:response.data.dataResult.stationSection.id})
        
        getSectionData(response.data.dataResult.stationSection.id)
        setSectionIsSelected(true)
        setValue("Price", response.data.dataResult.price)
        setValue("Id", response.data.dataResult.id)
        //StationSectionId: selectedSection.value,

        setInvoiceDate(response.data.dataResult.invoiceDate)
        //setValue("InvoiceDate", response.data.dataResult.invoiceDate)
        setValue("InvoiceNumber", response.data.dataResult.invoiceNumber)
        setValue("InvoiceVolume",  response.data.dataResult.invoiceVolumeDec)
        setValue("InvoiceDensity",  response.data.dataResult.invoiceDensityDec)
        setValue("InvoiceTemperature", response.data.dataResult.invoiceTemperatureDec)
        setValue("InvoiceMass", response.data.dataResult.invoiceMassDec)

        setValue("StartLevel", response.data.dataResult.startLevelDec)
        setValue("StartVolume", response.data.dataResult.startVolumeDec)
        setValue("StartDensity", response.data.dataResult.startDensityDec)
        setValue("StartTemperature", response.data.dataResult.startTemperatureDec)
        setValue("StartDateTime", response.data.dataResult.startDateTime)

        setValue("EndLevel", response.data.dataResult.endLevelDec)
        setValue("EndVolume", response.data.dataResult.endVolumeDec)
        setValue("EndDensity", response.data.dataResult.endDensityDec)
        setValue("EndTemperature", response.data.dataResult.endTemperatureDec)
        setValue("EndDateTime", response.data.dataResult.endDateTime)

        setStartMass((response.data.dataResult.startVolumeDec) * (response.data.dataResult.startDensityDec) / 1000)
        setValue("EndMass", ((response.data.dataResult.endVolumeDec) * (response.data.dataResult.endDensityDec) / 1000))

        setValue("TankTruckVolume", response.data.dataResult.tankTruckVolume)
        setValue("TankTruckDensity", response.data.dataResult.tankTruckDensity)

        setDeltaVolume(response.data.dataResult.factVolume)
        setDeltaTemperatur(response.data.dataResult.factTemperature)
        setDeltaMass(response.data.dataResult.factKg)
        setDeltaLevel(response.data.dataResult.factLitre)
        setDeltaDensity(response.data.dataResult.factDensity)

        setModalShow(true)
        setBtnIsUpdate(true)
        
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const onSubmit = data => { 

    console.log(data)
    let link = btnIsUpdate ? "/api/pourings/details/update" : "/api/pourings/create"
    setLoadingData({button: true})
    api.post(link, {
        Id: btnIsUpdate ? data.Id : 0,
        Price: data.Price,
        StationSectionId: selectedSection.value,

        InvoiceDate: invoiceDate,
        InvoiceNumber: data.InvoiceNumber,
        InvoiceVolume: data.InvoiceVolume,
        InvoiceDensity: data.InvoiceDensity,
        InvoiceTemperature: data.InvoiceTemperature,
        InvoiceMass: data.InvoiceMass,

        StartLevel: data.StartLevel,
        StartVolume: data.StartVolume,
        StartDensity: data.StartDensity,
        StartTemperature: data.StartTemperature,
        StartDateTime: startDateTime,

        FactVolume: data.DeltaVolume,
        FactKg: data.DeltaMass,
        FactLitre: data.DeltaLevel,
        FactDensity: data.DeltaDensity,
        FactTemperature: data.DeltaTemperature,

        EndLevel: data.EndLevel,
        EndVolume: data.EndVolume,
        EndDensity: data.EndDensity,
        EndTemperature: data.EndTemperature,
        EndDateTime: endDateTime,

        TankTruckVolume: data.TankTruckVolume,
        TankTruckDensity: data.TankTruckDensity
    })
    .then(function (response) {
        console.log("response create pourings")
        
        displayToast(response.data.message, response.data.isSuccess)
        pourings()
        setLoadingData({button: false})
        setModalShow(false)
        reset({
            "Price":0,
            "InvoiceNumber": 0,
            "InvoiceVolume": 0,
            "InvoiceDensity": 0,
            "InvoiceTemperature": 0,
            "InvoiceMass": 0,

            "StartLevel": 0,
            "StartVolume": 0,
            "StartDensity": 0,
            "StartTemperature": 0,

            "FactVolume": 0,
            "FactKg": 0,
            "FactLitre": 0,
            "FactVolume": 0,
            "FactTemperature": 0,

            "EndLevel": 0,
            "EndVolume": 0,
            "EndDensity": 0,
            "EndTemperature": 0,

            "TankTruckVolume": 0,
            "TankTruckDensity": 0
        })
    })
    .catch(function (error) {
        console.log(error);
        displayToast(error.response.data.errors[0].errorMessage, error.response.data.isSuccess)
        setLoadingData({button:false})
    });
  }

  const deletePouring = id => {
    api.post('/api/pourings/details/delete', {
        Id: id,
        withCredentials:true
    })
    .then(function (response) {
        console.log("response create pourings")
        
        displayToast(response.data.message, response.data.isSuccess)
        pourings()
    })
    .catch(function (error) {
        console.log(error);
        setLoadingData({button:false})
    });
  }

  useEffect(() => {
    
    methods.setLocale(CurrentSelectLanguage);
    setIsLoading(true)
    pourings()
    getCompanies()
  }, []);

  const columns = useMemo(
    () => [
        {
            accessorFn: (row) => row.sectionName,
            accessorKey: 'sectionName',
            header: translated.Section,
        },
        {
            accessorFn: (row) => translated.FuelIntake,
            accessorKey: 'operation',
            header: translated.Operation,
        },
        {
            accessorFn: (row) => row.price,
            accessorKey: 'price',
            header: translated.PricePerLiter,
        },
        {
            accessorFn: (row) => row.factVolume,
            accessorKey: 'factVolume',
            header: translated.ActualArrival,
        },
        {
            accessorFn: (row) => row.invoiceVolume,
            accessorKey: 'factInvoice',
            header: translated.ArrivalInvoice,
        },
        {
            accessorFn: (row) => moment(row.dateTimeOperation).format("DD.MM.YYYY HH:mm"),
            accessorKey: 'dateOperation',
            header: translated.OperationDate,
        },
        {
            accessorFn: (row) => moment(row.dateTimeCorrection).format("DD.MM.YYYY HH:mm"),
            accessorKey: 'dateOperationUpdate',
            header: translated.ChangeDate,
        },
        {
            accessorFn: (row) => row.userName,
            accessorKey: 'userName',
            header: translated.User,
        },
        {
            accessorFn: (row) => row.arrivalReady === 1 ? translated.Yes : translated.No ,
            accessorKey: 'full',
            header: translated.FullAdmission,
        },
        {
            accessorFn: (row) => row.operationLocation == 1 ? translated.Controller: translated.benzamat ,
            accessorKey: 'operationLocations',
            header: translated.EnterData,
        },
    ],
    [],
  );

  return (
    isAuth ?
    <>
    {
        isLoading
        ? <Spinner animation="border" variant="info" />
        :
        <>

        <div className="d-sm-flex align-items-center justify-content-between">
                <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
                    {translated.FuelIntakeTitle}
                    <span className="text-secondary benzamat-font-bold font-size-20"> {countGsm}</span>
                </h2>
                {
                    permission.create || permission.fullAccess
                    ?
                    <div className="page-title-right">
                        <Button varian="primary" onClick={() => handleShow(true)}>
                            <i className="bx bx-plus font-size-16 align-middle me-2"></i> 
                            {translated.AddIntake}
                        </Button>
                    </div>
                    : null
                }
                   
        </div>

        <div className="row">
            <div className="col-xs-12 col-lg-12 col-md-12 ">
                <div className="mt-4">
                    {
                    permission.read || permission.fullAccess
                    ?
                    <MaterialReactTable 
                        columns={columns} 
                        data={listPourings}
                        localization={MRT_Localization_RU}
                        initialState={{ showColumnFilters: true }}
                        enableRowVirtualization
                        enableTopToolbar={false}
                        enableRowActions
                        renderRowActionMenuItems={({ row  }) => [
                            
                            <MenuItem key="edit" onClick={() => handleUpdate(row.original.id)}>
                                {translated.Edit}
                            </MenuItem>,

                            <MenuItem key="delete" onClick={() => deletePouring(row.original.id)}>
                                {translated.Delete}
                            </MenuItem>,
                        ]}
                        muiTablePaperProps={{
                        elevation: 1,
                        }}
                        muiTableHeadCellProps={{
                        //simple styling with the `sx` prop, works just like a style prop in this example
                        sx: {
                            fontWeight: 'bold',
                            color:"#000",
                            backgroundColor:'#F3F3F3',
                            fontFamily: 'BenzamatRegular',
                        },
                        }}
                        muiTableBodyCellProps={{
                        sx: {
                            color:"#000",
                            fontFamily: 'BenzamatRegular',
                        },
                        }}
                    />
                    :
                    <Alert variant="danger">
                        {translated.PermissionDenied}
                    </Alert>
                    }
                </div>
            </div>
        </div>
        
        <Modal
            size="lg"
            show={modalShow}
            onHide={() => setModalShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            dialogClassName="modal-90w"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg" className="text-black benzamat-font-bold font-size-22">
               {translated.FuelInflow}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-xs-12 col-lg-4 col-md-4">
                            <Form.Group className="mb-2">
                                <Form.Label className="text-black">{translated.Company} <sup className="text-danger">*</sup></Form.Label>
                                    <Controller
                                        name="Company"                                
                                        control={control}
                                        render={({ field:{value} }) => (
                                        <Select
                                            options={listCompanies}                   
                                            isSearchable 
                                            value={value}                 
                                            placeholder={translatedForm.SelectFromList}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                getStations(item.id)
                                                setSelectedCompany({label:item.label, value:item.value})
                                                setValue("Company", {label:item.label, value:item.value})
                                            }}
                                        />
                                        )}
                                    />
                                {errors.Company && 
                                    <span className="text-danger font-size-13">{errors.Company.message}</span>
                                }
                            </Form.Group>
                        </div>

                        <div className="col-xs-12 col-lg-4 col-md-4">
                            <Form.Group className="mb-2">
                                <Form.Label className="text-black">{translated.Station} <sup className="text-danger">*</sup></Form.Label>
                                    <Controller
                                        name="Station"                                
                                        control={control}
                                        render={({ field:{value} }) => (
                                        <Select
                                            options={listStation}                   
                                            isSearchable
                                            value={value}             
                                            placeholder={translatedForm.SelectFromList}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                console.log(item)
                                                getSections(item.id)
                                                setSelectedStation({label:item.label, value:item.value})
                                                setValue("Station", {label:item.label, value:item.value})
                                            }}
                                        />
                                        )}
                                    />
                                {errors.Station && 
                                    <span className="text-danger font-size-13">{errors.Station.message}</span>
                                }
                            </Form.Group>
                        </div>

                        <div className="col-xs-12 col-lg-4 col-md-4">
                        <Form.Group className="mb-2">
                            <Form.Label className="text-black">{translated.Section} <sup className="text-danger">*</sup></Form.Label>
                                <Controller
                                    name="Section"                                
                                    control={control}
                                    render={({ field:{value} }) => (
                                    <Select                     
                                        isSearchable
                                        value={value}
                                        options={listSections}                        
                                        placeholder={translatedForm.SelectFromList}
                                        classNamePrefix="select"
                                        onChange={(item) => {
                                            setSelectedSection({label:item.label, value:item.value})
                                            setValue("Section", {label:item.label, value:item.value})
                                            setSectionIsSelected(true)
                                            getSectionData(item.value)
                                        }}
                                    />
                                    )}
                                />
                            {errors.Section && 
                                <span className="text-danger font-size-13">{errors.Section.message}</span>
                            }
                        </Form.Group>
                        </div>

                        {
                            sectionIsSelected
                            ?
                            <>
                                <div className="col-xs-12 col-lg-3 col-md-3 mt-3">

                                    <input type="hidden" {...register("Id")} />
                                    <span className="text-black benzamat-font-bold font-size-18">
                                        {translated.InvoiceNumber}
                                    </span>
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">{translated.Number} <sup className="text-danger">*</sup></Form.Label>
                                        <Form.Control 
                                            {...register("InvoiceNumber", {
                                            maxLength : {
                                                value: 100,
                                                message: translatedForm.MaxLenghtString(100)
                                            },
                                            required: translatedForm.Required,
                                            })}
                                            type="text" maxLength="100" isInvalid={errors.InvoiceNumber}  placeholder={translatedForm.EnterSingleValue} className="form-control"  />
                                        {errors.InvoiceNumber && 
                                            <span className="text-danger font-size-13">{errors.InvoiceNumber.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Volume} <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="InvoiceVolume"                                
                                            control={control}
                                            rules={{required:translatedForm.Required}}
                                            render={({ field:{value} }) => (
                                            <CurrencyInput
                                                placeholder={translatedForm.EnterSingleValue}
                                                defaultValue={0}
                                                value={value}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("InvoiceVolume", value)
                                                    let volume = getValues("InvoiceDensity")

                                                    if(volume === undefined) {
                                                        setValue("InvoiceDensity", 0)
                                                        setValue("InvoiceMass", parseFloat(value) * parseFloat(0) / 1000)
                                                    }

                                                    if(value === undefined) {
                                                        setValue("InvoiceVolume", 0)
                                                        setValue("InvoiceMass", parseFloat(0) * parseFloat(volume) / 1000)
                                                    }

                                                    if(volume != undefined && value != undefined) {
                                                        setValue("InvoiceMass", parseFloat(value) * parseFloat(volume) / 1000)
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.InvoiceVolume && 
                                            <span className="text-danger font-size-13">{errors.InvoiceVolume.message}</span>
                                        } 
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Density} <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="InvoiceDensity"                                
                                            control={control}
                                            rules={{required:translatedForm.Required}}
                                            render={({ field:{value} }) => (
                                            <CurrencyInput
                                                placeholder={translatedForm.EnterSingleValue}
                                                value={value}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("InvoiceDensity", value)
                                                    let volume = getValues("InvoiceVolume")
                                                    
                                                    if(volume === undefined) {
                                                        setValue("InvoiceVolume", 0)
                                                        setValue("InvoiceMass", parseFloat(value) * parseFloat(0) / 1000)
                                                    }

                                                    if(value === undefined) {
                                                        setValue("InvoiceDensity", 0)
                                                        setValue("InvoiceMass", parseFloat(0) * parseFloat(volume) / 1000)
                                                    }

                                                    if(volume != undefined && value != undefined) {
                                                        setValue("InvoiceMass", parseFloat(value) * parseFloat(volume) / 1000)
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.InvoiceDensity && 
                                            <span className="text-danger font-size-13">{errors.InvoiceDensity.message}</span>
                                        } 
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Temperature} <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="InvoiceTemperature"                                
                                            control={control}
                                            rules={{required:translatedForm.Required}}
                                            render={({ field:{value} }) => (
                                            <CurrencyInput
                                                placeholder={translatedForm.EnterSingleValue}
                                                //defaultValue={0}
                                                decimalsLimit={2}
                                                value={value}
                                                className="form-control"
                                                onValueChange={(value) => setValue("InvoiceTemperature", value)}
                                            />
                                            )}
                                        />
                                        {errors.InvoiceTemperature && 
                                            <span className="text-danger font-size-13">{errors.InvoiceTemperature.message}</span>
                                        } 
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Mass}</Form.Label>
                                        <Form.Control 
                                            {...register("InvoiceMass", {
                                            maxLength : {
                                                value: 100,
                                                message: translatedForm.MaxLenghtString(100)
                                            },
                                            })}
                                            type="text" maxLength="100" isInvalid={errors.InvoiceMass} disabled className="form-control"  />
                                        {errors.InvoiceMass && 
                                            <span className="text-danger font-size-13">{errors.InvoiceMass.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Date} <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="InvoiceDate"                                
                                            control={control}
                                            //rules={{required:translatedForm.Required}}
                                            render={({ field:{value} }) => (
                                            <Flatpickr
                                                className='form-control'
                                                options={{ 
                                                    time_24hr: true,  
                                                    enableTime: true,
                                                    dateFormat: "d.m.Y H:i",
                                                    locale: Russian
                                                }}
                                                value={invoiceDate}
                                                onChange={([date]) => {
                                                    setInvoiceDate(date)
                                                    setValue("InvoiceDate", date)
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.InvoiceDate && 
                                            <span className="text-danger font-size-13">{errors.InvoiceDate.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="col-xs-12 col-lg-3 col-md-3 mt-3">
                                <span className="text-black benzamat-font-bold font-size-18">{translated.StartDrain}</span>
                                    
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">{translated.Level} <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="StartLevel"                                
                                            control={control}
                                            rules={{required:translatedForm.Required}}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder={translatedForm.EnterSingleValue}
                                                value={value}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("StartLevel", value)
                                                    let end = getValues("EndLevel")

                                                    if(value === undefined) {
                                                        setValue("StartLevel", 0)
                                                        setValue("DeltaLevel", parseFloat(end) - parseFloat(0))
                                                    }

                                                    if(end === undefined) {
                                                        setValue("EndLevel", 0)
                                                        setValue("DeltaLevel", parseFloat(0) - parseFloat(value))
                                                    }

                                                    if(end != undefined && value != undefined) {
                                                        setValue("DeltaLevel", parseFloat(end) - parseFloat(value))
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.StartLevel && 
                                            <span className="text-danger font-size-13">{errors.StartLevel.message}</span>
                                        } 
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Volume} <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="StartVolume"                                
                                            control={control}
                                            rules={{required:translatedForm.Required}}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder={translatedForm.EnterSingleValue}
                                                value={value}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("StartVolume", value)
                                                    let end = getValues("EndVolume")
                                                    
                                                    if(value === undefined) {
                                                        setValue("StartLevel", 0)
                                                        setValue("DeltaVolume", parseFloat(end) - parseFloat(0))
                                                    }

                                                    if(end === undefined) {
                                                        setValue("EndLevel", 0)
                                                        setValue("DeltaVolume", parseFloat(0) - parseFloat(value))
                                                    }

                                                    if(end != undefined && value != undefined) {
                                                        setValue("DeltaVolume", parseFloat(end) - parseFloat(value))
                                                    }

                                                    if(value != undefined) {
                                                        let density = getValues("StartDensity")
                                                        setValue("StartMass", (parseFloat(density) * parseFloat(value)) / 1000)
                                                        let endMass = getValues("EndMass")
                                                        let startMass = getValues("StartMass")
                                                        setValue("DeltaMass", parseFloat(endMass) - parseFloat(startMass))
                                                    }

                                                }}
                                            />
                                            )}
                                        />
                                        {errors.StartVolume && 
                                            <span className="text-danger font-size-13">{errors.StartVolume.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Density} <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="StartDensity"                                
                                            control={control}
                                            rules={{required:translatedForm.Required}}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder={translatedForm.EnterSingleValue}
                                                value={value}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("StartDensity", value)
                                                    let end = getValues("EndDensity")

                                                    if(value === undefined) {
                                                        setValue("StartDensity", 0)
                                                        setValue("DeltaDensity", parseFloat(end) - parseFloat(0))
                                                    }

                                                    if(end === undefined) {
                                                        setValue("EndDensity", 0)
                                                        setValue("DeltaDensity", parseFloat(0) - parseFloat(value))
                                                    }

                                                    if(end != undefined && value != undefined) {
                                                        setValue("DeltaDensity", parseFloat(end) - parseFloat(value))
                                                    }

                                                    if(value != undefined) {
                                                        let volume = getValues("StartVolume")
                                                        setValue("StartMass", (parseFloat(volume) * parseFloat(value)) / 1000)
                                                        let endMass = getValues("EndMass")
                                                        let startMass = getValues("StartMass")
                                                        setValue("DeltaMass", parseFloat(endMass) - parseFloat(startMass))
                                                    }
                                                    
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.StartDensity && 
                                            <span className="text-danger font-size-13">{errors.StartDensity.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Temperature} <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="StartTemperature"                                
                                            control={control}
                                            rules={{required:translatedForm.Required}}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder={translatedForm.EnterSingleValue}
                                                value={value}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("StartTemperature", value)
                                                    let end = getValues("EndTemperature")

                                                    if(value === undefined) {
                                                        setValue("StartTemperature", 0)
                                                        setValue("DeltaTemperature", parseFloat(end) - parseFloat(0))
                                                    }

                                                    if(end === undefined) {
                                                        setValue("EndTemperature", 0)
                                                        setValue("DeltaTemperature", parseFloat(0) - parseFloat(value))
                                                    }

                                                    if(end != undefined && value != undefined) {
                                                        setValue("DeltaTemperature", parseFloat(end) - parseFloat(value))
                                                    }

                                                }}
                                            />
                                            )}
                                        />
                                        {errors.StartTemperature && 
                                            <span className="text-danger font-size-13">{errors.StartTemperature.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Mass}</Form.Label>
                                        <Form.Control 
                                            {...register("StartMass", {
                                            maxLength : {
                                                value: 800,
                                                message: translatedForm.MaxLenghtString(800)
                                            },
                                            })}
                                            type="text" value={startMass} maxLength="800" isInvalid={errors.StartMass} disabled className="form-control"  />
                                        {errors.StartMass && 
                                        <span className="text-danger font-size-13">{errors.StartMass.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Date} <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="StartDateTime"                                
                                            control={control}
                                            //rules={{required:translatedForm.Required}}
                                            render={({ field:{value} }) => (
                                            <Flatpickr
                                                className='form-control'
                                                options={{ 
                                                    time_24hr: true,  
                                                    enableTime: true,
                                                    dateFormat: "d.m.Y H:i",
                                                    locale: Russian
                                                }}
                                                value={startDateTime}
                                                onChange={([date]) => {
                                                    setStartDateTime(date)
                                                    setValue("StartDateTime", date)
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.StartDateTime && 
                                            <span className="text-danger font-size-13">{errors.StartDateTime.message}</span>
                                        }
                                    </Form.Group>

                                    {
                                        issetLevelMeter
                                        ?
                                        <Form.Group>
                                            <button type="button" onClick={() => getLevelMeterData(true)}  disabled={loadingLevelMeterBtn.start} style={{width:"100%"}} className="btn btn-primary">
                                                {loadingLevelMeterBtn.start && (
                                                    <Spinner animation="border" variant="light" 
                                                        as="span"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                )}
                                                {!loadingLevelMeterBtn.start && <span>{translated.LevelMeter}</span>}
                                            </button>
                                        </Form.Group> 
                                        : null
                                    }
                                </div>

                                <div className="col-xs-12 col-lg-3 col-md-3 mt-3">
                                <span className="text-black benzamat-font-bold font-size-18">{translated.Variation}</span>
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">{translated.Level}</Form.Label>
                                        <Form.Control 
                                            {...register("DeltaLevel", {
                                            maxLength : {
                                                value: 800,
                                                message: translatedForm.MaxLenghtString(800)
                                            },
                                            })}
                                            type="text" value={deltaLevel} maxLength="800" disabled isInvalid={errors.DeltaLevel} className="form-control"  />
                                        {errors.DeltaLevel && 
                                        <span className="text-danger font-size-13">{errors.DeltaLevel.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Volume}</Form.Label>
                                        <Form.Control 
                                            {...register("DeltaVolume", {
                                            maxLength : {
                                                value: 800,
                                                message: translatedForm.MaxLenghtString(800)
                                            },
                                            })}
                                            type="text" value={deltaVolume} maxLength="800" disabled isInvalid={errors.Description} className="form-control"  />
                                        {errors.Description && 
                                        <span className="text-danger font-size-13">{errors.Description.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Density}</Form.Label>
                                        <Form.Control 
                                            {...register("DeltaDensity", {
                                            maxLength : {
                                                value: 800,
                                                message: translatedForm.MaxLenghtString(800)
                                            },
                                            })}
                                            type="text" value={deltaDensity} maxLength="800" disabled isInvalid={errors.DeltaDensity} className="form-control"  />
                                        {errors.DeltaDensity && 
                                        <span className="text-danger font-size-13">{errors.DeltaDensity.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Temperature}</Form.Label>
                                        <Form.Control 
                                            {...register("DeltaTemperature", {
                                            maxLength : {
                                                value: 800,
                                                message: translatedForm.MaxLenghtString(800)
                                            },
                                            })}
                                            type="text" value={deltaTemperatur} maxLength="800" disabled  isInvalid={errors.DeltaTemperature} className="form-control"  />
                                        {errors.DeltaTemperature && 
                                        <span className="text-danger font-size-13">{errors.DeltaTemperature.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Mass}</Form.Label>
                                        <Form.Control 
                                            {...register("DeltaMass", {
                                            maxLength : {
                                                value: 800,
                                                message: translatedForm.MaxLenghtString(800)
                                            },
                                            })}
                                            type="text" value={deltaMass} maxLength="800" isInvalid={errors.Description} disabled className="form-control"  />
                                        {errors.Description && 
                                        <span className="text-danger font-size-13">{errors.Description.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="col-xs-12 col-lg-3 col-md-3 mt-3">
                                <span className="text-black benzamat-font-bold font-size-18">{translated.EndDrain}</span>
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">{translated.Level} <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="EndLevel"                                
                                            control={control}
                                            rules={{required:translatedForm.Required}}
                                            render={({ field:{value} }) => (
                                            <CurrencyInput
                                                placeholder={translatedForm.EnterSingleValue}
                                                value={value}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("EndLevel", value)
                                                    let start = getValues("StartLevel")
                                                    
                                                    if(start === undefined) {
                                                        setValue("StartLevel", 0)
                                                        setValue("DeltaLevel", parseFloat(value) - parseFloat(0))
                                                    }

                                                    if(value === undefined) {
                                                        setValue("EndLevel", 0)
                                                        setValue("DeltaLevel", parseFloat(0) - parseFloat(start))
                                                    }

                                                    if(start != undefined && value != undefined) {
                                                        setValue("DeltaLevel", parseFloat(value) - parseFloat(start))
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.EndLevel && 
                                            <span className="text-danger font-size-13">{errors.EndLevel.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Volume} <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="EndVolume"                                
                                            control={control}
                                            rules={{required:translatedForm.Required}}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder={translatedForm.EnterSingleValue}
                                                value={value}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("EndVolume", value)
                                                    let start = getValues("StartVolume")
                                                    
                                                    if(start === undefined) {
                                                        setValue("StartLevel", 0)
                                                        setValue("DeltaVolume", parseFloat(value) - parseFloat(0))
                                                    }

                                                    if(value === undefined) {
                                                        setValue("EndLevel", 0)
                                                        setValue("DeltaVolume", parseFloat(0) - parseFloat(start))
                                                    }

                                                    if(start != undefined && value != undefined) {
                                                        setValue("DeltaVolume", parseFloat(value) - parseFloat(start))
                                                    }

                                                    if(value != undefined) {
                                                        let volume = getValues("EndDensity")
                                                        setValue("EndMass", (parseFloat(volume) * parseFloat(value)) / 1000)
                                                        let endMass = getValues("EndMass")
                                                        let startMass = getValues("StartMass")
                                                        setValue("DeltaMass", parseFloat(endMass) - parseFloat(startMass))
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.EndVolume && 
                                            <span className="text-danger font-size-13">{errors.EndVolume.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Density} <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="EndDensity"                                
                                            control={control}
                                            rules={{required:translatedForm.Required}}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder={translatedForm.EnterSingleValue}
                                                value={value}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("EndDensity", value)

                                                    let start = getValues("StartDensity")
                                                    
                                                    if(start === undefined) {
                                                        setValue("StartDensity", 0)
                                                        setValue("DeltaDensity", parseFloat(value) - parseFloat(0))
                                                    }

                                                    if(value === undefined) {
                                                        setValue("EndDensity", 0)
                                                        setValue("DeltaDensity", parseFloat(0) - parseFloat(start))
                                                    }

                                                    if(start != undefined && value != undefined) {
                                                        setValue("DeltaDensity", parseFloat(value) - parseFloat(start))
                                                    }

                                                    if(value != undefined) {
                                                        let volume = getValues("EndVolume")
                                                        setValue("EndMass", (parseFloat(volume) * parseFloat(value)) / 1000)
                                                        let endMass = getValues("EndMass")
                                                        let startMass = getValues("StartMass")
                                                        setValue("DeltaMass", parseFloat(endMass) - parseFloat(startMass))
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.EndDensity && 
                                            <span className="text-danger font-size-13">{errors.EndDensity.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Temperature} <sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="EndTemperature"                                
                                            control={control}
                                            rules={{required:translatedForm.Required}}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder={translatedForm.EnterSingleValue}
                                                value={value}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => {
                                                    setValue("EndTemperature", value)
                                                    let start = getValues("StartTemperature")
                                                    
                                                    if(start === undefined) {
                                                        setValue("StartTemperature", 0)
                                                        setValue("DeltaTemperature", parseFloat(value) - parseFloat(0))
                                                    }

                                                    if(value === undefined) {
                                                        setValue("EndTemperature", 0)
                                                        setValue("DeltaTemperature", parseFloat(0) - parseFloat(start))
                                                    }

                                                    if(start != undefined && value != undefined) {
                                                        setValue("DeltaTemperature", parseFloat(value) - parseFloat(start))
                                                    }
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.EndTemperature && 
                                            <span className="text-danger font-size-13">{errors.EndTemperature.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Mass}</Form.Label>
                                        <Form.Control 
                                            {...register("EndMass", {
                                            maxLength : {
                                                value: 800,
                                                message: translatedForm.MaxLenghtString(800)
                                            },
                                            })}
                                            type="text" maxLength="800" isInvalid={errors.EndMass} disabled className="form-control"  />
                                        {errors.EndMass && 
                                            <span className="text-danger font-size-13">{errors.EndMass.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.Date}<sup className="text-danger">*</sup></Form.Label>
                                        <Controller
                                            name="EndDateTime"                                
                                            control={control}
                                            //rules={{required:translatedForm.Required}}
                                            render={({ field }) => (
                                            <Flatpickr
                                                className='form-control'
                                                options={{ 
                                                    time_24hr: true,  
                                                    enableTime: true,
                                                    dateFormat: "d.m.Y H:i",
                                                    locale: Russian
                                                }}
                                                value={endDateTime}
                                                onChange={([date]) => {
                                                    setEndDateTime(date)
                                                    setValue("EndDateTime", date)
                                                }}
                                            />
                                            )}
                                        />
                                        {errors.EndDateTime && 
                                            <span className="text-danger font-size-13">{errors.EndDateTime.message}</span>
                                        }
                                    </Form.Group>

                                    {
                                        issetLevelMeter
                                        ?
                                        <Form.Group>
                                            <button type="button" onClick={() => getLevelMeterData(false)}  disabled={loadingLevelMeterBtn.end} style={{width:"100%"}} className="btn btn-primary">
                                                {loadingLevelMeterBtn.end && (
                                                    <Spinner animation="border" variant="light" 
                                                        as="span"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                )}
                                                {!loadingLevelMeterBtn.end && <span>{translated.LevelMeter}</span>}
                                            </button>
                                        </Form.Group>
                                        : null
                                    }

                                </div>

                                <div className="col-xs-12 col-lg-6 col-md-6 mt-3">
                                <span className="text-black benzamat-font-bold font-size-18">{translated.FuelTankTruck}</span>
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">{translated.MeasuredVolume}</Form.Label>
                                        <Controller
                                            name="TankTruckVolume"                                
                                            control={control}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder={translatedForm.EnterSingleValue}
                                                value={value}
                                                defaultValue={0}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => setValue("TankTruckVolume", value)}
                                            />
                                            )}
                                        />
                                        {errors.TankTruckVolume && 
                                            <span className="text-danger font-size-13">{errors.TankTruckVolume.message}</span>
                                        }
                                    </Form.Group>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-black">{translated.MeasuredDensity}</Form.Label>
                                        <Controller
                                            name="TankTruckDensity"                                
                                            control={control}
                                            render={({ field: {value} }) => (
                                            <CurrencyInput
                                                placeholder={translatedForm.EnterSingleValue}
                                                defaultValue={0}
                                                value={value}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => setValue("TankTruckDensity", value)}
                                            />
                                            )}
                                        />
                                        {errors.TankTruckDensity && 
                                            <span className="text-danger font-size-13">{errors.TankTruckDensity.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="col-xs-12 col-lg-6 col-md-6 mt-3">
                                <span className="text-black benzamat-font-bold font-size-18">{translated.FuelCost}</span>
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">{translated.PricePerLiter}</Form.Label>
                                        <Controller
                                            name="Price"                                
                                            control={control}
                                            render={({ field:{value} }) => (
                                            <CurrencyInput
                                                placeholder={translatedForm.EnterSingleValue}
                                                defaultValue={0}
                                                value={value}
                                                decimalsLimit={2}
                                                className="form-control"
                                                onValueChange={(value) => setValue("Price", value)}
                                            />
                                            )}
                                        />
                                        {errors.Price && 
                                            <span className="text-danger font-size-13">{errors.Price.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="col-xs-12 col-lg-12 col-md-12 mt-3">
                                    <span className="text-black benzamat-font-bold font-size-18">{translated.LevelGaging}</span>
                                </div>

                                <div className="col-xs-12 col-lg-4 col-md-4 ">
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">{translated.StartDrain}</Form.Label>
                                        <Form.Control 
                                            {...register("Description", {
                                            maxLength : {
                                                value: 800,
                                                message: translatedForm.MaxLenghtString(800)
                                            },
                                            })}
                                            type="text" maxLength="800" isInvalid={errors.Description} placeholder="0" className="form-control"  />
                                        {errors.Description && 
                                        <span className="text-danger font-size-13">{errors.Description.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="col-xs-12 col-lg-4 col-md-4">
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">{translated.EndDrain}</Form.Label>
                                        <Form.Control 
                                            {...register("Description", {
                                            maxLength : {
                                                value: 800,
                                                message: translatedForm.MaxLenghtString(800)
                                            },
                                            })}
                                            type="text" maxLength="800" isInvalid={errors.Description} placeholder="0" className="form-control"  />
                                        {errors.Description && 
                                        <span className="text-danger font-size-13">{errors.Description.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="col-xs-12 col-lg-4 col-md-4">
                                    <Form.Group className="mb-2 mt-3">
                                        <Form.Label className="text-black">{translated.Variation}</Form.Label>
                                        <Form.Control 
                                            {...register("Description", {
                                            maxLength : {
                                                value: 800,
                                                message: translatedForm.MaxLenghtString(800)
                                            },
                                            })}
                                            type="text" maxLength="800" isInvalid={errors.Description} placeholder="0" className="form-control"  />
                                        {errors.Description && 
                                        <span className="text-danger font-size-13">{errors.Description.message}</span>
                                        }
                                    </Form.Group>
                                </div>

                                <div className="form-group mt-3">
                                    <button type="submit" disabled={loadingData.button} className="btn btn-success">
                                        {loadingData.button && (
                                            <Spinner animation="border" variant="light" 
                                                as="span"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}
                                        {loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                                        {!loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                                    </button>

                                </div>
                            </>
                            : 
                            <div className="col-xs-12 col-lg-12 col-md-12">
                                <Alert className="mt-3" variant='danger'>
                                    {translated.MessageFuelInflow}
                                </Alert>
                            </div>
                        }
                    </div>
                </Form>
            </Modal.Body>
        </Modal>   
        </>
    }
    </>
    :
    <Redirect to='/login'/>
  );

}
