import React, { useState, useEffect, useContext, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Form, Spinner} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import './../../boxicons.css';
import CurrencyInput from 'react-currency-input-field';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import api from '../../utils/axiosInterceptions';
import InputMask from 'react-input-mask';

export const FormAddTransport = ({translated, translatedForm, isChild, stationData, setListTransport}) => {
    
  const [listCompanies, setListCompanies] = useState([])
  const [selectedTypeLimit, setSelectedTypeLimit] = useState(0)
  const [listTypeLimit, setListTypeLimit] = useState([])
  const [rfidBtnLoad, setRfidBtnLoad] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState({
    label:translatedForm.SelectFromList,
    value:0
  })
  const [selectedBenzaRfidType, setSelectedBenzaRfidType] = useState({
    label:"Benza Mifare",
    value:true
  })
  const [limitDay, setLimitDay] = useState(0)
  const [limitMonth, setLimitMonth] = useState(0)
  const [loadingData, setLoadingData] = useState({
    button:false,
    grid:false
  })
  const [defaultItem, setDefaultItem] = useState({
    stations:[],
  })
  const [listTypeAuth, setListTypeAuth] = useState({
    label:'',
    value:0
  })
  const [selectedAuth, setSelectedAuth] = useState({
    label:translatedForm.SelectFromList,
    value:0
  })
  const [selectedTypeAuth, setSelectedTypeAuth] = useState({
    rfid:false,
    password:false,
    rfid_password:false,
  })
  const [listControllers, setListControllers] = useState([])
  const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
  const listBenzaRfidType = [
    {label:"Benza Mifare", value:true},
    {label:"Benza EM Marine", value:false}
  ]
  const formatChars = {
    '*': '[A-Fa-f0-9]'
  }
  const getCompanies = () => {
    api.get('/api/company/all', {
        withCredentials:true
    })
    .then(function (response) {
        const addCompany = response.data.listDatas.map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }))
        setListCompanies(addCompany)
    })
    .catch(function (error) {
        console.log(error);
        setLoadingData({grid:false})
    });
  }

  const getTypeAuth = () => {
    api.get('/api/type-auths/all', {
      withCredentials:true
    })
    .then(function (response) {
      const add = response.data.listDatas.filter((i) => i.id == 1).map(item => ({
          ...item,
          value: item.id,
          label: item.name
      }))
      setListTypeAuth(add)

    })
    .catch(function (error) {
        console.log(error);
    });
  }
  
  const getControllers = id => {
    api.get('/api/company/details/controllers', {
      headers:{
        "content-type" : "application/json",
        "companyId":id
      },
      params:{
        companyId: id,
      },
      withCredentials:true
    })
    .then(function (response) {

      if(response.data.listDatas.length == 0)
      {
        displayToast("Контроллеры еще не добавлены к компании", false)
      }
      else
      {
        const add = response.data.listDatas.map(item => ({
          ...item,
          value: item.id,
          label: item.name
        }))
        setListControllers(add)
        displayToast("Список контроллеров компании успешно получен", response.data.isSuccess)
      }
    })
    .catch(function (error) {
        console.log(error);
        setLoadingData({grid:false})
    });
  }

  const getEnumTypeLimits = () => {
    api.get("/api/enum-type-limits/all", {
    })
    .then(function (response) {
        const addTypeLimit = response.data.listDatas.filter((i) => i.id != 1 && i.id != 2).map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }))
        setListTypeLimit(addTypeLimit)
    })
    .catch(function (error) {
        console.log(error);
    });    
  }

  useEffect(() => {
    setSelectedBenzaRfidType({
      label:listBenzaRfidType[0].label,
      value:listBenzaRfidType[0].value
    })
    getCompanies()
    getTypeAuth()
    getEnumTypeLimits()
  }, [])

  const onSubmit = data => {
    data.LimitDay = limitDay
    data.LimitMonth = limitMonth
    data.SingleStationId = isChild ? stationData.id : 0
    setLoadingData({button:true})

    api.post('/api/transport/create', {
      Name: data.Name,
      Number:data.Number,
      CompanyId: selectedCompany.value,
      Controllers: data.Controllers,
      SingleStationId: data.SingleStationId,
      Model: data.Model,
      Contract: data.Contract,
      Password: data.Password,
      NumberRfid: data.NumberRfid,
      LimitDay: data.LimitDay,
      LimitMonth: data.LimitMonth,
      LimitAbsolute: data.AbsolutLimit,
      EnumTypeAuthId: selectedAuth.value,
      EnumTypeLimitId: selectedTypeLimit.value,
      RfidIsMifare: selectedBenzaRfidType.value,
      ServiceDate: data.ServiceDate != null ? data.ServiceDate.toISOString() : null
    })
    .then(function (response) {
      
      displayToast(response.data.message, response.data.isSuccess)
      setLoadingData({button:false})
      setListTransport(response.data.listDatas)
      reset({
        Number:'',
        Model:'',
        NumberRfid:'',
        Contract:'',
        Password:'',
        LimitDay:'',
        LimitMonth:''
      })
    })
    .catch(function (error) {
      console.log(error)
      error.response.data.errors.map((item) => {
        displayToast(item.errorMessage, error.response.data.isSuccess)
      })
      setLoadingData({button:false})
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
  
    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }   
  }

  const getLastCard = () => {
    setRfidBtnLoad(true)
    api.get("/api/users/details/cards/last", {
      withCredentials:true
    })
    .then(function (response) {
      
      setValue("NumberRfid", response.data.dataResult)
      setRfidBtnLoad(false)
    })
    .catch(function (error) {
      console.log(error);
      setRfidBtnLoad(false)
    }); 
  }

  return (
      <Form onSubmit={handleSubmit(onSubmit)}>

      <Form.Group className="mb-3">

      <Form.Group className="mb-3">
        <Form.Label className="text-black">{translated.Name} <sup className="text-danger">*</sup></Form.Label>
        <Form.Control 
            {...register("Name", {
            maxLength : {
                value: 100,
                message: translatedForm.MaxLenghtString(100)
            },
            minLength : {
                value: 5,
                message: translatedForm.MinLengthString(5)
            },
            required : translatedForm.Required
            })}
            type="text" maxLength="100" isInvalid={errors.Name} 
            placeholder={translatedForm.EnterValue(100)} className="form-control"  />
          {errors.Name && 
            <span className="text-danger font-size-13">{errors.Name.message}</span>
          }
        </Form.Group>

        <Form.Label className="text-black">{translated.Company} <sup className="text-danger">*</sup></Form.Label>        
            <Controller
                name="Companies"                                
                control={control}
                rules={{ required: translatedForm.Required }}
                render={({ field }) => (
                <Select 
                    value={selectedCompany}                             
                    isSearchable
                    options={listCompanies}                        
                    placeholder={translatedForm.SelectFromList}
                    classNamePrefix="select"
                    error={Boolean(errors.Company)}
                    onChange={(item) => {
                      setSelectedCompany({label:item.name, value:item.id})
                      setValue("Companies", selectedCompany)
                      getControllers(item.id)
                    }}
                />
                )}
            />
          {errors.Company && 
              <span className="text-danger font-size-13">{errors.Company.message}</span>
          }   
      </Form.Group>

      <Form.Group className="mb-3">
          <Form.Label className="text-black">{translated.Controllers} <sup className="text-danger">*</sup></Form.Label>        
              <Controller
                  name="Controllers"                                
                  control={control}
                  rules={{ required: translatedForm.Required }}
                  render={({ field }) => (
                  <Select 
                  {...field}                               
                      isSearchable
                      isMulti
                      options={listControllers}                        
                      placeholder={translatedForm.SelectFromList}
                      classNamePrefix="select"
                  />
                )}
              />
          {errors.Controllers && 
              <span className="text-danger font-size-13">{errors.Controllers.message}</span>
          }   
      </Form.Group>

      <Form.Group className="mb-3">
      <Form.Label className="text-black">{translated.TypeAuth} <sup className="text-danger">*</sup></Form.Label>
        <Controller
            name="TypeAuth"                                
            control={control}
            rules={{ required: translatedForm.Required }}
            render={({ field: value }) => (
            <Select
              onChange={(item) => {
                if(item.id == 1) {
                  setSelectedTypeAuth({rfid:true, password:false, rfid_password:false})
                } else if (item.id == 2) {
                  setSelectedTypeAuth({rfid:false, password:true, rfid_password:false})
                } else {
                  setSelectedTypeAuth({rfid:false, password:false, rfid_password:true})
                }
                setSelectedAuth({label: item.label, value:item.value})
                setValue("TypeAuth", selectedAuth)
              }}
              value={selectedAuth}
              options={listTypeAuth}                        
              placeholder={translatedForm.SelectFromList}
              classNamePrefix="select"
            />
          )}
        />
        {errors.TypeAuth && 
            <span className="text-danger font-size-13">{errors.TypeAuth.message}</span>
        }
      </Form.Group>

      {
        selectedTypeAuth.password || selectedTypeAuth.rfid_password
        ?
        <Form.Group className="mb-3 mt-3">
          <Form.Label className="text-black">{translated.Password}</Form.Label>
          <Form.Control 
            {...register("Password", {
              minLength : {
                value: 5,
                message: translatedForm.MinLengthString(5)
              },
              maxLength : {
                value: 10,
                message: translatedForm.MaxLenghtString(10)
              },
            })}
            type="number" maxLength="10" isInvalid={errors.Password}  
            placeholder={translatedForm.EnterValue(10)} className="form-control"  />
            {errors.Password && 
              <span className="text-danger font-size-13">{errors.Password.message}</span>
            }
        </Form.Group>
        : 
        ''
      }

      {
        selectedTypeAuth.rfid || selectedTypeAuth.rfid_password
        ?
        <>

        <Form.Group className="mb-3">
            <Form.Label className="text-black">{translated.BenzaRfidType} </Form.Label>
            <Controller
                name="BenzaRfidType"
                control={control}
                render={({ field: value }) => (
                <Select
                  onChange={(item) => {
                    setSelectedBenzaRfidType({
                      label:item.label,
                      value:item.value
                    })
                  }}
                  value={selectedBenzaRfidType}
                  options={listBenzaRfidType}                        
                  placeholder={translatedForm.SelectFromList}
                  classNamePrefix="select"
                />
                )}
            />
            {errors.BenzaRfidType && 
                <span className="text-danger font-size-13">{errors.BenzaRfidType.message}</span>
            }
          </Form.Group>
        
        {/*<Form.Group className="mb-3 mt-3">
          <Form.Label className="text-black">{translated.NumberRfid}</Form.Label>
          <Form.Control 
            {...register("NumberRfid", {
              maxLength : {
                value: 15,
                message: translatedForm.MaxLenghtString(15)
              },
              minLength : {
                value: 5,
                message: translatedForm.MinLengthString(5)
              },
            })}
            type="text" maxLength="15" isInvalid={errors.NumberRfid} 
            placeholder={translatedForm.EnterValue(15)} className="form-control"  />
            {errors.NumberRfid && 
              <span className="text-danger font-size-13">{errors.NumberRfid.message}</span>
            }
        </Form.Group>*/}

        <Form.Group className="mb-3 mt-3">
          <Form.Label className="text-black">{translated.NumberRfid} <sup className="text-danger">*</sup></Form.Label>
          <Controller
              name="NumberRfid"                                
              control={control}
              rules={{ required: translatedForm.Required }}
              render={({ field: {value} }) => (
                  <InputMask 
                      mask="**************"
                      className="form-control"
                      type="text"
                      formatChars={formatChars}
                      value={value}
                      placeholder={translatedForm.EnterValue(14)}
                      onChange={(item) => {
                        setValue("NumberRfid", item.target.value)
                      }}
                  />
              )}
          />
          {errors.NumberRfid && 
              <span className="text-danger font-size-13">{errors.NumberRfid.message}</span>
          }
        </Form.Group>

        <Button type="submit" onClick={() => getLastCard()} className="mb-2" variant="primary" disabled={rfidBtnLoad}>
          {rfidBtnLoad && (
              <Spinner animation="border" variant="light" 
                  as="span"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: "5px" }}
              />
          )}
          {rfidBtnLoad && <span>{translated.ButtonRfid}</span>}
          {!rfidBtnLoad && <span>{translated.ButtonRfid}</span>}
        </Button>
        </>
        :
        null
      }

      <Form.Group className="mb-3">
      <Form.Label className="text-black">{translated.TypeLimit} <sup className="text-danger">*</sup></Form.Label>
        <Controller
            name="TypeLimit"                                
            control={control}
            rules={{ required: translatedForm.Required}}
            render={({ field: {value} }) => (
            <Select
              onChange={(item) => {
                console.log(item)
                setSelectedTypeLimit(item)
                setValue("TypeLimit", {label:item.label, value:item.value})
              }}
              value={value}
              options={listTypeLimit}                        
              placeholder={translatedForm.SelectFromList}
              classNamePrefix="select"
            />
          )}
        />
        {errors.TypeLimit && 
            <span className="text-danger font-size-13">{errors.TypeLimit.message}</span>
        }
      </Form.Group>

      {
        selectedTypeLimit.id == 5
        ?
        <>
          <Form.Group className="mb-3">
          <Form.Label className="text-black">{translated.LimitMonth}</Form.Label>
        
            <Controller
                name="LimitMonth"                                
                control={control}
                render={({ field }) => (
                <CurrencyInput
                    placeholder={translatedForm.Liters}
                    defaultValue={0}
                    decimalsLimit={2}
                    className="form-control"
                    onValueChange={(value) => setLimitMonth(value)}
                />
                )}
            />
            {errors.LimitMonth && 
                <span className="text-danger font-size-13">{errors.LimitMonth.message}</span>
            }   

          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="text-black">{translated.LimitDay}</Form.Label>
          
              <Controller
                  name="LimitDay"                           
                  control={control}
                  render={({ field }) => (
                  <CurrencyInput
                      placeholder={translatedForm.Liters}
                      defaultValue={0}
                      decimalsLimit={2}
                      className="form-control"
                      onValueChange={(value) => setLimitDay(value)}
                  />
                  )}
              />
              {errors.LimitDay && 
                  <span className="text-danger font-size-13">{errors.LimitDay.message}</span>
              }   

          </Form.Group>
        </>
        : selectedTypeLimit.id == 3
          ? 
          <Form.Group className="mb-3">
          <Form.Label className="text-black">{translated.LimitAbsolute}</Form.Label>
        
            <Controller
                name="AbsolutLimit"                                
                control={control}
                render={({ field: {value} }) => (
                <CurrencyInput
                    placeholder={translatedForm.Liters}
                    defaultValue={0}
                    decimalsLimit={2}
                    value={value}
                    className="form-control"
                    onValueChange={(value) => setValue("AbsolutLimit", value)}
                />
                )}
            />
            {errors.AbsolutLimit && 
                <span className="text-danger font-size-13">{errors.AbsolutLimit.message}</span>
            }   

          </Form.Group>
          : null
      }

      <Form.Group className="mb-2">
          <Form.Label className="text-black">{translated.ServiceDate}</Form.Label>
          <Controller
              name="ServiceDate"                                
              control={control}
              render={({ field:{value} }) => (
              <Flatpickr
                  className='form-control'
                  options={{ 
                      time_24hr: true,  
                      enableTime: true,
                      dateFormat: "d.m.Y H:i",
                      locale: Russian
                  }}
                  value={value}
                  placeholder={translatedForm.SelectFromList}
                  onChange={([date]) => {
                      setValue("ServiceDate", date)
                  }}
              />
              )}
          />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="text-black">{translated.Number}</Form.Label>
          <Form.Control 
          {...register("Number")}
          type="text" maxLength="10" isInvalid={errors.Number}
          placeholder={translatedForm.EnterValue(10)}
          className="form-control"  />
          {errors.Number && 
            <span className="text-danger font-size-13">{errors.Number.message}</span>
          }
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="text-black">{translated.Model}</Form.Label>
        <Form.Control 
          {...register("Model")}
          type="text" maxLength="10" isInvalid={errors.Model} 
          placeholder={translatedForm.EnterValue(10)} className="form-control"  />
          {errors.Model && 
            <span className="text-danger font-size-13">{errors.Model.message}</span>
          }
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="text-black">{translated.Contract}</Form.Label>
        <Form.Control 
          {...register("Contract")}
          type="text" maxLength="100" isInvalid={errors.Contract}  
          placeholder={translatedForm.EnterValue(100)} className="form-control"  />
          {errors.Contract && 
            <span className="text-danger font-size-13">{errors.Contract.message}</span>
          }
      </Form.Group>
      
      <div className="form-group mt-3">
        <button type="submit" disabled={loadingData.button} className="btn btn-primary">
            {loadingData.button && (
                <Spinner animation="border" variant="light" 
                    as="span"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: "5px" }}
                />
            )}
            {loadingData.button && <span>{translatedForm.SaveChanges}</span>}
            {!loadingData.button && <span>{translatedForm.SaveChanges}</span>}
        </button>
      </div>
  
</Form>

  )
}