import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslatePouring = {

    Section:{
        en_US: "Section",
        ru_RU: "Секция"
    },
    Operation:{
        en_US: "Operation",
        ru_RU: "Операция"
    },
    PricePerLiter:{
        en_US: "Price per liter",
        ru_RU: "Цена за литр"
    },
    ActualArrival:{
        en_US: "Actual arrival, l",
        ru_RU: "Факт. приход, л"
    },
    ArrivalInvoice:{
        en_US: "Arrival of invoice, l",
        ru_RU: "Факт по накладной"
    },
    OperationDate:{
        en_US: "Operation date",
        ru_RU: "Дата операции"
    },
    ChangeDate:{
        en_US: "Change date",
        ru_RU: "Дата изменения"
    },
    User:{
        en_US: "User",
        ru_RU: "Пользователь"
    },
    FullAdmission:{
        en_US: "Full admission",
        ru_RU: "Полное постулпние"
    },
    FuelIntake:{
        en_US: "Full intake",
        ru_RU: "Приход топлива"
    },
    EnterData:{
        en_US: "Enter data",
        ru_RU: "Приход сделан"
    },
    Controller:{
        en_US: "Controller",
        ru_RU: "Контроллер"
    },
    Occupancy:{
        en_US: "Occupancy",
        ru_RU: "Заполненность"
    },
    benzamat:{
        en_US: "benzamatPro 2.0",
        ru_RU: "benzamatPro 2.0"
    },
    FuelIntakeTitle:{
        en_US: "Fuel Intake",
        ru_RU: "Поступление ГСМ"
    },
    AddIntake:{
        en_US: "Add fuel intake",
        ru_RU: "Добавить поступление"
    },
    PermissionDenied:{
        en_US: "Permission denied",
        ru_RU: "У вас недостаточно прав для просмотра данного раздела"
    },
    FuelInflow:{
        en_US: "Fuel inflow",
        ru_RU: "Оформление поступления ГСМ"
    },
    MessageFuelInflow:{
        en_US: "To register the receipt of fuel - select a section near the station and check if the level gauge is indicated at the selected section",
        ru_RU: "Для оформления поступления топлива - выберите секцию у станции и проверьте указан ли уровнемер у выбранной секции"
    },
    Company:{
        en_US: "Company",
        ru_RU: "Компания"
    },
    Station:{
        en_US: "Station",
        ru_RU: "Станция"
    },
    Section:{
        en_US: "Section",
        ru_RU: "Секция"
    },
    InvoiceNumber:{
        en_US: "Invoice number",
        ru_RU: "Накладная"
    },
    Volume:{
        en_US: "Volume, l",
        ru_RU: "Объем, л"
    },
    Density:{
        en_US: "Density, kg/m3",
        ru_RU: "Плотность, кг/м3"
    },
    Temperature:{
        en_US: "Temperature",
        ru_RU: "Температура"
    },
    Mass:{
        en_US: "Mass, kg",
        ru_RU: "Масса, кг"
    },
    Date:{
        en_US: "Date",
        ru_RU: "Дата"
    },
    Level:{
        en_US: "Level, sm",
        ru_RU: "Уровень, см"
    },
    LevelMeter:{
        en_US: "Level meter",
        ru_RU: "Уровнемер"
    },
    Variation:{
        en_US: "Variation",
        ru_RU: "Изменение"
    },
    FuelTankTruck:{
        en_US: "Fuel tank truck",
        ru_RU: "Бензовоз"
    },
    MeasuredVolume:{
        en_US: "Measured volume, l",
        ru_RU: "Замер объема, л"
    },
    MeasuredDensity:{
        en_US: "Measured denstiry, kg/m3",
        ru_RU: "Замер плотности, кг/м3"
    },
    FuelCost:{
        en_US: "Fuel cost",
        ru_RU: "Стоимость топлива"
    },
    LevelGaging:{
        en_US: "Level gaging",
        ru_RU: "Измерение уровня метроштоком"
    },
    StartDrain:{
        en_US: "Start drain",
        ru_RU: "Начало слива"
    },
    EndDrain:{
        en_US: "End drain",
        ru_RU: "Конец слива"
    },
    Number:{
        en_US: "Number",
        ru_RU: "Номер"
    },
    FuelReceiptController:{
        en_US: "Fuel receipt by controller",
        ru_RU: "Поступление топлива на контроллере" 
    },
    AccessDenied:{
        en_US: "Access denied",
        ru_RU: "У вас недостаточно прав для просмотра данного раздела" 
    },
    TitleFuelIntake:{
        en_US: "Add fuel intake",
        ru_RU: "Оформление поступления ГСМ" 
    },
    Company:{
        en_US: "Company",
        ru_RU: "Компания" 
    },
    Station:{
        en_US: "Station",
        ru_RU: "Станция" 
    },
    Section:{
        en_US: "Section",
        ru_RU: "Секция" 
    },
    Yes:{
        en_US: "Yes",
        ru_RU: "Да" 
    },
    No:{
        en_US: "No",
        ru_RU: "Нет" 
    },
    Delete:{
        en_US: "Delete",
        ru_RU: "Удалить" 
    },
    Edit:{
        en_US: "Edit",
        ru_RU: "Изменить" 
    }

};
