import React, { useContext, useEffect, useState, useMemo, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Nav, DropdownButton, Badge, Dropdown, Form, OffCanvas, Alert, Table, Spinner, Offcanvas} from 'react-bootstrap';
import { AuthContext } from '../../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import './../../../boxicons.css';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import {TranslateCompanyModule} from '../../../lang/company'
import { CurrentSelectLanguage } from '../../../helpers/language';
import {FormLocale} from '../../../lang/form';
import api from '../../../utils/axiosInterceptions';

const ControllerCompanies = ({controllerId, controllerInfo}) => {

  const router = useHistory();
  const {isAuth} = useContext(AuthContext);
  const { register, handleSubmit, control, reset, setValue, formState, formState: { errors }} = useForm();
  const [listCompanies, setListCompanies] = useState([])
  const [listServiceCompanies, setListServiceCompanies] = useState([])
  const [selectedCompanies, setSelectedCompanies] = useState([])
  const [showFormAdd, setShowFormAdd] = useState(false);
  const translated = useLittera(TranslateCompanyModule);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [textButtonCompany, setTextBtnCompany] = useState(translated.ActionWithCompanies)
  const [permission, setPermission] = useState({
    read:false,
    create:false,
    fullAccess:false
  })
  const [loadingData, setLoadingData] = useState({
    button:false,
    grid:false
  })

  const onDelete = id => {
 
    api.post('/api/controllers/details/companies/delete', {
      ControllerId: controllerId,
      CompanyId: id,
      withCredentials:true
    })
    .then(function (response) {

      
      setListCompanies(response.data.listDatas)
      setLoadingData({grid:false})
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
        console.log(error);
        setLoadingData({grid:false})
        displayToast(error.response.data.message, error.response.data.isSuccess)
    });

  }

  const onSubmit = data => {
    console.log(data)
    console.log(controllerInfo.id)
    setLoadingData({button:true})
    let array_ids = []
    data.Companies.map((item) => {
      array_ids.push(item.id)
    })
    api.post("/api/controllers/details/companies/create", {
      Company: array_ids,
      ControllerId: controllerInfo.id,
      withCredentials:true
    })
    .then(function (response) {
      setListCompanies(response.data.listDatas)
      displayToast(response.data.message, response.data.isSuccess)
      setLoadingData({button:false})
    })
    .catch(function (error) {
      setLoadingData({button:false})
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  const getControllerCompanies = () => {
    api.get('/api/controllers/details/companies/all', {
      headers:{
        "content-type" : "application/json",
        "controllerId": controllerInfo.id
      },
      params:{
        controllerId: controllerInfo.id
      },
      withCredentials:true
      })
      .then(function (response) {
          console.log('controller companies')
          
          setPermission({
            read: response.data.read,
            create: response.data.create,
            fullAccess: response.data.fullAccess
          })
          setListCompanies(response.data.listDatas)
          setLoadingData({grid:false})
      })
      .catch(function (error) {
          console.log(error);
          setLoadingData({grid:false})
      });
  }

  const getCompanies = () => {

    api.get('/api/company/all', {
        withCredentials:true
    })
    .then(function (response) {
        

        const addCompany = response.data.listDatas.map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }))

        setListServiceCompanies(addCompany)

    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const handleShow = () => {
    setShowFormAdd(true)
  }

  const handleClose = () => setShowFormAdd(false);

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    setLoadingData({grid:true})
    getControllerCompanies()
    getCompanies()
  }, [])

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });

    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
  
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: translated.Name,
      },
      {
        accessorFn: (row) => 
        <>
          <Button onClick={() => router.push(`/company/${row.id}`)} className="btn btn-light btn-sm">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
          </Button> 
      
          <Button onClick={() => onDelete(row.id)}  className="btn btn-danger btn-sm" style={{marginLeft:'20px'}}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.875 4.375L3.125 4.37504" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M8.125 8.125V13.125" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.875 8.125V13.125" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M15.625 4.375V16.25C15.625 16.4158 15.5592 16.5747 15.4419 16.6919C15.3247 16.8092 15.1658 16.875 15 16.875H5C4.83424 16.875 4.67527 16.8092 4.55806 16.6919C4.44085 16.5747 4.375 16.4158 4.375 16.25V4.375" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M13.125 4.375V3.125C13.125 2.79348 12.9933 2.47554 12.7589 2.24112C12.5245 2.0067 12.2065 1.875 11.875 1.875H8.125C7.79348 1.875 7.47554 2.0067 7.24112 2.24112C7.0067 2.47554 6.875 2.79348 6.875 3.125V4.375" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
          </Button>
        </>,
        accessorKey: 'button',
        header: translatedForm.Actions,
      },
    ],
    [],
  );

  return ( 
    isAuth ?
    <>
       <div>
        
        {
          permission.create || permission.fullAccess
          ?
          <DropdownButton className="mt-3" title={textButtonCompany} disabled={loadingData.button}>
            <Dropdown.Item onClick={() => handleShow()}>{translated.AddCompany}</Dropdown.Item>
          </DropdownButton>
          : null
        }
        
        <div className="mt-3">
          {
            permission.read || permission.fullAccess
            ?
            <MaterialReactTable 
              columns={columns} 
              data={listCompanies}
              localization={MRT_Localization_EN}
              initialState={{ showColumnFilters: true }}
              enableTopToolbar={false}
              enableRowVirtualization
              muiTablePaperProps={{
                elevation: 1,
              }}
              muiTableHeadCellProps={{
                //simple styling with the `sx` prop, works just like a style prop in this example
                sx: {
                  fontWeight: 'bold',
                  color:"#000",
                  backgroundColor:'#F3F3F3',
                  fontFamily: 'BenzamatRegular',
                },
              }}
              muiTableBodyCellProps={{
                sx: {
                  color:"#000",
                  fontFamily: 'BenzamatRegular',
                },
              }}
            />
            : 
            <Alert variant='danger'>
              {translated.AccessDenied}
            </Alert>
          }
          </div>
      </div>

    <Offcanvas placement="end" show={showFormAdd} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
            {translated.AddCompanyProgress}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <div className="dropdown-divider"></div>
        <Offcanvas.Body>  

          <Form onSubmit={handleSubmit(onSubmit)}>
         
          <Form.Group>
              <Form.Label className="text-black">{translated.ListCompanies} <sup className="text-danger">*</sup></Form.Label>
              <Controller
                  name="Companies"                                
                  control={control}
                  rules={{ required: translatedForm.Required }}
                  render={({ field }) => (
                  <Select
                    {...field}
                    isSearchable
                    isMulti
                    options={listServiceCompanies}
                    placeholder={translated.SelectFromList}
                    classNamePrefix="select"
                  />
                  )}
              />
              {errors.Companies && 
                  <span className="text-danger font-size-13">{errors.Companies.message}</span>
              }
            </Form.Group>

            <div className="form-group mt-3">
              <button type="submit" disabled={loadingData.button} className="btn btn-primary">
                {loadingData.button && (
                    <Spinner animation="border" variant="light" 
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                {!loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                </button>
            </div>
        
          </Form>
      
        </Offcanvas.Body>
    </Offcanvas>
    </>
    :
    <Redirect to='/login'/>
  );

}

export default ControllerCompanies;
