import React, { useContext, useEffect, useMemo, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Table, DropdownButton, Alert, ProgressBar, Dropdown, Badge, Modal, Form, Offcanvas, Spinner, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import api from '../../utils/axiosInterceptions';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import MaterialReactTable from 'material-react-table';

export const Sections = ({translated, translatedForm, controllerInfo, typeController}) => {

    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [listSection, setListSection] = useState([])
    const [listStationSection, setListStationSection] = useState([])
    const { control, register, handleSubmit, setValue, reset, formState, formState: { errors }} = useForm();

    useEffect(() => {
        getSections()
    }, [])

    const columns = useMemo(
        () => [
          {
            accessorKey: 'name', //access nested data with dot notation
            header: translated.Name,
          },
          {
            accessorFn: (row) => 
            <>
              <Button className="btn btn-danger btn-sm mx-3">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 7l16 0"></path>
                    <path d="M10 11l0 6"></path>
                    <path d="M14 11l0 6"></path>
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                  </svg>
              </Button> 
            </>,
            accessorKey: 'button',
            header: translatedForm.Actions,
          },
        ],
        [],
    );    

    const onSubmit = data => {
        setLoading(true)
        api.post("/api/controllers/details/sections/add", {
            ControllerId: controllerInfo.id,
            StationSectionId: data.Section.value
        })
        .then(function (response) {
            setLoading(false)
            displayToast(response.data.message, response.data.isSuccess)
            setListSection(response.data.listDatas)
        })
        .catch(function (error) {
            console.log(error);
            setLoading(false)
            displayToast(error.response.data.message, error.response.data.isSuccess)
        });
    }

    const getSections = () => {
        api.get('/api/controllers/details/sections', {
            headers:{
                "content-type" : "application/json",
                "ControllerId": controllerInfo.id
            },
            params:{
                ControllerId: controllerInfo.id,
            },
            withCredentials:true
        })
        .then(function (response) {
            console.log('controller sections')
            
            setListSection(response.data.listDatas)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getStationSections = () => {
        api.get("/api/stations/details/sections", {
            headers:{
              "content-type" : "application/json",
              "StationId": controllerInfo.stationId
            },
            params:{
              StationId: controllerInfo.stationId
            }
        })
        .then(function (response) {
            console.log('sections')
            
            displayToast(response.data.message, response.data.isSuccess)
      
            const addSection = response.data.listDatas.map(item => ({
              ...item,
              value: item.id,
              label: item.name
            }))
      
            setListStationSection(addSection)
        })
        .catch(function (error) {
            console.log(error);
            displayToast(error.response.data.message, error.response.data.isSuccess)
          });
    }

    const onDelete = id => {
        api.post("/api/controllers/details/sections/delete", {
            ControllerId: controllerInfo.id,
            Id: id
        })
        .then(function (response) {
            setListSection(response.data.listDatas)
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error)
            displayToast(error.response.data.errors.errorMessage, error.response.data.isSuccess)
        });
    }

    const displayToast = (message, status) => {

        if(status){
          toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
          });
    
        } else {
          toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
          });
        }
      
    }

    return(
        <>
            <div className="row">
                <div className="col-xs-12 col-lg-3 col-md-3 mb-3">
                    <button onClick={() => {
                        getStationSections()
                        setShow(true)
                    }} type="button" className="btn btn-primary">
                        Добавить секцию
                    </button>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12 responsive">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Название</th>
                                <th>Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listSection.length != 0
                                ?
                                listSection.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.name}</td>
                                        <td>
                                            <Button onClick={() => onDelete(item.id)} className="btn btn-danger btn-sm mx-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M4 7l16 0"></path>
                                                    <path d="M10 11l0 6"></path>
                                                    <path d="M14 11l0 6"></path>
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                </svg>
                                            </Button> 
                                        </td>
                                    </tr>
                                ))
                                :
                                <tr>
                                    <td colspan="2">Данных нет</td>
                                </tr>
                            }                    
                        </tbody>
                    </Table>
                </div>
            </div>

            <Offcanvas placement="end" show={show} onHide={() => setShow(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>Добавление секций</Offcanvas.Title>
                </Offcanvas.Header>
                <div className="dropdown-divider"></div>
            <Offcanvas.Body>  

                <Form onSubmit={handleSubmit(onSubmit)}>
                
                <Form.Group className="mb-3">
                <Form.Label className="text-black">Секции <sup className="text-danger">*</sup></Form.Label>
                
                    <Controller
                        name="Section"                                
                        control={control}
                        rules={{ required: translatedForm.Required }}
                        render={({ field: {value} }) => (
                        <Select                   
                            isSearchable
                            options={listStationSection}                        
                            placeholder={translatedForm.SelectFromList}
                            classNamePrefix="select"
                            value={value}
                            onChange={(item) => {
                                setValue("Section", item)
                            }}
                        />
                        )}
                    />
                    {errors.Section && 
                        <span className="text-danger font-size-13">{errors.Section.message}</span>
                    }
                </Form.Group>

                <div className="form-group mt-3">
                <button type="submit" disabled={loading} className="btn btn-primary">
                    {loading && (
                        <Spinner animation="border" variant="light" 
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                        />
                    )}
                    {loading && <span>{translatedForm.SaveChanges}</span>}
                    {!loading && <span>{translatedForm.SaveChanges}</span>}
                    </button>
                </div>
            
                </Form>
            
            </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}