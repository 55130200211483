import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Card, ProgressBar, Form, Offcanvas, Spinner} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { FormAddSection } from './FormAddSection';
import { FormEditSection } from './FormEditSection';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import { ControllerStation } from './ControllerStation';
import { StationSection } from './sections';
import 'react-toastify/dist/ReactToastify.min.css';
import Select from 'react-select';
import Chart from "react-apexcharts";
import axios from 'axios';
import './../../boxicons.css';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { TranslateStation } from '../../lang/stations/translateStation';
import {FormLocale} from '../../lang/form';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { CurrentSelectLanguage } from '../../helpers/language';
import api from '../../utils/axiosInterceptions';

export const StationSingle = () => {

  let component = ''
  let headerComponentName = ''
  const router = useHistory()
  const params = useParams();
  const {isAuth} = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false)
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [listSections, setListSections] = useState([])
  const [listCompanies, setListCompanies] = useState([])
  const [currentActiveTab, setCurrentActiveTab] = useState('')
  const [listTimezone, setListTimezone] = useState([])
  const translated = useLittera(TranslateStation);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [permission, setPermission] = useState({
    read: false,
    update:false,
    fullAccess: false
  })
  const [listSeries, setListSeries] = useState([])
  const [sectionChart, setSectionChart] = useState({
    names:[],
    series:[]
  })
  const [componentForm, setComponentForm] = useState({
    componen:'',
    name:''
  })
  const [sectionData, setSectionData] = useState({
    name:'',
    id:0
  })
  const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
  const [stationData, setStationData] = useState({
    name:'',
    id:''
  })
  const [selectedCompany, setSelectedCompany] = useState({
    label:'',
    value:''
  })
  const [loadingDataBtn, setLoadingDataButton] = useState(false)
  
  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    fetchingData()
    getSections()
  }, [])

  const handleShow = (data, id) => {
    setSectionData({id:id})

    if(data == "addSection") {

      component = <FormAddSection setListSections={setListSections} stationData={stationData}/>
      headerComponentName='Добавление секции'
      setComponentForm({
        component: component,
        name:headerComponentName
      })

    } 
    else if (data == "updateSection") {

      component = <FormEditSection setListSections={setListSections} stationData={stationData} test={id} sectionData={sectionData}/>
      headerComponentName='Редактирование секции'
      setComponentForm({
        component: component,
        name:headerComponentName
      })
    }

    setShowFormAdd(true);
  }

  const handleClose = () => setShowFormAdd(false);

  const options = {
    labels: [translated.OccupancySingle],
    dataLabels: {
      enabled: true
    },
    chart: {
      type: 'bar',
      height: 500,
      stacked: true,
    },
    xaxis: {
      categories: sectionChart.names,
    },
    yaxis:{
      min:0,
      max:100
    },
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
        borderRadius: 5,
        stackType:"100%"
      }
    }
  };

  const series = [{
    name: 'Остаток',
    data: listSeries
  }]

  const onSubmit = data => {
    setLoadingDataButton(true)
    api.post('/api/stations/update', {
      Id: data.Id,
      Name: data.Name,
      CompanyId: data.Company.id,
      Description: data.Description,
      SerialNumber: data.SerialNumber,
      OrderNumber: data.OrderNumber,
      Longtitude: data.Longtitude,
      Lantitude: data.Lantitude,
      Timezone: data.Timezone
    })
    .then(function (response) {
      setLoadingDataButton(false)
      setStationData({name: response.data.dataResult.name})
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });

    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
  
  }

  const getSections = () => {
    api.get("/api/stations/details/sections", {
      headers:{
        "content-type" : "application/json",
        "id":params.id
      },
      params:{
        StationId: params.id,
      },
    })
    .then(function (response) {
        console.log("sections")
        
        let array_series = []

        const add = response.data.listDatas.map(item => {
          array_series.push(Math.ceil(item.fuelBalance))
        })

        setListSeries(array_series)
        
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const fetchingData = () => {
    setIsLoading(true)
    api.get("/api/stations/details", {
      headers:{
        "content-type" : "application/json",
        "id":params.id
      },
      params:{
        id: params.id,
      },
    })
    .then(function (response) {
      console.log("station data")
      

      setPermission({
        read: response.data.read,
        update: response.data.update,
        create: response.data.create,
        fullAccess: response.data.fullAccess
      })

      setValue("Timezone", response.data.dataResult.timezoneHour)

      setStationData({
        name: response.data.dataResult.name,
        id: response.data.dataResult.id
      })

      setSelectedCompany({
        label:response.data.dataResult.company.name,
        value: response.data.dataResult.company.id
      })
      
      setValue("Company", selectedCompany)
      setValue("Id", response.data.dataResult.id)
      setValue("Name", response.data.dataResult.name);
      setValue("Description", response.data.dataResult.description);
      setValue("SerialNumber", response.data.dataResult.serialNumber);
      setValue("OrderNumber", response.data.dataResult.orderNumber);
      setValue("Longtitude", response.data.dataResult.longtitude);
      setValue("Lantitude", response.data.dataResult.lantitude);

      setIsLoading(false) 
      
    })
    .catch(function (error) {
      console.log(error);
    });

    api.get("/api/company/all", {

    })
    .then(function (response) {
        const addCompany = response.data.listDatas.map(item => ({
            ...item,
            value: item.id,
            label: item.name
          }))
        setListCompanies(addCompany)
        
    })
    .catch(function (error) {
        console.log(error);
    });
  }  

  return(
    isAuth ?
    <>
    <div className=" d-sm-flex align-items-center justify-content-between">
      <h2 className="font-size-18 benzamat-font-bold text-black">
        <NavLink to="/stations">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.125 13.8125L3.25 8.9375L8.125 4.0625" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.125 20.3125H17.0625C18.5709 20.3125 20.0176 19.7133 21.0842 18.6467C22.1508 17.5801 22.75 16.1334 22.75 14.625V14.625C22.75 13.8781 22.6029 13.1385 22.3171 12.4485C22.0312 11.7584 21.6123 11.1315 21.0842 10.6033C20.556 10.0752 19.9291 9.65626 19.239 9.37043C18.549 9.08461 17.8094 8.9375 17.0625 8.9375H3.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </NavLink> 
        {translated.TitleSingle} - {isLoading ? <Skeleton width={200} height={20}/> : stationData.name}
      </h2>      
    </div>
      
    <div className="row">
      
      <Tabs defaultActiveKey="maininfo" onClick={(e) => setCurrentActiveTab(e.target.dataset.rrUiEventKey)} id="uncontrolled-tab-example" className="mt-3 nav-tabs-custom">

        <Tab eventKey="maininfo" title={translated.General} tabClassName="font-size-15">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mt-2">
              <div className="col-xs-12 col-md-4 col-lg-4">

                  <Form.Control {...register("Id")} type="hidden"  />

                  {
                    isLoading
                    ? <Skeleton height={25} className="mt-4" count={5} />
                    :
                    <>
                    <Form.Group className="mb-3 mt-3">
                      <Form.Label className="text-black">{translated.Name} <sup className="text-danger">*</sup></Form.Label>
                      <Form.Control 
                          {...register("Name", {
                          minLength : {
                              value: 5,
                              message: translatedForm.MinLengthString(5)
                          },
                          maxLength : {
                              value: 100,
                              message: translatedForm.MaxLenghtString(100)
                          },
                          required: "Обязательное поле"
                          })}
                          type="text" maxLength="100" isInvalid={errors.Name}  
                          placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                      {errors.Number && 
                      <span className="text-danger font-size-13">{errors.Name.message}</span>
                      }
                    </Form.Group>

                    <Form.Group className="mb-3 mt-3">
                      <Form.Label className="text-black">{translated.Company} <sup className="text-danger">*</sup></Form.Label>
                      
                        <Controller
                            name="Company"                                
                            control={control}
                            rules={{ required: translatedForm.Required }}
                            render={({ field }) => (
                            <Select 
                                onChange={(item) => {
                                    console.log(item)
                                    setSelectedCompany({label:item.name, value:item.id})
                                }}
                                isSearchable
                                value={selectedCompany}
                                options={listCompanies}          
                                placeholder={translatedForm.SelectFromList}
                                classNamePrefix="select"
                                error={Boolean(errors.Company)}
                            />
                            )}
                        />
                        {errors.Company && 
                            <span className="text-danger font-size-13">{errors.Company.message}</span>
                        }   

                      </Form.Group>

                      <Form.Group className="mb-3 mt-3">
                        <Form.Label className="text-black">{translated.Timezone} <sup className="text-danger">*</sup></Form.Label>
                        <Form.Control 
                          {...register("Timezone", {
                            minLength : {
                              value: 1,
                              message: translatedForm.MinNumber(1)
                            },
                            maxLength : {
                              value: 2,
                              message: translatedForm.MaxNumber(2)
                            },
                          })}
                          type="number" maxLength="2" isInvalid={errors.Timezone}  
                          placeholder={translatedForm.EnterValue(2)} className="form-control"  />
                          {errors.Timezone && 
                            <span className="text-danger font-size-13">{errors.Timezone.message}</span>
                          }
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.Description}</Form.Label>
                        <Form.Control 
                            {...register("Description", {
                            minLength : {
                                value: 5,
                                message: translatedForm.MinLengthString(5)
                            },
                            maxLength : {
                                value: 100,
                                message: translatedForm.MaxLenghtString(100)
                            },
                            })}
                            type="text" maxLength="100" isInvalid={errors.Description}  
                            placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                            
                          {errors.Description && 
                            <span className="text-danger font-size-13">{errors.Description.message}</span>
                          }
                      </Form.Group>
                      
                      <Form.Group className="mb-3">
                      <Form.Label className="text-black">{translated.SerialNumber}</Form.Label>
                      <Form.Control 
                          {...register("SerialNumber", {
                          minLength : {
                              value: 5,
                              message: translatedForm.MinLengthString(2)
                          },
                          maxLength : {
                              value: 100,
                              message: translatedForm.MaxLenghtString(100)
                          },
                          })}
                          type="number" maxLength="100" isInvalid={errors.SerialNumber}  
                          placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                          {errors.SerialNumber && 
                          <span className="text-danger font-size-13">{errors.SerialNumber.message}</span>
                          }
                      </Form.Group>                      
                      
                    {
                      permission.update || permission.fullAccess
                      ?
                      <div className="form-group mt-3">
                        <button type="submit" disabled={loadingDataBtn} className="btn btn-primary">
                          {loadingDataBtn && (
                              <Spinner animation="border" variant="light" 
                                  as="span"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  style={{ marginRight: "5px" }}
                              />
                          )}
                          {loadingDataBtn && <span>{translated.SaveChanges}</span>}
                          {!loadingDataBtn && <span>{translated.SaveChanges}</span>}
                        </button>
                      </div>
                      : ''
                    }
                    </>
                  }
              </div>

              <div class="col-xs-12 col-md-4 col-lg-4">

              {
                isLoading
                ? <Skeleton height={25} className="mt-4" count={5} />
                : 
                <>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label className="text-black">{translated.OrderNumber}</Form.Label>
                  <Form.Control 
                      {...register("OrderNumber", {
                      minLength : {
                          value: 5,
                          message: translatedForm.MinLengthString(5)
                      },
                      maxLength : {
                          value: 100,
                          message: translatedForm.MaxLenghtString(100)
                      },
                      })}
                      type="number" maxLength="100" isInvalid={errors.OrderNumber}  
                      placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                  {errors.OrderNumber && 
                  <span className="text-danger font-size-13">{errors.OrderNumber.message}</span>
                  }
                </Form.Group>

                <Form.Group className="mb-3 mt-3">
                  <Form.Label className="text-black">{translated.Longtitude}</Form.Label>
                  <Form.Control 
                      {...register("Longtitude", {
                      minLength : {
                          value: 5,
                          message: translatedForm.MinLengthString(5)
                      },
                      maxLength : {
                          value: 100,
                          message: translatedForm.MaxLenghtString(100)
                      },
                      })}
                      type="text" maxLength="100" isInvalid={errors.Longtitude}
                      placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                      {errors.Longtitude && 
                      <span className="text-danger font-size-13">{errors.Longtitude.message}</span>
                      }
                  </Form.Group>

                  <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.Langtitude}</Form.Label>
                    <Form.Control 
                        {...register("Lantitude", {
                        minLength : {
                            value: 5,
                            message: translatedForm.MinLengthString(5)
                        },
                        maxLength : {
                            value: 100,
                            message: translatedForm.MaxLenghtString(100)
                        },
                        })}
                        type="text" maxLength="100" isInvalid={errors.Lantitude} 
                        placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                      {errors.Lantitude && 
                      <span className="text-danger font-size-13">{errors.Lantitude.message}</span>
                      }
                  </Form.Group>
                </>
              }
            
              </div>

              <div class="col-xs-12 col-md-4 col-lg-4">
              <Card border="light" className="border mt-3 shadow-none">
                  <Card.Body>
                  <div className="d-flex">
                  <h5 className="benzamat-font-bold flex-fill text-black">
                    {translated.Occupancy}
                  </h5>
                  </div>
                  
                  <Chart series={series} options={options} className="d-block mx-auto " height={320} type="bar" />
          
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Form>
        </Tab>

        <Tab eventKey="sections" title={translated.Sections} tabClassName="font-size-15">
          {
            currentActiveTab == "sections"
            ? <StationSection />
            : null
          }          
        </Tab>

        <Tab eventKey="controllers" title={translated.Controllers} tabClassName="font-size-15">
          {
            currentActiveTab == "controllers"
            ? <ControllerStation stationData={stationData}  />
            : null
          }
        </Tab>

      </Tabs>
    </div>
    </>
    :
    <Redirect to='/login'/>
    );
}