import React, { useState, useEffect, useContext, useMemo, Redirect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Alert, Offcanvas, Form, Spinner, DropdownButton, Badge, Dropdown} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';    
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import {TranslateTransport} from '../../../lang/transports/translateTransport';
import {CurrentSelectLanguage} from '../../../helpers/language';
import {FormLocale} from '../../../lang/form';
import api from '../../../utils/axiosInterceptions';

const TransportControllers = ({controllerInfo}) => {

  const router = useHistory();
  const params = useParams()
  const { register, handleSubmit, control, reset, setValue, formState, formState: { errors }} = useForm();
  const [listTransport, setListTransport] = useState([])
  const [listTransportForSelect, setListTransportForSelect] = useState([])
  const [selectedTransports, setSelectedTransports] = useState([])
  const [listCompaniesForSelect, setListCompaniesForSelect] = useState([])
  const [show, setShow] = useState(false)
  const [selectAllUsers, setSelectAllUsers] = useState(false)
  const [usersIsDisabled, setUsersIsDisabled] = useState(false)
  const translated = useLittera(TranslateTransport);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [loadingData, setLoadingData] = useState({
    button:false,
    grid:false
  })

  const onHandleShow = () => {
    getCompanies()
    setSelectAllUsers(false)
    setUsersIsDisabled(false)
    setValue("Company", null)
    setShow(true)
  }

  const onDelete = transportId => {

    setLoadingData({grid:true})
    api.get('/api/controllers/details/transports/delete', {
      headers:{
          "content-type" : "application/json",
          "controllerId":controllerInfo.id,
          "transportId": transportId
      },
      params:{
          controllerId: controllerInfo.id,
          transportId: transportId
      },
      withCredentials:true
    })
    .then(function (response) {
      
      setListTransport(response.data.listDatas)
      setLoadingData({grid:false})
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
      console.log(error);
      setLoadingData({grid:false})
      displayToast(error.data.message, error.data.isSuccess)
    });

  }

  const onSubmit = data => {
    console.log(data)
    let transport_ids = []
    if(data.Transport != null)
    {
      data.Transport.map((i) => {
        transport_ids.push(i.id)
      })
    }
    setLoadingData({button:true})
    api.post("/api/controllers/details/transports/add", {
      TransportIds: transport_ids,
      ControllerId: controllerInfo.id,
      SelectAllUsers: selectAllUsers,
      CompanyId: data.Company.id,
    })
    .then(function (response) {
      setListTransport(response.data.listDatas)
      displayToast(response.data.message, response.data.isSuccess)
      setLoadingData({button:false})
    })
    .catch(function (error) {
      setLoadingData({button:false})
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  const getTransport = () => {
    api.get('/api/controllers/details/transports/include', {
        headers:{
          "content-type" : "application/json",
          "controllerId": params.id
        },
        params:{
          controllerId: params.id
        },
        withCredentials:true
      })
      .then(function (response) {
        console.log("transport include")
        

        const add = response.data.listDatas.map(item => ({
          ...item,
          value: item.id,
          label: item.name
        }))
        setListTransportForSelect(add)
        
      })
      .catch(function (error) {
          console.log(error);
          setLoadingData({grid:false})
      });
  }

  const getCompanyTransport = companyId => {
    api.get('/api/company/details/transports', {
      headers:{
          "content-type" : "application/json",
          "companyId":companyId
      },
      params:{
          companyId: companyId,
      },
      withCredentials:true
  })
  .then(function (response) {
    console.log("transport company")
    
      let add = response.data.listDatas.map((item) => ({
          ...item,
          value:item.id,
          label:item.name
      }))
      setListTransportForSelect(add)
  })
  .catch(function (error) {
      console.log(error);
  });
  }

  const getCompanies = () => {
    api.get('/api/company/all', {
        withCrenditials:true
    })
    .then(function(response){
      const add = response.data.listDatas.map(item => ({
        ...item,
        value: item.id,
        label: item.name
      }))
      setListCompaniesForSelect(add)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    setLoadingData({grid:true})
    api.get('/api/controllers/details/transports', {
    headers:{
        "content-type" : "application/json",
        "id":controllerInfo.id
    },
    params:{
        id: controllerInfo.id
    },
    withCredentials:true
    })
    .then(function (response) {
      
      setListTransport(response.data.listDatas)
      setLoadingData({grid:false})
    })
    .catch(function (error) {
        console.log(error);
        setLoadingData({grid:false})
    });
  }, [])

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });

    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
  
  }

  const buttons = rowData => {
    return <>
      <Button onClick={() => router.push(`/transport/${rowData.id}`)} className="btn me-3 btn-light btn-sm">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </Button>
    <Button onClick={() => onDelete(rowData.id)} className="btn btn-danger me-2 btn-sm">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.875 4.375L3.125 4.37504" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.125 8.125V13.125" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.875 8.125V13.125" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.625 4.375V16.25C15.625 16.4158 15.5592 16.5747 15.4419 16.6919C15.3247 16.8092 15.1658 16.875 15 16.875H5C4.83424 16.875 4.67527 16.8092 4.55806 16.6919C4.44085 16.5747 4.375 16.4158 4.375 16.25V4.375" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.125 4.375V3.125C13.125 2.79348 12.9933 2.47554 12.7589 2.24112C12.5245 2.0067 12.2065 1.875 11.875 1.875H8.125C7.79348 1.875 7.47554 2.0067 7.24112 2.24112C7.0067 2.47554 6.875 2.79348 6.875 3.125V4.375" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </Button>
    </>
  }

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.name,
        accessorKey: 'name',
        header: translated.Name,
      },
      {
        accessorFn: (row) => row.rfidKey,
        accessorKey: 'rfid',
        header: translated.NumberRfid,
      },
      {
        accessorFn: (row) => 
        <>
          <Button onClick={() => router.push(`/transport/${row.id}`)} className="btn me-3 btn-light btn-sm">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </Button>
        <Button onClick={() => onDelete(row.id)} className="btn btn-danger me-2 btn-sm">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.875 4.375L3.125 4.37504" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.125 8.125V13.125" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.875 8.125V13.125" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.625 4.375V16.25C15.625 16.4158 15.5592 16.5747 15.4419 16.6919C15.3247 16.8092 15.1658 16.875 15 16.875H5C4.83424 16.875 4.67527 16.8092 4.55806 16.6919C4.44085 16.5747 4.375 16.4158 4.375 16.25V4.375" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13.125 4.375V3.125C13.125 2.79348 12.9933 2.47554 12.7589 2.24112C12.5245 2.0067 12.2065 1.875 11.875 1.875H8.125C7.79348 1.875 7.47554 2.0067 7.24112 2.24112C7.0067 2.47554 6.875 2.79348 6.875 3.125V4.375" stroke="#FFF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </Button>
        </>,
        accessorKey: 'button',
        header: translatedForm.Actions,
      },
    ],
    [],
  );

  return ( 
    <>
      <DropdownButton title={translated.ActionWithTransports} className="mt-3" disabled={loadingData.button}>
        <Dropdown.Item onClick={() => onHandleShow()}>{translated.AddTransport}</Dropdown.Item>
      </DropdownButton>
        
     <div className="mt-3">
      <MaterialReactTable 
          columns={columns} 
          data={listTransport}
          localization={MRT_Localization_EN}
          initialState={{ showColumnFilters: true }}
          enableTopToolbar={false}
          enableRowVirtualization
          muiTablePaperProps={{
            elevation: 1,
          }}
          muiTableHeadCellProps={{
            //simple styling with the `sx` prop, works just like a style prop in this example
            sx: {
              fontWeight: 'bold',
              color:"#000",
              backgroundColor:'#F3F3F3',
              fontFamily: 'BenzamatRegular',
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              color:"#000",
              fontFamily: 'BenzamatRegular',
            },
          }}
        />
      </div>

      <Offcanvas placement="end" show={show} onHide={() => setShow(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
            {translated.AddTransport}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <div className="dropdown-divider"></div>
        <Offcanvas.Body>

        <div className="col-xs-12 col-lg-12 col-md-12">
            <Alert variant='info'>
              {translated.MessageSelectUsers}
            </Alert>
        </div>

          <Form onSubmit={handleSubmit(onSubmit)}>

          <Form.Group>
              <Form.Label className="text-black">{translated.ListCompanies} <sup className="text-danger">*</sup></Form.Label>
              <Controller
                  name="Company"                                
                  control={control}
                  rules={{ required: translated.Required }}
                  render={({ field: {value} }) => (
                  <Select
                    isSearchable
                    value={value}
                    options={listCompaniesForSelect}
                    placeholder={translatedForm.SelectFromList}
                    classNamePrefix="select"
                    onChange={(item) => {
                      setValue("Company", item)
                      getCompanyTransport(item.id)
                      setValue("User", null)
                    }}
                  />
                  )}
              />
              {errors.User && 
                  <span className="text-danger font-size-13">{errors.User.message}</span>
              }
          </Form.Group>

          <div className="col-xs-12 col-lg-12 col-md-12 mt-3">
              <Form.Check
                  type="checkbox"
                  id="checkbox-time"
                  label={translated.SelectAllUsers}
                  className="text-black"
                  checked={selectAllUsers}
                  onChange={(e) => {
                      setSelectAllUsers(e.target.checked)
                      setUsersIsDisabled(e.target.checked)
                  }}
              />
          </div>
         
          <Form.Group>
              <Form.Label className="text-black mt-3">{translated.ListTransports} <sup className="text-danger">*</sup></Form.Label>
              <Controller
                  name="Transport"                                
                  control={control}
                  //rules={{ required: translatedForm.Required }}
                  render={({ field }) => (
                  <Select
                    {...field}
                    isSearchable
                    isMulti
                    isDisabled={usersIsDisabled}
                    options={listTransportForSelect}
                    placeholder={translatedForm.SelectFromList}
                    classNamePrefix="select"
                  />
                  )}
              />
              {errors.Transport && 
                  <span className="text-danger font-size-13">{errors.Transport.message}</span>
              }
            </Form.Group>

            <div className="form-group mt-3">
              <button type="submit" disabled={loadingData.button} className="btn btn-primary">
                {loadingData.button && (
                    <Spinner animation="border" variant="light" 
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                {!loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                </button>
            </div>
        
          </Form>
      
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );

}

export default TransportControllers;
