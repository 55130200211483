import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, DropdownButton, Alert, ProgressBar, Dropdown, Badge, Modal, Form, Offcanvas, Spinner, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import TransportControllers from './Transport/all';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import { ControllerPump } from './Pump';
import { ControllerValve } from './Valve';
import { ControllerButton } from './Button';
import { ControllerFlowSensor } from './FlowSensor';
import { Printer } from './Printer';
import { ControllerFilling } from './Filling';
import { GsmModem } from './GsmModem';
import { Metrolog } from './Metrolog';
import { Indicator } from './Indicator';
import { LevelMeter } from './LevelMeter'
import { ModalHardware } from './ModalHardware';
import { Livenka } from './Smart/Livenka';
import { Topaz } from './Smart/Topaz';
import { Gilbarco } from './Smart/Gilbarco';
import { MainInfoBenzaController } from './Main';
import ControllerUsers from './User';
import ControllerCompanies from './Company/all';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import moment from 'moment';
import './../../boxicons.css';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import * as signalR from "@microsoft/signalr"
import StatusConnect01 from "../../img/image_connect/StatusConnect-01.png";
import StatusConnect02 from "../../img/image_connect/StatusConnect-02.png";
import StatusConnect03 from "../../img/image_connect/StatusConnect-03.png";
import StatusConnect04 from "../../img/image_connect/StatusConnect-04.png";
import StatusConnect05 from "../../img/image_connect/StatusConnect-05.png";
import StatusConnect06 from "../../img/image_connect/StatusConnect-06.png";
import StatusConnect07 from "../../img/image_connect/StatusConnect-07.png";
import StatusConnect08 from "../../img/image_connect/StatusConnect-08.png";
import StatusConnect09 from "../../img/image_connect/StatusConnect_09.png";
import StatusConnect009 from "../../img/image_connect/StatusConnect-09.png";
import StatusConnect10 from "../../img/image_connect/StatusConnect-10.png";
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateController } from '../../lang/controllers/translateController';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import { UpdateFirmware } from './UpdateFirmware';
import api from '../../utils/axiosInterceptions';
import { UpdateFromServer } from './Firmware/UpdateFromServer';

const ControllerSingle = () => {

  const formatChars = {
    '*': '[A-Fa-f0-9]'
  };
  const router = useHistory()
  const params = useParams();
  const {isAuth} = useContext(AuthContext);
  const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
  const [isLoading, setIsLoading] = useState(false)
  const [isGetConfig, setIsGetConfig] = useState(false)
  const [isAutoRead, setIsAutoRead] = useState(false)
  const [signalrConnection, setSignalrConnection] = useState({})
  const [textActionRead, setTextActionRead] = useState('')
  const [btnUpdateMainInfo, setBtnUpdateMainInfo] = useState(false)
  const [buttonReadSettings, setButtonReadSettings] = useState(false)
  const [buttonSetSettings, setButtonSetSettings] = useState(false)
  const [job, setJob] = useState(0)
  const [showReadController, setShowReadController] = useState(false)
  const [showWriteController, setShowWriteController] = useState(false)
  const [showSetFactoryController, setShowFactoryController] = useState(false)
  const [currentActiveTab, setCurrentActiveTab] = useState('maininfo')
  const [periodLevelMeterRead, setPeriodLevelMeterRead] = useState(0)
  const [showModalChangeType, setShowModalChangeType] = useState(false)
  const [typeController, setTypeController] = useState(0)
  const [statusConnect, setStatusConnect] = useState('')
  const [checkboxReadJournal, setCheckBoxReadJournal] = useState(true)
  const [checkboxReadUserLimit, setCheckboxReadUserLimit] = useState(false)
  const [checkboxWriteSetting, setCheckboxWriteSetting] = useState(true)
  const [checkboxWriteTime, setCheckboxWriteTime] = useState(true)
  const [checkboxWriteUserLimit, setCheckboxWriteUserLimit] = useState(false)
  const [checkboxTable, setCheckboxTable] = useState(false)
  const [modalUpdateFirmware, setModalUpdateFirmware] = useState(false)
  const [modalFirmwareUpdateServer, setModalFirmwareUpdateServer] = useState(false)
  const translated = useLittera(TranslateController);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [license, setLicense] = useState({
    start:0,
    end:0
  })
  const defaultTextButtonController = translated.ActionWithControllers
  const [smartData, setSmartData] = useState({
    subtype:0,
    address:0,
    address2:0,
    address3:0,
    address4:0,
    address5:0,
    address6:0,
    address7:0,
    address8:0,
    fuel_type:0,
    fuel_type1:0,
    fuel_type2:0,
    fuel_type3:0,
    fuel_type4:0,
    fuel_type5:0,
    fuel_type6:0,
    fuel_type7:0,
    fuel_type8:0,
    countParty:0,
    countTypeFuel:0
  })
  const [selectedCompany, setSelectedCompany] = useState({
    label:'',
    value:''
  })
  const [selectedTrkWork, setSelectedTrkWork] = useState({
    label:'',
    value:''
  })
  const [selectedTypePanel, setSelectedPanel] = useState({
    label:'',
    value:0
  })
  const [selectedTopaz, setSelectedTopaz] = useState({
    label:'',
    value:0
  })
  const [selectedModeFilling, setSelectedModeFilling] = useState({
    label:'',
    value:0
  })
  const [selectedModeRfid, setSelectedModeRfid] = useState({
    label:'',
    value:0
  })
  const [selectedModeIntake, setSelectedModeIntake] = useState({
    label:'',
    value:0
  })
  const [selectedScanning, setSelectedScanning] = useState({
    label:'',
    value:0
  })
  const [progressBarJournal, setProgressBarJournal] = useState(0)
  const [textButtonController, setTextButtonController] = useState(defaultTextButtonController)
  const [listCompanies, setListCompanies] = useState([])
  const [controllerInfo, setControllerInfo] = useState({
    connectId: 0,
    id:0,
    type:0,
    companyId:0,
    stationId:0,
    hasModem:false,
    dayManufacture:0,
    firmwareVersionString:"",
    typeControllerLevelMeter:0
  })
  const [loadingBtn, setLoadingBtn] = useState({
    save:false,
    controllerBtn:false
  })

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    setIsLoading(true)
    controllerData()
  }, [])


  const listBooleans = [
    {label:translated.Yes, value:1},
    {label:translated.No, value:0}
  ]

  const listBoolean = [
    {label:translated.Yes, value:true},
    {label:translated.No, value:false}
  ]

  const listStopMode = [
    {label:translated.Yes, value:false},
    {label:translated.No, value:true}
  ]

  const listPanel = [
    {label:"Benza", value:0},
    {label:"Kamka", value:1}
  ]

  const listRfid = [
    {label:"Benza Mifare", value:0},
    {label:"Benza EM Marine", value:1},
    {label:"Инфорком", value:2},
    {label:"Benza-NFC", value:3}
  ]

  const listTypeWork = [
    {label: translated.SelfFilling, value:0},
    {label: translated.RefuelingComputer, value:1},
    {label: translated.OfflineComputer, value:2},
    {label: translated.SvoyClub, value:3},
  ]

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });

    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
  
  }

  const controllerData = () => {
    api.get("/api/controllers/details", {
      headers:{
          "content-type" : "application/json",
          "controllerId":params.id
      },
      params:{
          controllerId: params.id,
      },
      withCredentials:true
    })
    .then(function (response) {
    console.log("controller")
    console.log(response)
    setLicense({
      start: moment(response.data.dataResult.licenseStart).format("DD.MM.YYYY HH:mm"),
      end: moment(response.data.dataResult.licenseEnd).format("DD.MM.YYYY HH:mm"),
    })

    setSmartData({
        subtype: response.data.dataResult.smart.subType,
        address: response.data.dataResult.smart.address,
        address2: response.data.dataResult.smart.address2,
        address3: response.data.dataResult.smart.address3,
        address4: response.data.dataResult.smart.address4,
        address5: response.data.dataResult.smart.address5,
        address6: response.data.dataResult.smart.address6,
        address7: response.data.dataResult.smart.address7,
        address8: response.data.dataResult.smart.address8,
        fuel_type: response.data.dataResult.fuel.id,
        fuel_type2: response.data.dataResult.smart.fuelType2,
        fuel_type3: response.data.dataResult.smart.fuelType3,
        fuel_type4: response.data.dataResult.smart.fuelType4,
        fuel_type5: response.data.dataResult.smart.fuelType5,
        fuel_type6: response.data.dataResult.smart.fuelType6,
        fuel_type7: response.data.dataResult.smart.fuelType7,
        fuel_type8: response.data.dataResult.smart.fuelType8,
        countParty: response.data.dataResult.smart.countParty,
        countTypeFuel: response.data.dataResult.smart.countTypeFuel
    })

    setControllerInfo({
        connectId: response.data.dataResult.connectId,
        id: response.data.dataResult.id,
        type:response.data.dataResult.enumControllerType.id,
        companyId: response.data.dataResult.companyId,
        stationId: response.data.dataResult.stationId,
        hasModem: response.data.dataResult.hasModem,
        dayManufacture: response.data.dataResult.dayManufacture,
        firmwareVersionString: response.data.dataResult.firmwareVersionString,
        typeControllerLevelMeter: response.data.dataResult.typeLevelMeters
    })
    setValue("Station", {
        label: response.data.dataResult.station.name,
        value: response.data.dataResult.station.id
    })
    setPeriodLevelMeterRead(response.data.dataResult.periodLevelMeterRead)
    setTypeController(response.data.dataResult.type)

    let modeIntake = response.data.dataResult.modeIntake ? 1 : 0;
    let findModeIntake = listBooleans.filter((item) => item.value === modeIntake)
    setValue("ModeIntake", {label: findModeIntake[0].label, value:findModeIntake[0].value})

    let findWorking = listStopMode.filter((item) => item.value === response.data.dataResult.stopMode)
    setValue("StopMode", {label: findWorking[0].label, value:findWorking[0].value})

    let findDoScanning = listStopMode.filter((item) => item.value === response.data.dataResult.doScanning)
    setValue("DoScanning", {label: findDoScanning[0].label, value:findDoScanning[0].value})
    setIsAutoRead(findDoScanning[0].value)
    
    let findRfid = listRfid.filter((item) => item.value == response.data.dataResult.smart.modeRfid)
    console.log("mode RFID")
    console.log(response.data.dataResult.smart.modeRfid)
    setValue("ModeRfid", {label: findRfid[0].label, value:findRfid[0].value})

    if(response.data.dataResult.enumControllerType.id != 3)
    {
      let findPanel = listPanel.filter((item) => item.value === response.data.dataResult.interfacePanel)
      setValue("InterfacePanel", {label: findPanel[0].label, value:findPanel[0].value})

      let topaz = response.data.dataResult.smart.modeTopaz20 ? 1 : 0;
      let findModeTopaz = listBooleans.filter((item) => item.value === topaz)
      setValue("ModeTopaz", {label: findModeTopaz[0].label, value:findModeTopaz[0].value})
    }

    console.log("response.data.dataResult.smar")
    console.log(response.data.dataResult.smart)
    let findWork = listTypeWork.filter((item) => item.value == response.data.dataResult.smart.modeFilling)
    setValue("ModeFilling", {label: findWork[0].label, value:findWork[0].value})

    setValue("Name", response.data.dataResult.name)
    setValue("ConnectId", response.data.dataResult.connectId)
    setValue("VersionSoftware", response.data.dataResult.versionSoftware)
    setValue("VersionProtocol", response.data.dataResult.versionProtocol)
    setValue("EnumControllerType", response.data.dataResult.enumControllerType.name)
    setIsLoading(false)

    if(response.data.dataResult.statusConnect == -1 || response.data.dataResult.statusConnect == -2 || response.data.dataResult.statusConnect == -99 || response.data.dataResult.statusConnect == -97 
        || response.data.dataResult.statusConnect == -96 || response.data.dataResult.statusConnect == -95 || response.data.dataResult.statusConnect == -93)
    {
        setStatusConnect(<img className="d-flex justify-content-center" src={StatusConnect06} />)
    }

    if(response.data.dataResult.statusConnect == -98) {
        setStatusConnect(<img src={StatusConnect07} />)
    }

    if(response.data.dataResult.statusConnect == -94) {
        setStatusConnect(<img src={StatusConnect10} />)
    }

    if(response.data.dataResult.statusConnect >= 0 && response.data.dataResult.statusConnect <= 4) {
        setStatusConnect(<img src={StatusConnect05} />)
    }

    if(response.data.dataResult.statusConnect > 4 && response.data.dataResult.statusConnect <= 9) {
        setStatusConnect(<img src={StatusConnect04} />)
    }

    if(response.data.dataResult.statusConnect > 9 && response.data.dataResult.statusConnect <= 16) {
        setStatusConnect(<img src={StatusConnect03} />)
    }

    if(response.data.dataResult.statusConnect > 16 && response.data.dataResult.statusConnect <= 21) {
        setStatusConnect(<img src={StatusConnect02} />)
    }

    if(response.data.dataResult.statusConnect > 21 && response.data.dataResult.statusConnect <= 31) {
        setStatusConnect(<img src={StatusConnect01} />)
    }

    if(response.data.dataResult.statusConnect == 99) {
        setStatusConnect(<img src={StatusConnect06} />)
    }

    if(response.data.dataResult.statusConnect == 128) {
        setStatusConnect(<img src={StatusConnect09} />)
    }
    })
    .catch(function (error) {
    console.log(error);
    });
  }

  const handleCheckConnect = (text) => {
    setLoadingBtn({controllerBtn:true})
    setTextButtonController(text)
    api.get("/api/controllers/details/check-connect", {
        headers:{
          "content-type" : "application/json",
          "controllerId":params.id
        },
        params:{
          controllerId: params.id,
        },
        withCredentials:true
      })
      .then(function (response) {
        displayToast(response.data.message, response.data.isSuccess)
        setLoadingBtn({controllerBtn:false})
        setTextButtonController(defaultTextButtonController)
      })
      .catch(function (error) {
        console.log(error);
        setLoadingBtn({controllerBtn:false})
        setTextButtonController(defaultTextButtonController)
        displayToast(error.data.message, error.data.isSuccess)
      });
  }

  const handleSetConfig = () => {
    setButtonSetSettings(true)
    setTextActionRead(translated.SetDataToController)
    api.get("/api/controllers/details/setconfig", {
        headers:{
          "content-type" : "application/json",
          "controllerId":params.id,
          "writeTime": checkboxWriteTime,
          "writeSettings": checkboxWriteSetting,
          "writeUserLimits": checkboxWriteUserLimit,
          "writeTable": checkboxTable
        },
        params:{
          controllerId: params.id,
          writeTime: checkboxWriteTime,
          writeSettings: checkboxWriteSetting,
          writeUserLimits: checkboxWriteUserLimit,
          writeTable: checkboxTable
        },
        withCredentials:true
      })
      .then(function (response) {
        displayToast(response.data.message, response.data.isSuccess)
        setTextActionRead('')
        setButtonSetSettings(false)
      })
      .catch(function (error) {
        console.log(error)
        displayToast(error.response.data.message, error.response.data.isSuccess)
        setButtonSetSettings(false)
      });
  }

  const handleGetConfig = (jobId) => {
    console.log("job id")
    console.log(jobId)
    setTextActionRead(translated.ReadingSettings)
    api.get("/api/controllers/details/getconfig", {
        headers:{
          "content-type" : "application/json",
          "controllerId": params.id,
          "readJournal": checkboxReadJournal,
          "readUserLimit": checkboxReadUserLimit,
          "jobId": jobId
        },
        params:{
          controllerId: params.id,
          readJournal: checkboxReadJournal,
          readUserLimit: checkboxReadUserLimit,
          jobId: jobId
        },
        withCredentials:true
      })
      .then(function (response) {
        console.log("controller dara")
        
        setSmartData({
          subtype: response.data.dataResult.smart.subType,
          address: response.data.dataResult.smart.address,
          address2: response.data.dataResult.smart.address2,
          address3: response.data.dataResult.smart.address3,
          address4: response.data.dataResult.smart.address4,
          address5: response.data.dataResult.smart.address5,
          address6: response.data.dataResult.smart.address6,
          address7: response.data.dataResult.smart.address7,
          address8: response.data.dataResult.smart.address8,
          fuel_type:0, //TODO
          fuel_type2: response.data.dataResult.smart.fuelType2,
          fuel_type3: response.data.dataResult.smart.fuelType3,
          fuel_type4: response.data.dataResult.smart.fuelType4,
          fuel_type5: response.data.dataResult.smart.fuelType5,
          fuel_type6: response.data.dataResult.smart.fuelType6,
          fuel_type7: response.data.dataResult.smart.fuelType7,
          fuel_type8: response.data.dataResult.smart.fuelType8,
          countParty: response.data.dataResult.smart.countParty,
          countTypeFuel: response.data.dataResult.smart.countTypeFuel
        })
        setTypeController(response.data.dataResult.smart.type)
        
        setControllerInfo({
          connectId: response.data.dataResult.connectId,
          id: response.data.dataResult.id,
          type:response.data.dataResult.enumControllerType.id,
          companyId: response.data.dataResult.companyId,
          stationId: response.data.dataResult.stationId,
          hasModem: response.data.dataResult.hasModem,
          dayManufacture: response.data.dataResult.dayManufacture,
          typeControllerLevelMeter: response.data.dataResult.typeLevelMeters
        })
        setSelectedCompany({
            label: response.data.dataResult.station.name,
            value: response.data.dataResult.station.id
        })
        setPeriodLevelMeterRead(response.data.dataResult.periodLevelMeterRead)
        setTypeController(response.data.dataResult.type)

        let modeIntake = response.data.dataResult.modeIntake ? 1 : 0;
        let findModeIntake = listBooleans.filter((item) => item.value === modeIntake)
        setSelectedModeIntake({label: findModeIntake[0].label, value:findModeIntake[0].value})
        setValue("ModeIntake", selectedModeIntake)

        let findWorking = listStopMode.filter((item) => item.value === response.data.dataResult.stopMode)
        setValue("StopMode", {label: findWorking[0].label, value:findWorking[0].value})

        let findDoScanning = listStopMode.filter((item) => item.value == response.data.dataResult.doScanning)
        setValue("DoScanning", {label: findDoScanning[0].label, value:findDoScanning[0].value})
        setIsAutoRead(findDoScanning[0].value)
        
        let findRfid = listRfid.filter((item) => item.value === response.data.dataResult.smart.modeRfid)
        setValue("ModeRfid", {label: findRfid[0].label, value:findRfid[0].value})

        if(response.data.dataResult.enumControllerType.id != 3)
        {
          let findPanel = listPanel.filter((item) => item.value === response.data.dataResult.interfacePanel)
          setValue("InterfacePanel", {label: findPanel[0].label, value:findPanel[0].value})

          let topaz = response.data.dataResult.smart.modeTopaz20 ? 1 : 0;
          let findModeTopaz = listBooleans.filter((item) => item.value === topaz)
          setValue("ModeTopaz", {label: findModeTopaz[0].label, value:findModeTopaz[0].value})
        }

        let findWork = listTypeWork.filter((item) => item.value === response.data.dataResult.smart.modeFilling)
        setValue("ModeFilling", {label: findWork[0].label, value:findWork[0].value})

        setValue("Name", response.data.dataResult.name)
        setValue("ConnectId", response.data.dataResult.connectId)
        setValue("VersionSoftware", response.data.dataResult.versionSoftware)
        setValue("VersionProtocol", response.data.dataResult.versionProtocol)
        setValue("EnumControllerType", response.data.dataResult.enumControllerType.name)

        displayToast(response.data.message, response.data.isSuccess)       

        if(checkboxReadJournal) 
        {
          let connection = new signalR.HubConnectionBuilder()
            .withUrl("/jobprogress")
            .withAutomaticReconnect()
            .build();

          console.log("progress start")
          connection.on("progress", (data, jobId) => {
            console.log(data + ' ' + jobId);
            setTextActionRead(translated.ReadingData)
            setProgressBarJournal(data)
            if(data == 100) {
              setButtonReadSettings(false)
            }
        });

        connection.start()
          .then(() => connection.invoke("AssociateJob", jobId))
          .catch(err => console.error(err.toString()));
        }

        if(response.data.dataResult.enumControllerType.id == 3)
        {
          setButtonReadSettings(false)
        }

      })
      .catch(function (error) {
        console.log(error);
        setButtonReadSettings(false)
        displayToast(error.response.data.message, error.response.data.isSuccess)
      });
  }

  const disconnect = () => {
    api.get("/api/controllers/details/disconnect", {
        headers:{
          "content-type" : "application/json",
          "controllerId":params.id
        },
        params:{
          controllerId: params.id,
        },
        withCredentials:true
      })
      .then(function (response) {
        
        setTextActionRead('')
        setProgressBarJournal(0)
        displayToast(response.data.message, response.data.isSuccess)
        setButtonReadSettings(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleReboot = text => {
    setLoadingBtn({controllerBtn:true})
    setTextButtonController(text)
    api.get("/api/controllers/details/reboot", {
        headers:{
          "content-type" : "application/json",
          "controllerId":params.id
        },
        params:{
          controllerId: params.id,
        },
        withCredentials:true
      })
      .then(function (response) {
        displayToast(response.data.message, response.data.isSuccess)
        setLoadingBtn({controllerBtn:false})
        setTextButtonController(defaultTextButtonController)
      })
      .catch(function (error) {
        console.log(error);
        setLoadingBtn({controllerBtn:false})
        setTextButtonController(defaultTextButtonController)
        displayToast(error.response.data.message, error.response.data.isSuccess)
      });
  }

  const setFactoryController = text => {
    setLoadingBtn({controllerBtn:true})
    setTextButtonController(translated.SetFactory)
    api.get("/api/controllers/details/setfactory", {
        headers:{
          "content-type" : "application/json",
          "controllerId":params.id
        },
        params:{
          controllerId: params.id,
        },
        withCredentials:true
    })
    .then(function (response) {
      displayToast(response.data.message, response.data.isSuccess)
      setLoadingBtn({controllerBtn:false})
      setTextButtonController(defaultTextButtonController)
    })
    .catch(function (error) {
      console.log(error);
      setLoadingBtn({controllerBtn:false})
      setTextButtonController(defaultTextButtonController)
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  const handleReadUserLimits = () => {
    setTextActionRead("Read limits")
    api.get("/api/controllers/details/read-user-limits", {
      headers:{
        "content-type" : "application/json",
        "controllerId":params.id
      },
      params:{
        controllerId: params.id,
      },
      withCredentials:true
    })
    .then(function (response) {
      displayToast(response.data.message, response.data.isSuccess)
      setTextActionRead('Read data from controller successed')
    })
    .catch(function (error) {
      console.log(error);
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  const handleWriteUserLimits = text => {
    setLoadingBtn({controllerBtn:true})
    setTextButtonController(text)
    api.get("/api/controllers/details/write-user-limits", {
      headers:{
        "content-type" : "application/json",
        "controllerId":params.id
      },
      params:{
        controllerId: params.id,
      },
      withCredentials:true
    })
    .then(function (response) {
      displayToast(response.data.message, response.data.isSuccess)
      setLoadingBtn({controllerBtn:false})
      setTextButtonController(defaultTextButtonController)
    })
    .catch(function (error) {
      console.log(error);
      setLoadingBtn({controllerBtn:false})
      setTextButtonController(defaultTextButtonController)
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  const handleJournal = () => {
    setButtonReadSettings(true)
      api.post("/api/controllers/start-progress", {
        withCredentials:true
      })
      .then(function (response) {
        console.log("progress bar")
          handleGetConfig(response.data.jobId)
          //readJournal(connection, response.data.jobId)
      })
      .catch(function (error) {
        console.log(error);
        displayToast(error.response.data.message, error.response.data.isSuccess)
      });
  }

  const readJournal = (connection, jobId) => {
    setTextActionRead(translated.ReadingJournal)
    api.get("/api/controllers/details/journals/read-all-messages", {
        headers:{
          "content-type" : "application/json",
          "controllerId":params.id,
          "jobId": jobId
        },
        params:{
          controllerId: params.id,
          jobId: jobId
        },
        withCredentials:true
      })
      .then(function (response) {
        console.log("task")
        
        setProgressBarJournal(100)

        if(checkboxReadUserLimit) {
          handleReadUserLimits()
        }

        setTextActionRead('')
      })
      .catch(function (error) {
        console.log(error);
        displayToast(error.response.data.message, error.response.data.isSuccess)
      });
  }

  const handleActiveTab = data => {
    setCurrentActiveTab(data)
  }

  const onSubmit = (data) => {
    console.log(data)
    setBtnUpdateMainInfo(true)
    api.post("/api/controllers/details/update", {
      Name: data.Name,
      ConnectId: data.ConnectId,
      ModeIntake: data.ModeIntake.value,
      DoScanning: data.DoScanning.value,
      ModeFilling: data.ModeFilling.value,
      ModeTopaz: data.ModeTopaz.value,
      ModeRfid: data.ModeRfid.value,
      StopMode: data.StopMode.value,
      StationId: data.Station.value,
      InterfacePanel: data.InterfacePanel.value,
      Id: params.id
    })
    .then(function (response) {
      setBtnUpdateMainInfo(false)
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
      console.log(error)
      setBtnUpdateMainInfo(false)
      displayToast(error.response.data.errors.errorMessage, error.response.data.isSuccess)
    });
  }

    return(
      isAuth ?
      <>
       <div className=" d-sm-flex align-items-center justify-content-between">

          <h2 className="mb-sm-0 fw-semibold font-size-18 benzamat-font-bold text-black">
              <NavLink to="/controllers">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.125 13.8125L3.25 8.9375L8.125 4.0625" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8.125 20.3125H17.0625C18.5709 20.3125 20.0176 19.7133 21.0842 18.6467C22.1508 17.5801 22.75 16.1334 22.75 14.625V14.625C22.75 13.8781 22.6029 13.1385 22.3171 12.4485C22.0312 11.7584 21.6123 11.1315 21.0842 10.6033C20.556 10.0752 19.9291 9.65626 19.239 9.37043C18.549 9.08461 17.8094 8.9375 17.0625 8.9375H3.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
              </NavLink> 
              {translated.TitleSingle} - 
              {
                isLoading 
                ? <Skeleton width={200} height={25} /> 
                :
                <>
                  {controllerInfo.connectId}
                  {' '}
                  <Badge bg="info" className="font-size-14 ml-3">
                    {

                      controllerInfo.type == 3
                      ? typeController == 0
                        ? translated.Electro
                        : typeController == 1
                          ? translated.Livenka
                          : typeController == 2
                            ? translated.Topaz
                            : typeController == 3
                              ? translated.Gilbarco
                              : typeController == 4
                                ? translated.Tokheim
                                : null
                      : typeController == 1 || typeController == 0
                        ? translated.Electro
                        : typeController == 2
                          ? translated.Livenka 
                          : typeController == 3
                            ? translated.Topaz 
                            : typeController == 4
                            ? translated.Gilbarco 
                            : null
                    }
                  </Badge>
                  {' '}
                  <Badge bg="warning" className="font-size-14 mt-2 ml-3 text-black">Действие лицензии: {license.start} - {license.end}</Badge>
                  {/*statusConnect*/}
                </>
              }
          </h2>
          <div className="page-title-right">

          {
            isLoading
            ? <Skeleton width={200} height={30} />
            :
              !isAutoRead
              ?
              <DropdownButton title={textButtonController} disabled={loadingBtn.controllerBtn}>
                <Dropdown.Item onClick={() => setShowReadController(true)}>
                  {translated.ReadDataFromController}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setShowWriteController(true)}>
                  {translated.SetDataToController}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setShowModalChangeType(true)}>
                  {translated.SetConfiguration}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setShowFactoryController(true)}>
                  {translated.DefaultSettings}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setModalUpdateFirmware(true)}>
                  {translated.UpdateFirmware}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setModalFirmwareUpdateServer(true)}>
                  {translated.UpdateFirmwareFromServer}
                </Dropdown.Item>
              </DropdownButton>
              : null
          }
          </div>
        </div>

        <div className="row">
          {
            isAutoRead
            ? 
            <Alert variant="warning" className='mt-3'>
              {translated.MessageAutoReadActive}
            </Alert>
            : null
          }

          <Tabs defaultActiveKey="maininfo" id="uncontrolled-tab-example" onClick={(e) => handleActiveTab(e.target.dataset.rrUiEventKey)}  className="mt-2 nav-tabs-custom">
                       
              <Tab eventKey="maininfo" title={translated.Settings} tabClassName="font-size-15">
                {
                  currentActiveTab == "maininfo"
                  ? <MainInfoBenzaController translated={translated} translatedForm={translatedForm} controlerInfo={controllerInfo}/>
                  : null
                }            
              </Tab>

              {
                controllerInfo.type == 2 || controllerInfo.type == 3
                ?
                <Tab eventKey="levelmeter" title={translated.LevelMeters} tabClassName="font-size-15">
                  {                  
                    currentActiveTab == "levelmeter"
                    ? <LevelMeter translated={translated} translatedForm={translatedForm} controllerInfo={controllerInfo} periodLevelMeterRead={periodLevelMeterRead} />
                    : ''                
                  }
                </Tab>
                : null
              }

              <Tab eventKey="users" title={translated.Users} tabClassName="font-size-15">
                {
                  currentActiveTab == "users"
                  ? <ControllerUsers />
                  : ''
                }            
              </Tab>

              <Tab eventKey="ts" title={translated.Transports} tabClassName="font-size-15">
                {
                  currentActiveTab == "ts"
                  ? <TransportControllers controllerInfo={controllerInfo} />
                  : ''
                }
              </Tab>

              <Tab eventKey="companies" title={translated.Companies} tabClassName="font-size-15">
                {
                  currentActiveTab == "companies"
                  ? <ControllerCompanies controllerId={params.id} controllerInfo={controllerInfo}/>
                  : ''
                }
              </Tab>
          </Tabs>
        </div>

        {
          showModalChangeType
          ? 
          <ModalHardware 
            translatedForm={translatedForm} 
            translated={translated} 
            controllerData={controllerData} 
            controllerInfo={controllerInfo} 
            typeController={typeController} 
            showModalChangeType={showModalChangeType} 
            setShowModalChangeType={setShowModalChangeType}
          />
          : null
        }

        {
          modalFirmwareUpdateServer
          ? 
          <UpdateFromServer 
            translatedForm={translatedForm} 
            translated={translated} 
            controllerInfo={controllerInfo}
            setModalFirmwareUpdateServer={setModalFirmwareUpdateServer}
            modalFirmwareUpdateServer={modalFirmwareUpdateServer}
          />
          : null
        }

        <Modal backdrop="static" keyboard={false} show={showWriteController} onHide={() => setShowWriteController(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="text-black">{translated.SetDataToController}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="row">

            <div className="col-xs-12 col-lg-12 col-md-12">
                <span className="text-black">{textActionRead}</span>
              </div>

              <div className="col-xs-12 col-lg-12 col-md-12 mt-2">
              <Form.Check
                type="checkbox"
                id="checkbox-write-data"
                label={translated.WriteControllerConfig}
                className="text-black"
                checked={checkboxWriteSetting}
                onChange={(e) => setCheckboxWriteSetting(e.target.checked)}
              />
              </div>

              <div className="col-xs-12 col-lg-12 col-md-12 mt-2">
                <Form.Check
                  type="checkbox"
                  id="checkbox-time"
                  label={translated.SynchControllerTime}
                  className="text-black"
                  checked={checkboxWriteTime}
                  onChange={(e) => setCheckboxWriteTime(e.target.checked)}
                />
              </div>

              <div className="col-xs-12 col-lg-12 col-md-12 mt-2">
                <Form.Check
                  type="checkbox"
                  id="checkbox-users-limits"
                  label={translated.WriteLimits}
                  className="text-black"
                  checked={checkboxWriteUserLimit}
                  onChange={(e) => setCheckboxWriteUserLimit(e.target.checked)}
                />
              </div>

              <div className="col-xs-12 col-lg-12 col-md-12 mt-2">
                <Form.Check
                  type="checkbox"
                  id="checkbox-level-meter-data"
                  label={translated.WriteTable}
                  className="text-black"
                  checked={checkboxTable}
                  onChange={(e) => setCheckboxTable(e.target.checked)}
                />
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => setShowReadController(false)}>
              {translated.Cancel}
            </Button>
            <Button onClick={() => handleSetConfig()} disabled={buttonSetSettings}  variant="primary">
              {buttonSetSettings && (
                  <Spinner animation="border" variant="light" 
                      as="span"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: "5px" }}
                  />
              )}
              {buttonSetSettings && <span>{translated.SetData}</span>}
              {!buttonSetSettings && <span>{translated.SetData}</span>}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal backdrop="static"  keyboard={false} show={showReadController} onHide={() => setShowReadController(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="text-black">{translated.ReadDataFromController}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">

              <div className="col-xs-12 col-lg-12 col-md-12">
                <span className="text-black">{textActionRead}</span>
              </div>

              <div className="col-xs-12 col-lg-12 col-md-12 mt-2">
                <ProgressBar animated now={progressBarJournal} variant="success" className="mb-2" label={`${progressBarJournal}%`} />
              </div>

              <div className="col-xs-12 col-lg-12 col-md-12">
                <Form.Check
                  type="checkbox"
                  id="checkbox-read-journal"
                  label={translated.ReadLogEvents}
                  className="text-black"
                  checked={checkboxReadJournal}
                  onChange={(e) => setCheckBoxReadJournal(e.target.checked)}
                />
              </div>
              <div className="col-xs-12 col-lg-12 col-md-12 mt-2">
                <Form.Check
                  type="checkbox"
                  id="checkbox-read-users-limits"
                  label={translated.ReadLimits}
                  className="text-black"
                  checked={checkboxReadUserLimit}
                  onChange={(e) => setCheckboxReadUserLimit(e.target.checked)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => {
              disconnect()
            }}>
              {translated.Cancel}
            </Button>
            <Button onClick={() => handleJournal()} disabled={buttonReadSettings}  variant="primary">
              {buttonReadSettings && (
                  <Spinner animation="border" variant="light" 
                      as="span"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: "5px" }}
                  />
              )}
              {buttonReadSettings && <span>{translated.ReadDataButton}</span>}
              {!buttonReadSettings && <span>{translated.ReadDataButton}</span>}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal backdrop="static"
          keyboard={false} 
          show={modalUpdateFirmware} 
          onHide={() => setModalUpdateFirmware(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-black">{translated.UpdateFirmware}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">

              <UpdateFirmware controllerInfo={controllerInfo} translated={translated} translatedForm={translatedForm} />
          
            </div>
          </Modal.Body>
        </Modal>

        <Modal backdrop="static" keyboard={false} show={showSetFactoryController} onHide={() => setShowFactoryController(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="text-black">{translated.SetFactory}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="text-black text-center col-xs-12 col-lg-12 col-md-12">
                <h4>{translated.TextSetFactory}</h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setFactoryController()} className="btn btn-success">{translated.Submit}</Button>
            <Button onClick={() => setShowFactoryController(false)} className="btn btn-danger">{translated.Cancel}</Button>
          </Modal.Footer>
        </Modal>
      </>
    :
    <Redirect to='/login'/>
    );
}

export default ControllerSingle;