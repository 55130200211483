import React, { useContext, useEffect, useMemo, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Nav, Tooltip, OverlayTrigger, Form, Card, Spinner, Offcanvas} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import CurrencyInput from 'react-currency-input-field';
import './../../boxicons.css';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';  
import { LimitDayMonthTransport } from './limitDayMonth';
import { LimitAbsoluteTransport } from './limitAbsolute';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateUser } from '../../lang/users/user';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import api from '../../utils/axiosInterceptions';

export const TransportLimits = ({typeLimit, setTypeLimit, transportData, permission}) => {

    const params = useParams();
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [showFormAdd, setShowFormAdd] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [btnDayLoad, setBtnDayLoad] = useState(false)
    const [btnMonthLoad, setBtnMonthLoad] = useState(false)
    const [listLimits, setListLimits] = useState({})
    const [historyLimit, setHistoryLimit] = useState([])
    const [liters, setLiters] = useState(0)
    const [loadingDataGrid, setLoadingDataGrid] = useState(false)
    const translated = useLittera(TranslateUser);
    const translatedForm = useLittera(FormLocale);
    const [lastChangeTypeLimit, setLastChangeTypeLimit] = useState(new Date())
    const methods = useLitteraMethods();
    const [dayLimit, setDayLimit] = useState({
        limit:0,
        balance:0
    })
    const [monthLimit, setMonthLimit] = useState({
        limit:0,
        balance:0
    });
    const [loadingDataBtn, setLoadingDataButton] = useState(false)

    const handleShow = () =>  setShowFormAdd(true)
    const handleClose = () => setShowFormAdd(false);

    const handleDayLimit = () => {
        setBtnDayLoad(true)
        api.get("/api/transport/details/limit-day", {
            headers:{
                "content-type" : "application/json",
                "id":params.id
            },
            params:{
                id: params.id,
            }
        })
        .then(function (response) {
            setDayLimit(response.data.dataResult.countDayLimit)
            displayToast(response.data.message, response.data.isSuccess)
            setCurrentTime(moment().format("DD.MM.YYYY"))
            setBtnDayLoad(false)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleMonthLimit = () => {
        setBtnMonthLoad(true)
        api.get("/api/transport/details/limit-month", {
            headers:{
                "content-type" : "application/json",
                "id":params.id
            },
            params:{
                id: params.id,
            }
        })
        .then(function (response) {
            setMonthLimit(response.data.dataResult.countMonthLimit)
            displayToast(response.data.message, response.data.isSuccess)
            setCurrentTime(moment().format("DD.MM.YYYY HH:mm"))
            setBtnMonthLoad(false)
        })
        .catch(function (error) {
            console.log(error);
            setBtnMonthLoad(false)
        });
    }
    
    const handleUpdateHistoryLimit = () => {
        setLoadingDataButton(true)
        setLoadingDataGrid(true)
        
        api.get("/api/transport/details/history-limits", {
            headers:{
                "content-type" : "application/json",
                "id":params.id
            },
            params:{
                id: params.id,
            }
        })
        .then(function (response) {
            setLoadingDataButton(false)
            setLoadingDataGrid(false)
            setHistoryLimit(response.data.listDatas)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const onHandleUpdateLimit = dataLimit => {    
        setLoadingDataButton(true)
        api.post('/api/transport/details/update-limit', {
            Id: params.id,
            Liters: liters,
            EnumTypeLimitId: dataLimit.EnumTypeLimit.id
        })
        .then(function (response) {
            console.log("update limit")
            
            setLoadingDataButton(false)
            displayToast(response.data.message, response.data.isSuccess)

            if (response.data.dataResult.enumTypeLimitId == 1) {
                setDayLimit({
                    limit: response.data.dataResult.countDayLimit,
                    balance: response.data.dataResult.balanceDay
                })
            } else {
                setMonthLimit({
                    limit: response.data.dataResult.countMonthLimit,
                    balance: response.data.dataResult.balanceMonth
                })
            }

        })
        .catch(function (error) {
            console.log(error);
            setLoadingDataButton(false)
        });
    }

    const getEnumTypeLimits = () => {
        api.get("/api/enum-type-limits/all", {
        })
        .then(function (response) {
            const addTypeLimit = response.data.listDatas.filter((i) => i.id == 3 || i.id == 4 || i.id == 5).map(item => ({
                ...item,
                value: item.id,
                label: item.name
            }))
    
            setListLimits(addTypeLimit)
            let findTypeLimit = response.data.listDatas.filter((item) => item.id == typeLimit)
            setValue("TypeLimit", {label:findTypeLimit[0].name, value:findTypeLimit[0].id})
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getHistoryLimits = () => {
        api.get("/api/transport/details/history-limits", {
            headers:{
                "content-type" : "application/json",
                "id":params.id
            },
            params:{
                id: params.id,
            }
        })
        .then(function (response) {
            
            //setHistoryLimit(response.data.listDatas)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getLimits = () => {
        api.get("/api/transport/details/limit-day", {
            headers:{
                "content-type" : "application/json",
                "id":params.id
            },
            params:{
                id: params.id,
            }
        })
        .then(function (response) {
            setDayLimit({
                limit: response.data.dataResult.countDayLimit,
                balance: response.data.dataResult.balanceDay
            })
            setCurrentTime(moment().format("DD.MM.YYYY"))
        })
        .catch(function (error) {
            console.log(error);
        });
        
        api.get("/api/transport/details/limit-month", {
            headers:{
                "content-type" : "application/json",
                "id":params.id
            },
            params:{
                id: params.id,
            }
        })
        .then(function (response) {
            
            setMonthLimit({
                limit: response.data.dataResult.countMonthLimit,
                balance: response.data.dataResult.balanceMonth
            })
            setCurrentTime(moment().format("DD.MM.YYYY"))
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const changeTypeLimit = (typeLimitId) => {
        api.post('/api/transport/details/change-type-limit', {
            Id: transportData.dataResult.id,
            EnumTypeLimitId:typeLimitId,
            withCredentials:true
        })
        .then(function (response) {
            setLastChangeTypeLimit(response.data.dataResult.lastChangeTypeLimit)
            setTypeLimit(typeLimitId)
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            displayToast(error.response.data.message, error.response.data.isSuccess)
        });
    }

    useEffect(() => {
        getLimits()
        getHistoryLimits()
        getEnumTypeLimits()
    }, [])

    const displayToast = (message, status) => {

        if(status) {
            toast.success(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"dark"
            });    
        } else {
            toast.error(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"dark"
            });
        }
    }

    const columns = useMemo(
        () => [
          {
            accessorFn: (row) => row.liters > 0 
              ? <span className="text-success">{row.liters}</span> 
              : <span className="text-danger">{row.liters}</span>,
            accessorKey: 'liters',
            header: translated.Liters,
          },
          {
            accessorFn: (row) =>  row.enumTypeLimit.name,
            accessorKey: 'enumTypeLimit',
            header: translated.TypeLimit,
          },
          {
            accessorFn: (row) => moment(row.createdAt).format('DD.MM.YYYY HH:mm'),
            accessorKey: 'createddAt',
            header: translated.CreatedDate,
          },
        ],
        [],
      );


    return (
        <>
        <div className="row mt-4">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

            <div className="col-xs-12 col-lg-3 col-md-3">
                <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                        <Nav.Link eventKey="first">
                            {translated.LimitPlan}
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link eventKey="second">
                            {translated.HistoryChangeLimit}
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>

            <div className="col-xs-12 col-lg-9 col-md-9">
                <Tab.Content>

                <Tab.Pane eventKey="first">
                <div className="row">

                <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.TypeLimit}</Form.Label>
                        <Controller
                            name="TypeLimit"                                
                            control={control}
                            rules={{ required: translatedForm.Required }}
                            render={({ field:{value} }) => (
                            <Select
                                value={value}
                                options={listLimits}                        
                                placeholder={translatedForm.SelectFromList}
                                classNamePrefix="select"
                                onChange={(item) => {
                                    setValue("TypeLimit", {label:item.label, value:item.value})
                                    changeTypeLimit(item.value)
                                }}
                            />
                            )}
                        />
                    {errors.TypeLimit && 
                        <span className="text-danger font-size-13">{errors.TypeLimit.message}</span>
                    }
                </Form.Group>

                {
                    typeLimit == 5
                    ? <LimitDayMonthTransport translatedForm={translatedForm} translated={translated} permission={permission}/>
                    : typeLimit == 3
                        ? <LimitAbsoluteTransport translatedForm={translatedForm} translated={translated} transportData={transportData} permission={permission}/>
                        : typeLimit == 4
                            ? null
                            : null
                }
                
                </div>
                </Tab.Pane>

                <Tab.Pane eventKey="second">
                <Button bg="primary" onClick={handleUpdateHistoryLimit} disabled={loadingDataBtn} className="mb-2">
                    {loadingDataBtn && (
                        <Spinner animation="border" variant="light" 
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                        />
                    )}
                    {loadingDataBtn && <span>{translated.UpdateLimits}</span>}
                    {!loadingDataBtn && <span>{translated.UpdateLimits}</span>}
                </Button>

                <MaterialReactTable 
                    columns={columns} 
                    data={historyLimit}
                    localization={MRT_Localization_EN}
                    initialState={{ showColumnFilters: true }}
                    enableTopToolbar={false}
                    enableRowVirtualization
                    muiTablePaperProps={{
                    elevation: 1,
                    }}
                    muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                        fontWeight: 'bold',
                        color:"#000",
                        backgroundColor:'#F3F3F3',
                        fontFamily: 'BenzamatRegular',
                    },
                    }}
                    muiTableBodyCellProps={{
                    sx: {
                        color:"#000",
                        fontFamily: 'BenzamatRegular',
                    },
                    }}
                />

                </Tab.Pane>

                </Tab.Content>
            </div>

            </Tab.Container>
        </div>

        
        <Offcanvas placement="end" show={showFormAdd} onHide={handleClose}>
        <Offcanvas.Header closeButton>
            <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
                {translated.EditLimit}
            </Offcanvas.Title>
        </Offcanvas.Header>
        <div className="dropdown-divider"></div>
        <Offcanvas.Body> 
            <Form onSubmit={handleSubmit(onHandleUpdateLimit)}>

                <Form.Group>

                <Form.Label className="text-black">{translated.TypeLimit}</Form.Label>
                <Controller
                    name="EnumTypeLimit"                                
                    control={control}
                    rules={{ required: translatedForm.Required}}
                    render={({ field }) => (
                    <Select 
                        {...field}                               
                        isSearchable
                        options={listLimits}                        
                        placeholder={translatedForm.SelectFromList}
                        classNamePrefix="select"
                    />
                    )}
                />
                {errors.EnumTypeLimit && 
                    <span className="text-danger font-size-13">{errors.EnumTypeLimit.message}</span>
                }   
                
                <Form.Label className="text-black mt-3">{translated.Liters}</Form.Label>
                <Controller
                    name="Liters"                                
                    control={control}
                    render={({ field }) => (
                    <CurrencyInput
                        placeholder={translatedForm.EnterValue(4)}
                        defaultValue={0}
                        decimalsLimit={2}
                        className="form-control"
                        onValueChange={(value) => setLiters(value)}
                    />
                    )}
                />

                </Form.Group>

                <div className="form-group mt-3">
                <button type="submit" disabled={loadingDataBtn} className="btn btn-primary">
                    {loadingDataBtn && (
                        <Spinner animation="border" variant="light" 
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                        />
                    )}
                    {loadingDataBtn && <span>{translatedForm.SaveChanges}</span>}
                    {!loadingDataBtn && <span>{translatedForm.SaveChanges}</span>}
                    </button>
                </div>

            </Form>
        </Offcanvas.Body>
        </Offcanvas>
    </>
    )
}