import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateUser = {

    Title:{
        en_US: "Users",
        ru_RU: "Пользователи"
    },
    TitleSingle:{
        en_US: "User",
        ru_RU: "Пользователь"
    },
    Fullname:{
        en_US: "Full name",
        ru_RU: "ФИО"
    },
    Role:{
        en_US: "Role",
        ru_RU: "Роль"
    },
    LK:{
        en_US: "Access",
        ru_RU: "Доступ в ЛК"
    },
    AccessActive:{
        en_US: "Active",
        ru_RU: "Включен"
    },
    AccessNotActive:{
        en_US: "Disable",
        ru_RU: "Отключен"
    },
    ButtonRfid:{
        en_US: "Get card number",
        ru_RU: "Получить номер карты"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    ActionsWithUser:{
        en_US: "Action with users",
        ru_RU: "Действия с пользователями"
    },
    AddUser:{
        en_US: "Add user",
        ru_RU: "Добавить пользователя"
    },
    AddUserTitle:{
        en_US: "Create user",
        ru_RU: "Добавление пользователя"
    },
    UploadFromExcel:{
        en_US: "Upload from excel",
        ru_RU: "Загрузить из Excel"
    },
    PermissionDenied:{
        en_US: "Permission denied",
        ru_RU: "У вас недостаточно прав доступа для просмотра информации"
    },
    Firstname:{
        en_US: "Firstname",
        ru_RU: "Имя"
    },
    Lastname: {
        en_US: "Lastname",
        ru_RU: "Фамилия"
    },
    Middlename: {
        en_US: "Middlename",
        ru_RU: "Отчество"
    },
    Company: {
        en_US: "Company",
        ru_RU: "Компания"
    },
    Role:{
        en_US: "System role",
        ru_RU: "Роль в системе"
    },
    AccessSystem:{
        en_US: "Access to system",
        ru_RU: "Доступ к ЛК"
    },
    ServiceDate: {
        en_US: "Maintain up to",
        ru_RU: "Обслуживать до"
    },
    Contract:{
        en_US: "Contract number",
        ru_RU: "Договор"
    },
    BenzamatOffice:{
        en_US: "ID from benzamatOffice",
        ru_RU: "ID из benzamatOffice"
    },
    ResetAbsoluteLimit:{
        en_US: "Reset limit",
        ru_RU: "Сбросить лимит"
    },
    StartDateFilling:{
        en_US: "Service start date",
        ru_RU: "Дата начала обслуживания"
    },
    TypeAuth:{
        en_US: "Type auth",
        ru_RU: "Тип авторизации"
    },
    BenzaRfidType:{
        en_US: "RFID type",
        ru_RU: "Тип считывателя"
    },
    Password:{
        en_US: "New password",
        ru_RU: "Новый пароль"
    },
    CurrentPassword:{
        en_US: "Current password",
        ru_RU: "Текущий пароль"
    },
    NumberRfid:{
        en_US: "RFID number",
        ru_RU: "Номер RFID"
    },
    AuthTransport:{
        en_US: "Use auth transport",
        ru_RU: "Авторизация ТС"
    },
    TypeLimit:{
        en_US: "Type limit",
        ru_RU: "Тип лимита"
    },
    LimitAbsolute:{
        en_US: "Limit absolute",
        ru_RU: "Абсолютный"
    },
    LimitDay:{
        en_US: "Daily limit",
        ru_RU: "Лимит день"
    },
    LimitMonth:{
        en_US: "Month limit",
        ru_RU: "Лимит месяц"
    },
    Login:{
        en_US: "Log in",
        ru_RU: "Логин"
    },
    Yes:{
        en_US: "Active",
        ru_RU: "Да"
    },
    No:{
        en_US: "Disabled",
        ru_RU: "Нет"
    },
    WarningLimitMonth:{
        en_US: "Monthly limit cannot be less than daily limit",
        ru_RU: "Лимит в месяц не может быть меньше дневного лимита"
    },
    Lock:{
        en_US: "Lock",
        ru_RU: "Заблокировать"
    },
    Unlock:{
        en_US: "Unlock",
        ru_RU: "Разблокировать"
    },
    LockMessage:{
        en_US: "The user is blocked, further writing to the controller is impossible",
        ru_RU: "Пользователь заблокирован дальнейшая запись в контроллер невозможна"
    },
    General:{
        en_US: "General",
        ru_RU: "Основная информация"
    },
    Limits:{
        en_US: "Limits",
        ru_RU: "Лимиты"
    },
    Options:{
        en_US: "Options",
        ru_RU: "Параметры"
    },
    HistoryAuth:{
        en_US: "History auth",
        ru_RU: "История авторизаций"
    },
    UserRights:{
        en_US: "User rights",
        ru_RU: "Права на контроллерах"
    },
    AdminFillTank:{
        en_US: "Administrator (fill into measuring tank)",
        ru_RU: "Администратор ТРК (налив в мерник)" 
    },
    FuelIntake:{
        en_US: "Fuel supply",
        ru_RU: "Прием топлива" 
    },
    ViewLevelSenser:{
        en_US: "View level sensor indications",
        ru_RU: "Просмотр показания уровнемеров" 
    },
    TurningOffSirena:{
        en_US: "Turning off sirena",
        ru_RU: "Выключение сирены" 
    },
    Controllers:{
        en_US: "Controllers",
        ru_RU: "Контроллеры" 
    },
    MessagePassword:{
        en_US: "The output of the user's password is not provided in the system, since all passwords are stored in encrypted form",
        ru_RU: "Вывод пароля пользователя не предусмотрен в системе, так как все пароли хранятся в зашифрованном виде",
    },
    IPAddress:{
        en_US: "IP address",
        ru_RU: "IP адрес" 
    },
    DateAuth:{
        en_US: "Auth date",
        ru_RU: "Дата входа" 
    },
    LimitPlan:{
        en_US: "Limit plan",
        ru_RU: "Лимитный план" 
    },
    HistoryChangeLimit:{
        en_US: "History change",
        ru_RU: "История изменений" 
    },
    Balance:{
        en_US: "Balance",
        ru_RU: "Остаток" 
    },
    Limit:{
        en_US: "Limit",
        ru_RU: "Лимит" 
    },
    EditLimit:{
        en_US: "Edit limit",
        ru_RU: "Изменение лимита" 
    },
    Liters:{
        en_US: "Liters",
        ru_RU: "Литры" 
    },
    CreatedDate:{
        en_US: "Date",
        ru_RU: "Дата" 
    },
    UpdateLimits:{
        en_US: "Get limit's data",
        ru_RU: "Обновить информацию" 
    },
    ListUsers:{
        en_US: "List of users",
        ru_RU: "Выберите пользователей" 
    },
    ListCompanies:{
        en_US: "List of companies",
        ru_RU: "Выберите компанию" 
    },
    UploadFromExcelHeader:{
        en_US: "Upload users from Excel",
        ru_RU: "Загрузка пользователей из Excel"  
    },
    UploadInfoAlert:{
        en_US: "You can upload a file with the extension .XLS or .XLSX",
        ru_RU: "Допускается загрузка файла с расширением .XLS или .XLSX"  
    },
    UploadDangerAlert:{
        en_US: "To bulk upload users, download and fill out the template according to the data specified inside",
        ru_RU: "Для массовой загрузки пользователей скачайте и заполните шаблон в соответствии с указанными внутри данными"  
    },
    SelectFile:{
        en_US: "Select file",
        ru_RU: "Выберите файл"
    },
    DownloadTemplate:{
        en_US: "Download template",
        ru_RU: "Скачать шаблон"
    },
    SelectAllUsers:{
        en_US: "Select all users",
        ru_RU: "Выбрать всех пользователей"
    },
    MessageSelectUsers:{
        en_US: "Only those transports that are not blocked, not deleted in the system and not added to the controller will be added to the controller",
        ru_RU: "В контроллер будут добавлены только тот транспорт, который: не заблокирован, не удалены в системе и не добавлен в контроллер"
    },
    MessageWarningUpdateLimits:{
        en_US: "When updating the daily and monthly limit, an automatic recalculation takes place taking into account refuelings for the current day and month",
        ru_RU: "При обновлении дневного и месячного лимита происходит автоматический пересчет с учетом заправок за текущий день и месяц"
    },
    Submit:{
        en_US: "Submit",
        ru_RU: "Подтвердить"
    },
    Cancel:{
        en_US: "Cancel",
        ru_RU: "Отмена"
    },
    TextDelete:{
        en_US: "Are you sure you want to delete the user?",
        ru_RU: "Вы уверены, что хотите удалить пользователя?"
    },
    HeaderDelete:{
        en_US: "Delete the user",
        ru_RU: "Удаление пользователя"
    }
};
