import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateStation = {

    Title:{
        en_US: "Stations",
        ru_RU: "Станции"
    },
    TitleSingle:{
        en_US: "Station",
        ru_RU: "Станция"
    },
    Name:{
        en_US: "Name",
        ru_RU: "Название"
    },
    Description:{
        en_US: "Description",
        ru_RU: "Описание"
    },
    Status:{
        en_US: "Status",
        ru_RU: "Статус"
    },
    Company:{
        en_US: "Company",
        ru_RU: "Компания"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    Add:{
        en_US: "Add station",
        ru_RU: "Добавить станцию"
    },
    SerialNumber:{
        en_US: "Serial number",
        ru_RU: "Серийный номер"
    },
    OrderNumber:{
        en_US: "Order number",
        ru_RU: "Наряд заказа"
    },
    Longtitude:{
        en_US: "Longtitude",
        ru_RU: "Долгота"
    },
    Langtitude:{
        en_US: "Lantitude",
        ru_RU: "Широта"
    },
    AddStationTitle:{
        en_US: "Create station",
        ru_RU: "Добавление станции"
    },
    Active:{
        en_US: "Active",
        ru_RU: "Активна"
    },
    Deleted:{
        en_US: "Deleted",
        ru_RU: "Удалена"
    },
    Empty:{
        en_US: "Empty",
        ru_RU: "Не указано"
    },
    Timezone:{
        en_US: "Timezone",
        ru_RU: "Часовой пояс"
    },
    PermissionDenied:{
        en_US: "Permission denied",
        ru_RU: "У вас недостаточно прав для просмотра данного раздела"
    },
    Sections:{
        en_US: "Sections",
        ru_RU: "Секции"
    },
    Controllers:{
        en_US: "Controllers",
        ru_RU: "Контроллеры"
    },
    General:{
        en_US: "General",
        ru_RU: "Основная информация"
    },
    SaveChanges:{
        en_US: "Save changes",
        ru_RU: "Сохранить изменения"
    },
    Occupancy:{
        en_US: "Occupancy sections, %",
        ru_RU: "Заполненность секций, %"
    },
    OccupancySingle:{
        en_US: "Occupancy",
        ru_RU: "Заполнено"
    }
};
