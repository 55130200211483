import React, { useContext, useEffect, useState } from 'react';
import CatalogSingle from '../single';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { CurrentSelectLanguage } from '../../../helpers/language';
import { FormLocale } from '../../../lang/form';
import { TranslateFuel } from '../../../lang/catalogs/translateFuel';

export const Fuel = () => {

    const FUEL_VALUES_URL = "api/fuels/all"
    const FUEL_VALUES_CREATE = "api/fuels/create"
    const FUEL_VALUES_UPDATE = "api/fuels/update"
    const FUEL_VALUES_FIND = "api/fuels/find"
    const translated = useLittera(TranslateFuel)
    const translatedForm = useLittera(FormLocale);
    const methods = useLitteraMethods();

    useEffect(() => {
        methods.setLocale(CurrentSelectLanguage);
    }, [])

    const single = {
        all_url: FUEL_VALUES_URL,
        create_url: FUEL_VALUES_CREATE,
        update_url: FUEL_VALUES_UPDATE,
        find_url: FUEL_VALUES_FIND,
        name: translated.FuelType
    }

    return (
        <>
            <CatalogSingle single={single} translated={translated} translatedForm={translatedForm} />
        </>
    )
}