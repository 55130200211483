import React, { useState, useEffect, useMemo, useContext, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Form, Nav, Tabs, Tab, Spinner, DropdownButton, Badge, Dropdown, Alert} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import './../../boxicons.css';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import api from '../../utils/axiosInterceptions';

export const ControllerGroups = () => {

  const router = useHistory();
  const { control, register, handleSubmit, setValue, reset, formState, formState: { errors }} = useForm();
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [listController, setListController] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [controllerBtn, setControllerBtn] = useState(false)
  const [loadingData, setLoadingData] = useState({
    button:false,
    grid:false
  })
  const handleShow = () => {
    setShowFormAdd(true)
  };
  const handleClose = () => setShowFormAdd(false);
  const {isAuth} = useContext(AuthContext);

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
  
    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
   
  }

  const getControllers = () => {
    setIsLoading(true)
    api.get('/api/controllers/groups', {
      withCredentials:true
    })
    .then(function (response) {
      console.log('controller groups')
       
      setListController(response.data.listDatas)
      setIsLoading(false)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  useEffect(() => {
    getControllers()
  }, [])

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.name,
        accessorKey: 'name', //access nested data with dot notation
        header: 'Название',
      },
      {
        accessorFn: (row) => row.companyName,
        accessorKey: 'companyName', //access nested data with dot notation
        header: 'Компания',
      }
    ],
    [],
  );

  return (

    <>
    <MaterialReactTable 
        columns={columns} 
        data={listController}
        localization={MRT_Localization_RU}
        initialState={{ showColumnFilters: true }}
        enableRowVirtualization
        enableTopToolbar={false}
        muiTablePaperProps={{
            elevation: 1,
        }}
        muiTableHeadCellProps={{
            //simple styling with the `sx` prop, works just like a style prop in this example
            sx: {
            fontWeight: 'bold',
            color:"#000",
            backgroundColor:'#F3F3F3',
            fontFamily: 'BenzamatRegular',
            },
        }}
        muiTableBodyCellProps={{
            sx: {
            color:"#000",
            fontFamily: 'BenzamatRegular',
            },
        }}
    />
    </>
  );

}

