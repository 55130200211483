import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateStations = {

    Name:{
        en_US: "Name",
        ru_RU: "Название"
    },
    Description:{
        en_US: "Description",
        ru_RU: "Описание"
    },
    SerialNumber:{
        en_US: "Serial number",
        ru_RU: "Серийный номер"
    },
    OrderNumber:{
        en_US: "Order number",
        ru_RU: "Наряд заказа"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    Empty:{
        en_US: "Empty",
        ru_RU: "Не указано"
    }
};
