import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateUsers = {

    Fullname:{
        en_US: "Fullname",
        ru_RU: "ФИО"
    },
    Role:{
        en_US: "System Role",
        ru_RU: "Роль"
    },
    LimitMonth:{
        en_US: "Limit month",
        ru_RU: "Лимит мес."
    },
    LimitDay:{
        en_US: "Limit day",
        ru_RU: "Лимит день"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
};
