import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Nav, Tooltip, OverlayTrigger, Form, Card, Alert, Spinner, Offcanvas} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import CurrencyInput from 'react-currency-input-field';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js"

export const LimitAbsolute = ({translated, translatedForm, permission, userData}) => {

    const params = useParams();
    const [currentTime, setCurrentTime] = useState(0)
    const [btnLoad, setBtnLoad] = useState(false)
    const [limitAbsolute, setLimitAbsolute] = useState(0)
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [showFormAdd, setShowFormAdd] = useState(false)
    const [listLimits, setListLimits] = useState({})
    const [liters, setLiters] = useState(0)
    const [loadingDataBtn, setLoadingDataButton] = useState(false)
    const [startDateFilling, setStartDateFilling] = useState(null)

    const handleShow = () => {
        setShowFormAdd(true)
        getEnumTypeLimits()
    }

    const handleClose = () => setShowFormAdd(false);

    const getEnumTypeLimits = () => {
        api.get("/api/enum-type-limits/all", {
        })
        .then(function (response) {
            const addTypeLimit = response.data.listDatas.filter((i) => i.id == 3).map(item => ({
                ...item,
                value: item.id,
                label: item.name
            }))
            setListLimits(addTypeLimit)
        })
        .catch(function (error) {
            console.log(error);
        });    
    }

    useEffect(() => {
        getAbsoluteLimit()
        setCurrentTime(moment().format("DD.MM.YYYY"))
        setStartDateFilling(moment(userData.dateLastFillingTime).format("DD.MM.YYYY HH:mm"))  
    }, [])

    const getAbsoluteLimit = () => {
        api.get("/api/users/details/limit-absolute", {
            headers:{
                "content-type" : "application/json",
                "id":params.id
            },
            params:{
                id: params.id,
            }
        })
        .then(function (response) {
            console.log("absolute")
            
            setLimitAbsolute(response.data.dataResult.balanceAbsolute)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const onHandleSetDate = date => {
        console.log(date)
        api.post('/api/users/details/set-date-absolute-limit', {
            Id: params.id,
            DateLastFillingString: date
        })
        .then(function (response) {     
             
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const resetAbsoluteLimit = () => {
        setLoadingDataButton(true)
        api.post('/api/users/details/reset-absolute-limit', {
            Id: params.id
        })
        .then(function (response) {     
            
            setLoadingDataButton(false)
            setLimitAbsolute(response.data.dataResult.balanceAbsolute)
            setStartDateFilling(moment(response.data.dataResult.dateLastFillingTime).format("DD.MM.YYYY HH:mm"))
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingDataButton(false)
        });
    }

    const onHandleUpdateLimit = dataLimit => {    
        setLoadingDataButton(true)
        api.post('/api/users/details/update-limit', {
            Id: params.id,
            Liters: liters,
            EnumTypeLimitId: dataLimit.EnumTypeLimit.id
        })
        .then(function (response) {     
                   
            setLoadingDataButton(false)
            displayToast(response.data.message, response.data.isSuccess)
            setLimitAbsolute(response.data.dataResult.balanceAbsolute)
            setCurrentTime(moment().format("DD.MM.YYYY"))
        })
        .catch(function (error) {
            console.log(error);
            displayToast(error.response.data.errors[0].errorMessage, error.response.data.isSuccess)
            setLoadingDataButton(false)
        });
    }

    const displayToast = (message, status) => {

        if(status) {
            toast.success(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"dark"
            });
        
            } else {
            toast.error(message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme:"dark"
            });
        }
    
    }

    return (
            <div className="col-xs-12 col-md-6 col-lg-6">
                <Card border="light" className="border shadow-none">
                <Card.Body>
                <div className="d-flex">
                <h5 className="benzamat-font-bold flex-fill text-black">{translated.LimitAbsolute} {currentTime}</h5>

                {
                    permission.update || permission.fullAccess
                    ?
                    <OverlayTrigger
                        placement="left"
                        overlay={
                        <Tooltip style={{fontSize:14+"px"}}>
                            {translated.EditLimit}
                        </Tooltip>
                        }
                    >
                    <Button onClick={() => handleShow()}  className="btn btn-light justify-content-end btn-sm">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.24112 16.8749H3.75C3.58424 16.8749 3.42527 16.8091 3.30806 16.6918C3.19085 16.5746 3.125 16.4157 3.125 16.2499V12.7588C3.125 12.6767 3.14117 12.5954 3.17258 12.5196C3.20398 12.4438 3.25002 12.3749 3.30806 12.3168L12.6831 2.94185C12.8003 2.82464 12.9592 2.75879 13.125 2.75879C13.2908 2.75879 13.4497 2.82464 13.5669 2.94185L17.0581 6.43296C17.1753 6.55017 17.2411 6.70915 17.2411 6.87491C17.2411 7.04067 17.1753 7.19964 17.0581 7.31685L7.68306 16.6918C7.62502 16.7499 7.55612 16.7959 7.48029 16.8273C7.40447 16.8587 7.32319 16.8749 7.24112 16.8749Z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.625 5L15 9.375" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.45986 16.8349L3.16455 12.5396" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </Button> 
                    </OverlayTrigger>
                    : ''
                }

                </div>
                <span className="benzamat-font-bold text-black d-block mb-3 mt-2" style={{fontSize:18+"px"}}>{translated.Balance}: {limitAbsolute}</span>

                <div className="d-grid gap-2">
                    <button onClick={() => resetAbsoluteLimit()} className="btn btn-primary">
                        {loadingDataBtn && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingDataBtn && <span>{translated.ResetAbsoluteLimit}</span>}
                        {!loadingDataBtn && <span>{translated.ResetAbsoluteLimit}</span>}
                    </button>
                    <span className="mt-2 text-black">{translated.StartDateFilling}</span>
                    <Flatpickr
                        className='form-control'
                        options={{ 
                            time_24hr: true,  
                            enableTime: false,
                            dateFormat: "d.m.Y",
                            locale: Russian
                        }}
                        placeholder={translatedForm.SelectFromList}
                        value={startDateFilling}
                        onChange={([date]) => {
                            onHandleSetDate(date)
                        }}
                    />
                </div>
                     
                </Card.Body>
                </Card>

            <Offcanvas placement="end" show={showFormAdd} onHide={() => handleClose()}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>{translated.EditLimit}</Offcanvas.Title>
            </Offcanvas.Header>
            <div className="dropdown-divider"></div>
                <Offcanvas.Body> 
                    <Form onSubmit={handleSubmit(onHandleUpdateLimit)}>

                        <Form.Group>

                        <Form.Label className="text-black">{translated.TypeLimit}</Form.Label>
                        <Controller
                            name="EnumTypeLimit"                                
                            control={control}
                            rules={{ required: translatedForm.Required }}
                            render={({ field }) => (
                            <Select 
                                {...field}                               
                                isSearchable
                                options={listLimits}                        
                                placeholder={translated.SelectFromList}
                                classNamePrefix="select"
                            />
                            )}
                        />
                        {errors.EnumTypeLimit && 
                            <span className="text-danger font-size-13">{errors.EnumTypeLimit.message}</span>
                        }   
                        
                        <Form.Label className="text-black mt-3">{translated.Liters}</Form.Label>
                        <Controller
                            name="Liters"                                
                            control={control}
                            render={({ field }) => (
                            <CurrencyInput
                                placeholder={translatedForm.EnterValue(2)}
                                defaultValue={0}
                                decimalsLimit={2}
                                className="form-control"
                                onValueChange={(value) => setLiters(value)}
                            />
                            )}
                        />

                        </Form.Group>

                        <div className="form-group mt-3">
                        <button type="submit" disabled={loadingDataBtn} className="btn btn-primary">
                            {loadingDataBtn && (
                                <Spinner animation="border" variant="light" 
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {loadingDataBtn && <span>{translatedForm.SaveChanges}</span>}
                            {!loadingDataBtn && <span>{translatedForm.SaveChanges}</span>}
                            </button>
                        </div>

                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}