import React, { useContext, useEffect, useState, useMemo, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Nav, Form, Spinner, Offcanvas} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import './../../boxicons.css';
import { UserControllers } from './controllers';
import { UserLimits } from './limits';
import { UserFillings } from './fillings';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateUser } from '../../lang/users/user';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import api from '../../utils/axiosInterceptions';
import InputMask from 'react-input-mask';

export const UserSingle = () => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, getValues, formState, formState: { errors }} = useForm();
    const [listCompany, setListCompany] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [rfidBtnLoad, setRfidBtnLoad] = useState(false)
    const [currentTabActive, setCurrentTabActive] = useState('')
    const [listHistoryAuth, setListHistoryAuth] = useState([])
    const [isExistDataAuth, setIsExistDataAuth] = useState(false)
    const [userHasLogin, setUserHasLogin] = useState(false)
    const [isAdminWebService, setIsAdminWebService] = useState(false)
    const [isDriver, setIsDriver] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    const [listRoles, setListRoles] = useState([])    
    const translated = useLittera(TranslateUser);
    const translatedForm = useLittera(FormLocale);
    const methods = useLitteraMethods();
    const [permission, setPermission] = useState({
      read: false,
      update: false,
      delete: false,
      fullAccess: false
    })
    const [selectedCompany, setSelectedCompany] = useState({
      label:'',
      value:0
    })
    const [selectedRole, setSelectedRole] = useState({
      label:'',
      value:0
    })
    const [selectedAuthAuto, setSelectedAuthAuto] = useState({
      label:'',
      value:0
    })
    const [selectedTypeAuth, setSelectedTypeAuth] = useState({
      label:'',
      value:0
    })
    const [typeAuth, setTypeAuth] = useState({
      rfid:false,
      password:false,
      rfid_password:false,
    })
    const [listTypeAuth, setListTypeAuth] = useState([])
    const [selectedAdminController, setSelectedAdminController] = useState({
      label:translated.No,
      value:false
    })
    const [selectedAdminFillTank, setSelectedAdminFillTank] = useState({
      label:translated.No,
      value:false
    })
    const [selectedAdminLevel, setSelectedAdminLevel] = useState({
      label:translated.No,
      value:false
    })
    const [selectedIsExistDataAuth, setSelectedIsExistDataAuth] = useState({
      label:translated.No,
      value:false
    })
    const [selectedAdminSirena, setSelectedAdminSirena] = useState({
      label:translated.No,
      value:false
    })
    const [FIO, setFIO] = useState('')
    const [userData, setUserData] = useState({})
    const [loadingData, setLoadingData] = useState({
      grid:false,
      button:false
    })
    const formatChars = {
      '*': '[A-Fa-f0-9]'
    }
    const listDisable = [
      {label:translated.No, value:1 },
      {label:translated.Yes, value:0}
    ]

    const listBooleanAdminController = [
      {label:translated.No, value:1},
      {label:translated.Yes, value:0}
    ]

    const listBoolean = [
      {label:translated.No, value:0},
      {label:translated.Yes, value:1}
    ]

    const handleTabActive = data => {
      if(data == "history-auth") {
        getHistoryAuth()
      }
      setCurrentTabActive(data)
    }

    const columns = useMemo(
      () => [
        {
          accessorFn: (row) => row.ipAddress,
          accessorKey: 'ipAddress',
          header: translated.IPAddress
        },
        {
          accessorFn: (row) => row.userAgent,
          accessorKey: 'userAgent',
          header: 'User-Agent',
        },
        {
          accessorFn: (row) => moment(row.dateAuth).format("DD.MM.YYYY HH:mm:ss"),
          accessorKey: 'dateAuth',
          header: translated.DateAuth,
        }
      ],
      [],
    );

    useEffect(() => {
      methods.setLocale(CurrentSelectLanguage);
      getRoles()
      getTypeAuth()
      setIsLoading(true)
      getUserData();
    }, [])

    const getRoles = () => {
      api.get('/api/roles/all', {
        withCredentials:true
      })
      .then(function (response) {
        console.log("roles")
        
        const add = response.data.listDatas.map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }))
        setListRoles(add)  
      })
      .catch(function (error) {
          console.log(error);
      });
    }

    const getTypeAuth = () => {
      api.get('/api/type-auths/all', {
        withCredentials:true
      })
      .then(function (response) {
        const add = response.data.listDatas.map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }))
        setListTypeAuth(add)  
      })
      .catch(function (error) {
          console.log(error);
      });
    }

    const getHistoryAuth = () => {
      api.get("/api/users/details/history-auth", {
        headers:{
          "content-type" : "application/json",
          "userId":params.id
        },
        params:{
          userId: params.id,
        }
      })
      .then(function (response) {
        console.log("history")
        setListHistoryAuth(response.data.listDatas)
      })
      .catch(function (error) {
        console.log(error);
      });

    }

    const onSubmit = data => {

      setLoadingData({button:true})

      let id = getValues('Id')
      let firstname = getValues('Firstname')
      let lastname = getValues('Lastname')
      let middlename = getValues('Middlename')
      let password_controller = getValues('PasswordController')
      let contract = getValues('Contract')
      let login = getValues('Login')
      let password = getValues('Password')
      let current_password = getValues('CurrentPassword')
      let rfid_key = getValues('NumberRfid')
      let service_date = getValues('ServiceDate')

      api.post('/api/users/details/update', {
        Id: id,
        Firstname: firstname,
        Lastname: lastname,
        Middlename: middlename,
        AdminController: selectedAdminController.value,
        AdminFillTank: selectedAdminFillTank.value,
        AdminViewLevelMeters: selectedAdminLevel.value,
        AdminOffSirena: selectedAdminSirena.value,
        IsExistDataAuth: selectedIsExistDataAuth.value,
        PasswordController: password_controller,
        Contract: contract,
        Login: login,
        Password: current_password,
        NewPassword: password,
        RoleId: selectedRole.value,
        AuthAuto: selectedAuthAuto.value,
        RfidKey: rfid_key,
        TypeAuthId: selectedTypeAuth.value,
        CompanyId: selectedCompany.value,
        ServiceDate: service_date != null ? service_date : null
      })
      .then(function (response) {
          displayToast(response.data.message, response.data.isSuccess)
          setLoadingData({button:false, grid:false})
          getUserData()
      })
      .catch(function (error) {
        setLoadingData({button:false})
        console.log(error)
        let message = "";

        if(error.response.data.errors != null)
        {
          error.response.data.errors.map((item) => {
            message += item.errorMessage + "\n";
          })
          displayToast(error.response.data.errors[0].errorMessage, error.response.data.isSuccess)
        }
        else
        {
          displayToast(error.response.data.message, error.response.data.isSuccess)
        }
      });
    }

    const displayToast = (message, status) => {

      if(status){
        toast.success(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });

      } else {
        toast.error(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });
      }
    
    }

    const getCompanies = () => {
      api.get('/api/company/all', {
          withCrenditials:true
      })
      .then(function(response){
        
        setListCompany(response.data.listDatas)
      })
      .catch(function (error) {
        console.log(error);
      });
    }  

    const getUserData = () => {
      api.get("/api/users/details", {
        headers:{
          "content-type" : "application/json",
          "id":params.id
        },
        params:{
          id: params.id,
        }
      })
      .then(function (response) {
        console.log("user")
        

        setPermission({
          read: response.data.read,
          delete: response.data.delete,
          update: response.data.update,
          fullAccess: response.data.fullAccess
        })

        setUserData(response.data.dataResult)
        setFIO(response.data.dataResult.lastname+' '+response.data.dataResult.firstname)
        setValue("Firstname", response.data.dataResult.firstname)
        setValue("Lastname", response.data.dataResult.lastname)
        setValue("Middlename", response.data.dataResult.middlename)
        setValue("Contract", response.data.dataResult.contract)
        setValue("Login", response.data.dataResult.login)
        setValue("PasswordController", response.data.dataResult.passwordController)
        setValue("ServiceDate", moment(response.data.dataResult.serviceDateTime).format("DD.MM.YYYY"))
        setValue("ExternalId", response.data.dataResult.externalId)
        setValue("Id", response.data.dataResult.id)
        setIsDeleted(response.data.dataResult.isBlocked)

        setIsExistDataAuth(response.data.dataResult.isExistDataAuth)
        let findExistAuth = listBoolean.filter((item) => item.value == response.data.dataResult.isExistDataAuth)
        setSelectedIsExistDataAuth({label:findExistAuth[0].label, value:findExistAuth[0].value})
        setValue("IsExistDataAuth", selectedIsExistDataAuth)

        if(response.data.dataResult.login != null)
        {
          setUserHasLogin(true)
          getCompanies()
        }
        else
        {
          setUserHasLogin(false)
        }

        if(response.data.dataResult.role.id == 5)
        {
          setIsDriver(true)
        }

        if(response.data.dataResult.role.id == 1)
        {
          setIsAdminWebService(true)
        }
        else
        {
          setSelectedCompany({label: response.data.dataResult.company.name, value: response.data.dataResult.company.id})
          setValue("Company", selectedCompany)
          setIsAdminWebService(false)
        }

        setValue("NumberRfid", response.data.dataResult.rfidKey)

        setSelectedTypeAuth({label: response.data.dataResult.typeAuth.name, value: response.data.dataResult.typeAuth.id})
        setValue("TypeAuth", selectedTypeAuth)

        let findAuthAuth = listBoolean.filter((item) => item.value == response.data.dataResult.authAuto)
        setSelectedAuthAuto({label:findAuthAuth[0].label, value:findAuthAuth[0].value})
        setValue("AuthAuto", selectedAuthAuto)

        if(response.data.dataResult.typeAuth.id == 1) {

          setTypeAuth({rfid:true, password:false, rfid_password:false})
          setValue("NumberRfid", response.data.dataResult.rfidKey)

        } else if(response.data.dataResult.typeAuth.id == 2) {

          setTypeAuth({rfid:false, password:true, rfid_password:false })
          setValue("Password", response.data.dataResult.password)

        } else {

          setTypeAuth({rfid:false, password:false, rfid_password:true })
          setValue("Password", response.data.dataResult.password)
          setValue("NumberRfid", response.data.dataResult.rfidKey)

        }       

        setSelectedRole({label: response.data.dataResult.role.name, value: response.data.dataResult.role.id})
        setValue("Role", selectedRole)

        let findAdminController = listBooleanAdminController.filter((item) => item.value == response.data.dataResult.adminController)
        setSelectedAdminController({label:findAdminController[0].label, value:findAdminController[0].value})
        setValue("AdminController", selectedAdminController)

        let findAdminSirena = listBooleanAdminController.filter((item) => item.value == response.data.dataResult.adminOffSirena)
        setSelectedAdminSirena({label:findAdminSirena[0].label, value:findAdminSirena[0].value})
        setValue("AdminOffSirena", selectedAdminSirena)

        let findAdminFillTank = listBooleanAdminController.filter((item) => item.value == response.data.dataResult.adminFillTank)
        setSelectedAdminFillTank({label:findAdminFillTank[0].label, value:findAdminFillTank[0].value})
        setValue("AdminFillTank", selectedAdminFillTank)

        let findAdminLevel = listBooleanAdminController.filter((item) => item.value == response.data.dataResult.adminViewLevelMeters)
        setSelectedAdminLevel({label:findAdminLevel[0].label, value:findAdminLevel[0].value})
        setValue("AdminViewLevelMeters", selectedAdminLevel)

        setIsLoading(false)

      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const getLastCard = () => {
      setRfidBtnLoad(true)
      api.get("/api/users/details/cards/last", {
        withCredentials:true
      })
      .then(function (response) {
        
        setValue("NumberRfid", response.data.dataResult)
        setRfidBtnLoad(false)
      })
      .catch(function (error) {
        console.log(error);
        setRfidBtnLoad(false)
      }); 
    }

    const blocked = () => {
      let id = getValues("Id")
      api.post('/api/users/details/blocked', {
        Id: id,
        IsBlocked: isDeleted ? false : true,
        withCredentials:true
      })
      .then(function (response) {
        
        setIsDeleted(response.data.dataResult.isBlocked)
        displayToast(response.data.message, response.data.isSuccess)
      })
      .catch(function (error) {
        error.response.data.errors.map((item) => {
          displayToast(item.errorMessage, error.response.data.isSuccess)
        })
      });
    }

    return(
      isAuth ?
      <>
        <div className=" d-sm-flex align-items-center justify-content-between">
          <h2 className="mb-sm-0 fw-semibold font-size-18 benzamat-font-bold text-black">
              <NavLink to="/users">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.125 13.8125L3.25 8.9375L8.125 4.0625" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8.125 20.3125H17.0625C18.5709 20.3125 20.0176 19.7133 21.0842 18.6467C22.1508 17.5801 22.75 16.1334 22.75 14.625V14.625C22.75 13.8781 22.6029 13.1385 22.3171 12.4485C22.0312 11.7584 21.6123 11.1315 21.0842 10.6033C20.556 10.0752 19.9291 9.65626 19.239 9.37043C18.549 9.08461 17.8094 8.9375 17.0625 8.9375H3.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
              </NavLink> 
              {translated.TitleSingle} - {isLoading ? <Skeleton width={200} height={25} /> : FIO}
          </h2>
          <div class="page-title-right">
          {
              isLoading
              ? <Skeleton width={200} height={30} />
              : 
                permission.update || permission.fullAccess
                ?
                <Button type="button" onClick={() => onSubmit()} className="me-2" variant="primary" disabled={loadingData.button}>
                  {loadingData.button && (
                      <Spinner animation="border" variant="light" 
                          as="span"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: "5px" }}
                      />
                  )}
                  {loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                  {!loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                </Button>
                : null
            }

          {
              isLoading
              ? <Skeleton width={200} height={30} />
              : 
                permission.delete || permission.fullAccess
                ?
                  isDeleted
                  ?
                  <Button onClick={() => blocked()} variant="success" className="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-lock-off" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M15 11h2a2 2 0 0 1 2 2v2m0 4a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2h4"></path>
                        <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0"></path>
                        <path d="M8 11v-3m.719 -3.289a4 4 0 0 1 7.281 2.289v4"></path>
                        <path d="M3 3l18 18"></path>
                      </svg>
                      {translated.Unlock}
                  </Button>
                  :
                  <Button onClick={() => blocked()} variant="danger" className="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-lock" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z"></path>
                        <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0"></path>
                        <path d="M8 11v-4a4 4 0 1 1 8 0v4"></path>
                      </svg>
                      {translated.Lock}
                  </Button>
                : null
            }
          </div>
    </div>
      
    <div className="row">
      {
        isDeleted
        ?
        <div className="col-xs-12 col-lg-12 col-md-12 mt-3">
          <div className="alert alert-danger">
            <span>{translated.LockMessage}</span>
          </div>
        </div>
        : null
      }
      
      <Tabs defaultActiveKey="maininfo" onClick={(e) => handleTabActive(e.target.dataset.rrUiEventKey)}  id="uncontrolled-tab-example" className="mt-1 nav-tabs-custom">

        <Tab eventKey="maininfo" title={translated.Options} tabClassName="font-size-15">
          <div className="row mt-4">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

              <div className="col-xs-12 col-lg-3 col-md-3">
                  <Nav variant="pills" onClick={(e) => handleTabActive(e.target.dataset.rrUiEventKey)} className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        {translated.General}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="secure_controller">
                        {translated.UserRights}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="auth">
                        {translated.AccessSystem}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="history-auth">
                        {translated.HistoryAuth}
                      </Nav.Link>
                    </Nav.Item>
                </Nav>
              </div>

              <div className="col-xs-12 col-lg-9 col-md-9">                
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Control {...register("Id")} type="hidden"  />
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="row">
                        
                      <div className="col-xs-12 col-md-6 col-lg-6">

                        {
                          isLoading
                          ? <Skeleton className="mb-4" count={4} height={30}/>
                          :
                          <>
                              <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.Lastname} <sup className="text-danger">*</sup></Form.Label>
                                <Form.Control 
                                    {...register("Lastname", {
                                        minLength : {
                                        value: 5,
                                        message: translatedForm.MinLengthString(5)
                                        },
                                        maxLength : {
                                        value: 100,
                                        message: translatedForm.MaxLenghtString(100)
                                        },
                                        required: translatedForm.Required
                                    })}
                                    type="text" maxLength="100" isInvalid={errors.Lastname}
                                    placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                                  {errors.Lastname && 
                                    <span className="text-danger font-size-13">{errors.Lastname.message}</span>
                                  }
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.Firstname} <sup className="text-danger">*</sup></Form.Label>
                                <Form.Control 
                                    {...register("Firstname", {
                                        minLength : {
                                        value: 5,
                                        message: translatedForm.MinLengthString(5)
                                        },
                                        maxLength : {
                                        value: 100,
                                        message: translatedForm.MaxLenghtString(100)
                                        },
                                        required: translatedForm.Required
                                    })}
                                    type="text" maxLength="100" isInvalid={errors.Firstname}
                                    placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                                  {errors.Firstname && 
                                    <span className="text-danger font-size-13">{errors.Firstname.message}</span>
                                  }
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.Middlename}</Form.Label>
                                <Form.Control 
                                    {...register("Middlename", {
                                        minLength : {
                                        value: 5,
                                        message: translatedForm.MinLengthString(5)
                                        },
                                        maxLength : {
                                        value: 100,
                                        message: translatedForm.MaxLenghtString(100)
                                        }
                                    })}
                                    type="text" maxLength="100" isInvalid={errors.Middlename}
                                     placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                                  {errors.Middlename && 
                                    <span className="text-danger font-size-13">{errors.Middlename.message}</span>
                                  }
                              </Form.Group>

                              {
                                !isAdminWebService
                                ?
                                <Form.Group className="mb-3">
                                  <Form.Label className="text-black">{translated.Company} <sup className="text-danger">*</sup></Form.Label>
                                    <Controller
                                      name="Company"                                
                                      control={control}
                                      rules={{ required: translatedForm.Required }}
                                      render={({ field }) => (
                                      <Select
                                        onChange={(item) => {
                                          setSelectedCompany({label: item.label, value:item.value})
                                          setValue("Company", selectedCompany)
                                        }}
                                        value={selectedCompany}                        
                                        placeholder={translatedForm.SelectFromList}
                                        classNamePrefix="select"
                                        options={listCompany}
                                      />
                                    )}
                                  />
                                  {errors.Company && 
                                      <span className="text-danger font-size-13">{errors.Company.message}</span>
                                  }
                                </Form.Group>
                                : null
                              }

                              <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.ServiceDate}</Form.Label>
                                <Controller
                                    name="ServiceDate"                                
                                    control={control}
                                    render={({ field:{value} }) => (
                                    <Flatpickr
                                        className='form-control'
                                        options={{                                            
                                            dateFormat: "d.m.Y",
                                            locale: Russian
                                        }}
                                        value={value}
                                        placeholder={translatedForm.SelectFromList}
                                        onChange={([date]) => {
                                            setValue("ServiceDate", moment(date).format("DD.MM.YYYY"))
                                        }}
                                    />
                                    )}
                                />
                              </Form.Group>                                                

                              {/*
                                permission.update || permission.fullAccess
                                ?
                                <div className="form-group mt-3">
                                  <Button type="submit" variant="primary" disabled={loadingData.button}>
                                    {loadingData.button && (
                                        <Spinner animation="border" variant="light" 
                                            as="span"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}
                                    {loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                                    {!loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                                  </Button>
                                </div>
                                : ''
                              */}
                          </>
                        }
                      </div>

                        <div className="col-xs-12 col-lg-6 col-md-6">                          
                          {
                            isLoading
                            ? <Skeleton className="mb-4" count={4} height={30} />
                            :
                            <>
                            <Form.Group className="mb-3">
                              <Form.Label className="text-black">{translated.Role} <sup className="text-danger">*</sup></Form.Label>
                                <Controller
                                  name="Role"                                
                                  control={control}
                                  rules={{ required: translatedForm.Required }}
                                  render={({ field }) => (
                                  <Select
                                    onChange={(item) => {
                                      setSelectedRole({label: item.label, value:item.value})
                                      setValue("Role", selectedRole)
                                    }}
                                    value={selectedRole}
                                    options={listRoles}                        
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                  />
                                )}
                              />
                              {errors.Role && 
                                  <span className="text-danger font-size-13">{errors.Role.message}</span>
                              }
                            </Form.Group>

                            <>
                              <Form.Group className="mb-3">
                              <Form.Label className="text-black">{translated.AuthTransport} <sup className="text-danger">*</sup></Form.Label>
                                <Controller
                                    name="AuthAuto"                                
                                    control={control}
                                    rules={{ required: translatedForm.Required }}
                                    render={({ field }) => (
                                    <Select
                                      onChange={(item) => {
                                        setSelectedAuthAuto({label:item.label, value:item.value})
                                        setValue("AuthAuto", selectedAuthAuto)
                                      }}
                                      value={selectedAuthAuto}
                                      options={listBoolean}                        
                                      placeholder={translatedForm.SelectFromList}
                                      classNamePrefix="select"
                                    />
                                    )}
                                />
                                {errors.AuthAuto && 
                                    <span className="text-danger font-size-13">{errors.AuthAuto.message}</span>
                                }
                              </Form.Group>

                              <Form.Group className="mb-3">
                              <Form.Label className="text-black">{translated.TypeAuth} <sup className="text-danger">*</sup></Form.Label>
                                <Controller
                                    name="TypeAuth"                                
                                    control={control}
                                    rules={{ required: translatedForm.Required }}
                                    render={({ field }) => (
                                    <Select
                                      onChange={(item) => {
                                        if(item.id == 1) {
                                          setTypeAuth({rfid:true, password:false, rfid_password:false})
                                        } else if (item.id == 2) {
                                          setTypeAuth({rfid:false, password:true, rfid_password:false})
                                        } else {
                                          setTypeAuth({rfid:false, password:false, rfid_password:true})
                                        }
                                        setSelectedTypeAuth({label: item.label, value:item.value})
                                        setValue("TypeAuth", selectedTypeAuth)
                                      }}
                                      value={selectedTypeAuth}
                                      options={listTypeAuth}                        
                                      placeholder={translatedForm.SelectFromList}
                                      classNamePrefix="select"
                                    />
                                    )}
                                />
                                {errors.TypeAuth && 
                                    <span className="text-danger font-size-13">{errors.TypeAuth.message}</span>
                                }
                              </Form.Group>

                              {
                                typeAuth.password || typeAuth.rfid_password
                                ?
                                <Form.Group className="mb-3 mt-3">
                                  <Form.Label className="text-black">{translated.Password}</Form.Label>
                                  <Form.Control 
                                    {...register("PasswordController", {
                                      minLength : {
                                        value: 3,
                                        message: translatedForm.MinLengthString(3)
                                      },
                                      maxLength : {
                                        value: 15,
                                        message: translatedForm.MaxLenghtString(15)
                                      },
                                    })}
                                    type="number" maxLength="15" isInvalid={errors.PasswordController}
                                      placeholder={translatedForm.EnterValue(15)} className="form-control"  />
                                    {errors.PasswordController && 
                                      <span className="text-danger font-size-13">{errors.PasswordController.message}</span>
                                    }
                                </Form.Group>
                                : null
                              }

                              {
                                typeAuth.rfid || typeAuth.rfid_password
                                ?
                                <>
                                  {/*<Form.Group className="mb-3 mt-3">
                                    <Form.Label className="text-black">{translated.NumberRfid} <sup className="text-danger">*</sup></Form.Label>
                                    <Form.Control 
                                      {...register("NumberRfid", {
                                        maxLength : {
                                          value: 15,
                                          message: translatedForm.MaxLenghtString(15)
                                        },
                                        minLength : {
                                          value: 5,
                                          message: translatedForm.MinLengthString(5)
                                        },
                                        required: translatedForm.Required
                                      })}
                                      type="text" maxLength="15" isInvalid={errors.NumberRfid} 
                                      placeholder={translatedForm.EnterValue(15)} className="form-control"  />
                                      {errors.NumberRfid && 
                                        <span className="text-danger font-size-13">{errors.NumberRfid.message}</span>
                                      }
                                  </Form.Group>*/}
                                  <Form.Group className="mb-3 mt-3">
                                    <Form.Label className="text-black">{translated.NumberRfid} <sup className="text-danger">*</sup></Form.Label>
                                    <Controller
                                        name="NumberRfid"                                
                                        control={control}
                                        rules={{ required: translatedForm.Required }}
                                        render={({ field: {value} }) => (
                                            <InputMask 
                                                mask="**************"
                                                className="form-control"
                                                type="text"
                                                formatChars={formatChars}
                                                value={value}
                                                placeholder={translatedForm.EnterValue(14)}
                                                onChange={(item) => {
                                                  setValue("NumberRfid", item.target.value)
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.NumberRfid && 
                                        <span className="text-danger font-size-13">{errors.NumberRfid.message}</span>
                                    }
                                  </Form.Group>

                                  <Button type="submit" onClick={() => getLastCard()} className="mb-2" variant="primary" disabled={rfidBtnLoad}>
                                      {rfidBtnLoad && (
                                          <Spinner animation="border" variant="light" 
                                              as="span"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                              style={{ marginRight: "5px" }}
                                          />
                                      )}
                                      {rfidBtnLoad && <span>{translated.ButtonRfid}</span>}
                                      {!rfidBtnLoad && <span>{translated.ButtonRfid}</span>}
                                  </Button>
                                </>
                                : null
                              }
                            </>

                            <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.Contract}</Form.Label>
                                <Form.Control 
                                    {...register("Contract", {
                                        minLength : {
                                        value: 5,
                                        message: translatedForm.MinLengthString(5)
                                        },
                                        maxLength : {
                                        value: 100,
                                        message: translatedForm.MaxLenghtString(100)
                                        }
                                    })}
                                    type="text" maxLength="100" isInvalid={errors.Contract}
                                    placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                                  {errors.Contract && 
                                    <span className="text-danger font-size-13">{errors.Contract.message}</span>
                                  }
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label className="text-black">External Id</Form.Label>
                                <Form.Control 
                                    {...register("ExternalId")}
                                    type="text" className="form-control"  />
                              </Form.Group>
                            </>
                          }            
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="secure_controller">
                      <div className="col-xs-12 col-lg-6 col-md-6">
                        
                        <Form.Group className=" mb-3">
                          <Form.Label className="text-black">{translated.AdminFillTank}</Form.Label>
                            <Controller
                                name="AdminController"                                
                                control={control}
                                rules={{ required: translatedForm.Required }}
                                render={({ field }) => (
                                <Select    
                                  {...field}      
                                  options={listBooleanAdminController}
                                  value={selectedAdminController}                      
                                  placeholder={translatedForm.SelectFromList}
                                  classNamePrefix="select"
                                  onChange={(item) => {
                                    console.log(item)
                                    setSelectedAdminController({label:item.label, value:item.value})
                                    setValue("AdminController", selectedAdminController)
                                  }}
                                />
                                )}
                            />
                            {errors.AdminTRK && 
                                <span className="text-danger font-size-13">{errors.AdminTRK.message}</span>
                            }
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label className="text-black">{translated.FuelIntake}</Form.Label>
                            <Controller
                                name="AdminFillTank"                                
                                control={control}
                                rules={{ required: translatedForm.Required }}
                                render={({ field }) => (
                                <Select    
                                  {...field}      
                                  options={listBooleanAdminController}
                                  value={selectedAdminFillTank}                      
                                  placeholder={translatedForm.SelectFromList}
                                  classNamePrefix="select"
                                  onChange={(item) => {
                                    setSelectedAdminFillTank({label:item.label, value:item.value})
                                    console.log(item)
                                    setValue("AdminFillTank", selectedAdminFillTank)
                                  }}
                                />
                                )}
                            />
                            {errors.AdminFillTank && 
                                <span className="text-danger font-size-13">{errors.AdminFillTank.message}</span>
                            }
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label className="text-black">{translated.ViewLevelSenser}</Form.Label>
                            <Controller
                                name="AdminViewLevelMeters"                                
                                control={control}
                                rules={{ required: translatedForm.Required }}
                                render={({ field }) => (
                                <Select    
                                  {...field}      
                                  options={listBooleanAdminController}                        
                                  placeholder={translatedForm.SelectFromList}
                                  classNamePrefix="select"
                                  value={selectedAdminLevel}
                                  onChange={(item) => {
                                    console.log(item)
                                    setSelectedAdminLevel({label:item.label, value:item.value})
                                    setValue("AdminViewLevelMeters", selectedAdminLevel)
                                  }}
                                />
                                )}
                            />
                            {errors.AdminViewLevelMeters && 
                                <span className="text-danger font-size-13">{errors.AdminViewLevelMeters.message}</span>
                            }
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label className="text-black">{translated.TurningOffSirena}</Form.Label>
                            <Controller
                                name="AdminOffSirena"                                
                                control={control}
                                rules={{ required: translatedForm.Required}}
                                render={({ field }) => (
                                <Select    
                                  {...field}      
                                  options={listBooleanAdminController} 
                                  value={selectedAdminSirena}                       
                                  placeholder={translatedForm.SelectFromList}
                                  classNamePrefix="select"
                                  onChange={(item) => {
                                    console.log(item)
                                    setSelectedAdminSirena({label:item.label, value:item.value})
                                    setValue("AdminOffSirena", selectedAdminSirena)
                                  }}
                                />
                                )}
                            />
                            {errors.AdminOffSirena && 
                              <span className="text-danger font-size-13">{errors.AdminOffSirena.message}</span>
                            }
                        </Form.Group>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="auth">
                      <div className="col-xs-12 col-lg-12 col-md-12">
                        <div className="alert alert-info">
                          {translated.MessagePassword}
                        </div>
                      </div>
                      <div className="col-xs-12 col-lg-6 col-md-6">

                        <Form.Group className="mb-3">
                            <Form.Label className="text-black">{translated.AccessSystem}</Form.Label>
                              <Controller
                                  name="IsExistDataAuth"                                
                                  control={control}
                                  rules={{ required: translatedForm.Required}}
                                  render={({ field }) => (
                                    <Select    
                                      {...field}      
                                      options={listBoolean}                        
                                      placeholder={translatedForm.SelectFromList}
                                      classNamePrefix="select"
                                      value={selectedIsExistDataAuth}
                                      onChange={(item) => {
                                        setSelectedIsExistDataAuth({label:item.label, value:item.value})
                                        setIsExistDataAuth(item.value)
                                        setValue("IsExistDataAuth", selectedIsExistDataAuth)
                                      }}
                                    />
                                  )}
                              />
                              {errors.AdminOffSirena && 
                                  <span className="text-danger font-size-13">{errors.AdminOffSirena.message}</span>
                              }
                        </Form.Group>

                          {
                            isExistDataAuth
                            ?
                            <>
                              <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.Login}</Form.Label>
                                  <Form.Control 
                                      {...register("Login", {
                                        minLength : {
                                          value: 5,
                                          message: translatedForm.MinLengthString(5)
                                        },
                                        maxLength : {
                                          value: 150,
                                          message: translatedForm.MaxLenghtString(150)
                                          }
                                      })}
                                      type="text" maxLength="150" isInvalid={errors.Login}  
                                      placeholder={translatedForm.EnterValue(150)} className="form-control"  />
                                    {errors.Login && 
                                    <span className="text-danger font-size-13">{errors.Login.message}</span>
                                    }
                              </Form.Group>

                              {
                                userHasLogin
                                ?
                                <Form.Group className="mb-3">
                                  <Form.Label className="text-black">{translated.CurrentPassword}</Form.Label>
                                  <Form.Control 
                                      {...register("CurrentPassword", {
                                        minLength : {
                                          value: 5,
                                          message: translatedForm.MinLengthString(5)
                                          },
                                        maxLength : {
                                          value: 20,
                                          message: translatedForm.MaxLenghtString(20)
                                          }
                                      })}
                                      type="text" maxLength="20" isInvalid={errors.CurrentPassword}
                                      placeholder={translatedForm.EnterValue(20)} className="form-control"  />
                                    {errors.CurrentPassword && 
                                      <span className="text-danger font-size-13">{errors.CurrentPassword.message}</span>
                                    }
                                </Form.Group>
                                : null
                              }

                              <Form.Group className="mb-3">
                                <Form.Label className="text-black">{translated.Password}</Form.Label>
                                <Form.Control 
                                    {...register("Password", {
                                      minLength : {
                                        value: 5,
                                        message: translatedForm.MinLengthString(5)
                                        },
                                      maxLength : {
                                        value: 20,
                                        message: translatedForm.MaxLenghtString(20)
                                        }
                                    })}
                                    type="text" maxLength="20" isInvalid={errors.Password}
                                    placeholder={translatedForm.EnterValue(20)} className="form-control"  />
                                  {errors.Password && 
                                    <span className="text-danger font-size-13">{errors.Password.message}</span>
                                  }
                              </Form.Group>

                            </>
                            : null
                          }
                          
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="history-auth">
                      {
                        currentTabActive == "history-auth"
                        ?
                        <MaterialReactTable 
                          columns={columns} 
                          data={listHistoryAuth}
                          localization={MRT_Localization_EN}
                          initialState={{ showColumnFilters: true }}
                          enableTopToolbar={false}
                          enableRowVirtualization
                          muiTablePaperProps={{
                            elevation: 1,
                          }}
                          muiTableHeadCellProps={{
                            //simple styling with the `sx` prop, works just like a style prop in this example
                            sx: {
                              fontWeight: 'bold',
                              color:"#000",
                              backgroundColor:'#F3F3F3',
                              fontFamily: 'BenzamatRegular',
                            },
                          }}
                          muiTableBodyCellProps={{
                            sx: {
                              color:"#000",
                              fontFamily: 'BenzamatRegular',
                            },
                          }}
                        />
                        : null
                      }
                    </Tab.Pane>
                  
                  </Tab.Content>

                </Form>
              </div>

            </Tab.Container>
          </div>
        </Tab>
   
        <Tab eventKey="limits" title={translated.Limits} tabClassName="font-size-15">
          {
            currentTabActive == "limits"
            ? <UserLimits translated={translated} translatedForm={translatedForm} permission={permission} userData={userData} />
            : null
          }
        </Tab>

        <Tab eventKey="controllers" title={translated.Controllers} tabClassName="font-size-15">
          {
            currentTabActive == "controllers"
            ? <UserControllers />
            : null
          }
        </Tab>

      </Tabs>
    </div>
    </>
    :
    <Redirect to='/login'/>
    );
}