import React, { useContext, useEffect, useMemo, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Nav, Form, OffCanvas ,Table, Spinner, Offcanvas} from 'react-bootstrap';
import { UpdateCatalogForm } from './updateForm';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import moment from 'moment';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

const CatalogSingle = ({single, translated, translatedForm}) => {

  console.log(single)
  const router = useHistory()
  const [catalogData, setCatalogData] = useState({});
  const [showFormAdd, setShowFormAdd] = useState(false);
  const [catalogName, setCatalogName] = useState('')
  const [listValues, addValueToList] = useState([])
  const [isUpdate, setIsUpdate] = useState(false)
  const [loadingData, setLoadingData] = useState({
    button:false,
    grid:false
  })
  const {isAuth} = useContext(AuthContext);
  const { register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
  const handleShow = () => setShowFormAdd(true);
  const handleClose = () => setShowFormAdd(false);

  const onSubmit = data => {
    console.log(data)
    setLoadingData({button:true})
    let link = isUpdate ? "api/fuels/update" : "api/fuels/create"
    api.post(link, {
      Id: isUpdate ? data.Id : 0,
      Name: data.Value,
      withCredentials:true
    })
    .then(function (response) {
      
      displayToast(response.data.message, response.data.isSuccess)
      setLoadingData({button:false})
      addValueToList(response.data.listDatas)
      reset({
        Value:''
      })
    })
    .catch(function (error) {
      console.log(error);
      setLoadingData({button:false})
    });
  }

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });

    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
  
  }

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.name,
        id: 'name',
        header: translated.Name,
      },
      {
        accessorFn: (row) => 
        <>
          <Button onClick={() => openFormUpdate(row.id)} className="btn btn-light btn-sm">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.24112 16.8749H3.75C3.58424 16.8749 3.42527 16.8091 3.30806 16.6918C3.19085 16.5746 3.125 16.4157 3.125 16.2499V12.7588C3.125 12.6767 3.14117 12.5954 3.17258 12.5196C3.20398 12.4438 3.25002 12.3749 3.30806 12.3168L12.6831 2.94185C12.8003 2.82464 12.9592 2.75879 13.125 2.75879C13.2908 2.75879 13.4497 2.82464 13.5669 2.94185L17.0581 6.43296C17.1753 6.55017 17.2411 6.70915 17.2411 6.87491C17.2411 7.04067 17.1753 7.19964 17.0581 7.31685L7.68306 16.6918C7.62502 16.7499 7.55612 16.7959 7.48029 16.8273C7.40447 16.8587 7.32319 16.8749 7.24112 16.8749Z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.625 5L15 9.375" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.45938 16.8349L3.16406 12.5396" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </Button> 
        </>,
        accessorKey: 'button',
        header: translated.Actions,
      },
    ],
    [],
  );

  useEffect(() => {
    api.get("api/fuels/all", {
      headers:{
        "content-type" : "application/json",
      },
      withCredentials:true
    })
    .then(function (response) {
      console.log('values')
      
      addValueToList(response.data.listDatas)
    })
    .catch(function (error) {
      console.log(error);
    });

  }, [])

  const openFormUpdate = (id) => {
    console.log(id)
    setIsUpdate(true)
    api.get("api/fuels/find", {
      headers:{
        "content-type" : "application/json",
        "Id":id
      },
      params:{
        Id:id
      },
      withCredentials:true
    })
    .then(function (response) {
      console.log("update")
      
      setValue("Value", response.data.dataResult.name)
      setValue("Id", response.data.dataResult.id)
      handleShow()
    })
    .catch(function (error) {
        console.log(error);
    });
  }

    return(
      isAuth ?
      <>
      <div className=" d-sm-flex align-items-center justify-content-between">
        <h2 className="mb-sm-0 fw-semibold font-size-18 benzamat-font-bold text-black">
          {translated.Title} - {single.name}
        </h2>
      </div>
      
    <div className="row">
      <Tabs defaultActiveKey="values" className="mt-3 nav-tabs-custom">
      
        <Tab eventKey="values" title={translated.Values} tabClassName="font-size-15">

        <div className="form-group mt-3">
          <button type="button" onClick={handleShow}  className="btn btn-primary">
            <i className="bx bx-plus font-size-16 align-middle me-2 "></i> 
            {translated.Add}
          </button>
        </div>

        <div className="mt-3">
          <MaterialReactTable 
              columns={columns} 
              data={listValues}
              localization={MRT_Localization_EN}
              initialState={{ showColumnFilters: true }}
              enableTopToolbar={false}
              enableRowVirtualization
              muiTablePaperSingle={{
                elevation: 1,
              }}
              muiTableHeadCellSingle={{
                sx: {
                  fontWeight: 'bold',
                  color:"#000",
                  backgroundColor:'#F3F3F3',
                  fontFamily: 'BenzamatRegular',
                },
              }}
              muiTableBodyCellSingle={{
                sx: {
                  color:"#000",
                  fontFamily: 'BenzamatRegular',
                },
              }}
            />
          </div>
        </Tab>

      </Tabs>
    </div>
    <Offcanvas placement="end" show={showFormAdd} onHide={() => handleClose()}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
            {
              isUpdate
              ? translated.TitleUpdate
              : translated.TitleAdd
            }
          </Offcanvas.Title>
        </Offcanvas.Header>
        <div className="dropdown-divider"></div>
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register("Id")} />
          <Form.Group>
              <Form.Label className="text-black">{translated.Value} <sup className="text-danger">*</sup></Form.Label>
              <Form.Control 
                {...register("Value", {
                  maxLength : {
                    value: 15,
                    message: translatedForm.MaxLenghtString(15)
                  },
                  minLength : {
                    value: 1,
                    message: translatedForm.MinLengthString(1)
                  },
                  required : translatedForm.Required
                })}
                type="text" maxLength="500" isInvalid={errors.Value}  placeholder={translatedForm.EnterValue(15)} className="form-control"  />
                {errors.Value && 
                  <span className="text-danger font-size-13">{errors.Value.message}</span>
                }
            </Form.Group>

            <div className="form-group mt-3">
              <button type="submit" disabled={loadingData.button} className="btn btn-primary">
                {loadingData.button && (
                    <Spinner animation="border" variant="light" 
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                {!loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                </button>
            </div>
        
          </Form>
      
        </Offcanvas.Body>
    </Offcanvas>
    </>
    :
    <Redirect to='/login'/>
    );
}

export default CatalogSingle;