import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {AuthContext} from '../context/AuthContext';

const api = axios.create({
    //baseURL: "https://localhost:44400",
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials:true
});

api.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem("accessToken");
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

/*api.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      console.log("ERRRORRR")
      console.log(err)
      if (err.response.status === 401) {
        console.log("401")
      }
      return Promise.reject(err);
    }
);*/

export default api;
