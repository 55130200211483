import React, { useState, useEffect, useMemo, useContext, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, FloatingLabel, Form, Nav, Tabs, Tab, Spinner, DropdownButton, Badge, Dropdown, Alert} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import './../../boxicons.css';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import moment from 'moment';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { translations } from '../../lang/main';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import api from '../../utils/axiosInterceptions';

export const Notifications = () => {
    
    const router = useHistory();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [countNotifications, setCountNotifications] = useState(0)
    const [listNotification, setListNotification] = useState([])
    const [show, showFormAdd] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const translated = useLittera(translations);
    const translatedForm = useLittera(FormLocale);
    const methods = useLitteraMethods();

    const [loadingData, setLoadingData] = useState({
      button:false,
    });

    const getNotifications = () => {
      api.get('/api/notifications/all', {
          withCrenditials:true
      })
      .then(function(response){
        
        setListNotification(response.data.listDatas)
        setCountNotifications(response.data.listDatas.length)
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const getNotification = (id) => {
      console.log(id)
      api.get('/api/notifications/details', {
          headers:{
            "content-type" : "application/json",
            "Id":id
          },
          params:{
            Id: id,
          },
          withCrenditials:true
      })
      .then(function(response){
        
        showFormAdd(true)
        setValue("Header", response.data.dataResult.header)
        setValue("Message", response.data.dataResult.message)
        setValue("Id", response.data.dataResult.id)
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const displayToast = (message, status) => {

      if(status){
        toast.success(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });
    
      } else {
        toast.error(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });
      }
    }

    useEffect(() => {
      methods.setLocale(CurrentSelectLanguage);
      getNotifications()
    }, [])

    const onSubmit = (data) => {
      console.log(data)
      setLoadingData({button:true})
      api.post('/api/notifications/details/update', {
        Id: data.Id,
        withCrenditials:true
    })
    .then(function (response) {
        
        setLoadingData({button:false})
        displayToast(response.data.message, response.data.isSuccess);
        getNotifications()
        showFormAdd(false)
    })
    .catch(function (error) {
        console.log(error);
        setLoadingData({button:false})
    });
    }

    const columns = useMemo(
        () => [
          {
            accessorFn: (row) => row.header,
            id: 'header',
            header: translated.notification,
          },
          {
            accessorFn: (row) => moment(row.createdDate).format("DD.MM.YYYY"),
            id: 'createdDate',
            header: translated.createDate,
          },
          {
            accessorFn: (row) => 
            <>
              <Button onClick={() => getNotification(row.id)} className="btn btn-light btn-sm">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
              </Button> 
            </>,
            accessorKey: 'button',
            header: translatedForm.Actions,
          },
        ],
        [],
    );

    return (
        isAuth ?
        <>    
        {
          isLoading
          ? <Spinner animation="border" variant="info" />
          :
          <>
          <div className="d-sm-flex align-items-center justify-content-between">
            <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
                {translated.notifications}
                <span className="text-secondary benzamat-font-bold font-size-20"> {countNotifications}</span>
            </h2>      
          </div>
          <div className="row">
              <div className="col-xs-12 col-lg-12 col-md-12 ">         
                  <div className="mt-4">         
                      <MaterialReactTable 
                        columns={columns} 
                        data={listNotification}
                        localization={MRT_Localization_EN}
                        initialState={{ showColumnFilters: true }}
                        enableTopToolbar={false}
                        enableRowVirtualization
                        muiTablePaperProps={{
                          elevation: 1,
                        }}
                        muiTableHeadCellProps={{
                          //simple styling with the `sx` prop, works just like a style prop in this example
                          sx: {
                            fontWeight: 'bold',
                            color:"#000",
                            backgroundColor:'#F3F3F3',
                            fontFamily: 'BenzamatRegular',
                          },
                        }}
                        muiTableBodyCellProps={{
                          sx: {
                            color:"#000",
                            fontFamily: 'BenzamatRegular',
                          },
                        }}
                      />
                  </div>
              </div>
          </div>
          <Offcanvas placement="end" show={show} onHide={() => showFormAdd(false)}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>Детальная информация</Offcanvas.Title>
            </Offcanvas.Header>
            <div className="dropdown-divider"></div>
            <Offcanvas.Body>  

            <Form onSubmit={handleSubmit(onSubmit)}>
            
            <input type="hidden" {...register("Id")} />
            <Form.Group className="mb-3">
            <Form.Label className="text-black">{translated.notification}</Form.Label>
            <Form.Control 
                {...register("Header")}
                type="text"  isInvalid={errors.Name} className="form-control"  />
            </Form.Group>  

            <Form.Group className="mb-3">
            <Form.Label className="text-black">{translated.message}</Form.Label>
              <Form.Control
                {...register("Message")}
                className="form-control"
              />
            </Form.Group>
                    
            <div className="form-group mt-3">
                <button type="submit" disabled={loadingData.button} className="btn btn-primary">
                    {loadingData.button && (
                        <Spinner animation="border" variant="light" 
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                        />
                    )}
                    {loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                    {!loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                </button>
            </div>
        
            </Form>

            </Offcanvas.Body>
          </Offcanvas>
          </>
        }
        </>
        :
        <Redirect to='/login'/>
      );

}

