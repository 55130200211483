import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Table, Form, Spinner, Offcanvas, Dropdown, DropdownButton, Card, Tooltip, OverlayTrigger, Nav} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import { UploadTable } from './upload';
import api from '../../../utils/axiosInterceptions';

export const FormAddTable = ({translated, translatedForm, id}) => {
    
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [listPoints, setListPoints] = useState([])

    const getTable = () => {
        api.get("/api/controllers/details/level-meters/details/table", {
            headers:{
                "content-type" : "application/json",
                "levelMeterId":id
              },
              params:{
                levelMeterId: id,
              },
            withCredentials:true
          })
          .then(function (response) { 
            setListPoints(response.data.listDatas)       
          })
          .catch(function (error) {
            console.log(error);
        });
    }

    const onSubmit= () => {
        setLoadingBtn(true)
        api.post('/api/controllers/details/level-meters/details/table/create', {
            LevelMeterId: id,
            CountPoints:1,
            withCredentials:true
        })
        .then(function (response) {
            console.log("table level")
            
            setLoadingBtn(false)
            displayToast(response.data.message, response.data.isSuccess)
            setListPoints(response.data.listDatas)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingBtn(false)
        });
    }

    const deletaLastPoint = () => {
        console.log(id)
        api.post('/api/controllers/details/level-meters/details/table/delete-point', {
            Id: id,
            withCredentials:true
        })
        .then(function (response) {
            setLoadingBtn(false)
            setListPoints(response.data.listDatas)
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingBtn(false)
        });
    }

    const deleteTable = () => {
        console.log(id)
        api.post('/api/controllers/details/level-meters/details/table/delete', {
            Id: id,
            withCredentials:true
        })
        .then(function (response) {
            setLoadingBtn(false)
            setListPoints(response.data.listDatas)
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingBtn(false)
        });
    }
        
    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    
    }

    useEffect(() => {
        getTable()
    }, [])

    return (
        <>
            <div className="row">
                <DropdownButton title="Действия">
                    <Dropdown.Item onClick={() => onSubmit()}>{translated.AddPoint}</Dropdown.Item>
                    <Dropdown.Item onClick={() => deletaLastPoint()}>{translated.DeleteLastPoint}</Dropdown.Item>
                    <Dropdown.Item onClick={() => deleteTable()}>{translated.DeleteCalibrTable}</Dropdown.Item>
                    <Dropdown.Item href="import_lm_table.xlsx">{translated.DownloadTemplateTable}</Dropdown.Item>
                </DropdownButton>
            </div>

            <UploadTable setListPoints={setListPoints} id={id} />

            <Table striped bordered hover className="mt-3">
                <thead>
                    <tr>
                        <th>{translated.Point}</th>
                        <th>{translated.Level}</th>
                        <th>{translated.Volume}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listPoints.length == 0
                        ? 
                        <tr>
                            <td colspan="3">{translatedForm.NotFound}</td>
                        </tr>
                        :
                        listPoints.map((item) => (
                            <tr>
                                <td>{item.point}</td>
                                <td>{item.levelMm}</td>
                                <td>{item.volume}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}