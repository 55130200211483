import React, { useState, useEffect, useContext, useMemo, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Form, Spinner, DropdownButton, Dropdown, Badge, Alert} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateUser } from '../../lang/users/user';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import api from '../../utils/axiosInterceptions';

export const UploadUsers = ({setListUsers, uploadFormShow, setUploadFormShow}) => {
    
    const { control, register, handleSubmit, setValue, reset, formState, getValues, formState: { errors }} = useForm();
    const [file, setFile] = useState('')
    const [listCompanies, setListCompanies] = useState([])
    const translated = useLittera(TranslateUser);
    const translatedForm = useLittera(FormLocale);
    const methods = useLitteraMethods();
    const [loadingData, setLoadingData] = useState({
        button:false,
        grid:false
    })

    useEffect(() => {
        getCompanies()
    }, [])

    const onSubmitUpload = data => {
        setLoadingData({button:true})
        console.log("upload")
        console.log(data)

        let companyIds = []
        data.Companies.map((item) => companyIds.push(item.id))

        console.log(file)
        let formData = new FormData()
        formData.append("uploadedFile", file)
        formData.append("companyIds", companyIds)

        console.log(formData)
        //let headers = {'Content-Type': 'multipart/form-data' }
        //let post_data = {data:formData}
        api({
            method: 'post',
            url: 'api/users/import',
            data: formData ,
            headers: {'Content-Type': 'multipart/form-data' },
            withCredentials: true
        })
        .then(function (response) {
            
            displayToast(response.data.message, response.data.isSuccess)
            setListUsers(response.data.listDatas)
            setLoadingData({button:false})
        })
        .catch(function (response) {
            //handle error
            
            setLoadingData({button:false})
        });    
    
        /*api.post("/api/users/import", post_data,  {
            headers: headers
        })
        .then(function (response) {
            
            displayToast(response.data.message, response.data.isSuccess)
            setListUsers(response.data.listDatas)
            setLoadingData({button:false})
        })
        .catch(function (error) {
            //handle error
            console.log(error);
            setLoadingData({button:false})
            displayToast(error.response.data.message, error.response.data.isSuccess)
        });*/  
    }

    const getCompanies = () => {
        api.get('/api/company/all', {
            withCredentials:true
        })
        .then(function (response) {
            const addCompany = response.data.listDatas.map(item => ({
                ...item,
                value: item.id,
                label: item.name
            }))
            setListCompanies(addCompany)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingData({grid:false})
        });
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    }

    return(
         <Offcanvas placement="end" show={uploadFormShow} onHide={() => setUploadFormShow(false)}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>{translated.UploadFromExcelHeader}</Offcanvas.Title>
          </Offcanvas.Header>
          <div className="dropdown-divider"></div>

          <Offcanvas.Body>

            <Alert variant='danger'>
              {translated.UploadInfoAlert}
            </Alert>

            <Alert variant="info" className="mt-3">
              {translated.UploadDangerAlert}
            </Alert>

            <a className="btn btn-success btn-sm" href="/import_user_benzamatPro.xlsx" download>
                {translated.DownloadTemplate}
            </a>

            <h4 className="text-black mt-3">{translated.SelectFile}</h4>

            <Form onSubmit={handleSubmit(onSubmitUpload)}>

            <Form.Group className="mb-3">
                <Form.Label className="text-black">{translated.Company} <sup className="text-danger">*</sup></Form.Label>        
                    <Controller
                        name="Companies"                                
                        control={control}
                        rules={{ required: translatedForm.Required }}
                        render={({ field}) => (
                        <Select 
                        {...field}
                            isSearchable
                            isMulti
                            options={listCompanies}                       
                            placeholder={translatedForm.SelectFromList}
                            classNamePrefix="select"
                            error={Boolean(errors.Company)}
                            
                        />
                        )}
                    />
                {errors.Company && 
                    <span className="text-danger font-size-13">{errors.Company.message}</span>
                }   
            </Form.Group>

            <Form.Group controlId="formFile">
                <Form.Control 
                    placeholder={translated.SelectFile} 
                    className="form-control" 
                    {...register("uploadedFile", { required: translatedForm.Required})} 
                    onChange={(e) => setFile(e.target.files[0])}
                    type="file"
                />
                {errors.file && 
                    <span className="text-danger font-size-13">{errors.file.message}</span>
                }
            </Form.Group>

            <div className="form-group mt-3">
                <Button type="submit" variant="primary" disabled={loadingData.button}>
                {loadingData.button && (
                    <Spinner animation="border" variant="light" 
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                {!loadingData.button && <span>{translatedForm.SaveChanges}</span>}
                </Button>
            </div>

            </Form>
            
          </Offcanvas.Body>
        </Offcanvas>
    )
}