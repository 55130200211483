import React, { useContext, useState, useEffect } from 'react';
import { useForm, Controller} from 'react-hook-form';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import { Form, Spinner } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import api from '../../../utils/axiosInterceptions';

export const FuelCompanyFormEdit = ({fuel, companyData, setFuels}) => {

    const params = useParams()
    const { control, register, handleSubmit, setValue, formState: { errors }} = useForm();
    const [loadingDataBtn, setLoadingDataButton] = useState(false)
    const [price, setPrice] = useState(0)

    useEffect(() => {      
      api.get("/api/company/details/fuels/find", {
        headers:{
            "content-type" : "application/json",
            "id":fuel
          },
          params:{
            id: fuel
          }
      })
      .then(function (response) {
        console.log(response.data.dataResult)
        setValue("Name", response.data.dataResult.fuel.name)

        if((response.data.dataResult.price / 100) < 1) {
            setPrice(response.data.dataResult.price)
        } else {
            setPrice(response.data.dataResult.price / 100)
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    }, [])

    const onSubmit = data => {

      console.log(data)

      setLoadingDataButton(true)
      api.post('/api/company/details/fuels/update', {
        Price: price,
        FuelId: fuel,
        CompanyId: params.id
      })
      .then(function (response) {
        displayToast(response.data.message, response.data.isSuccess)
        setLoadingDataButton(false)
        const addFuel = response.data.listDatas.map(item => ({
          ...item,
          value: item.id,
          label: item.name
        }))
  
        setFuels(addFuel)
      })
      .catch(function (error) {
        console.log(error);
        setLoadingDataButton(false)
        displayToast(error.response.data.message, error.response.data.isSuccess)
      });
    }
  
    const displayToast = (message, status) => {
  
      if(status){
        toast.success(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });
    
      } else {
        toast.error(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });
      }
     
    }

    return (
        <>
        <Form onSubmit={handleSubmit(onSubmit)}>

            <Form.Group className="mb-3">
                <Form.Label className="text-black">Топливо</Form.Label>
                <Form.Control 
                {...register("Name")}
                type="text" readOnly="true" className="form-control"  />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label className="text-black">Новая цена за литр</Form.Label>
              <Controller
                  name="Price"                                
                  control={control}
                  render={({ field }) => (
                  <CurrencyInput
                    placeholder="Введите цену"
                    defaultValue={price}
                    value={price}
                    decimalSeparator='.'
                    decimalsLimit={2}
                    className="form-control"
                    onValueChange={(value) => setPrice(value)}
                  />
                )}
                />
                {errors.Price && 
                    <span className="text-danger font-size-13">{errors.Price.message}</span>
                }   
            </Form.Group>

            <div className="form-group mt-3">
              <button type="submit" disabled={loadingDataBtn} className="btn btn-primary">
                {loadingDataBtn && (
                    <Spinner animation="border" variant="light" 
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {loadingDataBtn && <span>Сохранение</span>}
                {!loadingDataBtn && <span>Сохранить</span>}
                </button>
            </div>
        
          </Form>
        </>
    )
}