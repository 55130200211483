import React, { useContext, useState, useEffect } from 'react';
import { useForm, Controller} from 'react-hook-form';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import { Form, Spinner } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field'
import api from '../../../utils/axiosInterceptions';

export const FuelCompanyFormAdd = ({translatedForm, translated, companyId, setFuels}) => {

    const { control, register, handleSubmit, reset, formState, formState: { errors }} = useForm();
    const [loadingDataBtn, setLoadingDataButton] = useState(false)
    const [catalogFuels, setCatalogFuels] = useState([])
    const [price, setPrice] = useState(0)

    useEffect(() => {      
      api.get("/api/fuels/all", {
        headers:{
          "content-type" : "application/json",
        },
      })
      .then(function (response) {
        const addFuel = response.data.listDatas.map(item => ({
          ...item,
          value: item.id,
          label: item.name
        }))

        setCatalogFuels(addFuel)
      })
      .catch(function (error) {
        console.log(error);
      });

    }, [])

    const onSubmit = data => {
      data.Price = price;
      
      setLoadingDataButton(true)
      api.post('/api/company/fuels/create', {
        Price: data.Price,
        FuelId: data.Fuel.id,
        CompanyId: companyId
      })
      .then(function (response) {
        console.log("fuels")
        
        displayToast(response.data.message, response.data.isSuccess)
        setLoadingDataButton(false)
        setFuels(response.data.listDatas)
      })
      .catch(function (error) {
        console.log(error);
        setLoadingDataButton(false)
        displayToast(error.response.data.message, error.response.data.isSuccess)
      });
    }
  
    const displayToast = (message, status) => {
  
      if(status){
        toast.success(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });
    
      } else {
        toast.error(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });
      }
     
    }

    return (
        <>
        <Form onSubmit={handleSubmit(onSubmit)}>

            <Form.Group>
              <Form.Label className="text-black">{translated.Fuel} <sup className="text-danger">*</sup></Form.Label>
              <Controller
                name="Fuel"                                
                control={control}
                rules={{ required: translatedForm.Required }}
                render={({ field }) => (
                <Select 
                  {...field}                               
                    isSearchable
                    options={catalogFuels}                        
                    placeholder={translatedForm.SelectValuFromList}
                    classNamePrefix="select"
                />
                )}
              />
              {errors.Company && 
                  <span className="text-danger font-size-13">{errors.Company.message}</span>
              }
            </Form.Group>
            
            <Form.Group className="mt-3">
              <Form.Label className="text-black">{translated.PricePerLitre} <sup className="text-danger">*</sup></Form.Label>
              <Controller
                  name="Price"                                
                  control={control}
                  render={({ field }) => (
                  <CurrencyInput
                    placeholder={translated.EnterPrice}
                    defaultValue={0}
                    decimalsLimit={2}
                    className="form-control"
                    onValueChange={(value) => setPrice(value)}
                  />
                )}
                />
                {errors.Price && 
                    <span className="text-danger font-size-13">{errors.Price.message}</span>
                }   
            </Form.Group>

            <div className="form-group mt-3">
              <button type="submit" disabled={loadingDataBtn} className="btn btn-primary">
                {loadingDataBtn && (
                    <Spinner animation="border" variant="light" 
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {loadingDataBtn && <span>{translated.AddFuel}</span>}
                {!loadingDataBtn && <span>{translated.AddFuel}</span>}
                </button>
            </div>
        
          </Form>
        </>
    )
}