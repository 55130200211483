import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateFuels = {

    Name:{
        en_US: "Name",
        ru_RU: "Название"
    },
    Price:{
        en_US: "Price",
        ru_RU: "Цена"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    CurrentPrice:{
        en_US: "Current prices",
        ru_RU: "Текущие цены"
    },
    AddFuel:{
        en_US: "Add fuel",
        ru_RU: "Добавить топливо"
    },
    PermissionDenied:{
        en_US: "Permission denied",
        ru_RU: "У вас недостаточно прав доступа для просмотра информации по данному разделу"
    },
    EditPrice:{
        en_US: "Change price",
        ru_RU: "Изменение цены"
    },
    AddFuelTitle:{
        en_US: "Add fuel",
        ru_RU: "Добавление топлива"
    },
    Fuel:{
        en_US: "Fuel",
        ru_RU: "Вид топлива"
    },
    EnterPrice:{
        en_US: "Enter price",
        ru_RU: "Введите цену"
    },
    PricePerLitre:{
        en_US: "Price per litre",
        ru_RU: "Цена за литр"
    }
};
