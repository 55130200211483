import React, { useContext, useState } from 'react';
import Cookies from 'js-cookie';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import logo from './../../img/benzamat_R_horiz.png';
import LogoBenzamatPro from './../../img/color_bpro.svg';
import OpenEye from './../../svg/open_eye.svg';
import CloseEye from './../../svg/close_eye.svg';
import './../../app.css';
import './../../style.css';
import './../../bootstrap.css';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateCompanyModule } from '../../lang/company';
import { TranslateLoginPage } from '../../lang/login';
import { CurrentSelectLanguage } from '../../helpers/language';
import { FormLocale } from '../../lang/form';

const Login = () => {
    
    const {isAuth, setIsAuth} = useContext(AuthContext);
    const [messageAuth, setMessageAuth] = useState('');
    const [showPassword, setShowPassword] = useState(false)
    const [loadingAuth, setLoadingAuth] = useState(false);
    const translated = useLittera(TranslateLoginPage);
    const translatedForm = useLittera(FormLocale);
    const methods = useLitteraMethods();

    const handleShowPassword = () => {
        return showPassword ? setShowPassword(false) : setShowPassword(true)
    }

    React.useEffect(() => {
        methods.setLocale(CurrentSelectLanguage);
    }, [])

    const { register, handleSubmit, formState: { errors } } = useForm();
    
    const onSubmit = data => {
        setLoadingAuth(true)
        axios.post('/api/account/auth', {
            Login: data.email,
            Password: data.password
          })
          .then(function (response) {
            console.log("auth response")
            
            if(response.data.isSuccess)
            {
                if(response.data.accessToken != null)
                {
                    localStorage.setItem("accessToken", response.data.accessToken)
                    setIsAuth(true)
                }
                setMessageAuth('')
                /*if(Cookies.get(".AspNetCore.Identity.Application") !== undefined) {
                    setLoadingAuth(false)
                    setIsAuth(true);      
                }*/
            } 
            else 
            {
                setMessageAuth(response.data.message)
                setLoadingAuth(false)
            }
            
        })
        .catch(function (error) {
            console.log(error);
            displayToast(error.response.data.message, error.response.data.isSuccess)
            setLoadingAuth(false)
        });
    }

    const displayToast = (message, status) => {

        if(status){
          toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
          });
  
        } else {
          toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
          });
        }
      
    }

    return (
    <>
    <ToastContainer />
    <div className="account-pages pt-sm-5">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8 col-lg-6 col-xl-5">    
                    <div className="card overflow-hidden">    
                        <div className="card-body pt-0">
                            <img src={LogoBenzamatPro} className="d-block mx-auto mt-4" style={{"height":"auto"}} />
                            <h2 className="text-center text-black mt-3">{translated.Login}</h2>
                            <h5 className="text-center text-black mt-3">{translated.PleaseSignIn}</h5>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="p-2">                                  
                                    <div className="mb-3">
                                        <label className="text-black">Email</label>
                                        <input 
                                        {...register("email", {
                                            maxLength : {
                                                value: 50,
                                                message: translatedForm.MaxLenghtString(50)
                                            },
                                            minLength : {
                                                value: 3,
                                                message: translatedForm.MinLengthString(3)
                                            },
                                            required : translatedForm.Required
                                        })}
                                        placeholder="example@mail.ru" className="form-control text-black" />                                      
                                    </div>

                                    <div className="mb-3">
                                        <label className="text-black">{translated.Password}</label>
                                        <div className="input-group text-black auth-pass-inputgroup">
                                            <input 
                                            {...register("password", {
                                                maxLength : {
                                                    value: 25,
                                                    message: translatedForm.MaxLenghtString(25)
                                                },
                                                minLength : {
                                                    value: 3,
                                                    message: translatedForm.MinLengthString(3)
                                                },
                                                required : translatedForm.Required
                                            })}
                                            type={showPassword ? "text" : "password"} required placeholder={translated.EnterPassword} id="password-field" className="form-control" aria-label="Password" aria-describedby="password-addon" />
                                            <button onClick={handleShowPassword} className="btn btn-light" type="button" id="password-addon">
                                            
                                            {showPassword && <img src={CloseEye} />}
                                            {!showPassword && <img src={OpenEye} />}
                                             
                                            </button>
                                        </div>                                       
                                    </div>

                                    <div className="mt-4 d-grid">
                                        <button type="submit" disabled={loadingAuth} className="btn btn-primary">
                                        {loadingAuth && (
                                            <Spinner animation="border" variant="light" 
                                                as="span"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}
                                        {loadingAuth && <span>{translated.Process}</span>}
                                        {!loadingAuth && <span>{translated.Continue}</span>}
                                        </button>
                                    </div>

                                    <div className="mt-3 text-center">
                                        <a href="./ForgotPassword" className="text-black"><i className="mdi mdi-lock me-1"></i> {translated.ForgotPassword}</a>
                                    </div>                            
                                </div>
                            </form>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
    );
}

export default Login;