import React, { useState, useEffect, useContext, useMemo, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Modal, Form, Spinner, Alert, Badge} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { FormAddTransport } from './FormAddTransport';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import './../../boxicons.css';
import CurrencyInput from 'react-currency-input-field';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateTransport } from '../../lang/transports/translateTransport';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import api from '../../utils/axiosInterceptions';

const Transport = () => {

  const router = useHistory();
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [countTransport, setCountTransport] = useState(0)
  const [listTransport, setListTransport] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [showDeleteTransport, setShowDeleteTransport] = useState(false)
  const [userIdForDelete, setUserIdForDelete] = useState(0)  
  const translated = useLittera(TranslateTransport);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [permission, setPermission] = useState({
    read:false,
    create:false,
    fullAccess:false
  })
  const [selectedItem, setSelectedItem] = useState({
    companies:''
  })
  const [loadingData, setLoadingData] = useState({
    button:false,
    grid:false
  })
  const handleShow = () => setShowFormAdd(true);
  const handleClose = () => setShowFormAdd(false);
  const {isAuth} = useContext(AuthContext);
  
  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    getTransports()
  }, [])

  const deleteTransport = () => {
    api.post('/api/transport/details/delete', {
      Id:userIdForDelete,
      IsBlocked:true,
      withCredentials: true
    })
    .then(function (response) {
      console.log(response.data)
      displayToast(response.data.message, response.data.isSuccess)
      setListTransport(response.data.listDatas)
      setCountTransport(response.data.listDatas.length)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const onDelete = (id) => {
    setShowDeleteTransport(true)
    setUserIdForDelete(id)
  }

  const displayToast = (message, status) => {

    if(status){
    toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
    });

    } else {
    toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
    });
    }
}

  const getTransports = () => {
    setIsLoading(true)
    api.get('/api/transport/all', {
      withCredentials:true
    })
    .then(function (response) {
      
      setPermission({
        read: response.data.read,
        create: response.data.create,
        fullAccess: response.data.fullAccess
      })
      setLoadingData({grid:false})
      setListTransport(response.data.listDatas)
      setCountTransport(response.data.listDatas.length)
      setIsLoading(false)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.name,
        accessorKey: 'name',
        header: translated.Name,
      },
      {
        accessorFn: (row) => row.number != "" && row.number != null ? row.number : translated.Unknow,
        accessorKey: 'carNumber',
        header: translated.CarNumber,
      },
      {
        accessorFn: (row) => row.numberRfid,
        accessorKey: 'rfidKey',
        header: translated.NumberRfid,
      },
      {
        accessorFn: (row) => <Badge bg="dark" className="font-size-12">{row.enumTypeLimitName}</Badge>,
        accessorKey: 'rfid',
        header: translated.TypeLimit,
      },
      {
        accessorFn: (row) => 
        <>
          <Button onClick={() => router.push(`/transport/${row.id}`)} className="btn btn-light btn-sm">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
          </Button> 

          <Button onClick={() => onDelete(row.id)} className="btn btn-danger btn-sm mx-3">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
          </Button> 
        </>,
        accessorKey: 'button',
        header: translatedForm.Actions,
      },
    ],
    [],
  );

  return (
    isAuth ?
      <>
      {
        isLoading
        ? <Spinner animation="border" variant="info" />
        : 
        <>
          <div className="d-sm-flex align-items-center justify-content-between">
              <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
                  {translated.Title}
                  <span className="text-secondary benzamat-font-bold font-size-20"> {countTransport}</span>
              </h2>
              {
                permission.create || permission.fullAccess
                ?
                <div className="page-title-right">
                  <Button varian="primary" onClick={handleShow}>
                      <i className="bx bx-plus font-size-16 align-middle me-2 "></i> 
                      {translated.AddTransport}
                  </Button>
                </div>
                : ''
              }          
          </div>
          <div className="row">
          <div className="col-xs-12 col-lg-12 col-md-12 ">         
              <div className="mt-4">
                {
                  permission.read || permission.fullAccess
                  ?
                  <MaterialReactTable 
                    columns={columns} 
                    data={listTransport}
                    localization={MRT_Localization_EN}
                    initialState={{ showColumnFilters: true }}
                    enableTopToolbar={false}
                    enableRowVirtualization
                    muiTablePaperProps={{
                      elevation: 1,
                    }}
                    muiTableHeadCellProps={{
                      //simple styling with the `sx` prop, works just like a style prop in this example
                      sx: {
                        fontWeight: 'bold',
                        color:"#000",
                        backgroundColor:'#F3F3F3',
                        fontFamily: 'BenzamatRegular',
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        color:"#000",
                        fontFamily: 'BenzamatRegular',
                      },
                    }}
                  />
                  : 
                  <Alert variant="danger">
                    {translated.PermissionDenied}
                  </Alert>
                }
              </div>
          </div>
          </div>

          <Modal backdrop="static" keyboard={false} show={showDeleteTransport} onHide={() => setShowDeleteTransport(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="text-black">{translated.HeaderDelete}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="text-black text-center col-xs-12 col-lg-12 col-md-12">
                <h4>{translated.TextDelete}</h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => deleteTransport()} className="btn btn-success">{translated.Submit}</Button>
            <Button onClick={() => setShowDeleteTransport(false)} className="btn btn-danger">{translated.Cancel}</Button>
          </Modal.Footer>
        </Modal>
          
          <Offcanvas placement="end" show={showFormAdd} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
                {translated.AddTransportTitle}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <div className="dropdown-divider"></div>
            <Offcanvas.Body>  
                <FormAddTransport translated={translated} translatedForm={translatedForm} setListTransport={setListTransport} isChild={false}/>            
            </Offcanvas.Body>
          </Offcanvas>
        </>
      }
  </>
  :
  <Redirect to='/login'/>
  );

}

export default Transport;
