import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Spinner, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import CurrencyInput from 'react-currency-input-field';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

export const ControllerFilling = ({translated, translatedForm, controllerInfo, typeController}) => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [listFuels, setFuels] = useState([])
    const [selectedMinDoze, setSelectedMinDoze] = useState({
        label:'',
        value:''
    })
    const [selectedFuel, setSelectedFuel] = useState({
        label:'',
        value:''
    })
    const [selectedMaxDoze, setSelectedMaxDoze] = useState({
        label:'',
        value:''
    })
    const [selectedBeforeStop, setSelectedBeforeStop] = useState({
        label:'',
        value:''
    })
    const [selectedAfterStop, setSelectedAfterStop] = useState({
        label:'',
        value:''
    })
    const [selectedDelayDoze, setSelectedDelayDoze] = useState({
        label:'',
        value:''
    })
    const [selectedFlowError, setSelectedFlowError] = useState({
        label:'',
        value:''
    })
    const [selectedTimeIndication, setSelectedTimeIndication] = useState({
        label:'',
        value:''
    })

    const [loadingBtn, setLoadingBtn] = useState(false)

    const stopForError = [
        {label:translated.Yes, value:1},
        {label:translated.No, value:0},
    ]

    const listMinDoze = [
        {label:'0', value:0},
        {label:'1', value:1},
        {label:'2', value:2},
        {label:'3', value:3},
        {label:'4', value:4},
        {label:'5', value:5},
        {label:'500', value:500},
    ]

    const listMaxDoze = [
        {label:'5', value:5},
        {label:'10', value:10},
        {label:'20', value:20},
        {label:'50', value:50},
        {label:'100', value:100},
        {label:'200', value:200},
        {label:'500', value:500},
        {label:'990', value:990},
        {label:'999', value:999}        
    ]

    const listVolumeBeforeStop = [
        {label:'0.5', value:0.5},
        {label:'1.0', value:1},
        {label:'1.5', value:1.5},
        {label:'2.0', value:2},
        {label:'5.0', value:5},
        {label:'10.0', value:10},
        {label:'15.0', value:15},
        {label:'20.0', value:20},
        {label:'999', value:999}   
    ]

    const listVolumeAfterStop = [
        {label:'0.5', value:0.5},
        {label:'1.0', value:1},
        {label:'1.5', value:1.5},
        {label:'2.0', value:2},
    ]

    const listDelayDoze = [
        {label:'0', value:0},
        {label:'1', value:1},
        {label:'2', value:2},
        {label:'3', value:3},
        {label:'4', value:4},
        {label:'5', value:5},
        {label:'6', value:6},
        {label:'7', value:7},
        {label:'8', value:8},
        {label:'9', value:9},
        {label:'10', value:10},
        {label:'15', value:12},
        {label:'20', value:20}
    ]

    const listTimeIndication = [
        {label:'5', value:5},
        {label:'10', value:10},
        {label:'15', value:15},
        {label:'20', value:20},
        {label:'30', value:30},
        {label:'40', value:40},
        {label:'50', value:50},
        {label:'60', value:60}
    ]

    const getFuels = () => {
        api.get(`/api/fuels/all`, {
            withCredentials:true
        })
        .then(function (response) { 
            const add = response.data.listDatas.map(item => ({
                ...item,
                value: item.id,
                label: item.name
            }))
            setFuels(add)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getFilling = () => {
        api.get(`api/controllers/details/filling`, {
            headers:{
                "content-type" : "application/json",
                "controllerId":params.id
            },
            params:{
                controllerId: params.id,
            },
            withCredentials:true
        })
        .then(function (response) { 

            console.log("Doze")
            

            setSelectedFuel({label:response.data.dataResult.fuel.name, value:response.data.dataResult.fuel.id})
            setValue("Fuel", selectedFuel)

            //let findMinDoze = listMinDoze.filter((item) => item.value == response.data.dataResult.dozeMin)
            //setSelectedMinDoze({label:findMinDoze[0].label, value:findMinDoze[0].value})
            setValue("DozeMin", response.data.dataResult.dozeMin)

            //let findMaxDoze = listMaxDoze.filter((item) => item.value == response.data.dataResult.dozeMax)
            //setSelectedMaxDoze({label:findMaxDoze[0].label, value:findMaxDoze[0].value})
            setValue("DozeMax", response.data.dataResult.dozeMax)

            //let findBeforeStop = listVolumeBeforeStop.filter((item) => item.value == response.data.dataResult.volumeBeforeStop)
            //setSelectedBeforeStop({label:findBeforeStop[0].label, value:findBeforeStop[0].value})
            setValue("VolumeBeforeStop", response.data.dataResult.volumeBeforeStop)

            //let findAfterStart = listVolumeAfterStop.filter((item) => item.value == response.data.dataResult.volumeAfterStart)
            //setSelectedAfterStop({label:findAfterStart[0].label, value:findAfterStart[0].value})
            setValue("VolumeAfterStart", response.data.dataResult.volumeAfterStart)

            //let findDelayDoze = listDelayDoze.filter((item) => item.value == response.data.dataResult.delayDozeStopAfterPumpOff)
            //setSelectedDelayDoze({label:findDelayDoze[0].label, value:findDelayDoze[0].value})
            setValue("DelayDozeStopAfterPumpOff", response.data.dataResult.delayDozeStopAfterPumpOff)

            let findError = stopForError.filter((item) => item.value == response.data.dataResult.stopIfErrorFlowSensor)
            setSelectedFlowError({label:findError[0].label, value:findError[0].value})
            setValue("StopIfErrorFlowSensor", selectedFlowError)

            //let findTimeIndication = listTimeIndication.filter((item) => item.value == response.data.dataResult.timeShowLastDoze)
            //setSelectedTimeIndication({label:findTimeIndication[0].label, value:findTimeIndication[0].value})
            setValue("TimeShowLastDoze", response.data.dataResult.timeShowLastDoze)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        getFuels()
        getFilling()
    }, [])

    const onSubmit = data => {

        setLoadingBtn(true);
        api.post("/api/controllers/details/filling/update", {
            DozeMin: data.DozeMin,
            DozeMax: data.DozeMax,
            VolumeBeforeStop: data.VolumeBeforeStop,
            VolumeAfterStart: data.VolumeAfterStart,
            DelayDozeStopAfterPumpOff: data.DelayDozeStopAfterPumpOff,
            StopIfErrorFlowSensor: selectedFlowError.value,
            TimeShowLastDoze: data.TimeShowLastDoze,
            FuelId: selectedFuel.value,
            ControllerId: params.id
        })
        .then(function (response) {
            setLoadingBtn(false)
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            setLoadingBtn(false)
            displayToast(error.response.data.errors[0].errorMessage, error.response.data.isSuccess)
        });
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    
    }

    return(
      isAuth ?
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-xs-12 col-lg-5 col-md-5">
                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.FuelType}</Form.Label>                    
                            <Controller
                                name="Fuel"                                
                                control={control}
                                rules={{ required: translatedForm.Required }}
                                render={({ field: {value} }) => (
                                <Select
                                    onChange={(item) => {
                                        setSelectedFuel({label:item.label, value:item.value})
                                        setValue("Fuel", selectedFuel)
                                    }}
                                    value={selectedFuel}
                                    options={listFuels}  
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                />
                                )}
                            />
                            {errors.Fuel && 
                                <span className="text-danger font-size-13">{errors.Fuel.message}</span>
                            }   
                    </Form.Group>

                    <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.MinimalDose}</Form.Label>
                        <Controller
                            name="DozeMin"                                
                            control={control}
                            rules={{ 
                                max : {
                                    value: 500,
                                    message:translatedForm.MaxNumber(500)
                                },
                                min : {
                                    value: 1,
                                    message: translatedForm.MinNumber(0)
                                },
                                required: translatedForm.Required
                            }}
                            render={({ field:{value} }) => (
                            <CurrencyInput
                                placeholder={translatedForm.EnterValue(50)}
                                value={value}
                                className="form-control"
                                onValueChange={(value) => setValue("DozeMin", value)}
                            />
                            )}
                        />
                        {errors.DozeMin && 
                            <span className="text-danger font-size-13">{errors.DozeMin.message}</span>
                        }
                    </Form.Group>

                    <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.MaximalDose}</Form.Label>
                        <Controller
                            name="DozeMax"                       
                            control={control}
                            rules={{ 
                                max : {
                                    value: 65000,
                                    message: translatedForm.MaxNumber(65000)
                                },
                                min : {
                                    value: 1,
                                    message: translatedForm.MinNumber(1)
                                },
                                required: translatedForm.Required 
                            }}
                            render={({ field:{value} }) => (
                            <CurrencyInput
                                placeholder={translatedForm.EnterValue(65000)}
                                value={value}
                                decimalsLimit={2}
                                className="form-control text-black"
                                onValueChange={(value) => setValue("DozeMax", value)}
                            />
                            )}
                        />
                        {errors.DozeMax && 
                            <span className="text-danger font-size-13">{errors.DozeMax.message}</span>
                        }
                    </Form.Group>

                    <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">
                        {translated.ReduceEndFill}
                    </Form.Label>
                    
                        <Controller
                            name="VolumeBeforeStop"                       
                            control={control}
                            rules={{ 
                                max : {
                                    value: 20.0,
                                    message: translatedForm.MaxNumber(20)
                                },
                                min : {
                                    value: 0.5,
                                    message: translatedForm.MinLengthString(0.5)
                                },
                                required: "Обязательное поле" 
                            }}
                            render={({ field:{value} }) => (
                            <CurrencyInput
                                placeholder={translatedForm.EnterValue(20)}
                                value={value}
                                decimalsLimit={2}
                                className="form-control text-black"
                                onValueChange={(value) => setValue("VolumeBeforeStop", value)}
                            />
                            )}
                        />
                        {errors.VolumeBeforeStop && 
                            <span className="text-danger font-size-13">{errors.VolumeBeforeStop.message}</span>
                        }   

                    </Form.Group>


                    <div className="form-group mt-3">
                        <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                            {loadingBtn && (
                                <Spinner animation="border" variant="light" 
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                            {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                        </button>
                    </div>
                </div>

                <div className="col-xs-12 col-lg-7 col-md-7">

                <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.FullCapacityAfterFilling}</Form.Label>
                    
                        <Controller
                            name="VolumeAfterStart"                       
                            control={control}
                            rules={{ 
                                max : {
                                    value: 2.0,
                                    message: translatedForm.MaxNumber(2)
                                },
                                min : {
                                    value: 0.5,
                                    message: translatedForm.MinLengthString(0.5)
                                },
                                required: translatedForm.Required
                            }}
                            render={({ field:{value} }) => (
                            <CurrencyInput
                                placeholder="Введите полный расход (0.5 - 2.0)"
                                value={value}
                                decimalsLimit={2}
                                className="form-control text-black"
                                onValueChange={(value) => setValue("VolumeAfterStart", value)}
                            />
                            )}
                        />
                        {errors.VolumeAfterStart && 
                            <span className="text-danger font-size-13">{errors.VolumeAfterStart.message}</span>
                        }   

                    </Form.Group>

                    <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.EndFillAfterSwitch}</Form.Label>
                        
                        <Controller
                            name="DelayDozeStopAfterPumpOff"                       
                            control={control}
                            rules={{ 
                                max : {
                                    value: 20,
                                    message: translatedForm.MaxNumber(20)
                                },
                                min : {
                                    value: 0,
                                    message: translatedForm.MinNumber(0)
                                },
                                required: "Обязательное поле" 
                            }}
                            render={({ field:{value} }) => (
                            <CurrencyInput
                                placeholder={translatedForm.EnterValue(20)}
                                value={value}
                                className="form-control text-black"
                                onValueChange={(value) => setValue("DelayDozeStopAfterPumpOff", value)}
                            />
                            )}
                        />
                        {errors.DelayDozeStopAfterPumpOff && 
                            <span className="text-danger font-size-13">{errors.DelayDozeStopAfterPumpOff.message}</span>
                        }   
                        
                    </Form.Group>

                    <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.StopFill}</Form.Label>
                        <Controller
                            name="StopIfErrorFlowSensor"                                
                            control={control}
                            rules={{ required: translatedForm.Required }}
                            render={({ field:{value} }) => (
                            <Select
                                onChange={(item) => {
                                    setSelectedFlowError({label:item.label, value:item.value})
                                    setValue("StopIfErrorFlowSensor", selectedFlowError)
                                }}
                                value={selectedFlowError}
                                options={stopForError} 
                                placeholder={translatedForm.SelectFromList}
                                classNamePrefix="select"
                            />
                            )}
                        />
                        {errors.StopIfErrorFlowSensor && 
                            <span className="text-danger font-size-13">{errors.StopIfErrorFlowSensor.message}</span>
                        }
                    </Form.Group>

                    <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.TimeLastFill}</Form.Label>
                        
                        <Controller
                            name="TimeShowLastDoze"                       
                            control={control}
                            rules={{ 
                                max : {
                                    value: 60,
                                    message: translatedForm.MaxNumber(60)
                                },
                                min : {
                                    value: 0,
                                    message: translatedForm.MinNumber(0)
                                },
                                required: translatedForm.Required
                            }}
                            render={({ field:{value} }) => (
                            <CurrencyInput
                                placeholder={translatedForm.EnterValue(60)}
                                value={value}
                                className="form-control text-black"
                                onValueChange={(value) => setValue("TimeShowLastDoze", value)}
                            />
                            )}
                        />
                        {errors.TimeShowLastDoze && 
                            <span className="text-danger font-size-13">{errors.TimeShowLastDoze.message}</span>
                        }  
                        
                    </Form.Group>
                </div>
            </div>
        </Form>
      </>
    :
    <Redirect to='/login'/>
    );
}