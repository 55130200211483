import React, { useState, useEffect, useContext, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, DropdownButton, Dropdown, Form, Card, Spinner, Alert, Table, ProgressBar} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import Chart from "react-apexcharts";
import moment from 'moment/moment';  
import './../../boxicons.css';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateReport } from '../../lang/reports/translateReport';
import {TranslatePouring} from '../../lang/pourings/translatePouring';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import api from '../../utils/axiosInterceptions';

export const ReportGsmUser = () => {

  const router = useHistory();
  const {isAuth} = useContext(AuthContext);
  const { control, register, handleSubmit, reset, setValue, getValues, formState, formState: { errors }} = useForm();
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [listStation, setListStation] = useState([])
  const [data, setData] = useState([])
  const [conclusionReport, setConclusionReport] = useState([])
  const [listCompanies, setListCompanies] = useState([])
  const [listControllers, setListControllers] = useState([])
  const [listUsers, setListUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectAllUsers, setSelectAllUsers] = useState(false)
  const [selectAllControllers, setSelectAllControllers] = useState(false)
  const [userDisabled, setUserDisabled] = useState(false)
  const [controllerDisabled, setControllerDisabled] = useState(false)
  const [countStations, setCountStations] = useState(0)
  const translated = useLittera(TranslateReport);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [columnColors, setColumnColors] = useState([
    'rgba(0, 70, 128, 0.8)',
    'rgba(238, 59, 128, 0.8)',
    'rgba(10, 100, 164, 0.8)',
    'rgba(75, 192, 192, 0.8)',
    'rgba(153, 102, 255, 0.8)',
    'rgba(255, 159, 64, 0.8)',
  ])
  const [columngData, setColumnData] = useState(
    {
        label:[],
        datasets: [],
    }
  )
  const [pieData, setPieData] = useState(
    {
        label: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
                'rgba(0, 70, 128, 0.8)',
                'rgba(238, 59, 128, 0.8)',
                'rgba(10, 100, 164, 0.8)',
                'rgba(75, 192, 192, 0.8)',
                'rgba(153, 102, 255, 0.8)',
                'rgba(255, 159, 64, 0.8)',
            ],
            borderColor: [
                'rgba(0, 70, 128, 0.8)',
                'rgba(238, 59, 128, 0.8)',
                'rgba(10, 100, 164, 0.8)',
                'rgba(75, 192, 192, 0.8)',
                'rgba(153, 102, 255, 0.8)',
                'rgba(255, 159, 64, 0.8)',
            ],
            borderWidth: 1,
          },
        ],
    }
  )
  const [permission, setPermission] = useState({
    read:false,
    create:false,
    fullAccess:false
  })
  const [selectedCompany, setSelectedCompany] = useState({
    label:'',
    value:0
  })
  const [selectedStation, setSelectedStation] = useState({
    label:'',
    value:0
  })
  const [loadingData, setLoadingData] = useState({
    grid:false,
    button:false
  });
  
  const displayToast = (message, status, typeMessage) => {
    
    if(typeMessage == "success") {
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    } else {
        toast.info(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    }
  }

  const exportExcel = () => {
    let start = getValues("StartPeriod")
    let end = getValues("EndPeriod")
    let controllers = getValues("ControllerIds");
    let users = getValues("UserIds")
    let company = getValues("Company")
    let arr = []
    let arr_users = []

    if(controllers != null)
    {
        controllers.map((item) => {
            arr.push(item.id)
        })    
    }

    if(users != null)
    {
        users.map((item) => {
            arr_users.push(item.id)
        })
    }

    api.get(`/api/reports/gsm-users/export?ControllerIdsQuery=${arr}&UserIdsQuery=${arr_users}&EndPeriod=${end.toISOString()}
    &StartPeriod=${start.toISOString()}&CompanyId=${company.id}&IsSelectAllUsers=${selectAllUsers}&IsGrouping=true`, {
        headers:{
            "content-type" : "application/json",
        },
        responseType: 'blob',
        withCredentials:true
    })
    .then(function (response) {
        let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'gsm-users.xls'
        link.click()
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const xmlExport = () => {
    let start = getValues("StartPeriod")
    let end = getValues("EndPeriod")
    let controllers = getValues("ControllerIds");
    let users = getValues("UserIds")
    let company = getValues("Company")
    let arr = []
    let arr_users = []

    if(controllers != null)
    {
        controllers.map((item) => {
            arr.push(item.id)
        })
    }

    if(users != null)
    {
        users.map((item) => {
            arr_users.push(item.id)
        })
    }

    api.get(`/api/reports/gsm-users/export/xml?ControllerIdsQuery=${arr}&UserIdsQuery=${arr_users}&EndPeriod=${end.toISOString()}
    &StartPeriod=${start.toISOString()}&CompanyId=${company.id}&IsSelectAllUsers=${selectAllUsers}&IsGrouping=true`, {
        headers:{
            "content-type" : "application/xml",
        },
        responseType: 'blob',
        withCredentials:true
    })
    .then(function (response) {
        let blob = new Blob([response.data], { type: 'application/xml' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'gsm-users.xml'
        link.click()
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getCompanies = () => {
    api.get('/api/company/all', {
        withCredentials:true
    })
    .then(function (response) {
        console.log("companies")
        

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))

        setListCompanies(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getControllers = companyId => {
    api.get('/api/company/details/controllers', {
        headers:{
            "content-type" : "application/json",
            "companyId":companyId
        },
        params:{
            companyId: companyId,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("company controllers")
        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))
        setListControllers(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getUsers = companyId => {
    api.get('/api/company/details/users', {
        headers:{
            "content-type" : "application/json",
            "companyId":companyId
        },
        params:{
            companyId: companyId,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("company users")
        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.lastname + ' ' + item.firstname 
        }))
        setListUsers(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const onSubmit = data => {
    console.log(data)
    let arr = []
    let arr_users = []

    if(data.ControllerIds != null)
    {
        data.ControllerIds.map((item) => 
        {
            arr.push(item.id)
        })
    }

    if(data.UserIds != null)
    {
        data.UserIds.map((item) => 
        {
            arr_users.push(item.id)
        })
    }

    setLoadingData({button:true})
    api.post('/api/reports/gsm-users', {
        ControllerIds: arr,
        CompanyId: data.Company.id,
        UserIds: arr_users,
        IsGrouping: true,
        IsSelectAllUsers: selectAllUsers,
        //IsSelectAllControllers: selectAllControllers,
        EndPeriod: data.EndPeriod.toISOString(),
        StartPeriod: data.StartPeriod.toISOString(),
        withCredentials:true
    })
    .then(function (response) {
        console.log("result recipts")
        
        setLoadingData({button:false})
        setIsLoading(true)
        setPermission({
            read: response.data.read,
            fullAccess: response.data.fullAccess
        })

        if(response.data.read || response.data.fullAccess)
        {
            setData(response.data.dataResult.data)
            setConclusionReport(response.data.dataResult.result)
            displayToast(response.data.message, response.data.isSuccess, response.data.typeMessage)

            let pie_array = []
            let pie_label = []
            response.data.dataResult.result.map((item) => {
                pie_array.push(item.sum)
                pie_label.push(item.fuelName)
            })

            let column_label = []
            let column_data = []
            let column_array = []

            response.data.dataResult.resultGraphics.map((item, index) => 
            {
                column_array.push(item.countLitre)
                column_label.push(moment(item.date).format("DD.MM.YYYY"))
            })

            column_data.push({
                label: "Литры",
                data: column_array,
                backgroundColor: columnColors[0],
            })

            setColumnData({
                labels: column_label,
                datasets: column_data
            })

            setPieData({
                labels: pie_label,
                datasets: [
                  {
                    data: pie_array,
                    backgroundColor: [
                        'rgba(0, 70, 128, 0.8)',
                        'rgba(238, 59, 128, 0.8)',
                        'rgba(10, 100, 164, 0.8)',
                        'rgba(75, 192, 192, 0.8)',
                        'rgba(153, 102, 255, 0.8)',
                        'rgba(255, 159, 64, 0.8)',
                    ],
                    borderColor: [
                        'rgba(0, 70, 128, 0.8)',
                        'rgba(238, 59, 128, 0.8)',
                        'rgba(10, 100, 164, 0.8)',
                        'rgba(75, 192, 192, 0.8)',
                        'rgba(153, 102, 255, 0.8)',
                        'rgba(255, 159, 64, 0.8)',
                    ],
                    borderWidth: 1,
                  },
                ],
            })


        }
    })
    .catch(function (error) {
        console.log(error);
        setIsLoading(false)
        setLoadingData({button:false})
    });
  }

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    getCompanies()
    setValue("StartPeriod", new Date())
    setValue("EndPeriod", new Date())
  }, []);

  const options = {
    plugins: {
      title: {
        display: false,
        text: 'Chart.js Bar Chart - Stacked',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  
  const optionsDonut2 = {
    responsive: true,
    animation:false,
    aspectRatio: 1.4,
    plugins: {
      legend: {
        position: 'bottom',
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy',
        }
      },
    },
  };

  return (
    isAuth ?
    <>
    <div className="d-sm-flex align-items-center justify-content-between">
        <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
            {translated.Analytics} - <span className="text-secondary benzamat-font-bold font-size-18">{translated.ReportFuelReleaseByUsers}</span>
        </h2>
        <div className="page-title-right">
            <Button varian="primary" onClick={() => setShowFormAdd(true)}>
                {translated.FilterReport}
            </Button>
        </div>               
    </div>
    <div className="row mt-4">
        {
            isLoading
            ?
            permission.read || permission.fullAccess
            ?
            <>
                <div className="col-xs-12 col-lg-7 col-md-7">
                    <div className="col-xs-12 col-lg-12 col-md-12">
                        <Bar options={options} data={columngData} />
                    </div>
                </div>

                <div className="col-xs-12 col-lg-5 col-md-5">
                    <div className="col-xs-12 col-lg-12 col-md-12">
                        <Doughnut options={optionsDonut2} data={pieData} />
                    </div>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12 mt-3">         
                <div className="mt-2">
                    {
                        data.length != 0
                        ?                  
                        data.map((item) => (
                            <>
                                <h4 className="text-black">{translated.Controller} - {item.controllerName} ({item.fuelName})</h4>
                                <Table bordered striped hover className="text-black">
                                    <thead>
                                        <tr>
                                            <th>{translated.DateDefault}</th>
                                            <th>{translated.User}</th>
                                            <th>{translated.Liters}</th>
                                            <th>{translated.Price}</th>
                                            <th>{translated.Sum}</th>
                                            <th>{translated.Description}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        item.records.map((i) => (
                                            <tr className="text-black">
                                                <td>{moment(i.date).format("DD.MM.YYYY HH:ss")}</td>
                                                <td>{i.user}</td>
                                                <td>{i.countLitre.toFixed(2)}</td>
                                                <td>{i.price}</td>
                                                <td>{(i.price * i.countLitre).toFixed(2)}</td>
                                                <td>{i.dosimeter == 0 ? translated.Fill : translated.Mernik}</td>
                                            </tr>
                                        ))
                                    }
                                    <tr className="text-black">
                                            <td>{translated.Final}</td>
                                            <td colSpan={5}>{item.countLitre}</td>
                                    </tr>
                                    <tr className="text-black">
                                            <td>{translated.Mernik}</td>
                                            <td colSpan={5}>{item.countLitreMernik.toFixed(2)}</td>
                                    </tr>
                                    <tr className="text-black">
                                            <td>{translated.TotalMoneys}</td>
                                            <td colSpan={5}>{item.finalSum.toFixed(2)}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </>
                        ))
                        : null
                    }

                    {
                        conclusionReport.length != 0
                        ?
                        <>
                        <h4 className="text-black">{translated.FinalPeriod}</h4>
                            <Table bordered hover className="text-black">
                                <thead>
                                    <tr>
                                        <th>{translated.Fuel}</th>
                                        <th>{translated.Final}</th>
                                        <th>{translated.Mernik}</th>
                                        <th>{translated.TotalMoneys}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        conclusionReport.map((item) => (
                                            <tr>
                                                <td>{item.fuelName}</td>
                                                <td>{item.sum}</td>
                                                <td>{item.sumMernik}</td>
                                                <td>{item.sumMoney}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                            </>
                        : null
                    }
                </div>
            </div>
            </>

            : 
            <div className="col-xs-12 col-md-12 col-lg-12">
                <Alert variant='danger'>{translated.PermissionDenied}</Alert>
            </div>
            :
            <div className="col-xs-12 col-lg-12 col-md-12">
                <Alert variant='info'>{translated.DataNotLoad}</Alert>
            </div>
        }

    </div>

    <Offcanvas placement="end" show={showFormAdd} onHide={() => setShowFormAdd(false)}>
        <Offcanvas.Header closeButton>
        <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
            {translated.FilterReport}
        </Offcanvas.Title>
        </Offcanvas.Header>
        <div className="dropdown-divider"></div>
        <Offcanvas.Body>
            
        <form onSubmit={handleSubmit(onSubmit)} >
            <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.Company}</Form.Label>
                            <Controller
                                name="Company"                                
                                control={control}
                                rules={{ required: translatedForm.Required }}
                                render={({ field: {value} }) => (
                                <Select
                                    options={listCompanies}                   
                                    isSearchable       
                                    value={value}    
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        getControllers(item.id)
                                        getUsers(item.id)
                                        setValue("Company", item)
                                        setValue("ControllerIds", null)
                                        setValue("UserIds", null)
                                    }}
                                />
                                )}
                            />
                        {errors.CompanyIds && 
                            <span className="text-danger font-size-13">{errors.CompanyIds.message}</span>
                        }
                    </Form.Group>
                </div>

                {/*<div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Check
                        type="checkbox"
                        id="checkbox-controller"
                        label={translated.SelectAllControllers}
                        className="text-black"
                        checked={selectAllControllers}
                        onChange={(e) => {
                            setSelectAllControllers(e.target.checked)
                            setControllerDisabled(e.target.checked)
                        }}
                    />
                </div>*/}

                <div className="col-xs-12 col-lg-12 col-md-12 mt-2">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.Controller}</Form.Label>
                            <Controller
                                name="ControllerIds"
                                rules={{ required: translatedForm.Required }}                             
                                control={control}
                                render={({ field }) => (
                                <Select
                                    {...field}
                                    options={listControllers}                   
                                    isSearchable
                                    isMulti
                                    isDisabled={controllerDisabled}           
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                />
                                )}
                            />
                        {errors.ControllerIds && 
                            <span className="text-danger font-size-13">{errors.ControllerIds.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Check
                        type="checkbox"
                        id="checkbox-user"
                        label={translated.SelectAllUsers}
                        className="text-black"
                        checked={selectAllUsers}
                        onChange={(e) => {
                            setSelectAllUsers(e.target.checked)
                            setUserDisabled(e.target.checked)
                        }}
                    />
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12 mt-2">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.User}</Form.Label>
                            <Controller
                                name="UserIds"                                
                                control={control}
                                //rules={{ required: translatedForm.Required }}
                                render={({ field }) => (
                                <Select
                                    {...field}
                                    options={listUsers}                   
                                    isSearchable
                                    isMulti
                                    isDisabled={userDisabled}
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                />
                                )}
                            />
                        {errors.UserIds && 
                            <span className="text-danger font-size-13">{errors.UserIds.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.StartDate}</Form.Label>
                        <Controller
                            name="StartPeriod"                                
                            control={control}
                            rules={{ required: translatedForm.Required }}
                            render={({ field:{value} }) => (
                            <Flatpickr
                                className='form-control'
                                options={{ 
                                    time_24hr: true,  
                                    enableTime: true,
                                    dateFormat: "d.m.Y H:i",
                                    locale: Russian
                                }}
                                value={value}
                                placeholder={translatedForm.EnterSingleValue}
                                onChange={([date]) => {
                                    setValue("StartPeriod", date)
                                }}
                            />
                            )}
                        />
                        {errors.StartPeriod && 
                            <span className="text-danger font-size-13">{errors.StartPeriod.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.EndDate}</Form.Label>
                        <Controller
                            name="EndPeriod"                                
                            control={control}
                            rules={{required:translatedForm.Required}}
                            render={({ field:{value} }) => (
                            <Flatpickr
                                className='form-control'
                                options={{ 
                                    time_24hr: true,  
                                    enableTime: true,
                                    dateFormat: "d.m.Y H:i",
                                    locale: Russian
                                }}
                                placeholder={translatedForm.EnterSingleValue}
                                value={value}
                                onChange={([date]) => {
                                    setValue("EndPeriod", date)
                                }}
                            />
                            )}
                        />
                        {errors.EndPeriod && 
                            <span className="text-danger font-size-13">{errors.EndPeriod.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <button type="submit" disabled={loadingData.button} className="btn mt-2 btn-primary">
                        {loadingData.button && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingData.button && <span>{translated.GenerateReport}</span>}
                        {!loadingData.button && <span>{translated.GenerateReport}</span>}
                    </button>
                </div>

                <div className="col-xs-12 col-lg-3 col-md-3">
                    <DropdownButton className="mt-2" variant="success" title={translated.Export}>
                      <Dropdown.Item onClick={() => exportExcel()}>Excel</Dropdown.Item>
                      <Dropdown.Item onClick={() => xmlExport()}>XML</Dropdown.Item>
                    </DropdownButton>
                </div>
            </div>
        </form>
        </Offcanvas.Body>
    </Offcanvas>

    </>
    :
    <Redirect to='/login'/>
  );

}
