import React, { useEffect, Component } from 'react';
import { Menu } from './Parts/Menu';
import { ToastContainer } from 'react-toastify/dist/react-toastify';
import { CurrentSelectLanguage } from '../helpers/language';
import { translations } from '../lang/main';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";

const Layout = ({children}) => {

    const translated = useLittera(translations);
    const methods = useLitteraMethods();
    React.useEffect(() => {
      methods.setLocale(CurrentSelectLanguage);
    }, [])

    return (
      <>
        <ToastContainer />
        <Menu />
        <div className="main-content">
        <div className="page-content bg-white">
            <div className="container-fluid">
                <div className="row">
                  {children}
                </div>
            </div>
        </div>
        <footer className="footer bg-white">
            <div className="container-fluid text-black">
            {translated.benzamatProWebService}
            </div>
        </footer>
      </div>
      </>
    );
  
}

export default Layout;