import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Badge, Tab, Nav, Form, Table, Spinner, Offcanvas} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import './../../boxicons.css';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { TranslateRole } from '../../lang/roles/translateRole';
import {FormLocale} from '../../lang/form';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { CurrentSelectLanguage } from '../../helpers/language';     
import api from '../../utils/axiosInterceptions';

export const RoleSingle = () => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [listPermission, setListPermission] = useState([])
    const [role, setRole] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [opened, setOpened] = useState(false)
    const [loadingBtn, setLoadingBtn] = useState(false)
    const translated = useLittera(TranslateRole);
    const translatedForm = useLittera(FormLocale);
    const methods = useLitteraMethods()
    const [selectedRead, setSelectedRead] = useState({
      label:'',
      value:0
    })
    const [selectedCreate, setSelectedCreate] = useState({
      label:'',
      value:0
    })
    const [selectedUpdate, setSelectedUpdate] = useState({
      label:'',
      value:0
    })
    const [selectedDelete, setSelectedDelete] = useState({
      label:'',
      value:0
    })
    const [selectedFull, setSelectedFull] = useState({
      label:'',
      value:0
    })
    const [permission, setPermission] = useState({
      id:0,
      name:'',
      read:false,
      create:false,
      update:false,
      delete:false,
      fullAccess:false
    })

    const handleClose = () => setOpened(false);

    const booleans = [
      {label:translated.Using, value:1},
      {label:translated.NotUsing, value:0}
    ]

    const updateForm = id => {
      setOpened(true)
      api.get("/api/roles/details/permission/details", {
        headers:{
          "content-type" : "application/json",
          "permissionId":id
        },
        params:{
          permissionId: id,
        }
      })
      .then(function (response) {
          console.log("permission")
          
          setPermission({
            id:response.data.dataResult.id,
            name:response.data.dataResult.moduleName,
          })

          setValue("ModuleId", response.data.dataResult.moduleId)

          let read = booleans.filter((item) => item.value == response.data.dataResult.read)
          setSelectedRead({label:read[0].label, value:read[0].value})
          setValue("Read", selectedRead)

          let create = booleans.filter((item) => item.value == response.data.dataResult.create)
          setSelectedCreate({label:create[0].label, value:create[0].value})
          setValue("Create", selectedCreate)

          let update = booleans.filter((item) => item.value == response.data.dataResult.update)
          setSelectedUpdate({label:update[0].label, value:update[0].value})
          setValue("Update", selectedUpdate)

          let deletes = booleans.filter((item) => item.value == response.data.dataResult.delete)
          setSelectedDelete({label:deletes[0].label, value:deletes[0].value})
          setValue("Delete", selectedDelete)

          let full = booleans.filter((item) => item.value == response.data.dataResult.fullAccess)
          setSelectedFull({label:full[0].label, value:full[0].value})
          setValue("FullAccess", selectedFull)
      })
      .catch(function (error) {
          console.log(error);
      });
    }

    const onSubmit = data => {
      setIsLoading(true)
      api.post("/api/roles/details/update", {
        Id: params.id,
        Name: data.Name
      })
      .then(function (response) {
        
        displayToast(response.data.message, response.data.isSuccess)
        setIsLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const onUpdate = data => {
      setLoadingBtn(true)
      api.post("/api/roles/details/permission/update", {
        RoleId: params.id,
        ModuleId: data.ModuleId,
        Read: selectedRead.value,
        Create: selectedCreate.value,
        Update: selectedUpdate.value,
        Delete: selectedDelete.value,
        FullAccess: selectedFull.value
      })
      .then(function (response) {
        
        displayToast(response.data.message, response.data.isSuccess)
        setLoadingBtn(false)
        setListPermission(response.data.listDatas)
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const displayToast = (message, status) => {

      if(status){
        toast.success(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });

      } else {
        toast.error(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });
      }
    
    }

    const getPermissions = () => {
        api.get("/api/roles/details/permissions", {
            method: 'get',
            url: `api/roles/details/permissions`,
            headers:{
              "content-type" : "application/json",
              "roleId":params.id
            },
            params:{
              roleId: params.id,
            }
        })
        .then(function (response) {
            console.log("permission")
            
            setListPermission(response.data.listDatas)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getRole = () => {
      api.get("/api/roles/details", {
          headers:{
            "content-type" : "application/json",
            "roleId":params.id
          },
          params:{
            roleId: params.id,
          }
      })
      .then(function (response) {
          console.log("role")
          
          setRole(response.data.dataResult)
          setValue("Name", response.data.dataResult.name)
      })
      .catch(function (error) {
          console.log(error);
      });
    }

    useEffect(() => {
        getPermissions()
        getRole()
    }, [])

    return(
    isAuth ?
    <>
    <div className=" d-sm-flex align-items-center justify-content-between">
        <h2 className="mb-sm-0 fw-semibold font-size-18 benzamat-font-bold text-black">
            <NavLink to="/roles">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.125 13.8125L3.25 8.9375L8.125 4.0625" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.125 20.3125H17.0625C18.5709 20.3125 20.0176 19.7133 21.0842 18.6467C22.1508 17.5801 22.75 16.1334 22.75 14.625V14.625C22.75 13.8781 22.6029 13.1385 22.3171 12.4485C22.0312 11.7584 21.6123 11.1315 21.0842 10.6033C20.556 10.0752 19.9291 9.65626 19.239 9.37043C18.549 9.08461 17.8094 8.9375 17.0625 8.9375H3.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </NavLink> 
            {translated.Role} - {role.name}
        </h2>
    </div>
      
    <div className="row">
      <Tabs defaultActiveKey="maininfo"  id="uncontrolled-tab-example" className="mt-3 nav-tabs-custom">

        <Tab eventKey="maininfo" title={translated.General} tabClassName="font-size-15">
          <div className="row mt-4">
            <div className="col-xs-12 col-lg-4 col-md-4">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Control {...register("Id")} type="hidden"  />
                <Form.Group>
                  <Form.Label className="text-black">{translated.Name} <sup className="text-danger">*</sup></Form.Label>
                  <Form.Control 
                    {...register("Name", {
                      maxLength : {
                        value: 100,
                        message: translatedForm.MaxLenghtString(100)
                      },
                      minLength : {
                        value: 5,
                        message: translatedForm.MinLengthString(5)
                      },
                      required : translatedForm.Required
                    })}
                    type="text" maxLength="100" isInvalid={errors.Name}  placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                    {errors.Name && 
                      <span className="text-danger font-size-13">{errors.Name.message}</span>
                    }
                </Form.Group>

                <div className="form-group mt-3">
                  <button type="submit" disabled={isLoading} className="btn btn-primary">
                    {isLoading && (
                        <Spinner animation="border" variant="light" 
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                        />
                    )}
                    {isLoading && <span>{translatedForm.SaveChanges}</span>}
                    {!isLoading && <span>{translatedForm.SaveChanges}</span>}
                  </button>
                </div>
              </Form>      
            </div>
          </div>
        </Tab>

        <Tab eventKey="fillings" title={translated.AccessForModules} tabClassName="font-size-15">
         <div className="row mt-4">
            <Table striped bordered responsive hover className="text-black">
              <thead>
                <tr>
                  <th>{translated.Module}</th>
                  <th>{translated.Read}</th>
                  <th>{translated.Create}</th>
                  <th>{translated.Update}</th>
                  <th>{translated.Block}</th>
                  <th>{translated.Fullaccess}</th>
                  <th>{translated.Actions}</th>
                </tr>
              </thead>
              <tbody>
                {
                  listPermission.map((item) => (
                    <tr>
                      <td className="text-black">{item.moduleName}</td>
                      <td>
                        {
                          item.read 
                            ? <Badge bg="success" className="font-size-13">{translated.Using}</Badge> 
                            : <Badge bg="danger" className="font-size-13">{translated.NotUsing}</Badge>
                        }
                      </td>
                      <td>
                        {
                          item.create 
                            ? <Badge bg="success" className="font-size-13">{translated.Using}</Badge> 
                            : <Badge bg="danger" className="font-size-13">{translated.NotUsing}</Badge>
                        }
                      </td>
                      <td>
                        {
                          item.update 
                            ? <Badge bg="success" className="font-size-13">{translated.Using}</Badge> 
                            : <Badge bg="danger" className="font-size-13">{translated.NotUsing}</Badge>
                        }
                      </td>
                      <td>
                        {
                          item.delete 
                            ? <Badge bg="success" className="font-size-13">{translated.Using}</Badge> 
                            : <Badge bg="danger" className="font-size-13">{translated.NotUsing}</Badge>
                        }
                      </td>
                      <td>
                        {
                          item.fullAccess 
                            ? <Badge bg="success" className="font-size-13">{translated.Using}</Badge> 
                            : <Badge bg="danger" className="font-size-13">{translated.NotUsing}</Badge>
                        }
                      </td>
                      <td>
                      <Button onClick={() => updateForm(item.id)} className="btn btn-light btn-sm">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.24112 16.8749H3.75C3.58424 16.8749 3.42527 16.8091 3.30806 16.6918C3.19085 16.5746 3.125 16.4157 3.125 16.2499V12.7588C3.125 12.6767 3.14117 12.5954 3.17258 12.5196C3.20398 12.4438 3.25002 12.3749 3.30806 12.3168L12.6831 2.94185C12.8003 2.82464 12.9592 2.75879 13.125 2.75879C13.2908 2.75879 13.4497 2.82464 13.5669 2.94185L17.0581 6.43296C17.1753 6.55017 17.2411 6.70915 17.2411 6.87491C17.2411 7.04067 17.1753 7.19964 17.0581 7.31685L7.68306 16.6918C7.62502 16.7499 7.55612 16.7959 7.48029 16.8273C7.40447 16.8587 7.32319 16.8749 7.24112 16.8749Z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M10.625 5L15 9.375" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M7.45938 16.8349L3.16406 12.5396" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                      </Button> 
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
         </div>
        </Tab>

      </Tabs>
    </div>
    <Offcanvas placement="end" show={opened} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>{translated.UpdateRole}</Offcanvas.Title>
        </Offcanvas.Header>
        <div className="dropdown-divider"></div>
        <Offcanvas.Body>  

          <Form onSubmit={handleSubmit(onUpdate)}>
          <Form.Control {...register("ModuleId")} type="hidden"  />
            <Form.Group>
            <Form.Label className="text-black">{translated.Module}</Form.Label>
              <input type="text" className="form-control" disabled value={permission.name} />
            </Form.Group>

            <Form.Group className="mt-3">
            <Form.Label className="text-black">{translated.Read} <sup className="text-danger">*</sup></Form.Label>
              <Controller
                name="Read"                                
                control={control}
                rules={{ required: translatedForm.Required }}
                render={({ field }) => (
                <Select
                    value={selectedRead}                       
                    options={booleans}                        
                    placeholder={translatedForm.SelectFromList}
                    classNamePrefix="select"
                    onChange={(item) => {
                      setSelectedRead({label:item.label, value:item.value})
                      setValue("Read", selectedRead)
                    }}
                />
                )}
              />
            </Form.Group>

            <Form.Group className="mt-3">
            <Form.Label className="text-black">{translated.Create}</Form.Label>
              <Controller
                  name="Create"                                
                  control={control}
                  rules={{ required: translatedForm.Required }}
                  render={({ field }) => (
                  <Select
                      value={selectedCreate}                       
                      options={booleans}                        
                      placeholder={translatedForm.SelectFromList}
                      classNamePrefix="select"
                      onChange={(item) => {
                        setSelectedCreate({label:item.label, value:item.value})
                        setValue("Create", selectedCreate)
                      }}
                  />
                  )}
                />
            </Form.Group>

            <Form.Group className="mt-3">
            <Form.Label className="text-black">{translated.Update}</Form.Label>
              <Controller
                  name="Update"                                
                  control={control}
                  rules={{ required: translatedForm.Required }}
                  render={({ field }) => (
                  <Select
                      value={selectedUpdate}                       
                      options={booleans}                        
                      placeholder={translatedForm.SelectFromList}
                      classNamePrefix="select"
                      onChange={(item) => {
                        setSelectedUpdate({label:item.label, value:item.value})
                        setValue("Update", selectedUpdate)
                      }}
                  />
                  )}
                />
            </Form.Group>

            <Form.Group className="mt-3">
            <Form.Label className="text-black">{translated.Blocking}</Form.Label>
              <Controller
                  name="Delete"                                
                  control={control}
                  rules={{ required: translatedForm.Required }}
                  render={({ field }) => (
                  <Select
                      value={selectedDelete}                       
                      options={booleans}                        
                      placeholder={translatedForm.SelectFromList}
                      classNamePrefix="select"
                      onChange={(item) => {
                        setSelectedDelete({label:item.label, value:item.value})
                        setValue("Delete", selectedDelete)
                      }}
                  />
                  )}
                />
            </Form.Group>

            <Form.Group className="mt-3">
            <Form.Label className="text-black">{translated.Fullaccess}</Form.Label>
              <Controller
                  name="FullAccess"                                
                  control={control}
                  rules={{ required: translated.Required }}
                  render={({ field }) => (
                  <Select
                      value={selectedFull}                       
                      options={booleans}                        
                      placeholder={translatedForm.SelectFromList}
                      classNamePrefix="select"
                      onChange={(item) => {
                        setSelectedFull({label:item.label, value:item.value})
                        setValue("FullAccess", selectedFull)
                      }}
                  />
                  )}
                />
            </Form.Group>

            <div className="form-group mt-3">
                <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                    {loadingBtn && (
                        <Spinner animation="border" variant="light" 
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                        />
                    )}
                    {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                    {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                </button>
            </div>
        
            </Form>

        </Offcanvas.Body>
    </Offcanvas>
    </>
    :
    <Redirect to='/login'/>
    );
}