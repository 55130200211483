import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateController = {

    Name:{
        en_US: "Name",
        ru_RU: "Название"
    },
    Settings:{
        en_US: "Settings",
        ru_RU: "Настройки"
    },
    AutoRead:{
        en_US: "Auto read",
        ru_RU: "Автоопрос"
    },
    VersionSoftware:{
        en_US: "Version software",
        ru_RU: "Версия ПО"
    },
    GSM:{
        en_US: "GSM status",
        ru_RU: "GSM связь"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    Unknow:{
        en_US: "Unknow",
        ru_RU: "Не указано"
    },
    Disable:{
        en_US: "Disable",
        ru_RU: "Отключен" 
    },
    Enable:{
        en_US: "Enable",
        ru_RU: "Включен"
    },
    Read:{
        en_US: "Readed",
        ru_RU: "Считаны"
    },
    NotRead:{
        en_US: "Not readed",
        ru_RU: "Не считаны"
    }
    
};
