import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateAccount = {

    SaveChanges:{
        en_US: "Save changes",
        ru_RU: "Сохранить"
    },
    Comment:{
        en_US: "Commentary",
        ru_RU: "Комментарий"
    },
    EnterComment:{
        en_US: "Enter comment",
        ru_RU: "Введите комментарий"
    },
    User:{
        en_US: "User",
        ru_RU: "Пользователь"
    },
    DateRecord:{
        en_US: "Date",
        ru_RU: "Дата записи"
    },
    Sum:{
        en_US: "Sum",
        ru_RU: "Сумма"
    },
    EnterSum:{
        en_US: "Enter sum",
        ru_RU: "Введиту сумму"
    },
    RefillAccount:{
        en_US: "Refill account",
        ru_RU: "Пополнение счета"
    },
    Valute:{
        en_US: "Rupee",
        ru_RU: "руб"
    }
};
