import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateTransport = {

    Title:{
        en_US: "Transports",
        ru_RU: "Транспортные средства"
    },
    TitleSingle:{
        en_US: "Transport",
        ru_RU: "Транспорт"
    },
    AddTransportTitle:{
        en_US: "Add transport",
        ru_RU: "Добавление ТС"
    },
    AddTransport:{
        en_US: "Add transport",
        ru_RU: "Добавить ТС"
    },
    BenzaRfidType:{
        en_US: "RFID type",
        ru_RU: "Тип считывателя"
    },
    ButtonRfid:{
        en_US: "Get card number",
        ru_RU: "Получить номер карты"
    },
    Name:{
        en_US: "Name",
        ru_RU: "Название"
    },
    AccessDenied:{
        en_US: "Access denied",
        ru_RU: "У вас недостаточно прав доступа для просмотра информации к данному разделу"
    },
    CarNumber:{
        en_US: "Transport's number",
        ru_RU: "Номер"
    },
    Lock:{
        en_US: "Lock",
        ru_RU: "Заблокировать"
    },
    Unlock:{
        en_US: "Unlock",
        ru_RU: "Разблокировать"
    },
    LockMessage:{
        en_US: "The transport is locked and will not be recorded in the controller",
        ru_RU: "Транспортное средство заблокировано и не будет записано в контроллер"
    },
    Unknow:{
        en_US: "Unknow",
        ru_RU: "Не указано"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    Company: {
        en_US: "Company",
        ru_RU: "Компания"
    },
    ServiceDate: {
        en_US: "Maintain up to",
        ru_RU: "Обслуживать до"
    },
    Contract:{
        en_US: "Contract number",
        ru_RU: "Договор"
    },
    BenzamatOffice:{
        en_US: "ID from benzamatOffice",
        ru_RU: "ID из benzamatOffice"
    },
    TypeAuth:{
        en_US: "Type auth",
        ru_RU: "Тип авторизации"
    },
    NumberRfid:{
        en_US: "RFID number",
        ru_RU: "Номер RFID"
    },
    RfidWithNumber:{
        en_US: "RFID with password",
        ru_RU: "RFID и пароль"
    },
    AuthTransport:{
        en_US: "Use auth transport",
        ru_RU: "Авторизация ТС"
    },
    TypeLimit:{
        en_US: "Type limit",
        ru_RU: "Тип лимита"
    },
    LimitAbsolute:{
        en_US: "Limit absolute",
        ru_RU: "Абсолютный"
    },
    LimitDay:{
        en_US: "Daily limit",
        ru_RU: "Лимит день"
    },
    LimitMonth:{
        en_US: "Month limit",
        ru_RU: "Лимит месяц"
    },
    Yes:{
        en_US: "Active",
        ru_RU: "Да"
    },
    No:{
        en_US: "Disabled",
        ru_RU: "Нет"
    },
    WarningLimitMonth:{
        en_US: "Monthly limit cannot be less than daily limit",
        ru_RU: "Лимит в месяц не может быть меньше дневного лимита"
    },
    Block:{
        en_US: "Block",
        ru_RU: "Заблокировать"
    },
    General:{
        en_US: "General",
        ru_RU: "Основная информация"
    },
    Limits:{
        en_US: "Limits",
        ru_RU: "Лимиты"
    },
    Options:{
        en_US: "Options",
        ru_RU: "Параметры"
    },
    Controllers:{
        en_US: "Controllers",
        ru_RU: "Контроллеры" 
    },
    LimitPlan:{
        en_US: "Limit plan",
        ru_RU: "Лимитный план" 
    },
    HistoryChangeLimit:{
        en_US: "History change",
        ru_RU: "История изменений" 
    },
    Balance:{
        en_US: "Balance",
        ru_RU: "Остаток" 
    },
    Limit:{
        en_US: "Limit",
        ru_RU: "Лимит" 
    },
    EditLimit:{
        en_US: "Edit limit",
        ru_RU: "Изменение лимита" 
    },
    Liters:{
        en_US: "Liters",
        ru_RU: "Литры" 
    },
    Number:{
        en_US: "Number",
        ru_RU: "Номер" 
    },
    Model:{
        en_US: "Model",
        ru_RU: "Модель" 
    },
    Settings:{
        en_US: "Settings",
        ru_RU: "Настройки"
    },
    AutoRead:{
        en_US: "Auto read",
        ru_RU: "Автоопрос"
    },
    VersionSoftware:{
        en_US: "Version software",
        ru_RU: "Версия ПО"
    },
    GSM:{
        en_US: "GSM status",
        ru_RU: "GSM связь"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    ActionWithTransports:{
        en_US: "Action with transports",
        ru_RU: "Действия с ТС"
    },
    Readed:{
        en_US: "Readed",
        ru_RU: "Считаны"
    },
    NotReaded:{
        en_US: "Not readed",
        ru_RU: "Не считаны"
    },
    Enable:{
        en_US: "Enable",
        ru_RU: "Включено"
    },
    Disable:{
        en_US: "Disable",
        ru_RU: "Отключено"
    },
    ListTransports:{
        en_US: "List of transports",
        ru_RU: "Выберите транспорт"
    },
    ListCompanies:{
        en_US: "List of companies",
        ru_RU: "Выберите компанию" 
    },
    SelectAllUsers:{
        en_US: "Select all transports",
        ru_RU: "Выбрать весь транспорт"
    },
    MessageSelectUsers:{
        en_US: "Only those transports that are not blocked, not deleted in the system and not added to the controller will be added to the controller",
        ru_RU: "В контроллер будут добавлены только тот транспорт, который: не заблокирован, не удалены в системе и не добавлен в контроллер"
    },
    Submit:{
        en_US: "Submit",
        ru_RU: "Подтвердить"
    },
    Cancel:{
        en_US: "Cancel",
        ru_RU: "Отмена"
    },
    TextDelete:{
        en_US: "Are you sure you want to delete the transport?",
        ru_RU: "Вы уверены, что хотите удалить транспорт?"
    },
    HeaderDelete:{
        en_US: "Delete the transport",
        ru_RU: "Удаление транспорта"
    }
};
