import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import {AuthContext} from './context/AuthContext';
import AppRouter from './components/AppRouter';
import axios from 'axios';
import { LitteraProvider } from "@assembless/react-littera";
import AxiosErrorHandler from './context/AxiosErrorHandler';
import api from './utils/axiosInterceptions';

const App = () => {

  const router = useHistory();
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("accessToken")

    if(token != null && token != undefined){
      api.get('/api/users/dashboard', {
        withCredentials:true
      })
      .then(function (response) {
        setIsAuth(true)
      })
      .catch(function (error) {
      }); 
    } else {
      setIsAuth(false)
      localStorage.removeItem("accessToken")
    }
  }, []);

  return ( 
    <AuthContext.Provider value={{
      isAuth, setIsAuth
    }}>
      <AxiosErrorHandler>
        <BrowserRouter>
          <LitteraProvider locales={["en_US", "ru_RU"]}>
            <AppRouter /> 
          </LitteraProvider>      
        </BrowserRouter>
      </AxiosErrorHandler>
    </AuthContext.Provider>
  );
}

export default App;