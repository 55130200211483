import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Spinner, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import CurrencyInput from 'react-currency-input-field';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

export const Metrolog = ({translated, translatedForm}) => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [selectedImpuls, setSelectedImpuls] = useState({
        label:'1',
        value:1
    })
    const [adjustingFactor, setAdjustingFactor] = useState({
      label:'',
      value:''
    })
    const [summator, setSummator] = useState(0)
    const [selectedCountPlomb, setSelectedCountPlomb] = useState(0)
    const [loadingBtn, setLoadingBtn] = useState(false)

    const listImpulses = [
        {label:"1", value:1},
        {label:"10", value:10},
        {label:"100", value:100},
        {label:"200", value:200},
    ]

    const adjustingFactorList = [
      {label:"0.9992", value:9992},
      {label:"0.9000", value:9000},
      {label:"1.0000", value:10000},
      {label:"1.1000", value:11000},
    ]
 
  useEffect(() => {
    api.get("/api/controllers/details/metrolog", {
      headers:{
        "content-type" : "application/json",
        "controllerId":params.id
      },
      params:{
        controllerId: params.id,
      },
      withCredentials:true
    })
    .then(function (response) {
      console.log("metrolog")
      

      setSelectedCountPlomb(response.data.dataResult.countChangePlomb)
      setSummator(response.data.dataResult.summator / 100)

      setValue("AdjustingFactor", response.data.dataResult.adjustingFactor)
      setValue("Impulse", response.data.dataResult.impulseCount)
    
    })
    .catch(function (error) {
      console.log(error);
    });
  }, [])

  const onSubmit = data => {

    setLoadingBtn(true);
    api.post("/api/controllers/details/metrolog/update", {
      ImpulseCount: data.Impulse,
      AdjustingFactor: data.AdjustingFactor,
      CountChangePlomb: selectedCountPlomb,
      Summator: summator,
      ControllerId: params.id
    })
    .then(function (response) {
      setLoadingBtn(false)
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
      setLoadingBtn(false)
      displayToast(error.response.data.errors[0].errorMessage, error.response.data.isSuccess)
    });
  }

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
  
    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
   
  }

    return(
      isAuth ?
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-xs-12 col-lg-5 col-md-5">

                <Form.Group className="mb-3">
                <Form.Label className="text-black">{translated.ImpulseCountPerMetro}</Form.Label>
                    <Controller
                        name="Impulse"                                
                        control={control}
                        rules={{ 
                            max : {
                                value: 400,
                                message:translatedForm.MaxNumber(400)
                            },
                            min : {
                                value: 1,
                                message: translatedForm.MinNumber(1)
                            },
                            required: translatedForm.Required
                        }}
                        render={({ field:{value} }) => (
                        <CurrencyInput
                            placeholder={translatedForm.EnterValue(400)}
                            value={value}
                            className="form-control"
                            onValueChange={(value) => setValue("Impulse", value)}
                        />
                        )}
                    />
                  {errors.Impulse && 
                      <span className="text-danger font-size-13">{errors.Impulse.message}</span>
                  }
                </Form.Group>

                <Form.Group className="mb-3">
                <Form.Label className="text-black">{translated.AdjustmentFactor}</Form.Label>
                <input 
                  {...register("AdjustingFactor", {
                      required : translatedForm.Required
                  })}
                  placeholder="0.9000 - 1.2000" type="text" className="form-control text-black" />
                  {errors.AdjustingFactor && 
                      <span className="text-danger font-size-13">{errors.AdjustingFactor.message}</span>
                  }

                </Form.Group>                

                  <div className="form-group mt-3">
                      <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                          {loadingBtn && (
                              <Spinner animation="border" variant="light" 
                                  as="span"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  style={{ marginRight: "5px" }}
                              />
                          )}
                          {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                          {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                      </button>
                  </div>
                </div>


              <div className="col-xs-12 col-lg-6 col-md-6">
                
                <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.ElectronicSeal}</Form.Label>
                    <input type="text" disabled={true} className="form-control" value={selectedCountPlomb} />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.Totaliser}</Form.Label>
                    <input type="text" disabled={true} className="form-control" value={summator} />
                </Form.Group>

              </div>
            </div>
        </Form>
      </>
    :
    <Redirect to='/login'/>
    );
}