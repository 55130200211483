import React, { useState, useMemo, useEffect, useContext, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, DropdownButton, ButtonGroup, Dropdown, Form, Card, Spinner, Alert, Table, ProgressBar} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import moment from 'moment';
import Select from 'react-select';
import Chart from "react-apexcharts";
import './../../boxicons.css';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import MaterialReactTable from 'material-react-table';
import { Russian } from "flatpickr/dist/l10n/ru.js"
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateReport } from '../../lang/reports/translateReport';
import { CurrentSelectLanguage } from '../../helpers/language';
import {FormLocale} from '../../lang/form';
import LazyLoad from 'react-lazy-load';
import { Chart as ChartJS } from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import api from '../../utils/axiosInterceptions';

export const ReportFillings = () => {

  const router = useHistory();
  const {isAuth} = useContext(AuthContext);
  const { control, register, handleSubmit, reset, setValue, getValues, formState, formState: { errors }} = useForm();
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [listStation, setListStation] = useState([])
  const [listUsers, setListUsers] = useState([])
  const [listCompanies, setListCompanies] = useState([])
  const [listControllers, setListControllers] = useState([])
  const [selectAllUsers, setSelectAllUsers] = useState(false)
  const [selectAllControllers, setSelectAllControllers] = useState(false)
  const [usersIsDisabled, setUsersIsDisabled] = useState(false)
  const [controllerIsDisabled, setControllerIsDisabled] = useState(false)
  const [listFillings, setListFillings] = useState([])
  const [countStations, setCountStations] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const translated = useLittera(TranslateReport);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [diagrammColumn, setDiagrammColumn] = useState({
    series:[],
    donut:{},
    bar:{}
  })
  const [pieData, setPieData] = useState(
    {
        labels:[],
        datasets: [
          {
            data: [],
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
        ],
    }
  )
  const [bar, setBar] = useState({
    labels:[],
        datasets: [
        {
            data: [],
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
    ],
  })
  const [permission, setPermission] = useState({
    read:false,
    create:false,
    fullAccess:false
  })
  const [selectedCompany, setSelectedCompany] = useState({
    label:'',
    value:0
  })
  const [selectedStation, setSelectedStation] = useState({
    label:'',
    value:0
  })
  const [loadingData, setLoadingData] = useState({
    grid:false,
    button:false
  });

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    getCompanies()
    setValue("StartPeriod", new Date())
    setValue("EndPeriod", new Date())
  }, []);

  const exportExcel = () => {
    let start = getValues("StartPeriod")
    let end = getValues("EndPeriod")
    let userId = getValues("UserId")
    let controllerIds = getValues("ControllerIds")
    let arr = ''

    if(controllerIds != null)
    {
        for(let i = 0; i < controllerIds.length; i++)
        {
            arr += controllerIds[i].id+',';
        }
    }

    api.get('/api/reports/fillings/export', {
        headers:{
            "content-type" : "application/json",
            "UserId": userId != null ? userId.id : 0,
            "IsSelectAllUsers": selectAllUsers,
            "CompanyId": selectedCompany != null ? selectedCompany.value : 0,
            "EndPeriod": end.toISOString(),
            "StartPeriod": start.toISOString(),
            "ControllerIdsQuery": arr,
            "IsSelectControllers": selectAllControllers
        },
        params:{
            UserId: userId != null ? userId.id : 0,
            CompanyId: selectedCompany != null ? selectedCompany.value : 0,
            EndPeriod: end.toISOString(),
            IsSelectAllUsers: selectAllUsers,
            StartPeriod: start.toISOString(),
            ControllerIdsQuery: arr,
            IsSelectControllers: selectAllControllers
        },
        responseType: 'blob',
        withCredentials:true
    })
    .then(function (response) {
        let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'fillings.xls'
        link.click()
    })
    .catch(function (error) {
        console.log(error);
    });
  }
  
  const options = {
    chart: {
        height: 350,
        type: 'bar',
        zoom: {
            enabled: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'straight'
    },
    title: {
        text: translated.MainLiters,
        align: 'center'
    },
    grid: {
        row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
        },
    },

  }

  var optionsDonut = {
    chart: {
        type: 'donut',
    },
    labels: pieData.labels,
    title: {
        text: translated.ByFuelType,
        align: 'center'
    },
    legend: {
        position:'bottom'
    },
    responsive: [{
        breakpoint: 480,
        options: {
        chart: {
            width: 200
        },
        legend: {
            position: 'bottom'
        }
        }
    }]
  };


  const displayToast = (message, status, typeMessage) => {
    
    if(typeMessage == "success") {
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    } else {
        toast.info(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    }
  }

  const getCompanies = () => {
    api.get('/api/company/all', {
        withCredentials:true
    })
    .then(function (response) {
        console.log("companies")       

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))

        setListCompanies(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getUsers = companyId => {
    api.get('/api/company/details/users', {
        headers:{
            "content-type" : "application/json",
            "companyId":companyId
        },
        params:{
            companyId: companyId,
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("stations of company")        

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.firstname + ' ' + item.lastname
        }))
        setListUsers(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getControllers = companyId => {
    api.get('/api/company/details/controllers', {
        headers:{
            "content-type" : "application/json",
            "companyId":companyId
        },
        params:{
            companyId: companyId,
        },
        withCredentials:true
    })
    .then(function (response) {
        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))
        setListControllers(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const onSubmit = data => {
    console.log(data)
    setLoadingData({button:true})
    let arr = ''
    if(!selectAllControllers && data.ControllerIds != null)
    {
        for(let i = 0; i < data.ControllerIds.length; i++)
        {
            arr += data.ControllerIds[i].id+',';
        }
    }

    console.log("array of controllers")
    console.log(arr)

    api.get("/api/reports/fillings", {
        headers:{
            "content-type" : "application/json",
            "UserId": data.UserId != null ? data.UserId.id : 0,
            "CompanyId": selectedCompany != null ? selectedCompany.value : 0,
            "IsSelectAllUsers": selectAllUsers,
            "EndPeriod": data.EndPeriod.toISOString(),
            "StartPeriod": data.StartPeriod.toISOString(),
            "ControllerIdsQuery": arr,
            "IsSelectControllers": selectAllControllers
        },
        params:{
            UserId: data.UserId != null ? data.UserId.id : 0,
            CompanyId: selectedCompany != null ? selectedCompany.value : 0,
            EndPeriod: data.EndPeriod.toISOString(),
            StartPeriod: data.StartPeriod.toISOString(),
            IsSelectallUsers: selectAllUsers,
            IsSelectControllers: selectAllControllers,
            ControllerIdsQuery: arr
        },
        withCredentials:true
    })
    .then(function (response) {
        console.log("fillings")
        console.log(response)
        setIsLoading(true)
        setPermission({
            read: response.data.read,
            fullAccess: response.data.fullAccess
        })

        if(response.data.read || response.data.fullAccess)
        {
            setListFillings(response.data.dataResult.fillings)
            setLoadingData({button:false})
            displayToast(response.data.message, response.data.isSuccess, response.data.typeMessage)
    
            let array_pie_labels = []
            let array_pie_series = []

            response.data.dataResult.gropuByFuels.map((item) => {
                array_pie_labels.push(item.fuelName)
                array_pie_series.push(item.countLitre)
            })

            let array_donut_series = []
            let array_bar_labels = []
            response.data.dataResult.groupByFillings.map((item) => {
                array_donut_series.push(item.countLitre)
                array_bar_labels.push(moment(item.date).format("DD.MM.YYYY"))
            })

            setPieData(
                {
                    labels: array_pie_labels,
                    datasets: [
                      {
                        data: array_pie_series,
                        backgroundColor: [
                            'rgba(0, 70, 128, 0.8)',
                            'rgba(238, 59, 128, 0.8)',
                            'rgba(10, 100, 164, 0.8)',
                            'rgba(75, 192, 192, 0.8)',
                            'rgba(153, 102, 255, 0.8)',
                            'rgba(255, 159, 64, 0.8)',
                        ],
                        borderColor: [
                            'rgba(0, 70, 128, 0.8)',
                            'rgba(238, 59, 128, 0.8)',
                            'rgba(10, 100, 164, 0.8)',
                            'rgba(75, 192, 192, 0.8)',
                            'rgba(153, 102, 255, 0.8)',
                            'rgba(255, 159, 64, 0.8)',
                        ],
                        borderWidth: 1,
                      },
                ],
                }
            )

            setBar(
                {
                    labels:array_bar_labels,
                    datasets: [
                      {
                        label:"Литры",
                        data: array_donut_series,
                        backgroundColor: 'rgba(0, 70, 128, 0.8)',
                      },
                    ],
                }
            )
        }
    })
    .catch(function (error) {
        console.log(error);
        setIsLoading(false)
         setLoadingData({button:false})
    });
  }

  const optionsTest = {
    responsive: true,
    animation:false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy',
        }
      },
    },
  };

  const optionsDonut2 = {
    responsive: true,
    animation:false,
    aspectRatio: 1.4,
    plugins: {
      legend: {
        position: 'bottom',
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy',
        }
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.firstname + ' ' + row.lastname,
        accessorKey: 'User', 
        header: translated.User,
      },
      {
        accessorFn: (row) => row.rfidUser != '' ? row.rfidUser : "Не указано",
        accessorKey: 'RfidUser',
        header: "RFID пользователя",
      },
      {
        accessorFn: (row) => row.transportName != null ? row.transportName : 'Не указано',
        accessorKey: 'Transport',
        header: translated.Transport,
      },
      {
        accessorFn: (row) => row.rfidTransport != '' ? row.rfidTransport : "Не указано",
        accessorKey: 'RfidTransport',
        header: "RFID машины",
      },
      {
        accessorFn: (row) => row.fuelName,
        accessorKey: 'Fuel',
        header: translated.Fuel,
      },
      {
        accessorFn: (row) => moment(row.fillingDateTime).format("DD.MM.YYYY HH:mm"),
        accessorKey: 'Date',
        header: translated.Date,
        size:200
      },
      {
        accessorFn: (row) => row.timeZone,
        accessorKey: 'timeStation',
        header: translated.Timezone,
        size:200
      },
      {
        accessorFn: (row) => row.countLitre,
        accessorKey: 'Liters',
        header: translated.Liters,
      },
      {
        accessorFn: (row) => row.price,
        accessorKey: 'Price',
        header: translated.Price,
      },
      {
        accessorFn: (row) => row.price * row.countLitre,
        accessorKey: 'Sum',
        header: translated.Sum,
      },
      {
        accessorFn: (row) => row.controllerName,
        accessorKey: 'Controller',
        header: translated.Controller,
      },
    ],
    [],
  );

  return (
    isAuth ?
    <>
    <div className="d-sm-flex align-items-center justify-content-between">
        <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
            {translated.Analytics} - <span className="text-secondary benzamat-font-bold font-size-18">{translated.LastFillings}</span>
        </h2>
        <div className="page-title-right">
            <Button varian="primary" onClick={() => setShowFormAdd(true)}>
             {translated.FilterReport}
            </Button>
        </div>            
    </div>
    
    <div className="row mt-4">

        {
            isLoading
            ?
            permission.read || permission.fullAccess
                ? 
                <>
                    
                    <div className="col-xs-12 col-lg-7 col-md-7">
                        <div className="col-xs-12 col-lg-12 col-md-12">
                            <Bar options={optionsTest} data={bar} style={{"height":"100px"}} />
                        </div>
                    </div>

                    <div className="col-xs-12 col-lg-5 col-md-5">
                        <div className="col-xs-12 col-lg-12 col-md-12">
                            <Doughnut options={optionsDonut2} data={pieData} tyle={{"height":"100px"}} />
                        </div>
                    </div>

                    <div className="col-xs-12 col-lg-12 col-md-12">         
                        <div className="mt-2">
                            <MaterialReactTable 
                                columns={columns} 
                                data={listFillings}
                                localization={MRT_Localization_EN}
                                initialState={{ showColumnFilters: true }}
                                enableTopToolbar={false}
                                enableRowVirtualization
                                muiTablePaperProps={{
                                elevation: 1,
                                }}
                                muiTableHeadCellProps={{
                                //simple styling with the `sx` prop, works just like a style prop in this example
                                sx: {
                                    fontWeight: 'bold',
                                    color:"#000",
                                    backgroundColor:'#F3F3F3',
                                    fontFamily: 'BenzamatRegular',
                                },
                                }}
                                muiTableBodyCellProps={{
                                sx: {
                                    color:"#000",
                                    fontFamily: 'BenzamatRegular',
                                },
                                }}
                            />
                        </div>
                    </div>
                </>
                : 
                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Alert variant='danger'>{translated.PermissionDenied}</Alert>
                </div>
            :
            <div className="col-xs-12 col-lg-12 col-md-12">
                <Alert variant='info'>{translated.DataNotLoad}</Alert>
            </div>
        }

    </div>

    <Offcanvas placement="end" show={showFormAdd} onHide={() => setShowFormAdd(false)}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
                {translated.FilterReport}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <div className="dropdown-divider"></div>
          <Offcanvas.Body>
          <form onSubmit={handleSubmit(onSubmit)} >
            <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.Company}</Form.Label>
                            <Controller
                                name="Company"                                
                                control={control}
                                render={({ field }) => (
                                <Select
                                    options={listCompanies}                   
                                    isSearchable
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        getUsers(item.id)
                                        getControllers(item.id)
                                        setSelectedCompany({label:item.label, value:item.value})
                                        setValue("UserId", null)
                                    }}
                                />
                                )}
                            />
                        {errors.Station && 
                            <span className="text-danger font-size-13">{errors.Station.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Check
                        type="checkbox"
                        id="checkbox-controllers"
                        label={translated.SelectAllControllers}
                        className="text-black"
                        checked={selectAllControllers}
                        onChange={(e) => {
                            setSelectAllControllers(e.target.checked)
                            setControllerIsDisabled(e.target.checked)
                        }}
                    />
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.Controller}</Form.Label>
                            <Controller
                                name="ControllerIds"                                
                                control={control}
                                //rules={{required:translatedForm.Required}}
                                render={({ field }) => (
                                <Select
                                    {...field}
                                    options={listControllers}                   
                                    isSearchable  
                                    isMulti
                                    isDisabled={controllerIsDisabled}     
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                />
                                )}
                            />
                        {errors.ControllerIds && 
                            <span className="text-danger font-size-13">{errors.ControllerIds.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Check
                        type="checkbox"
                        id="checkbox-time"
                        label={translated.SelectAllUsers}
                        className="text-black"
                        checked={selectAllUsers}
                        onChange={(e) => {
                            setSelectAllUsers(e.target.checked)
                            setUsersIsDisabled(e.target.checked)
                        }}
                    />
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.User}</Form.Label>
                            <Controller
                                name="UserId"                                
                                control={control}
                                //rules={{required:translatedForm.Required}}
                                render={({ field: {value} }) => (
                                <Select
                                    options={listUsers}                   
                                    isSearchable
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                    value={value}
                                    isDisabled={usersIsDisabled}
                                    onChange={(item) => {
                                        setValue("UserId", item)
                                    }}
                                />
                                )}
                            />
                        {errors.ControllerId && 
                            <span className="text-danger font-size-13">{errors.ControllerId.message}</span>
                        }
                    </Form.Group>
                </div>

                {/*<div className="col-xs-12 col-lg-12 col-md-12 mb-2 mt-2">
                    <ButtonGroup size="md">
                        <Button variant="light" onClick={() => {
                            var start = new Date();
                            start.setUTCHours(0,0,0,0);
                            
                            var end = new Date();
                            end.setUTCHours(23,59,59,999);
                            
                            console.log( start.toUTCString() + ':' + end.toUTCString() );
                        }}>День</Button>
                        <Button variant="light">Неделя</Button>
                        <Button variant="light">Месяц</Button>
                    </ButtonGroup>
                </div>*/}

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.StartDate}</Form.Label>
                        <Controller
                            name="StartPeriod"                                
                            control={control}
                            rules={{required:translatedForm.Required}}
                            render={({ field:{value} }) => (
                            <Flatpickr
                                className='form-control'
                                options={{ 
                                    dateFormat: "d.m.Y",
                                    locale: Russian
                                }}
                                value={value}
                                placeholder={translatedForm.SelectFromList}
                                onChange={([date]) => {
                                    setValue("StartPeriod", date)
                                }}
                            />
                            )}
                        />
                        {errors.StartPeriod && 
                            <span className="text-danger font-size-13">{errors.StartPeriod.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">{translated.EndDate}</Form.Label>
                        <Controller
                            name="EndPeriod"                                
                            control={control}
                            rules={{required:translatedForm.Required}}
                            render={({ field:{value} }) => (
                            <Flatpickr
                                className='form-control'
                                options={{ 
                                    time_24hr: true,  
                                    enableTime: true,
                                    dateFormat: "d.m.Y",
                                    locale: Russian
                                }}
                                placeholder={translatedForm.SelectFromList}
                                value={value}
                                onChange={([date]) => {
                                    setValue("EndPeriod", date)
                                }}
                            />
                            )}
                        />
                        {errors.EndPeriod && 
                            <span className="text-danger font-size-13">{errors.EndPeriod.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <button type="submit" disabled={loadingData.button} className="btn mt-2 btn-primary">
                        {loadingData.button && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingData.button && <span>{translated.GenerateReport}</span>}
                        {!loadingData.button && <span>{translated.GenerateReport}</span>}
                    </button>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <DropdownButton className="mt-2" variant="success" title={translated.Export}>
                      <Dropdown.Item onClick={() => exportExcel()}>Excel</Dropdown.Item>
                    </DropdownButton>
                </div>

            </div>
        </form>

        </Offcanvas.Body>
    </Offcanvas>

    </>
    :
    <Redirect to='/login'/>
  );

}
