import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Spinner, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import CurrencyInput from 'react-currency-input-field';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

export const ExternalInput = ({translated, translatedForm}) => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, getValues, formState, formState: { errors }} = useForm();
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [buttonIsSelected, setButtonIsSelected] = useState(false)

    const listValues = [
        {label:translated.NotSet, value:0},
        {label:translated.TamperSensor, value:1},
        {label:translated.ClosingButton, value:2},
        {label:translated.OpenButton, value:3},
    ]

    useEffect(() => {
        api.get("/api/controllers/details/external-inputs/details", {
            headers:{
              "content-type" : "application/json",
              "controllerId":params.id
            },
            params:{
              controllerId: params.id,
            },
            withCredentials:true
          })
          .then(function (response) {
            
            
            let input2 = listValues.filter((item) => item.value == response.data.dataResult.externalInput2)
            setValue("ExternalInput2", {label:input2[0].label, value:input2[0].value})

            let input3 = listValues.filter((item) => item.value == response.data.dataResult.externalInput3)
            setValue("ExternalInput3", {label:input3[0].label, value:input3[0].value})

            let input4 = listValues.filter((item) => item.value == response.data.dataResult.externalInput4)
            setValue("ExternalInput4", {label:input4[0].label, value:input4[0].value})
            setValue("ButtonDuration", response.data.dataResult.buttonDuration)

          })
          .catch(function (error) {
            console.log(error);
          });
    }, [])


    const onSubmit = data => {
        console.log(data)

        let input2 = getValues("ExternalInput2")
        let input3 = getValues("ExternalInput3")
        let input4 = getValues("ExternalInput4")

        let array_button_values = []
        array_button_values.push(input2.value)
        array_button_values.push(input3.value)
        array_button_values.push(input4.value)

        console.log(array_button_values)

        let find_closing_button = array_button_values.filter((i) => i == 2).length
        let find_open_button = array_button_values.filter((i) => i == 3).length

        console.log(find_closing_button)
        console.log(find_open_button)

        if(find_closing_button >= 2 || find_open_button >= 2 || (find_closing_button == 1 && find_open_button == 1))
        {
            displayToast(translated.MessageButtonExternalInputs)
        }
        else
        {
            setLoadingBtn(true);
            api.post("/api/controllers/details/external-inputs/details/update", {
                ExternalInput2: data.ExternalInput2.value,
                ExternalInput3: data.ExternalInput3.value,
                ExternalInput4: data.ExternalInput4.value,
                ButtonDuration: data.ButtonDuration,
                ControllerId: params.id
            })
            .then(function (response) {
                setLoadingBtn(false)
                displayToast(response.data.message, response.data.isSuccess)
            })
            .catch(function (error) {
                console.log(error)
                setLoadingBtn(false)
                displayToast(error.response.data.errors[0].errorMessage, error.response.data.isSuccess)
            });
        }
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    
    }

    return(
      isAuth ?
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">

                <div className="col-xs-12 col-lg-5 col-md-5">
                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.InputIn}</Form.Label>                    
                            <input className="form-control" value={translated.FlowSensor} disabled/>
                        {errors.ExternalInput && 
                            <span className="text-danger font-size-13">{errors.ExternalInput.message}</span>
                        }   
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.InputIn2}</Form.Label>                    
                            <Controller
                                name="ExternalInput2"                                
                                control={control}
                                rules={{ required: translatedForm.Required}}
                                render={({ field: {value} }) => (
                                <Select
                                    onChange={(item) => {
                                        setValue("ExternalInput2", item)
                                    }}
                                    value={value}
                                    options={listValues}  
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                />
                                )}
                            />
                        {errors.ExternalInput2 && 
                            <span className="text-danger font-size-13">{errors.ExternalInput2.message}</span>
                        }   
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.InputIn3}</Form.Label>             
                            <Controller
                                name="ExternalInput3"                                
                                control={control}
                                rules={{ required: translatedForm.Required }}
                                render={({ field: {value} }) => (
                                <Select
                                    onChange={(item) => {
                                        setValue("ExternalInput3", item)
                                    }}
                                    value={value}
                                    options={listValues}  
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                />
                                )}
                            />
                        {errors.ExternalInput3 && 
                            <span className="text-danger font-size-13">{errors.ExternalInput3.message}</span>
                        }   
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.InputIn4}</Form.Label>                    
                            <Controller
                                name="ExternalInput4"                                
                                control={control}
                                rules={{ required: translatedForm.Required }}
                                render={({ field: {value} }) => (
                                <Select
                                    onChange={(item) => {
                                        setValue("ExternalInput4", item)
                                    }}
                                    value={value}
                                    options={listValues}  
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                />
                                )}
                            />
                        {errors.ExternalInput4 && 
                            <span className="text-danger font-size-13">{errors.ExteExternalInput4rnalInput2.message}</span>
                        }   
                    </Form.Group>

                    <div className="form-group mt-3">
                        <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                            {loadingBtn && (
                                <Spinner animation="border" variant="light" 
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                            {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                        </button>
                    </div>
                </div>

                <div className="col-xs-12 col-lg-7 col-md-7">
                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.MininalSignalDuration}</Form.Label>
                        <Controller
                            name="ButtonDuration"                                
                            control={control}
                            rules={{ 
                                max : {
                                    value: 30000,
                                    message: translatedForm.MaxNumber(30000)
                                },
                                min : {
                                    value: 100,
                                    message: translatedForm.MinNumber(100)
                                },
                            }}
                            render={({ field:{value} }) => (
                            <CurrencyInput
                                placeholder={translatedForm.EnterValue(30000)}
                                value={value}
                                className="form-control"
                                onValueChange={(value) => setValue("ButtonDuration", value)}
                                //disabled={isDisabled}
                            />
                            )}
                        />
                        {errors.ButtonDuration && 
                            <span className="text-danger font-size-13">{errors.ButtonDuration.message}</span>
                        }
                    </Form.Group>
                </div>
            </div>
        </Form>
      </>
    :
    <Redirect to='/login'/>
    );
}