import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Modal, Form, Spinner, Offcanvas, Dropdown, DropdownButton, Card, Tooltip, OverlayTrigger, Nav} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import { FormAddParam } from './FormAddParam';
import { FormAddTable } from './FormAddTable';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import api from '../../../utils/axiosInterceptions';

export const LevelMeterSingle = ({addresses, controllerInfo, versionSoftware, translated, translatedForm, index, item}) => {

    const router = useHistory()
    const params = useParams();
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [settingShow, setSettingShow] = useState(false)
    const [showModalTable, setShowModalTable] = useState(false)
    const [show, setShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [modalShowParam, setModalShowParam] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [is16LevelMeter, setIs16LevelMeter] = useState(false)
    const [paramsLevelMeter, setParamsLevelMeter] = useState({})
    const [selectedAddress, setSelectedAddress] = useState({
        label:"",
        value:0
    })
    const [selectedType, setSelectedType] = useState({
        label:'',
        value:0
    })

    const types = [
        {label:translated.NotSet, value:0},
        {label:"201", value:1},
        {label:"Omnicomm", value:2},
        {label:translated.Struna, value:3},
        {label:"Veeder-Root TLS", value:4},
    ]

    const listEnabled = [
        {label: "Выключен", value:0},
        {label: "Включен", value:1},
    ]

    const setDataLevelMeter = (split, level_meter) => 
    {
        if(split[0] >= 3 && split[1] >= 10)
        {
            setIs16LevelMeter(true)

            if(level_meter.type == 0)
            {
                setDisabled(true)
            }

            let type = listEnabled.filter((i) => i.value == level_meter.type)
            setSelectedType({label:type[0].label, value: type[0].value})
            setValue("Type", selectedType)

            let address = addresses.filter((i) => i.value == level_meter.address)
            setSelectedAddress({label:address[0].label, value: address[0].value})
            setValue("Address", selectedAddress)
        }
        else
        {
            let type = types.filter((i) => i.value == level_meter.type)
            setSelectedType({label:type[0].label, value: type[0].value})
            setValue("Type", selectedType)

            if(level_meter.type == 2) {
                setSettingShow(true)
            } else {
                setSettingShow(false)
            }

            if(type[0].value == 3)
            {
                setSelectedAddress({label:"80", value: 80})
                setValue("Address", selectedAddress)
                setDisabled(true)
            }
            else
            {
                let address = addresses.filter((i) => i.value == level_meter.address)
                setSelectedAddress({label:address[0].label, value: address[0].value})
                setValue("Address", selectedAddress)
            }
        }
    }

    useEffect(() => {
        setIsLoading(true)
        let split = versionSoftware.split('.')
        setDataLevelMeter(split, item)
        
        setIsLoading(false)
    }, [])

    const onSubmit = data => {
        setLoadingBtn(true)
        api.post('/api/controllers/details/level-meters/update', {
            Id: item.id,
            Type: selectedType.value,
            Address: selectedAddress.value,
            ControllerId: controllerInfo.id,
            withCredentials:true
        })
        .then(function (response) {
            
            setLoadingBtn(false)            
            
            let split = versionSoftware.split('.')
            setDataLevelMeter(split, response.data.dataResult)

            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingBtn(false)
            displayToast(error.response.data.message, error.response.data.isSuccess)
        });
    }
    
    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    
    }

    return(
        <>
        {
            !isLoading
            ?
            <div key={item.id} className="col-xs-12 col-lg-3 col-md-3">
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Card border="light" className="border shadow-none">
                    <Card.Body>
                    <div className="d-flex">
                        <h5 className="benzamat-font-bold flex-fill text-black">{translated.LevelMeter} {++index}</h5>
                        {
                            settingShow
                            ?
                            <Dropdown>
                                <Dropdown.Toggle split className="btn btn-light justify-content-end btn-sm" />
                                    <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setShow(true)}>{translated.Params}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setShowModalTable(true)}>{translated.GradTable}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            : null
                        }
                    </div>

                    <div>
                        {
                            !is16LevelMeter
                            ?
                            <Form.Group className="mb-3 mt-2">
                                <Form.Label className="text-black">{translated.Type}</Form.Label>                                
                                <Controller
                                    name="Type"                                
                                    control={control}
                                    rules={{ required: translatedForm.Required }}
                                    render={({ field }) => (
                                    <Select    
                                    {...field}                        
                                        isSearchable
                                        options={types.filter((item) => item.value != 4)}                        
                                        placeholder={translated.SelectFromList}
                                        classNamePrefix="select"
                                        value={selectedType}
                                        onChange={(item) => {
                                            if(item.value === 2) {
                                                setSettingShow(true)
                                            } else {
                                                setSettingShow(false)
                                            }

                                            if(item.value == 3) {
                                                setSelectedAddress({
                                                    label:"80",
                                                    value:80
                                                })
                                                setDisabled(true)
                                            } else {
                                                setSelectedAddress({
                                                    label:addresses[0].label,
                                                    value:addresses[0].value
                                                })
                                                setDisabled(false)
                                            }

                                            setSelectedType({
                                                label: item.label,
                                                value: item.value
                                            })
                                        }}
                                    />
                                    )}
                                />
                                {errors.Type && 
                                    <span className="text-danger font-size-13">{errors.Type.message}</span>
                                } 
                            </Form.Group>
                            :
                            <Form.Group className="mb-3 mt-2">
                                <Form.Label className="text-black">Вкл/Выкл</Form.Label>                                
                                <Controller
                                    name="Type"                                
                                    control={control}
                                    rules={{ required: translatedForm.Required }}
                                    render={({ field }) => (
                                    <Select    
                                    {...field}                        
                                        isSearchable
                                        options={listEnabled}                        
                                        placeholder={translated.SelectFromList}
                                        classNamePrefix="select"
                                        value={selectedType}
                                        onChange={(item) => {
                                            if(item.value == 0)
                                            {
                                                setDisabled(true)
                                            }
                                            else
                                            {
                                                setDisabled(false)
                                            }
                                            setSelectedType({
                                                label: item.label,
                                                value: item.value
                                            })
                                        }}
                                    />
                                    )}
                                />
                                {errors.Type && 
                                    <span className="text-danger font-size-13">{errors.Type.message}</span>
                                } 
                            </Form.Group>
                        }

                        <Form.Group className="mb-3 mt-2">
                            <Form.Label className="text-black">{translated.Address}</Form.Label>                                
                            <Controller
                                name="Address"                                
                                control={control}
                                rules={{ required: translatedForm.Required }}
                                render={({ field }) => (
                                <Select    
                                {...field}                        
                                    isSearchable
                                    options={addresses}      
                                    isDisabled={disabled}                  
                                    placeholder={translatedForm.SelectFromList}
                                    classNamePrefix="select"
                                    value={selectedAddress}
                                    onChange={(item) => {
                                        setSelectedAddress({
                                            label: item.label,
                                            value: item.value
                                        })
                                    }}
                                />
                                )}
                            />
                            {errors.Address && 
                                <span className="text-danger font-size-13">{errors.Address.message}</span>
                            } 
                        </Form.Group>
                        
                        <div className="form-group mt-3">
                            <button type="submit" disabled={loadingBtn} className="btn btn-success">
                                {loadingBtn && (
                                    <Spinner animation="border" variant="light" 
                                        as="span"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        style={{ marginRight: "5px" }}
                                    />
                                )}
                                {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                                {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                            </button>
                        </div>
                    </div>
                    </Card.Body>
                </Card>
                </Form>
            </div>
            : <Spinner animation="border" variant="info" />
        }

        <Modal
            size="lg"
            show={showModalTable}
            onHide={() => setShowModalTable(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            dialogClassName="modal-90w"
        >
            <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg" className="text-black benzamat-font-bold font-size-22">
                {translated.CalibrationTable}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormAddTable translated={translated} translatedForm={translatedForm} id={item.id} />
            </Modal.Body>
        </Modal>

        <Offcanvas placement="end" show={show} onHide={() => setShow(false)}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
                    {translated.LevelSensorSetting}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <div className="dropdown-divider"></div>
            <Offcanvas.Body>  
                <FormAddParam translatedForm={translatedForm} translated={translated} id={item.id}/>
            </Offcanvas.Body>
        </Offcanvas>
        </>
    )
}