import React, { useState, useEffect, useContext, useMemo, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Form, Badge, Spinner, Alert, ProgressBar, DropdownButton, Dropdown} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import './../../boxicons.css';
import { TranslateStation } from '../../lang/stations/translateStation';
import {FormLocale} from '../../lang/form';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { CurrentSelectLanguage } from '../../helpers/language';

export const FuelRequest = () => {

  const router = useHistory();
  const {isAuth} = useContext(AuthContext);
  const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [listOfStations, addStationInList] = useState({})
  const [listTimezone, setListTimezone] = useState([])
  const [listOfCompanies, setListCompanies] = useState([])
  const [countStations, setCountStations] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const translated = useLittera(TranslateStation);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [permission, setPermission] = useState({
    read:true,
    create:true,
    fullAccess:true
  })
  const [loadingData, setLoadingData] = useState({
    grid:false,
    button:false
  });

  const handleShow = () => {
    setShowFormAdd(true)
  };

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
  }, []);

  const handleClose = () => setShowFormAdd(false);

  const test_data = [
    {
        Number:1, 
        Status: "New",
        Client:"Ansurt India Ltd.", 
        KiloLiters:1250, 
        CreatedDate:"20.09.2024"
    },
    {
        Number:2, 
        Status: "Load",
        Client:"Ansurt India Ltd.", 
        KiloLiters:1250, 
        CreatedDate:"20.09.2024"
    },
    {
        Number:3, 
        Status: "Delivery",
        Client:"Ansurt India Ltd.", 
        KiloLiters:1250, 
        CreatedDate:"20.09.2024"
    },
    {
        Number:4, 
        Status: "Not done",
        Client:"Ansurt India Ltd.", 
        KiloLiters:1250, 
        CreatedDate:"20.09.2024"
    },
    {
        Number:5, 
        Status: "Completed",
        Client:"Ansurt India Ltd.", 
        KiloLiters:1250, 
        CreatedDate:"20.09.2024"
    },
  ]

  const columns = useMemo(
    () => [
        {
            accessorFn: (row) => row.Number,
            id: 'Number',
            header: "Number",
        },
        {
            accessorFn: (row) => row.Status,
            id: 'Status',
            header: "Status",
            type: 'html'
        },
        {
            accessorFn: (row) => row.Client,
            id: 'Client',
            header: "Client",
        },
        {
            accessorFn: (row) => row.KiloLiters,
            id: 'KiloLiters',
            header: "Kilo Litres",
        },
        {
            accessorFn: (row) => row.CreatedDate,
            id: 'Date',
            header: "Created date",
        },
        {
        accessorFn: (row) => 
        <>
            <Button onClick={() => router.push("/fuel-request")}  className="btn btn-light btn-sm">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </Button> 
        </>,
        accessorKey: 'button',
        header: "Actions",
        },
    ],
    [],
  );

  const onSubmit = () => {

  }

  return (
    isAuth ?
    <>
    {
      isLoading
      ? <Spinner animation="border" variant="info" />
      :
      <>
      <div className="d-sm-flex align-items-center justify-content-between">
              <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
                  Fuel requests
                  <span className="text-secondary benzamat-font-bold font-size-20"> 56</span>
              </h2>
              {
                permission.create || permission.fullAccess
                ?
                <div className="page-title-right">
                    <DropdownButton title="CRM actions" className="mt-3">
                        <Dropdown.Item onClick={() => handleShow()}>Create request</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleShow()}>Create provider</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleShow()}>Create client</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleShow()}>Create delivery</Dropdown.Item>
                    </DropdownButton>
                </div>
                : ''
              }                   
        </div>
        <div className="row">
        <div className="col-xs-12 col-lg-12 col-md-12 ">         
            <div className="mt-4">
              {
                permission.read || permission.fullAccess
                ?
                <MaterialReactTable 
                  columns={columns} 
                  data={test_data}
                  localization={MRT_Localization_EN}
                  enableTopToolbar={false}
                  initialState={{ showColumnFilters: true }}
                  positionToolbarAlertBanner="left"
                  enableRowVirtualization
                  muiTablePaperProps={{
                    elevation: 1,
                  }}
                  muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                      fontWeight: 'bold',
                      color:"#000",
                      backgroundColor:'#F3F3F3',
                      fontFamily: 'BenzamatRegular',
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      color:"#000",
                      fontFamily: 'BenzamatRegular',
                    },
                  }}
                  muiTopToolbarProps={{
                    sx: {
                      textAlign:"left"
                    },
                  }}
                />
                :
                <Alert variant="danger">
                  {translated.PermissionDenied}
                </Alert>
              }
            </div>
        </div>
      </div>

      <Offcanvas placement="end" show={showFormAdd} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
              Create fuel request
            </Offcanvas.Title>
          </Offcanvas.Header>
          <div className="dropdown-divider"></div>
          <Offcanvas.Body>

            <Form onSubmit={handleSubmit(onSubmit)}>

            <Form.Group className="mb-3">
                <Form.Label className="text-black">Customer <sup className="text-danger">*</sup></Form.Label>        
                    <Controller
                        name="Controllers"                                
                        control={control}
                        rules={{ required: translatedForm.Required }}
                        render={({ field }) => (
                        <Select 
                        {...field}                               
                            isSearchable
                            isMulti
                            options={[]}                        
                            placeholder={translatedForm.SelectFromList}
                            classNamePrefix="select"
                        />
                        )}
                    />
                {errors.Controllers && 
                    <span className="text-danger font-size-13">{errors.Controllers.message}</span>
                }   
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label className="text-black">benzamatPro manager <sup className="text-danger">*</sup></Form.Label>        
                    <Controller
                        name="Controllers"                                
                        control={control}
                        rules={{ required: translatedForm.Required }}
                        render={({ field }) => (
                        <Select 
                        {...field}                               
                            isSearchable
                            isMulti
                            options={[]}                        
                            placeholder={translatedForm.SelectFromList}
                            classNamePrefix="select"
                        />
                        )}
                    />
                {errors.Controllers && 
                    <span className="text-danger font-size-13">{errors.Controllers.message}</span>
                }   
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">Sold to Party / CMS code <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">Ship to address <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">Quantity <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">Transporter's name <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">Vehicle Registration <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">Name of Driver <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">Nayara id no if any <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">License numberof Driver <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">Adhar number of Driver <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
            <Form.Label className="text-black">Name of co-driver / Cleaner <sup className="text-danger">*</sup></Form.Label>
            <Form.Control 
                {...register("Name", {
                minLength : {
                    value: 5,
                    message: translatedForm.MinLengthString(5)
                },
                maxLength : {
                    value: 100,
                    message: translatedForm.MaxLenghtString(100)
                },
                required: translatedForm.Required
                })}
                type="text" maxLength="100" isInvalid={errors.Name}  
                placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                {errors.Name && 
                    <span className="text-danger font-size-13">{errors.Name.message}</span>
                }
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label className="text-black">Product <sup className="text-danger">*</sup></Form.Label>        
                    <Controller
                        name="Controllers"                                
                        control={control}
                        rules={{ required: translatedForm.Required }}
                        render={({ field }) => (
                        <Select 
                        {...field}                               
                            isSearchable
                            isMulti
                            options={[]}                        
                            placeholder={translatedForm.SelectFromList}
                            classNamePrefix="select"
                        />
                        )}
                    />
                {errors.Controllers && 
                    <span className="text-danger font-size-13">{errors.Controllers.message}</span>
                }   
            </Form.Group>

            <div className="form-group mt-3">
                <button type="submit" disabled={loadingData.button} className="btn btn-primary">
                    {loadingData.button && (
                        <Spinner animation="border" variant="light" 
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                        />
                    )}
                    {loadingData.button && <span>Create</span>}
                    {!loadingData.button && <span>Create</span>}
                </button>
            </div>
              
          </Form>

          </Offcanvas.Body>
      </Offcanvas>
    </>
    }
    </>
    :
    <Redirect to='/login'/>
  );

}
