import React, { useState, useEffect, useContext, useMemo, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Form, Spinner, DropdownButton, Dropdown, Badge, Alert} from 'react-bootstrap';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import api from '../../../utils/axiosInterceptions';

export const UploadTable = ({setListPoints, id}) => {

    const { control, register, handleSubmit, setValue, reset, formState, getValues, formState: { errors }} = useForm();
    const [file, setFile] = useState('')
    const [loadingData, setLoadingData] = useState({
        button:false,
        grid:false
    })

    const onSubmitUpload = data => {
        setLoadingData({button:true})
        console.log("upload")
        let formData = new FormData()
        formData.append("uploadFile", file)
        formData.append("levelMeterId", id)
        api({
            method: 'post',
            url: '/api/controllers/details/level-meters/details/table/import',
            data: formData ,
            headers: {'Content-Type': 'multipart/form-data' },
            withCredentials: true
        })
        .then(function (response) {
            
            displayToast(response.data.message, response.data.isSuccess)
            setListPoints(response.data.listDatas)
            setLoadingData({button:false})
        })
        .catch(function (error) {
            console.log(error)
            setLoadingData({button:false})
            displayToast(error.response.data.message, error.response.data.isSuccess)
        });    
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    }

    return(
        <>
            <h5 className="text-black mt-3">Выберите файл для загрузки</h5>
            <Form onSubmit={handleSubmit(onSubmitUpload)}>

            <Form.Group controlId="formFile">
                <Form.Control 
                    placeholder='Выберите файл со своего компьютера' 
                    className="form-control" 
                    {...register("uploadedFile", { required: "Обязательное поле"})} 
                    onChange={(e) => setFile(e.target.files[0])}
                    type="file"
                />
                {errors.file && 
                    <span className="text-danger font-size-13">{errors.file.message}</span>
                }
            </Form.Group>

            <div className="form-group mt-3">
                <Button type="submit" variant="success" disabled={loadingData.button}>
                {loadingData.button && (
                    <Spinner animation="border" variant="light" 
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {loadingData.button && <span>Идет загрузка</span>}
                {!loadingData.button && <span>Загрузить</span>}
                </Button>
            </div>

            </Form>
        </>         

    )
}