import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateCompanyModule = {

    Companies: {
        en_US: "Companies",
        ru_RU: "Компании"
    },
    Name:{
        en_US: "Name",
        ru_RU: "Название"
    },
    CreatedDate:{
        en_US: "Created date",
        ru_RU: "Дата добавления"
    },
    IsBlocked:{
        en_US: "Blocked",
        ru_RU: "Заблокирована"
    },
    CompanyIsActive:{
        en_US: "Active",
        ru_RU: "Активна"
    },
    CompanyIsBlocked:{
        en_US: "Blocked",
        ru_RU: "Заблокирована"
    },
    CompanyType:{
        en_US: "Type",
        ru_RU: "Вид компании"
    },
    CompanyIsClient:{
        en_US: "Client",
        ru_RU: "Клиент"
    },
    CompanyIsVendor:{
        en_US: "Oil Trader",
        ru_RU: "Нефтетрейдер"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    AddCompany:{
        en_US: "Add company",
        ru_RU: "Добавить компанию"
    },
    AddCompanyProgress:{
        en_US: "Creating company",
        ru_RU: "Добавление компании"
    },
    CreateCompany:{
        en_US: "Create company",
        ru_RU: "Добавление компании"
    },
    LegalInfo:{
        en_US: "Legal information",
        ru_RU: "Юридическая информация"
    },
    LegalAddress:{
        en_US: "Legal address",
        ru_RU: "Юридический адрес"
    },
    BIK:{
        en_US: "BIK",
        ru_RU: "БИК"
    },
    EnterBIK:{
        en_US: "Enter BIK (until 9 symbols)",
        ru_RU: "Введите БИК (до 9 цифр)"
    },
    EnterLegalAddress: {
        en_US: "Enter legal address (until 800 symbols)",
        ru_RU: "Введите юридический адрес (до 800 символов)"
    },
    EnterMailAddress: {
        en_US: "Enter mail address (until 800 sybmols)",
        ru_RU: "Введите почтовый адрес (до 800 символов)"
    },    
    MailAddress:{
        en_US: "Mail address",
        ru_RU: "Почтовый адрес"
    },
    OGRN:{
        en_US: "OGRN",
        ru_RU: "ОГРН"
    },
    EnterOGRN:{
        en_US: "Enter OGRN (13-15 symbols)",
        ru_RU: "Введите ОГРН (13-15 цифр)"
    },
    KPP:{
        en_US: "KPP",
        ru_RU: "КПП"
    },
    EnterKPP:{
        en_US: "Enter KPP (until 9 symbols)",
        ru_RU: "Введите КПП (до 9 цифр)"
    },
    EnterName:{
        en_US: "Enter name (until 500 symbols)",
        ru_RU: "Введите название (до 500 символов)"
    },
    TIN:{
        en_US: "TIN",
        ru_RU: "ИНН"
    },
    EnterINN: {
        en_US: "Enter TIN (10-12 digits)",
        ru_RU: "Введите ИНН (10-12 цифр)"
    },
    Required:{
        en_US: "Required",
        ru_RU: "Обязательное поле"
    },
    MaxLenghtString: (number) => ({
        en_US: `Max length is ${number} symbols`,
        ru_RU: `Максимальная длина ${number} символов`
    }),
    MinLengthString: (number) => ({
        en_US: `Min length is ${number} symbols`,
        ru_RU: `Минимальная длина ${number} символов`
    }),
    MaxNumber: (number) => ({
        en_US: `Max length is ${number} symbols`,
        ru_RU: `Максимальная длина ${number} символов`
    }),
    MinNumber: (number) => ({
        en_US: `Min length is ${number} symbols`,
        ru_RU: `Минимальная длина ${number} символов`
    }),
    AccessDenied:{
        en_US: "Access denied",
        ru_RU: "У вас недостаточно прав доступа для просмотра информации к данному разделу"
    },
    Title: {
        en_US: `Company`,
        ru_RU: `Компания`
    },
    Unlock: {
        en_US: `Unlock`,
        ru_RU: `Разблокировать`
    },
    Block: {
        en_US: `Block`,
        ru_RU: `Заблокировать`
    },
    General: {
        en_US: `General`,
        ru_RU: `Информация`
    },
    Balance: {
        en_US: `Balance`,
        ru_RU: `Баланс`
    },
    Users: {
        en_US: `Users`,
        ru_RU: `Пользователи`
    },
    Stations: {
        en_US: `Stations`,
        ru_RU: `Станции`
    },
    Fuels: {
        en_US: `Fuels`,
        ru_RU: `Виды топлива`
    },
    Clients: {
        en_US: `Clients`,
        ru_RU: `Клиентские компании`
    },
    API: {
        en_US: `API`,
        ru_RU: `API`
    },
    WarningApiKey: {
        en_US: "Attention! Do not share this key with third parties. If it was stolen, update it by clicking on the button below",
        ru_RU: "Внимание! Не передавайте данный ключ третьим лицам. В случае если его украли, обновите его нажав на кнопку ниже"
    },
    ChangeApiButton:{
        en_US: "Change API key",
        ru_RU: "Сменить API-ключ"
    },
    CompanyIsBlockAlert: {
        en_US: `Company is blocked`,
        ru_RU: `Компания заблокирована. Дальнейшая работа невозможна`
    },
    SignalStep: {
        en_US: `Singnal step`,
        ru_RU: `Сигнальный порог`
    },
    BankName: {
        en_US: `Name of bank`,
        ru_RU: `Банк`
    },
    RatedAccount: {
        en_US: `Rated account`,
        ru_RU: `Расчетный счет`
    },
    EnterRatedAccount: {
        en_US: `Enter rated account (until 20 sybmols)`,
        ru_RU: `Введите номер (до 20 цифр)`
    },
    CorrAccount: {
        en_US: `Correspondent account`,
        ru_RU: `Корреспондетский счет`
    },
    EnterCorrAccount:{
        en_US: `Enter cor. account (until 20 sybmols)`,
        ru_RU: `Введите норм (до 20 цифр)`
    },
    SaveChanges:{
        en_US: `Save changes`,
        ru_RU: `Сохранить изменения`
    },
    SaveChangesProccess:{
        en_US: `Saving`,
        ru_RU: `Сохранение`
    },
    InputLiters:{
        en_US: `Input liters`,
        ru_RU: `Введите литры`
    },
    SignalStepAlert: {
        en_US: `Attention! Signal step is exceded balance of company. You need add money to account for continue work`,
        ru_RU: `Внимание! Превышен сигнальный порог. Пополните баланс компании для продолжения работы заправок`
    },
    ListCompanies: {
        en_US: "List of companies",
        ru_RU: "Список компаний"
    },
    ActionWithCompanies:{
        en_US: "Actions with company",
        ru_RU: "Действия с компаниями"
    }
    
};
