import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Card, Spinner, Nav} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import './../../../boxicons.css';
import { TopazBs2 } from './Bs2/TopazBs2';
import { TopazBs2v2 } from './Bs2v2/TopazBs2v2';

export const Topaz = ({translated, translatedForm, smartData, listSmartData, countDispenser, controllerInfo}) => {
    return(
        <>
            {
                controllerInfo.type != 3
                ? <TopazBs2 smartData={smartData} />
                : 
                <TopazBs2v2
                    translated={translated} 
                    translatedForm={translatedForm} 
                    listSmartData={listSmartData} 
                    countDispenser={countDispenser}
                    controllerInfo={controllerInfo}
                />
            }
        </>
    )
}