import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Offcanvas, Form, Spinner} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import moment from 'moment';
import axios from 'axios';
import Select from 'react-select';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';
import './../../boxicons.css';
import CurrencyInput from 'react-currency-input-field';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { CurrentSelectLanguage } from '../../helpers/language';
import { TranslateStations } from '../../lang/company/translateStations';
import api from '../../utils/axiosInterceptions';

export const CompanyStations = () => {
    
    const router = useHistory();
    const params = useParams()
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [showFormAdd, setShowFormAdd] = useState(false);
    const [listHistory, setListHistory] = useState([])
    const [loading, setLoading] = useState(false)
    const [money, setMoney] = useState(0)
    const translated = useLittera(TranslateStations)
    const methods = useLitteraMethods();
    const handleShow = () => setShowFormAdd(true);
    const handleClose = () => setShowFormAdd(false);

    useEffect(() => {
      methods.setLocale(CurrentSelectLanguage);
        api.get("/api/company/details/stations", {
            headers:{
              "content-type" : "application/json",
              "companyId":params.id
            },
            params:{
              companyId: params.id,
            }
        })
        .then(function (response) {
            console.log("stations")
            
            setListHistory(response.data.listDatas)
        })
          .catch(function (error) {
            console.log(error);
        });
    }, [])

    const columns = useMemo(
        () => [
          {
            accessorFn: (row) => row.name,
            id: 'name',
            header: translated.Name,
          },
          {
            accessorFn: (row) => row.description != null && row.description != '' 
            ? row.description 
            : translated.Empty,
            id: 'description',
            header: translated.Description,
          },
          {
            accessorFn: (row) => row.orderNumber != '' && row.orderNumber != null 
            ? row.orderNumber 
            : translated.Empty,
            id: 'orderNumber',
            header: translated.OrderNumber,
          },
          {
            accessorFn: (row) => row.serialNumber != '' && row.serialNumber != null 
            ? row.serialNumber 
            : translated.Empty,
            id: 'serialNumber',
            header: translated.SerialNumber,
          },
          {
            accessorFn: (row) => 
            <>
            <Button onClick={() => router.push(`/station/${row.id}`)} className="btn btn-light btn-sm">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </Button>
            </>,
            accessorKey: 'button',
            header: translated.Actions,
          },
        ],
      [],
    );

    return (
        <>
            <div className="mt-4">
                <MaterialReactTable 
                    columns={columns} 
                    data={listHistory}
                    localization={MRT_Localization_EN}
                    initialState={{ showColumnFilters: true }}
                    enableTopToolbar={false}
                    enableRowVirtualization
                    muiTablePaperProps={{
                      elevation: 1,
                    }}
                    muiTableHeadCellProps={{
                      //simple styling with the `sx` prop, works just like a style prop in this example
                      sx: {
                        fontWeight: 'bold',
                        color:"#000",
                        backgroundColor:'#F3F3F3',
                        fontFamily: 'BenzamatRegular',
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        color:"#000",
                        fontFamily: 'BenzamatRegular',
                      },
                    }}
                  />
            </div>
        </>
    )
}