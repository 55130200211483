import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Card, Spinner, Nav, Table, InputGroup} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import './../../../../boxicons.css';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateController } from '../../../../lang/controllers/translateController';
import { CurrentSelectLanguage } from '../../../../helpers/language';
import {FormLocale} from '../../../../lang/form';
import api from '../../../../utils/axiosInterceptions';

export const LivenkaBs2v2 = ({smartData, controllerInfo}) => {

    let arr = []
    const router = useHistory()
    const params = useParams();
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [showSecondSleeva, setShowSecondSleeve] = useState(false)
    const [fuels, setFuels] = useState([])
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [arrayTrTable, setArrayTrTable] = useState([])
    const [buttonTrk, setButtonTrk] = useState(false)
    const [gerkon, setGerkon] = useState(false)
    const [valueCountSide, setValueCountSide] = useState(1)
    const translated = useLittera(TranslateController);
    const translatedForm = useLittera(FormLocale);
    const methods = useLitteraMethods();
    const CountStorona = [
        {label:"1", value:1},
        {label:"2", value:2},
    ]

    const listCountFuels = [
        {label:"1", value:1},
        {label:"2", value:2},
        {label:"3", value:3},
        {label:"4", value:4},
    ]

    const addresses = [
        {label:"0", value:0},
        {label:"1", value:1},
        {label:"2", value:2},
        {label:"3", value:3},
        {label:"4", value:4},
        {label:"5", value:5},
        {label:"6", value:6},
        {label:"7", value:7},
        {label:"8", value:8},
        {label:"9", value:9},
        {label:"10", value:10},
        {label:"11", value:11},
        {label:"12", value:12},
        {label:"13", value:13},
        {label:"14", value:14},
        {label:"15", value:15},
        {label:"16", value:16},
        {label:"17", value:17},
        {label:"18", value:18},
        {label:"19", value:19},
        {label:"20", value:20},
    ]

    
    useEffect(() => {
        getFuels()
    }, [])


    const getFuels = () => {
        api.get(`/api/fuels/all`, {
            withCredentials:true
        })
        .then(function (response) { 
            const add = response.data.listDatas.map(item => ({
                ...item,
                value: item.id,
                label: item.name
            }))
            setFuels(add)
            getSmarts(add)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getSmarts = (fuels) => {
        api.get(`/api/controllers/details/smarts`, {
            headers:{
                "content-type" : "application/json",
                "controllerId":controllerInfo.id
            },
            params:{
                controllerId: controllerInfo.id,
            },
            withCredentials:true
        })
        .then(function (response) 
        { 
            console.log("SMART DATA method")
            console.log(response.data)

            setButtonTrk(response.data.listDatas[0].buttonLivenkaIsChecked)
            setGerkon(response.data.listDatas[0].gerkonIsChecked)
            let address = addresses.filter((item) => item.value == response.data.listDatas[0].address)
            let address2 = addresses.filter((item) => item.value == response.data.listDatas[0].address2)
            let address3 = addresses.filter((item) => item.value == response.data.listDatas[0].address3)
            let address4 = addresses.filter((item) => item.value == response.data.listDatas[0].address4)
            let address5 = addresses.filter((item) => item.value == response.data.listDatas[0].address5)
            let address6 = addresses.filter((item) => item.value == response.data.listDatas[0].address6)
            let address7 = addresses.filter((item) => item.value == response.data.listDatas[0].address7)
            let address8 = addresses.filter((item) => item.value == response.data.listDatas[0].address8)

            let fuelType = fuels.filter((item) => item.id == response.data.listDatas[0].fuelType)
            let fuelType2 = fuels.filter((item) => item.id == response.data.listDatas[0].fuelType2)
            let fuelType3 = fuels.filter((item) => item.id == response.data.listDatas[0].fuelType3)
            let fuelType4 = fuels.filter((item) => item.id == response.data.listDatas[0].fuelType4)

            setValue("AddressFirst1", address)
            setValue("AddressSecond1", address2)
            
            setValue("AddressFirst2", address3)
            setValue("AddressSecond2", address4)
            
            setValue("AddressFirst3", address5)
            setValue("AddressSecond3", address6)
            
            setValue("AddressFirst4", address7)
            setValue("AddressSecond4", address8)

            setValue("FuelType1", fuelType)
            setValue("FuelType2", fuelType2)
            setValue("FuelType3", fuelType3)
            setValue("FuelType4", fuelType4)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const onSubmit = data => {
        setLoadingBtn(true)
        console.log(data)
        api.post('/api/controllers/details/livenka/update', {
            ControllerId: controllerInfo.id,
            Address: data.AddressFirst1.value,
            Address2: data.AddressSecond1.value,
            Address3: data.AddressFirst2.value,
            Address4: data.AddressSecond2.value,
            Address5: data.AddressFirst3.value,
            Address6: data.AddressSecond3.value,
            Address7: data.AddressFirst4.value,
            Address8: data.AddressSecond4.value,
            FuelType: data.FuelType != undefined ? data.FuelType.id : 1,
            FuelType2: data.FuelType2 != undefined ? data.FuelType2.value : 1,
            FuelType3: data.FuelType2 != undefined ? data.FuelType3.value : 1,
            FuelType4: data.FuelType2 != undefined ? data.FuelType4.value : 1,
            withCredentials:true
        })
        .then(function (response) {
            setLoadingBtn(false)
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingBtn(false)
        });
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    
    }

    return(      
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12">
                    <h4 className="text-black">{translated.Livenka}</h4>
                </div>

                <div className="col-xs-12 col-lg-6 col-md-6 mt-2">
                    <Form.Check
                        type="checkbox"
                        id="checkbox-button-trk"
                        label={translated.IgnoreButtonStart}
                        className="text-black"
                        checked={buttonTrk}
                        onChange={(e) => setButtonTrk(e.target.checked)}
                    />
                </div>

                <div className="col-xs-12 col-lg-6 col-md-6 mt-2">
                    <Form.Check
                        type="checkbox"
                        id="checkbox-gerkon"
                        label={translated.ReadSwtich}
                        className="text-black"
                        checked={gerkon}
                        onChange={(e) => setGerkon(e.target.checked)}
                    />
                </div>
            

                <div className="col-xs-12 col-lg-12 col-md-12 mt-3">
                    <Table striped bordered responsive hover className="text-black">
                        <thead>
                            <tr>
                                <th>{translated.SleeveGrade}</th>
                                <th>{translated.FuelType}</th>
                                <th>{translated.AddressParty1}</th>
                                <th>{translated.AddressParty2}</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>{translated.SleeveGrade1}</td>
                                <td>
                                    <Controller
                                        name="FuelType1"                               
                                        control={control}
                                        render={({ field:{value} }) => (
                                        <Select
                                            value={value}
                                            options={fuels}
                                            placeholder={translated.FuelType}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                console.log(item)
                                                setValue("FuelType1", item)
                                            }}
                                        />
                                        )}
                                    />
                                </td>
                                <td>
                                    <Controller
                                        name="AddressFirst1"                               
                                        control={control}
                                        render={({ field:{value} }) => (
                                        <Select
                                            value={value}
                                            options={addresses}
                                            placeholder={translated.Address}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                console.log(item)
                                                setValue("AddressFirst1", item)
                                            }}
                                        />
                                        )}
                                    />
                                </td>
                                <td>
                                    <Controller
                                        name="AddressSecond1"                               
                                        control={control}
                                        render={({ field:{value} }) => (
                                        <Select
                                            value={value}
                                            options={addresses}
                                            placeholder={translated.Address}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                setValue("AddressSecond1", item)
                                            }}
                                        />
                                        )}
                                    />
                                </td>
                            </tr>

                            <tr>
                            <td>{translated.SleeveGrade2}</td>
                                <td>
                                    <Controller
                                        name="FuelType2"                               
                                        control={control}
                                        render={({ field:{value} }) => (
                                        <Select
                                            value={value}
                                            options={fuels}
                                            placeholder={translated.FuelType}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                console.log(item)
                                                setValue("FuelType2", item)
                                            }}
                                        />
                                        )}
                                    />
                                </td>
                                <td>
                                    <Controller
                                        name="AddressFirst2"                               
                                        control={control}
                                        render={({ field:{value} }) => (
                                        <Select
                                            value={value}
                                            options={addresses}
                                            placeholder={translated.Address}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                console.log(item)
                                                setValue("AddressFirst2", item)
                                            }}
                                        />
                                        )}
                                    />
                                </td>
                                <td>
                                    <Controller
                                        name="AddressSecond2"                               
                                        control={control}
                                        render={({ field:{value} }) => (
                                        <Select
                                            value={value}
                                            options={addresses}
                                            placeholder={translated.Address}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                console.log(item)
                                                setValue("AddressSecond2", item)
                                            }}
                                        />
                                        )}
                                    />
                                </td>
                            </tr>

                            <tr>
                            <td>{translated.SleeveGrade3}</td>
                                <td>
                                    <Controller
                                        name="FuelType3"                               
                                        control={control}
                                        render={({ field:{value} }) => (
                                        <Select
                                            value={value}
                                            options={fuels}
                                            placeholder={translated.FuelType}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                console.log(item)
                                                setValue("FuelType3", item)
                                            }}
                                        />
                                        )}
                                    />
                                </td>
                                <td>
                                    <Controller
                                        name="AddressFirst3"                               
                                        control={control}
                                        render={({ field:{value} }) => (
                                        <Select
                                            value={value}
                                            options={addresses}
                                            placeholder={translated.Address}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                setValue("AddressFirst3", item)
                                            }}
                                        />
                                        )}
                                    />
                                </td>
                                <td>
                                    <Controller
                                        name="AddressSecond3"                               
                                        control={control}
                                        render={({ field:{value} }) => (
                                        <Select
                                            value={value}
                                            options={addresses}
                                            placeholder={translated.Address}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                setValue("AddressSecond3", item)
                                            }}
                                        />
                                        )}
                                    />
                                </td>
                            </tr>

                            <tr>
                            <td>{translated.SleeveGrade4}</td>
                                <td>
                                    <Controller
                                        name="FuelType4"                               
                                        control={control}
                                        render={({ field:{value} }) => (
                                        <Select
                                            value={value}
                                            options={fuels}
                                            placeholder={translated.FuelType}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                console.log(item)
                                                setValue("FuelType4", item)
                                            }}
                                        />
                                        )}
                                    />
                                </td>
                                <td>
                                    <Controller
                                        name="AddressFirst4"                               
                                        control={control}
                                        render={({ field:{value} }) => (
                                        <Select
                                            value={value}
                                            options={addresses}
                                            placeholder={translated.Address}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                setValue("AddressFirst4", item)
                                            }}
                                        />
                                        )}
                                    />
                                </td>
                                <td>
                                    <Controller
                                        name="AddressSecond4"                               
                                        control={control}
                                        render={({ field:{value} }) => (
                                        <Select
                                            value={value}
                                            options={addresses}
                                            placeholder={translated.Address}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                                setValue("AddressSecond4", item)
                                            }}
                                        />
                                        )}
                                    />
                                </td>
                            </tr>

                        </tbody>
                    </Table>
                </div>

                <div className="form-group ">
                    <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                        {loadingBtn && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                        {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                    </button>
                </div>
            </div>
        </Form>
    );
}