import React from "react";
import { useLittera } from "@assembless/react-littera";

export const DashboardTranslate = {
    ObjectNotFound:{
        en_US: "Object not found",
        ru_RU: "Объекты еще не добавлены"
    },
    Unknow:{
        en_US: "Unknow",
        ru_RU: "Не указано"
    },
    StationOnTheMap:{
        en_US: "Stations on the map",
        ru_RU: "Размещение объектов на карте"
    },
    User:{
        en_US: "User",
        ru_RU: "Пользователь"
    },
    FillingDate:{
        en_US: "Filling date",
        ru_RU: "Дата налива"
    },
    Fuel:{
        en_US: "Fuel",
        ru_RU: "Топливо"
    },
    Liters:{
        en_US: "Liters",
        ru_RU: "Литры"
    },
    Controller:{
        en_US: "Controller",
        ru_RU: "Контроллер"
    },
    More:{
        en_US: "More",
        ru_RU: "Подробнее"
    },
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    LastFillings:{
        en_US: "Last fillings today",
        ru_RU: "Последние заправки"
    },
    OpenTheMap:{
        en_US: "Open the map",
        ru_RU: "Показать на карте"
    },
    Fuel:{
        en_US: "Fuel",
        ru_RU: "Топливо"
    },
    Volume:{
        en_US: "Volume",
        ru_RU: "Объем"
    },
    CurrentVolume:{
        en_US: "Current volume",
        ru_RU: "Текущий объем"
    },
    Occupancy:{
        en_US: "Occupancy",
        ru_RU: "Заполненность"
    }
};
