import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Spinner, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

export const Indicator = ({translated, translatedForm}) => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const [isDefined, setIsDefined] = useState(false)
    const [setlectedTypeIndicator, setTypeIndicator] = useState({
      label:'',
      value:''
    })
    const [selectedInput, setSelectedInput] = useState({
      label:'',
      value:''
    })
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [loadingBtn, setLoadingBtn] = useState(false)

    const listInput = [
        {label: translated.Input1, value: 0},
        {label: translated.Input2, value: 1},
        {label: translated.Input3, value: 2}
    ]

    const listConnect = [
        {label: translated.NotConnected, value: 0},
        {label: translated.CommonSensor, value: 1},
    ]
 
  useEffect(() => {
    api.get("/api/controllers/details/indicator", {
      headers:{
        "content-type" : "application/json",
        "controllerId":params.id
      },
      params:{
        controllerId: params.id,
      },
      withCredentials:true
    })
    .then(function (response) {

      response.data.dataResult.hatchOpenType == 1 ? setIsDefined(true) : setIsDefined(false)
      let findIndicator = listConnect.filter((item) => item.value == response.data.dataResult.hatchOpenType)
      setTypeIndicator({label:findIndicator[0].label, value:findIndicator[0].value})
      setValue("HatchOpenType", setlectedTypeIndicator)

      let findInput = listInput.filter((item) => item.value == response.data.dataResult.hatchOpenInput)
      setSelectedInput({label:findInput[0].label, value:findInput[0].value})
      setValue("HatchOpenInput", selectedInput)
    })
    .catch(function (error) {
      console.log(error);
    });
  }, [])

  const onSubmit = data => {

    setLoadingBtn(true);
    api.post("/api/controllers/details/indicator/update", {
        HatchOpenType: setlectedTypeIndicator.value,
        HatchOpenInput: selectedInput.value,
        ControllerId: params.id
    })
    .then(function (response) {
      setLoadingBtn(false)
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
      setLoadingBtn(false)
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
  
    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
   
  }

    return(
      isAuth ?
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-xs-12 col-lg-5 col-md-5">

                <Form.Group className="mb-3">
                <Form.Label className="text-black">{translated.HatchOpenType}</Form.Label>
                    <Controller
                        name="HatchOpenType"                                
                        control={control}
                        rules={{ required: translatedForm.Required }}
                        render={({ field:{value} }) => (
                        <Select
                            onChange={(item) => {
                              setTypeIndicator({label:item.label, value:item.value})
                              setValue("HatchOpenType", setlectedTypeIndicator)
                              isDefined ? setIsDefined(false) : setIsDefined(true)
                            }}
                            value={setlectedTypeIndicator}
                            options={listConnect}
                            placeholder={translatedForm.SelectFromList}
                            classNamePrefix="select"
                        />
                        )}
                    />
                    {errors.HatchOpenType && 
                        <span className="text-danger font-size-13">{errors.HatchOpenType.message}</span>
                    }
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.OpeningSensor}</Form.Label>
                    
                    {
                      isDefined ?                     
                      <Controller
                          name="HatchOpenInput"                                
                          control={control}
                          rules={{ required: translatedForm.Required }}
                          render={({ field:{value} }) => (
                          <Select
                              onChange={(item) => {
                                setSelectedInput({label:item.label, value:item.value})
                                setValue("HatchOpenInput", selectedInput)
                              }}
                              value={selectedInput}
                              options={listInput}
                              placeholder={translatedForm.SelectFromList}
                              classNamePrefix="select"
                          />
                          )}
                      />
                      : <input type="text" className="form-control" disabled value="Вход 1" />
                    }

                </Form.Group>       

                  <div className="form-group mt-3">
                      <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                          {loadingBtn && (
                              <Spinner animation="border" variant="light" 
                                  as="span"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  style={{ marginRight: "5px" }}
                              />
                          )}
                          {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                          {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                      </button>
                  </div>
                </div>
            </div>
        </Form>
      </>
    :
    <Redirect to='/login'/>
    );
}