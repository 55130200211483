import React, { useContext, useEffect, useState } from 'react';
import CatalogSingle from '../single';

export const TypeLimit = () => {

    const FUEL_VALUES_URL = "api/type-limits/all"
    const FUEL_VALUES_CREATE = "api/type-limits/create"
    
    const data = {
        all_url: FUEL_VALUES_URL,
        create_url: FUEL_VALUES_CREATE,
        name: "Виды лимитов"
    }

    return (
        <>
            <CatalogSingle {...data} />
        </>
    )
}