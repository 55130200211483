import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, ProgressBar, Badge, Form, Offcanvas, Spinner} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import axios from 'axios';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

export const FormEditSection = ({translated, translatedForm, id, setListSections}) => {

    const params = useParams();
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [listFuels, setFuels] = useState([])
    const [listLm, setListLm] = useState([])
    const [selectedFuel, setSelectedFuel] = useState({
        value:'',
        label:''
    })
    const [selectedLm, setSelectedLm] = useState({
        label:translatedForm.SelectFromList,
        value:0
    })
    const [loadingData, setLoadingDataButton] = useState({
        button:false
    })
    const [listTrk, setListTrk] = useState([])
    const [listDispenser, setListDispenser] = useState([])


    const getFuels = () => {
        api.get('/api/fuels/all', {
            withCredentials:true
        })
        .then(function(response){
            console.log("fuels")
            
            const addFuel = response.data.listDatas.map(item => ({
                ...item,
                value: item.id,
                label: item.name
            }))
            setFuels(addFuel)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getSection = () => {
        api.get('/api/stations/details/sections/details', {
            headers:{
            "content-type" : "application/json",
            "id":id
        },
            params:{
            id: id
        }
        })
        .then(function(response){

            console.log("section")
            console.log(response)
               
            setValue("Id", response.data.dataResult.id)
            setValue("Name", response.data.dataResult.name)
            setValue("LevelHeightLow", response.data.dataResult.levelHeightLow)
            setValue("LevelHeightUp", response.data.dataResult.levelHeightUp)
            setValue("VolumeLow", response.data.dataResult.volumeLow)
            setValue("VolumeUp", response.data.dataResult.volumeUp)
            setValue("Volume", response.data.dataResult.volume)
            setValue("Height", response.data.dataResult.height)
            setValue("Fuel", 
            {
                label:response.data.dataResult.fuelName,
                value:response.data.dataResult.fuelId
            })

            if(response.data.dataResult.levelMeterId != 0)
            {
                let numberLevelMeter = response.data.dataResult.levelMeterOnController + 1;
                setValue("LevelMeter", {
                    label: response.data.dataResult.levelMeterControllerName + ' - ' + "Уровнемер " + numberLevelMeter ,
                    value: response.data.dataResult.levelMeterId
                })
            }

            if(response.data.dataResult.sleeveTrkConfigs != null && response.data.dataResult.sleeveTrkConfigs.length != 0)
            {
                let array_dispenser = []
                for(let i = 0; i < response.data.dataResult.sleeveTrkConfigs.length; i++)
                {
                    array_dispenser.push({
                        label: response.data.dataResult.sleeveTrkConfigs[i].trkName + ' - ' + response.data.dataResult.sleeveTrkConfigs[i].sleeveName,
                        value: response.data.dataResult.sleeveTrkConfigs[i].sleeveTrkConfigId
                    })
                }
                setValue("Dispenser", array_dispenser)
            }

            if(response.data.dataResult.levelMeterId != 0)
            {
                getControllerTrk(response.data.dataResult.levelMeterControllerId)
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getLevelMetersController = (controllerId, controllerName) => {
        api.get('/api/controllers/details/level-meters', {
            headers:{
                "content-type" : "application/json",
                "controllerId":controllerId
            },
            params:{
                controllerId: controllerId
            }
        })
        .then(function(response) {
            console.log("level meters controller")
            console.log(response)

            let i = 1;
            const add = response.data.dataResult.levelMeters.map((item, index) => ({
                ...item,
                value: item.id,
                label: `${controllerName} - ${translated.LevelMeter} ${i++}`
            }))
            setListLm(add)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getLevelMeters = () => {
        api.get('/api/stations/details/sections/details/controllers', {
            headers:{
                "content-type" : "application/json",
                "sectionId":id
            },
            params:{
                sectionId: id
            }
        })
        .then(function(response) {
            console.log("level meters section")
            
            let controllerName = response.data.listDatas[0].controllerName
            let controllerId = response.data.listDatas[0].controllerId
            getLevelMetersController(controllerId, controllerName)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const getControllerTrk = (controllerId) => {
        api.get('/api/controllers/details/trk-dispensers', {
            headers:{
                "content-type" : "application/json",
                "controllerId":controllerId
            }
        })
        .then(function(response) {
            console.log("CONTROLLER TRK")
            console.log(response)

            let array_dispenser = []
            for(let i = 0; i < response.data.dataResult.sleeveTrkConfigs.length; i++)
            {
                array_dispenser.push({
                    label: response.data.dataResult.sleeveTrkConfigs[i].trkName + ' - ' + response.data.dataResult.sleeveTrkConfigs[i].sleeveName,
                    value: response.data.dataResult.sleeveTrkConfigs[i].sleeveTrkConfigId
                })
            }

            setListDispenser(array_dispenser)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        getFuels()
        getSection()
        getLevelMeters()
    },[])

    const onSubmit = data => {
        console.log("data")
        console.log(data)

        let dispensers = []
        
        if(data.Dispenser != null)
        {
            data.Dispenser.map((i) => dispensers.push(i.value))
        }

        setLoadingDataButton({button:true})
        api.post('/api/stations/details/sections/update', {
            Id: data.Id,
            StationId: params.id,
            Name: data.Name,
            FuelId: data.Fuel.value,
            LevelHeightLow: data.LevelHeightLow,
            LevelHeightUp: data.LevelHeightUp,
            VolumeLow: data.VolumeLow,
            VolumeUp: data.VolumeUp,
            Height: data.Height,
            Volume: data.Volume,
            LevelMeterId: selectedLm.value,
            DispenserIndexes: dispensers
        })
        .then(function (response) {
            
            displayToast(response.data.message, response.data.isSuccess)
            setLoadingDataButton({button:false})
            setListSections(response.data.listDatas)
        })
        .catch(function (error) {
        console.log(error);
            setLoadingDataButton({button:false})
            displayToast(error.response.data.message, error.response.data.isSuccess)
        });
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>

            <input type="hidden" {...register("Id")} />

                <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.Name} <sup className="text-danger">*</sup></Form.Label>
                    <Form.Control 
                        {...register("Name", {
                        minLength : {
                            value: 5,
                            message: translatedForm.MinLengthString(5)
                        },
                        maxLength : {
                            value: 100,
                            message: translatedForm.MaxLenghtString(100)
                        },
                        required: translatedForm.Required
                        })}
                        type="text" maxLength="100" isInvalid={errors.Name}  
                        placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                        {errors.Name && 
                        <span className="text-danger font-size-13">{errors.Name.message}</span>
                        }
                </Form.Group>

                <Form.Group className="mb-3 mt-3">
                <Form.Label className="text-black">{translated.Fuel} <sup className="text-danger">*</sup></Form.Label>

                    <Controller
                        name="Fuel"                                
                        control={control}
                        rules={{ required: translatedForm.Required }}
                        render={({ field:{value} }) => (
                        <Select                              
                            isSearchable
                            value={value}
                            options={listFuels}                        
                            placeholder={translatedForm.SelectFromList}
                            classNamePrefix="select"
                            onChange={(item) => {
                                setValue("Fuel", {label:item.label, value:item.value})
                            }}
                        />
                        )}
                    />
                    {errors.Fuel && 
                        <span className="text-danger font-size-13">{errors.Fuel.message}</span>
                    }   

                </Form.Group>

                <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.LevelMeter}</Form.Label>                    
                        <Controller
                            name="LevelMeter"                                
                            control={control}
                            render={({ field:{value} }) => (
                            <Select                       
                                isSearchable
                                options={listLm}  
                                value={value}                      
                                placeholder={translatedForm.SelectFromList}
                                classNamePrefix="select"
                                onChange={(item) => {
                                    setValue("LevelMeter", {label:item.label, value:item.value})
                                    setSelectedLm({label:item.label, value:item.value})
                                }}  
                            />
                            )}
                        />
                </Form.Group>

                {/*<Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">ТРК</Form.Label>                    
                        <Controller
                            name="TRK"                                
                            control={control}
                            render={({ field }) => (
                            <Select
                            {...field}    
                                isSearchable
                                isMulti
                                options={listTrk}                        
                                placeholder={translatedForm.SelectFromList}
                                classNamePrefix="select"
                            />
                            )}
                        />
                </Form.Group>*/}

                <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">Рукав</Form.Label>                    
                        <Controller
                            name="Dispenser"                                
                            control={control}
                            render={({ field }) => (
                            <Select      
                                {...field}                  
                                isSearchable
                                isMulti
                                options={listDispenser}                       
                                placeholder={translatedForm.SelectFromList}
                                classNamePrefix="select"
                            />
                            )}
                        />
                </Form.Group>

                <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.Height}</Form.Label>
                    <Form.Control 
                        {...register("Height",)}
                        type="number" maxLength="100" isInvalid={errors.Height}  
                        placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                        {errors.Height && 
                        <span className="text-danger font-size-13">{errors.Height.message}</span>
                        }
                </Form.Group>

                <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.MinLevel}</Form.Label>
                    <Form.Control 
                        {...register("LevelHeightLow")}
                        type="number" maxLength="100" isInvalid={errors.LevelHeightLow}  
                        placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                        {errors.LevelHeightLow && 
                        <span className="text-danger font-size-13">{errors.LevelHeightLow.message}</span>
                        }
                </Form.Group>

                <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.MaxLevel}</Form.Label>
                    <Form.Control 
                        {...register("LevelHeightUp")}
                        type="number" maxLength="100" isInvalid={errors.LevelHeightUp} 
                        placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                        {errors.LevelHeightUp && 
                        <span className="text-danger font-size-13">{errors.LevelHeightUp.message}</span>
                        }
                </Form.Group>

                <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.MinVolume}</Form.Label>
                    <Form.Control 
                        {...register("VolumeLow")}
                        type="number" maxLength="100" isInvalid={errors.VolumeLow}  
                        placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                        {errors.VolumeLow && 
                        <span className="text-danger font-size-13">{errors.VolumeLow.message}</span>
                        }
                </Form.Group>

                <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.MaxVolume}</Form.Label>
                    <Form.Control 
                        {...register("VolumeUp")}
                        type="number" maxLength="100" isInvalid={errors.VolumeUp}  
                        placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                        {errors.VolumeUp && 
                        <span className="text-danger font-size-13">{errors.VolumeUp.message}</span>
                        }
                </Form.Group>

                <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.FullVolume}</Form.Label>
                    <Form.Control 
                        {...register("Volume")}
                        type="number" maxLength="100" isInvalid={errors.Volume} 
                        placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                        {errors.Volume && 
                        <span className="text-danger font-size-13">{errors.Volume.message}</span>
                        }
                </Form.Group>
                    
                <div className="form-group mt-3">
                <button type="submit" disabled={loadingData.button} className="btn btn-primary">
                    {loadingData.button && (
                        <Spinner animation="border" variant="light" 
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                        />
                    )}
                    {loadingData.button && <span>{translated.SaveChanges}</span>}
                    {!loadingData.button && <span>{translated.SaveChanges}</span>}
                    </button>
                </div>            
        </Form>
    )
}