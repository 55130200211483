import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateClients = {

    Name:{
        en_US: "Name",
        ru_RU: "Название"
    },
    CreatedDate:{
        en_US: "Created date",
        ru_RU: "Дата добавления"
    },
    Message: (name) => ({
        en_US: `List of client companies that receive fuel services from the company ${name}`,
        ru_RU: `Список клиентских компаний, которым предоставляются услуги по топливу от компании ${name}`
    }),
    Actions:{
        en_US: "Actions",
        ru_RU: "Действия"
    },
    PermissionDenied:{
        en_US: "Permission denied",
        ru_RU: "У вас недостаточно прав доступа для просмотра информации по данному разделу"
    },
    Blocked:{
        en_US: "Blocked",
        ru_RU: "Заблокирована"
    },
    Active:{
        en_US: "Active",
        ru_RU: "Активна"
    },
    Served:{
        en_US: "Served",
        ru_RU: "Обслуживается"
    },
    NotServed:{
        en_US: "Not served",
        ru_RU: "Не обслуживается"
    },
};
