import React from "react";
import { useLittera } from "@assembless/react-littera";

export const BalanceLang = {

    LimitPlan: {
        en_US: "Limit plan",
        ru_RU: "Лимитный план"
    },
    PlannedСonsumption: {
        en_US: "Planned consumption",
        ru_RU: "Плановое потребление"
    },
    HistoryChanges:{
        en_US: "History changes",
        ru_RU: "История измнений"
    },
    Account:{
        en_US: "Account",
        ru_RU: "Счет"
    },
    Limit:{
        en_US: "Limit",
        ru_RU: "Лимит"
    },
    Balance:{
        en_US: "Balance",
        ru_RU: "Остаток"
    },
    BalanceOn: {
        en_US: "Balance on",
        ru_RU: "Остаток на"
    },
    RefreshLimitAndAccount:{
        en_US: "Refresh limit and account",
        ru_RU: "Обновить лимит и счет"
    },
    UseMoneyWallet:{
        en_US: "Use account of company for recalculation balance",
        ru_RU: "Использовать деньги для пересчета остатка топлива"
    },
    EditLimit:{
        en_US: "Edit",
        ru_RU: "Изменение лимита"
    },
    TitleEditLimit: {
        en_US: "Change limit",
        ru_RU: "Изменение лимита"
    },
    MessageRecalcLimit:{
        en_US: "Fuel balance of company will be recalculation from current money account and last fillings from controller, with current fuel's price",
        ru_RU: "Остаток топлива будет автоматически расчитываться из текущего остатка денег на счете компании и проведенных заправок на контроллерах, с учетом указаной цены на конкретный вид топлива у контроллера"
    },
    Unlimited:{
        en_US: "Unlimited",
        ru_RU: "Безлимитный"
    },
    Absolute:{
        en_US: "Absolute",
        ru_RU: "Абсолютный"
    },
    SaveChanges:{
        en_US: "Save changes",
        ru_RU: "Сохранить"
    },
    TypeLimit:{
        en_US: "Type limit",
        ru_RU: "Тип лимита" 
    },
    SelectValueFromList:{
        en_US: "Select value from list",
        ru_RU: "Выберите значение из списка" 
    },
    Liters:{
        en_US: "Liters",
        ru_RU: "Литры"
    },
    EnterLiters:{
        en_US: "Enter liters",
        ru_RU: "Введите литры"
    },
    UpdateData:{
        en_US: "Get updated data",
        ru_RU: "Обновить информацию"
    },
    Comment:{
        en_US: "Commentary",
        ru_RU: "Комментарий"
    },
    User:{
        en_US: "User",
        ru_RU: "Пользователь"
    },
    DateRecord:{
        en_US: "Date",
        ru_RU: "Дата записи"
    }
};
