import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Alert, ProgressBar, Badge, Form, Offcanvas, Spinner, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import { CompanyFormAdd } from './CompanyFormAdd';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import moment from 'moment';
import './../../boxicons.css';
import { FuelCompanyFormAdd } from './FuelForm/FuelCompanyFormAdd';
import { FuelCompanyFormEdit } from './FuelForm/FuelCompanyFormEdit';
import { CompanyStations } from './stations';
import { CompanyUsers } from './users';
import { CompanyBalance } from './balance';
import { CompanyService } from './companyService';
import { FuelsCompany } from './fuels';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { TranslateCompanyModule } from '../../lang/company';
import { CurrentSelectLanguage } from '../../helpers/language';
import { CompanyApi } from './CompanyApi';
import api from '../../utils/axiosInterceptions';

const CompanySingle = () => {

  const router = useHistory()
  const params = useParams();
  const {isAuth} = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false)
  const [showFormAdd, setShowFormAdd] = useState(false);
  const [currentActiveTab, setCurrentActiveTab] = useState()
  const [listTimezone, setListTimezone] = useState([])
  const [listStation, setListStation] = useState([])
  const [block, setIsBlock] = useState(false)
  const translated = useLittera(TranslateCompanyModule);
  const methods = useLitteraMethods();
  const [isSignalAccountBad, setIsSignalAccountBad] = useState(false)
  const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
  const [permission, setPermission] = useState({
    read:false,
    update:false,
    create:false,
    delete:false,
    fullAccess:false
  })
  const [companyData, setCompanyData] = useState({
    name:'',
    id:''
  });
  const [loadingDataBtn, setLoadingDataButton] = useState(false)

  const handleActiveTab = data => {
    setCurrentActiveTab(data)
  }

  const handleShow = (name, titleOffCanvas, data) => {
    setShowFormAdd(true);
  }
  const handleClose = () => setShowFormAdd(false);
  
  const onSubmit = data => {
    console.log(data)
    setLoadingDataButton(true)
    api.post('/api/company/update', {
        Id: data.Id,
        Name: data.Name,
        INN: data.INN,
        KPP: data.KPP,
        OGRN: data.OGRN,
        Address: data.Address,
        MailAddress: data.MailAddress,
        NameBank: data.Bank,
        BIK: data.BIK,
        CorrAccount: data.CorrAccount,
        RateAccount: data.RateAccount,
        SignalAccount: data.SignalAccount
      })
      .then(function (response) {
        setLoadingDataButton(false)
        displayToast(response.data.message, response.data.isSuccess)
        setCompanyData({
          name:response.data.dataResult.name,
          id: response.data.dataResult.id
        });
        setValue("Name", response.data.dataResult.name);
        setValue("Id", response.data.dataResult.id);
        setValue("INN", response.data.dataResult.inn)
        setValue("KPP", response.data.dataResult.kpp)
        setValue("Address", response.data.dataResult.address)
        setValue("MailAddress", response.data.dataResult.mailAddress)
        setValue("OGRN", response.data.dataResult.ogrn)

        setValue("Bank", response.data.dataResult.nameBank)
        setValue("BIK", response.data.dataResult.bik)
        setValue("RateAccount", response.data.dataResult.rateAccount)
        setValue("CorrAccount", response.data.dataResult.corrAccount)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });

    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
  
  }

  const fetchingData = () => {
    setIsLoading(true)
    api.get("/api/company/details", {
      headers:{
        "content-type" : "application/json",
        "id":params.id
      },
      params:{
        id: params.id,
      },
      withCrenditials:true
    })
    .then(function (response) {
      console.log("company single")
      
      setPermission({
        read: response.data.read,
        update:response.data.update,
        delete:response.data.delete,
        create: response.data.create,
        fullAccess: response.data.fullAccess
      })

      setIsBlock(response.data.dataResult.isBlocked)

      setCompanyData({
        name:response.data.dataResult.name,
        id: response.data.dataResult.id
      });

      if(response.data.dataResult.isFuelWallet)
      {
         if(response.data.dataResult.balanceLimit < response.data.dataResult.signalAccount)
         {
            setIsSignalAccountBad(true)
         }
         else 
         {
            setIsSignalAccountBad(false)
         }
      }

      setValue("Name", response.data.dataResult.name);
      setValue("Id", response.data.dataResult.id);
      setValue("INN", response.data.dataResult.inn)
      setValue("KPP", response.data.dataResult.kpp)
      setValue("Address", response.data.dataResult.address)
      setValue("MailAddress", response.data.dataResult.mailAddress)
      setValue("OGRN", response.data.dataResult.ogrn)
      setValue("SignalAccount", response.data.dataResult.signalAccount)

      setValue("Bank", response.data.dataResult.nameBank)
      setValue("BIK", response.data.dataResult.bik)
      setValue("RateAccount", response.data.dataResult.rateAccount)
      setValue("CorrAccount", response.data.dataResult.corrAccount)
      setIsLoading(false)
      
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const blocked = () => {
    api.post('/api/company/details/delete', {
      Id: params.id,
      withCrenditials: true
    })
    .then(function (response) {
      setIsBlock(response.data.dataResult.isBlocked)
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
      console.log(error);
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }
  
  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    fetchingData()
  }, [])

    return(
      isAuth ?
      <>
        <div className=" d-sm-flex align-items-center justify-content-between">
          <h2 className="mb-sm-0 fw-semibold font-size-18 benzamat-font-bold text-black">
              <NavLink to="/companies">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.125 13.8125L3.25 8.9375L8.125 4.0625" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M8.125 20.3125H17.0625C18.5709 20.3125 20.0176 19.7133 21.0842 18.6467C22.1508 17.5801 22.75 16.1334 22.75 14.625V14.625C22.75 13.8781 22.6029 13.1385 22.3171 12.4485C22.0312 11.7584 21.6123 11.1315 21.0842 10.6033C20.556 10.0752 19.9291 9.65626 19.239 9.37043C18.549 9.08461 17.8094 8.9375 17.0625 8.9375H3.25" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
              </NavLink> 
              {translated.Title} - {isLoading ? <Skeleton width={200} height={30}/> : companyData.name}
          </h2>
          <div className="page-title-right">
            {
              isLoading && (permission.delete || permission.fullAccess)
              ? <Skeleton width={200} height={30}/>
              : 
                block
                ?
                <Button onClick={() => blocked()} variant="success" className="me-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-lock-off" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M15 11h2a2 2 0 0 1 2 2v2m0 4a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2h4"></path>
                      <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0"></path>
                      <path d="M8 11v-3m.719 -3.289a4 4 0 0 1 7.281 2.289v4"></path>
                      <path d="M3 3l18 18"></path>
                    </svg>
                    {translated.Unlock}
                </Button>
                :
                <Button onClick={() => blocked()} variant="danger" className="me-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-lock" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z"></path>
                      <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0"></path>
                      <path d="M8 11v-4a4 4 0 1 1 8 0v4"></path>
                    </svg>
                    {translated.Block}
                </Button>
            }            
          </div>
         </div>
    

    <div className="row mt-3">      
      {
        block
        ?
        <div className="col-xs-12 col-lg-12 col-md-12">
          <Alert variant='danger'>{translated.CompanyIsBlockAlert}</Alert>
        </div>
        : null
      }

      {
        isSignalAccountBad
        ?
        <div className="col-xs-12 col-lg-12 col-md-12">
          <Alert variant='danger'>{translated.SignalStepAlert}</Alert>
        </div>
        : null
      }
      <Tabs defaultActiveKey="maininfo" onClick={(e) => handleActiveTab(e.target.dataset.rrUiEventKey)} id="uncontrolled-tab-example" className="mt-2 nav-tabs-custom">

        <Tab eventKey="maininfo" title={translated.General} tabClassName="font-size-15">
          <div className="row mt-2">            
            <div className="col-xs-12 col-md-4 col-lg-4">
              <Form onSubmit={handleSubmit(onSubmit)}>

                <Form.Control {...register("Id")} type="hidden"  />

                {
                  isLoading
                  ? <Skeleton height={25} className="mt-4" count={6} />
                  : 
                  <>
                    <Form.Group className="mb-3 mt-3">
                      <Form.Label className="text-black">{translated.Name} <sup className="text-danger">*</sup></Form.Label>
                      <Form.Control 
                        {...register("Name", {
                          maxLength : {
                            value: 500,
                            message: translated.MaxLenghtString(500)
                          },
                          minLength : {
                            value: 3,
                            message: translated.MinLengthString(3)
                          },
                          required : translated.Required
                        })}
                        type="text" maxLength="500" minLength="3" isInvalid={errors.Name}  placeholder="Введите название (до 500 символов)" className="form-control"  />
                        {errors.Name && 
                          <span className="text-danger font-size-13">{errors.Name.message}</span>
                        }
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="text-black">{translated.TIN} <sup className="text-danger">*</sup></Form.Label>
                      <Form.Control 
                        {...register("INN", {
                          maxLength : {
                            value: 12,
                            message: translated.MaxNumber(12)
                          },
                          minLength : {
                            value: 10,
                            message: translated.MinNumber(10)
                          },
                          required : translated.Required
                        })}
                        type="number" maxLength="12" minLength="10" isInvalid={errors.INN}  placeholder="Введите ИНН (10-12 цифр)" className="form-control"  />
                        {errors.INN && 
                          <span className="text-danger font-size-13">{errors.INN.message}</span>
                        }
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="text-black">{translated.OGRN} <sup className="text-danger">*</sup></Form.Label>
                      <Form.Control 
                        {...register("OGRN", {
                          maxLength : {
                            value: 15,
                            message: translated.MaxNumber(15)
                          },
                          minLength : {
                            value: 13,
                            message: translated.MinNumber(13)
                          },
                          required : translated.Required
                        })}
                        type="number" maxLength="15" minLength="13" isInvalid={errors.OGRN}  placeholder={translated.EnterOGRN} className="form-control"  />
                        {errors.OGRN && 
                          <span className="text-danger font-size-13">{errors.OGRN.message}</span>
                        }
                    </Form.Group>

                    <Form.Group className="mt-3">
                      <Form.Label className="text-black">{translated.KPP}</Form.Label>
                      <Form.Control 
                        {...register("KPP", {
                          minLength : {
                            value: 9,
                            message: translated.MinNumber(9)
                          },
                          maxLength : {
                            value: 9,
                            message: translated.MaxNumber(9)
                          },
                        })}
                        type="text" maxLength="9" isInvalid={errors.KPP}  placeholder={translated.EnterKPP} className="form-control"  />
                        {errors.KPP && 
                          <span className="text-danger font-size-13">{errors.KPP.message}</span>
                        }
                    </Form.Group>
                    
                    {
                      permission.update || permission.fullAccess
                      ?
                      <div className="form-group mt-3">
                        <button type="submit" disabled={loadingDataBtn} className="btn btn-primary">
                          {loadingDataBtn && (
                              <Spinner animation="border" variant="light" 
                                  as="span"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  style={{ marginRight: "5px" }}
                              />
                          )}
                          {loadingDataBtn && <span>{translated.SaveChangesProccess}</span>}
                          {!loadingDataBtn && <span>{translated.SaveChanges}</span>}
                        </button>
                      </div>
                      : ''
                    }
                  </>
                }
              </Form>
            </div>

            <div className="col-xs-12 col-md-4 col-lg-4">

            {
              isLoading
              ? <Skeleton height={25} className="mt-4" count={4} />
              : <>
              
              <Form.Group className="mb-3 mt-3">
                  <Form.Label className="text-black">{translated.LegalAddress} <sup className="text-danger">*</sup></Form.Label>
                  <Form.Control 
                    {...register("Address", {
                      maxLength : {
                        value: 800,
                        message: translated.MaxLenghtString(800)
                      },
                      minLength : {
                        value: 5,
                        message: translated.MinLengthString(5)
                      },
                      required : translated.Required
                    })}
                    type="text" maxLength="800" isInvalid={errors.Address}  placeholder={translated.EnterLegalAddress} className="form-control"  />
                    {errors.Address && 
                      <span className="text-danger font-size-13">{errors.Address.message}</span>
                    }
              </Form.Group>

              <Form.Group className="mb-3 mt-3">
                <Form.Label className="text-black">{translated.MailAddress} <sup className="text-danger">*</sup></Form.Label>
                <Form.Control 
                  {...register("MailAddress", {
                    maxLength : {
                      value: 800,
                      message: translated.MaxLenghtString(800)
                    },
                    minLength : {
                      value: 5,
                      message: translated.MinLengthString(5)
                    },
                    required : translated.Required
                  })}
                  type="text" maxLength="800" isInvalid={errors.MailAddress}  placeholder={translated.EnterMailAddress} className="form-control"  />
                  {errors.MailAddress && 
                    <span className="text-danger font-size-13">{errors.MailAddress.message}</span>
                  }
              </Form.Group>

              <Form.Group className="mb-3 mt-3">
              <Form.Label className="text-black">{translated.SignalStep}</Form.Label>
            
                <Controller
                    name="SignalAccount"                           
                    control={control}
                    render={({ field:{value} }) => (
                    <CurrencyInput
                        placeholder={translated.InputLiters}
                        defaultValue={value}
                        decimalsLimit={2}
                        suffix=" л."
                        className="form-control"
                        onValueChange={(value) => {
                          setValue("SignalAccount", value)
                        }}
                    />
                    )}
                />
              </Form.Group>

              </>
            }
            </div>

            <div className="col-xs-12 col-md-4 col-lg-4">

            {
              isLoading
              ? <Skeleton count={4} className="mt-4" height={25} />
              :
              <>
                <Form.Group className="mb-3 mt-3">
                  <Form.Label className="text-black">{translated.BankName}</Form.Label>
                  <Form.Control 
                    {...register("Bank", {
                      maxLength : {
                        value: 500,
                        message: translated.MaxLenghtString(500)
                      },
                      minLength : {
                        value: 3,
                        message: translated.MinLengthString(3)
                      },
                    })}
                    type="text" maxLength="500" minLength="3" isInvalid={errors.Bank}  placeholder={translated.EnterName} className="form-control"  />
                    {errors.Bank && 
                      <span className="text-danger font-size-13">{errors.Bank.message}</span>
                    }
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.BIK}</Form.Label>
                  <Form.Control 
                    {...register("BIK", {
                      maxLength : {
                          value: 9,
                          message: translated.MaxNumber(9)
                      },
                      minLength : {
                          value: 9,
                          message: translated.MinNumber(9)
                      },
                      })}
                      type="number" maxLength="9" isInvalid={errors.CorrAccount}  placeholder={translated.EnterBIK} className="form-control"  />
                      {errors.CorrAccount && 
                      <span className="text-danger font-size-13">{errors.CorrAccount.message}</span>
                      }
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.CorrAccount}</Form.Label>
                  <Form.Control 
                    {...register("CorrAccount", {
                    maxLength : {
                        value: 20,
                        message: translated.MaxNumber(20)
                    },
                    minLength : {
                        value: 20,
                        message: translated.MinNumber(20)
                    },
                    })}
                    type="number" maxLength="20" isInvalid={errors.CorrAccount}  placeholder={translated.EnterCorrAccount} className="form-control"  />
                    {errors.CorrAccount && 
                    <span className="text-danger font-size-13">{errors.CorrAccount.message}</span>
                    }
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.RatedAccount}</Form.Label>
                  <Form.Control 
                    {...register("RateAccount", {
                    maxLength : {
                        value: 20,
                        message: translated.MaxNumber(20)
                    },
                    minLength : {
                        value: 20,
                        message: translated.MinNumber(20)
                    },
                    })}
                    type="number" maxLength="20" isInvalid={errors.RateAccount}  placeholder={translated.EnterRatedAccount} className="form-control"  />
                    {errors.RateAccount && 
                    <span className="text-danger font-size-13">{errors.RateAccount.message}</span>
                    }
                </Form.Group>
              </>
            }
            </div>
          </div>
        </Tab>

        <Tab eventKey="limit" title={translated.Balance} tabClassName="font-size-15">
          {
            currentActiveTab == "limit"
            ? <CompanyBalance />
            : null
          }
        </Tab>

        <Tab eventKey="users" title={translated.Users} tabClassName="font-size-15">
          {
            currentActiveTab == "users"
            ? <CompanyUsers />
            : null
          }
        </Tab>
    
        <Tab eventKey="tanks" title={translated.Stations} tabClassName="font-size-15">
          {
            currentActiveTab == "tanks"
            ? <CompanyStations />
            : null
          }
        </Tab>

        <Tab eventKey="fuels" title={translated.Fuels} tabClassName="font-size-15">
          {
            currentActiveTab == "fuels"
            ? <FuelsCompany />
            : null
          }
        </Tab>

        <Tab eventKey="children" title={translated.Clients} tabClassName="font-size-15">
          {
            currentActiveTab == "children"
            ? <CompanyService companyData={companyData}/>
            : null
          }
        </Tab>

        <Tab eventKey="api" title={translated.API} tabClassName="font-size-15">
          {
            currentActiveTab == "api"
            ? <CompanyApi translated={translated} />
            : null
          }
        </Tab>

      </Tabs>
    </div>
    </>
    :
    <Redirect to='/login'/>
    );
}

export default CompanySingle;