import React, { useContext, useEffect, useState } from 'react';
import {AuthContext} from './AuthContext';
import axios from 'axios'
import api from '../utils/axiosInterceptions';

const AxiosErrorHandler = ({ children }) => {
    const { setIsAuth } = useContext(AuthContext);

    useEffect(() => {
        api.interceptors.response.use(
        (res) => {
            setIsAuth(true)
            return res;
        },
        async (err) => {
            if (err.response.status === 401) {
                setIsAuth(false)
                localStorage.removeItem("accessToken")
            }
            return Promise.reject(err);
        }
    );
    }, [setIsAuth])

    return children
}

export default AxiosErrorHandler