import React, { useState, useEffect, useContext, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Form, Card, Spinner, Alert, Table, ProgressBar} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import Chart from "react-apexcharts";
import moment from 'moment/moment';
import './../../boxicons.css';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import api from '../../utils/axiosInterceptions';

export const ReportGsmPouringStation = () => {

  const router = useHistory();
  const {isAuth} = useContext(AuthContext);
  const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [listStation, setListStation] = useState([])
  const [data, setData] = useState([])
  const [conclusionReport, setConclusionReport] = useState([])
  const [listCompanies, setListCompanies] = useState([])
  const [listControllers, setListControllers] = useState([])
  const [countStations, setCountStations] = useState(0)
  const [diagrammColumn, setDiagrammColumn] = useState({
    series:[],
  })
  const [permission, setPermission] = useState({
    read:false,
    create:false,
    fullAccess:false
  })
  const [selectedCompany, setSelectedCompany] = useState({
    label:'',
    value:0
  })
  const [selectedStation, setSelectedStation] = useState({
    label:'',
    value:0
  })
  const [loadingData, setLoadingData] = useState({
    grid:false,
    button:false
  });
  
  const displayToast = (message, status, typeMessage) => {
    
    if(typeMessage == "success") {
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    } else {
        toast.info(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    }
  }

  const getCompanies = () => {
    api.get('/api/company/all', {
        withCredentials:true
    })
    .then(function (response) {
        console.log("companies")
        

        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))

        setListCompanies(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const getStations = companyId => {
    api.get('/api/company/details/controllers', {
        headers:{
            "content-type" : "application/json",
            "companyId":companyId
        },
        params:{
            companyId: companyId,
        },
        withCredentials:true
    })
    .then(function (response) {
        let add = response.data.listDatas.map((item) => ({
            ...item,
            value:item.id,
            label:item.name
        }))
        setListControllers(add)
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  const onSubmit = data => {
    console.log(data)
    let arr = []
    data.ControllerIds.map((item) => {
        arr.push(item.id)
    })
    setLoadingData({button:true})
    api.post('/api/reports/gsm-controllers', {
        ControllerIds: arr,
        IsGrouping: true,
        EndPeriod: data.EndPeriod.toISOString(),
        StartPeriod: data.StartPeriod.toISOString(),
        withCredentials:true
    })
    .then(function (response) {
        console.log("result recipts")
        
        setLoadingData({button:false})
        setData(response.data.dataResult.data)
        setConclusionReport(response.data.dataResult.result)
        displayToast(response.data.message, response.data.isSuccess, response.data.typeMessage)
    })
    .catch(function (error) {
        console.log(error);
        setLoadingData({button:false})
    });
  }

  useEffect(() => {
    getCompanies()
  }, []);

  return (
    isAuth ?
    <>
    <div className="d-sm-flex align-items-center justify-content-between">
        <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
            Аналитика - <span className="text-secondary benzamat-font-bold font-size-18">Отчет по поступлению топлива</span>
        </h2>                 
    </div>
    <div className="row mt-4">

        <form onSubmit={handleSubmit(onSubmit)} >
            <div className="row">
                <div className="col-xs-12 col-lg-3 col-md-3">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">Компания</Form.Label>
                            <Controller
                                name="CompanyId"                                
                                control={control}
                                render={({ field: {value} }) => (
                                <Select
                                    options={listCompanies}                   
                                    isSearchable       
                                    value={value}    
                                    placeholder="Выберите значение"
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        getStations(item.id)
                                        setValue("CompanyId", item)
                                    }}
                                />
                                )}
                            />
                        {errors.CompanyIds && 
                            <span className="text-danger font-size-13">{errors.CompanyIds.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-3 col-md-3">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">Контроллеры</Form.Label>
                            <Controller
                                name="ControllerIds"                                
                                control={control}
                                render={({ field }) => (
                                <Select
                                    {...field}
                                    options={listControllers}                   
                                    isSearchable
                                    isMulti             
                                    placeholder="Выберите значение"
                                    classNamePrefix="select"
                                />
                                )}
                            />
                        {errors.Controller && 
                            <span className="text-danger font-size-13">{errors.Controller.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-3 col-md-3">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">Начальная дата</Form.Label>
                        <Controller
                            name="StartPeriod"                                
                            control={control}
                            rules={{required:"Обязательное поле"}}
                            render={({ field:{value} }) => (
                            <Flatpickr
                                className='form-control'
                                options={{ 
                                    time_24hr: true,  
                                    enableTime: true,
                                    dateFormat: "d.m.Y H:i",
                                    locale: Russian
                                }}
                                value={value}
                                placeholder="Выберите дату"
                                onChange={([date]) => {
                                    setValue("StartPeriod", date)
                                }}
                            />
                            )}
                        />
                        {errors.StartPeriod && 
                            <span className="text-danger font-size-13">{errors.StartPeriod.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-3 col-md-3">
                    <Form.Group className="mb-2">
                        <Form.Label className="text-black">Конечная дата</Form.Label>
                        <Controller
                            name="EndPeriod"                                
                            control={control}
                            rules={{required:"Обязательное поле"}}
                            render={({ field:{value} }) => (
                            <Flatpickr
                                className='form-control'
                                options={{ 
                                    time_24hr: true,  
                                    enableTime: true,
                                    dateFormat: "d.m.Y H:i",
                                    locale: Russian
                                }}
                                placeholder="Выберите дату"
                                value={value}
                                onChange={([date]) => {
                                    setValue("EndPeriod", date)
                                }}
                            />
                            )}
                        />
                        {errors.EndPeriod && 
                            <span className="text-danger font-size-13">{errors.EndPeriod.message}</span>
                        }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-4 col-md-4">
                    <button type="submit" disabled={loadingData.button} className="btn mt-2 btn-primary">
                        {loadingData.button && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingData.button && <span>Формирование отчета</span>}
                        {!loadingData.button && <span>Сформировать отчет</span>}
                    </button>
                </div>
            </div>
        </form>

        <div className="col-xs-12 col-lg-12 col-md-12 mt-3">         
            <div className="mt-2">
                {
                    data.length != 0
                    ?                  
                    data.map((item) => (
                        <>
                            <h4 className="text-black">Контроллер - {item.controllerName} ({item.fuelName})</h4>
                            <Table bordered hover className="text-black">
                                <thead>
                                    <tr>
                                        <th>Дата</th>
                                        <th>Литры</th>
                                        <th>Пользователь</th>
                                        <th>ТС</th>
                                        <th>Примечание</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   {
                                    item.records.map((i) => (
                                        <tr className="text-black">
                                            <td>{moment(i.date).format("DD.MM.YYYY HH:ss")}</td>
                                            <td>{i.countLitre}</td>
                                            <td>{i.user}</td>
                                            <td>{i.transport}</td>
                                            <td>{i.description}</td>
                                        </tr>
                                    ))
                                   }
                                   <tr className="text-black">
                                        <td colSpan={4}>Итого, л</td>
                                        <td>{item.countLitre}</td>
                                   </tr>
                                   <tr className="text-black">
                                        <td colSpan={4}>Итого в мерник, л</td>
                                        <td>{item.countLitreMernik}</td>
                                   </tr>
                                </tbody>
                            </Table>
                        </>
                    ))
                    : null
                }

                {
                    conclusionReport.length != 0
                    ?
                    <>
                     <h4 className="text-black">Итого за период</h4>
                        <Table bordered hover className="text-black">
                            <thead>
                                <tr>
                                    <th>Топливо</th>
                                    <th>Итого л.</th>
                                    <th>Итого л. мерник</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    conclusionReport.map((item) => (
                                        <tr>
                                            <td>{item.fuelName}</td>
                                            <td>{item.sum}</td>
                                            <td>{item.sumMernik}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        </>
                    : null
                }
            </div>
        </div>
    </div>
    </>
    :
    <Redirect to='/login'/>
  );

}
