import React from "react";
import { useLittera } from "@assembless/react-littera";

export const translations = {

    dashboard: {
        en_US: "Dashboard",
        ru_RU: "Главная"
    },
    CompanyMenu: {
        en_US: "Компании",
        ru_RU: "Компании"
    },
    notifications:{
        en_US: "Notifications",
        ru_RU: "Оповещения"
    },
    notification:{
        en_US: "Notification",
        ru_RU: "Оповещение"
    },
    createDate:{
        en_US: "Created date",
        ru_RU: "Дата создания"
    },
    message:{
        en_US: "Message",
        ru_RU: "Сообщение"
    },
    stations: {
        en_US: "Станции",
        ru_RU: "Станции"
    },
    controllers: {
        en_US: "Контроллеры (ТРК)",
        ru_RU: "Контроллеры (ТРК)"
    },
    users: {
        en_US: "Пользователи",
        ru_RU: "Пользователи"
    },
    transports: {
        en_US: "Транспорт",
        ru_RU: "Транспорт"
    },
    delivery:{
        en_US: "Delivery",
        ru_RU: "Доставка"
    },
    FuelRequest:{
        en_US: "Fuel request",
        ru_RU: "Заявки"
    },
    crm:{
        en_US: "CRM",
        ru_RU: "CRM"
    },
    FuelIntake: {
        en_US: "Fuel intake",
        ru_RU: "Поступление ГСМ"
    },
    analytics: {
        en_US: "Analytics",
        ru_RU: "Аналитика"
    },
    systemRole: {
        en_US: "System Roles",
        ru_RU: "Системные роли"
    },
    logs: {
        en_US: "System logs",
        ru_RU: "Журнал логов"
    },
    dictionaries: {
        en_US: "Dictionaries",
        ru_RU: "Справочники"
    },
    fuels: {
        en_US: "Fuels",
        ru_RU: "Виды топлива"
    },
    settings: {
        en_US: "Settings",
        ru_RU: "Настройки"
    },
    Logout: {
        en_US: "Logout",
        ru_RU: "Выйти"
    },
    benzamatProWebService: {
        en_US: "benzamatPro is online web service for control fuel",
        ru_RU: "Онлайн сервис для управления топливом benzamatPro"
    },
    generalMenuFunc:{
        en_US: "GENERAL FUNCTIONAL",
        ru_RU: "ОБЩИЙ ФУНКЦИОНАЛ"
    },
    AdminMenuFunc:{
        en_US: "ADMINISTRATION",
        ru_RU: "АДМИНИСТРИРОВАНИЕ"
    },
    hideMenu: {
        en_US: "Hide menu",
        ru_RU: "Свернуть меню"
    },

    AdminWebService: {
        en_US: "Administrator service",
        ru_RU: "Администратор сервиса"
    },
    Driver: {
        en_US: "Driver",
        ru_RU: "Водитель"
    },
    Admin: {
        en_US: "Administrator",
        ru_RU: "Администратор"
    },
    Operator: {
        en_US: "Operator",
        ru_RU: "Оператор"
    },
    MajorOperator: {
        en_US: "Major operator",
        ru_RU: "Старший оператор"
    },
    LastFillings: {
        en_US: "Last fillings",
        ru_RU: "Последние заправки"
    },
    ChangePrices: {
        en_US: "Price changes",
        ru_RU: "Изменение цен"
    },
    ReadingsLevelMeter:{
        en_US: "Readings level meter",
        ru_RU: "Показания уровнемеров"
    },
    LogAllMessage: {
        en_US: "Log of all messages",
        ru_RU: "Журнал всех сообщений"
    },
    LogAllActionUsers:{
        en_US: "Log of all user's actions",
        ru_RU: "Журнал всех действий пользователей"
    },
    TotalReleaseFuelController: {
        en_US: "Total fuel release by controllers",
        ru_RU: "Суммарный отпуск ГСМ по контроллерам"
    },
    TotalReleaseFuelUser: {
        en_US: "Total fuel release by users",
        ru_RU: "Суммарный отпуск ГСМ по пользователям"
    },
    TotalReleaseFuelTransport: {
        en_US: "Total fuel release by transports",
        ru_RU: "Суммарный отпуск ГСМ по транспорту"
    },
    TotalReleaseFuelCompany: {
        en_US: "Total fuel release by companies",
        ru_RU: "Суммарный отпуск ГСМ по компаниям"
    },
    FuelReleaseMass: {
        en_US: "Fuel release by mass",
        ru_RU: "Отпуск ГСМ по массе"
    },
    FuelReleaseController: {
        en_US: "Fuel release by controllers",
        ru_RU: "Отпуск ГСМ по контроллерам"
    },
    FuelReleaseUser: {
        en_US: "Fuel release by users",
        ru_RU: "Отпуск ГСМ по пользователям"
    },
    FuleReleaseWithoutGroup: {
        en_US: "Fuel release without grouping",
        ru_RU: "Отпуск ГСМ по контроллерам (без группировки)"
    },
    GsmOperationLog:{
        en_US: "GSM operation log",
        ru_RU: "Журнал качества связи"
    },
    TankLidReadings:{
        en_US: "Tank lid sensor readings",
        ru_RU: "Показания датчика крышки резервуара"
    },
    CashDynamics:{
        en_US: "Cash dynamics",
        ru_RU: "Приход и расход денег"
    },
    FuelIntakController:{
        en_US: "Fuel reception by controller",
        ru_RU: "Прием топлива на контроллере"
    },
    FuelReceiptReport:{
        en_US: "Fuel receipt report",
        ru_RU: "Отчет по поступлению топлива"
    },
    FuelReceiptLog:{
        en_US: "Fuel receipt log",
        ru_RU: "Журнал поступления топлива"
    },
    FuelIntakOnController:{
        en_US: "Fuel receipt report",
        ru_RU: "Поступление топлива на контроллере"
    }
    
};