import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Spinner, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

export const ControllerPump = ({translated, translatedForm, controllerInfo}) => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [selectedPumpControl, setSelectedPumpControl] = useState({
        label:'',
        value:''
    })
    const [selectedPumpStartDelay, setStartPumpDelay] = useState({
        label:'',
        value:''
    })
    const [loadingBtn, setLoadingBtn] = useState(false)

    const pumpControlOutput = [
        {label:translated.PumpRelay1, value:1},
        {label:translated.PumpOut1, value: 0}
    ]

    const pumpStartDelay = [
        {label:"0", value:0},
        {label:"1", value:1},
        {label:"2", value:2},
        {label:"3", value:3},
        {label:"4", value:4},
        {label:"5", value:5},
        {label:"6", value:6},
        {label:"7", value:7},
        {label:"8", value:8},
        {label:"9", value:9},
        {label:"10", value:10},
    ]

  useEffect(() => {

    api.get(`api/controllers/details/pump`, {
        headers:{
            "content-type" : "application/json",
            "controllerId":params.id
        },
        params:{
        controllerId: params.id,
        },
        withCredentials:true
      })
      .then(function (response) { 

        let pumpControl = pumpControlOutput.filter((item) => item.value == response.data.dataResult.pumpControlOutput)
        let findPumpStartDelay = pumpStartDelay.filter((item) => item.value == response.data.dataResult.pumpStartDelay)
        setSelectedPumpControl({label:pumpControl[0].label, value:pumpControl[0].value})
        setStartPumpDelay({label:findPumpStartDelay[0].label, value:findPumpStartDelay[0].value})

        setValue("PumpControlOutput", selectedPumpControl)
        setValue("PumpStartDelay", selectedPumpStartDelay)
      })
      .catch(function (error) {
        console.log(error);
    });

  }, [])

  const onSubmit = data => {

    setLoadingBtn(true);
    api.post("/api/controllers/details/pump/update", {
        PumpControlOutput: selectedPumpControl.value,
        PumpStartDelay: selectedPumpStartDelay.value,
        ControllerId: params.id
    })
    .then(function (response) {
      setLoadingBtn(false)
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
      setLoadingBtn(false)
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
  
    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
   
  }

    return(
      isAuth ?
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-xs-12 col-lg-5 col-md-5">

                    <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.PumpControl}</Form.Label>
                        <Controller
                            name="PumpControlOutput"                                
                            control={control}
                            rules={{ required: translatedForm.Required }}
                            render={({ field:{value} }) => (
                            <Select
                                onChange={(item) => {
                                    console.log(item)
                                    setSelectedPumpControl({label:item.label, value:item.value})
                                    setValue("PumpControlOutput", selectedPumpControl)
                                }}
                                value={selectedPumpControl}
                                options={pumpControlOutput}          
                                placeholder={translatedForm.SelectFromList}
                                classNamePrefix="select"
                            />
                            )}
                        />
                        {errors.PumpControlOutput && 
                            <span className="text-danger font-size-13">{errors.PumpControlOutput.message}</span>
                        }
                    </Form.Group>

                    {
                      controllerInfo.type != 3
                      ?
                      <Form.Group className="mb-3">
                      <Form.Label className="text-black">{translated.TurningPumpOn}</Form.Label>
                          <input type="text" disabled={true} className="form-control" value={translated.PumpConnect} />
                      </Form.Group>
                      : null
                    }

                    <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.PumpSwitchDelay}</Form.Label>
                    
                        <Controller
                            name="PumpStartDelay"                                
                            control={control}
                            rules={{ required: translated.Required }}
                            render={({ field: {value} }) => (
                            <Select
                                onChange={(item) => {
                                    setStartPumpDelay({label:item.label, value:item.value})
                                    setValue("PumpStartDelay", selectedPumpStartDelay)
                                }}
                                value={selectedPumpStartDelay}
                                options={pumpStartDelay}          
                                placeholder={translated.SelectFromList}
                                classNamePrefix="select"
                            />
                            )}
                        />
                        {errors.PumpStartDelay && 
                            <span className="text-danger font-size-13">{errors.PumpStartDelay.message}</span>
                        }   

                    </Form.Group>

                    <div className="form-group mt-3">
                        <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                            {loadingBtn && (
                                <Spinner animation="border" variant="light" 
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                            {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                        </button>
                    </div>
                </div>
            </div>
        </Form>
      </>
    :
    <Redirect to='/login'/>
    );
}