import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Alert, ProgressBar, Tab, Form, Offcanvas, Table, Spinner, Card, Dropdown, Modal, DropdownButton, Tooltip, OverlayTrigger, Nav} from 'react-bootstrap';
import { AuthContext } from '../../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import moment from 'moment/moment';
import api from '../../../utils/axiosInterceptions';
import * as signalR from "@microsoft/signalr"

export const UpdateFromServer = ({
    controllerInfo, 
    translated, 
    translatedForm, 
    setModalFirmwareUpdateServer, 
    modalFirmwareUpdateServer}) => 
{
    const [loadingBtn, setLoadingBtn] = useState({
        check:false,
        update:false,
        download:false
    })
    const [showButton, setShowButton] = useState({
        download:false,
        update:false
    })
    const [buttonDisable, setButtonDisable] = useState({
        check: false
    })
    const [currentVersions, setCurrentVersions] = useState({
        motherboardCurrent:'...',
        indicatorCurrent:'...',
        keyboardCurrent:'...'
    })
    const [serverVersions, setServerVersions] = useState({
        motherboardCurrent:'...',
        motherboardServer:'...',
        indicatorCurrent:'...',
        indicatorServer:'...',
        keyboardCurrent:'...',
        keyboardServer:'...'
    })
    const [progressBar, setProgressBar] = useState(0)
    const [signalrConnection, setSignalrConnection] = useState({})
    const [jobId, setJobId] = useState('')

    useEffect(() => {
        setShowButton({
            download:false,
            update:false
        })
        setProgressBar(0)
    }, [])

    const getUuid = () => {
        api.get("/api/jobs/create-uuid", {
            withCredentials:true
        })
        .then(function (response) 
        {
            console.log("uuid")
            console.log(response)
            checkUpdateMotherboard(response.data.jobId)     
            setJobId(response.data.jobId)          
        })
        .catch(function (error) {
            setLoadingBtn({check:false})
            setButtonDisable({check:false})
            console.log(error);
        });
    }

    const checkUpdateMotherboard = (jobId) => {
        console.log(jobId)
        setLoadingBtn({check:true})
        setButtonDisable({check:true})
        api.get("/api/controllers/details/firmwares/check-update", {
            headers:{
              "content-type" : "application/json",
              "id":controllerInfo.id,
              "jobId": jobId
            },
            withCredentials:true
        })
        .then(function (response) 
        {
            console.log(response)
            setLoadingBtn({check:false})
            setButtonDisable({check:false})
            console.log("motherboard")
            console.log(response.data.message)
            setServerVersions({
                motherboardServer: response.data.listDatas[0],
                indicatorServer: response.data.listDatas[1],
                keyboardServer: response.data.listDatas[2],
            })

            setCurrentVersions({
                indicatorCurrent: response.data.listDatas[3],
                keyboardCurrent: response.data.listDatas[4],
                motherboardCurrent: response.data.listDatas[5]
            })

            setShowButton({update:true})                  
        })
        .catch(function (error) {
            setLoadingBtn({check:false})
            setButtonDisable({check:false})
            displayToast(error.response.data.message, error.response.data.isSuccess)
            console.log(error);
        });
    }

    const update = () => 
    {
        let connection = new signalR.HubConnectionBuilder()
            .withUrl("/firmware-server-update-progress")
            .withAutomaticReconnect()
            .build();

        connection.on("progress", (data, jobId, messages, isNotification, notificationMessage) => {

            if(isNotification)
            {
                displayToast(notificationMessage)
            }
            else
            {
                setProgressBar(data)

                setServerVersions({
                    motherboardServer: messages[0],
                    indicatorServer: messages[1],
                    keyboardServer: messages[2],                
                })
            }

            if(data == 100)
            {
                setLoadingBtn({update:false})
                showButton({update:false})
            }
            
        })

        connection.start()
            .then(() => connection.invoke("AssociateJob", jobId))
            .catch(err => console.error(err.toString()));

        setLoadingBtn({update:true})
        setButtonDisable({check:true})

        api.post("/api/controllers/details/firmwares/update", {
            Id: controllerInfo.id,
            JobId: jobId,
            withCredentials:true
        })
        .then(function (response) 
        {
            setLoadingBtn({update:false})
            setButtonDisable({check:false})
            setServerVersions({
                motherboardServer: response.data.listDatas[0],
                indicatorServer: response.data.listDatas[1],
                keyboardServer: response.data.listDatas[2]
            })
            setCurrentVersions({
                indicatorCurrent: response.data.listDatas[3],
                keyboardCurrent: response.data.listDatas[4],
                motherboardCurrent: response.data.listDatas[5]
            })
        })
        .catch(function (error) {
            console.log(error);
            setLoadingBtn({update:false})
            setButtonDisable({check:false})
        });
    }

    const displayToast = (message) => 
    {
        toast.info(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    }

    return(
        <Modal backdrop="static" keyboard={false} size="lg" show={modalFirmwareUpdateServer} onHide={() => setModalFirmwareUpdateServer(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="text-black">{translated.UpdateFirmwareFromServer}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{"padding":"none"}}>

        <Alert variant='warning'>Проверка и установка версий прошивок может занимать несколько минут и происходит в автоматическом режиме. После обновления контроллер будет перезагружен и потребуется заново считать настройки</Alert>

            <button type="button" onClick={() => getUuid()} 
                disabled={buttonDisable.check} 
                className="btn btn-warning btn-sm text-black"
            >
                {loadingBtn.check && (
                    <Spinner animation="border" variant="dark" 
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {loadingBtn.check && <span>{translatedForm.CheckUpdate}</span>}
                {!loadingBtn.check && <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-refresh">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
                        <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
                    </svg>
                    {translatedForm.CheckUpdate}</span>
                }
            </button>

            {
                showButton.update
                ?
                <button type="button" 
                    disabled={loadingBtn.update} 
                    className="btn btn-success btn-sm mx-3"
                    onClick={() => update()}
                >
                    {loadingBtn.update && (
                        <Spinner animation="border" variant="light" 
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                        />
                    )}
                    {loadingBtn.update && <span>{translatedForm.Update}</span>}
                    {!loadingBtn.update && <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-cloud-download">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4" />
                            <path d="M12 13l0 9" />
                            <path d="M9 19l3 3l3 -3" />
                        </svg>
                        {translatedForm.Update}</span>
                    }
                </button>
                : null
            }
            <div className="col-xs-12 col-lg-12 col-md-12 mt-2">
                <ProgressBar animated now={progressBar} variant="success" className="mb-2" label={`${progressBar}%`} />
            </div>

            <Table striped bordered hover className="mt-2">
                <thead>
                    <tr>
                        <th>Модуль</th>
                        <th>Текущая версия</th>
                        <th>Версия на серверe и описание</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Плата</td>
                        <td>{currentVersions.motherboardCurrent}</td>
                        <td>{serverVersions.motherboardServer}</td>
                    </tr>
                    <tr>
                        <td>Индикатор</td>
                        <td>{currentVersions.indicatorCurrent}</td>
                        <td>{serverVersions.indicatorServer}</td>
                    </tr>
                    <tr>
                        <td>Клавиатура</td>
                        <td>{currentVersions.keyboardCurrent}</td>
                        <td>{serverVersions.keyboardServer}</td>
                    </tr>
                </tbody>
            </Table>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    )
}