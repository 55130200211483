import React, { useState, useEffect, useContext, useMemo, Redirect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Offcanvas, Form, Badge, Spinner, Alert, ProgressBar} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller} from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import Select from 'react-select';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import './../../boxicons.css';
import { TranslateStation } from '../../lang/stations/translateStation';
import {FormLocale} from '../../lang/form';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { CurrentSelectLanguage } from '../../helpers/language';
import api from '../../utils/axiosInterceptions';

export const Stations = () => {

  const router = useHistory();
  const {isAuth} = useContext(AuthContext);
  const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
  const [showFormAdd, setShowFormAdd] = useState(false)
  const [listOfStations, addStationInList] = useState({})
  const [listTimezone, setListTimezone] = useState([])
  const [listOfCompanies, setListCompanies] = useState([])
  const [countStations, setCountStations] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const translated = useLittera(TranslateStation);
  const translatedForm = useLittera(FormLocale);
  const methods = useLitteraMethods();
  const [permission, setPermission] = useState({
    read:false,
    create:false,
    fullAccess:false
  })
  const [loadingData, setLoadingData] = useState({
    grid:false,
    button:false
  });

  const handleShow = () => {
    setShowFormAdd(true)
    getCompanies()
  };

  useEffect(() => {
    methods.setLocale(CurrentSelectLanguage);
    getStations()
  }, []);

  const handleClose = () => setShowFormAdd(false);

  const onSubmit = data => {

    console.log(data)
      setLoadingData({button:true})
      api.post('/api/stations/create', {
        Name: data.Name,
        CompanyId: data.Company.id,
        Description: data.Description,
        SerialNumber: data.SerialNumber,
        OrderNumber: data.OrderNumber,
        Longtitude: data.Longtitude,
        Lantitude: data.Lantitude,
        Timezone: data.Timezone
      })
      .then(function (response) {
        
        displayToast(response.data.message, response.data.isSuccess)
        setLoadingData({button:false})
        addStationInList(response.data.listDatas)
        setCountStations(response.data.listDatas.length);
        reset({
          Name:'',
          Description:'',
          SerialNumber:'',
          OrderNumber:'',
          Longtitude:'',
          Lantitude:''
        })
      })
      .catch(function (error) {
        console.log(error);
        setLoadingData({button:false})
      });
  }

  const displayToast = (message, status) => {

      if(status){
        toast.success(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });
    
      } else {
        toast.error(message, {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"dark"
        });
      }
  }

  const getCompanies = () => {

      api.get('/api/company/all', {
          withCredentials:true
      })
      .then(function (response) {
          

          const addCompany = response.data.listDatas.map(item => ({
              ...item,
              value: item.id,
              label: item.name
          }))

          setListCompanies(addCompany)

      })
      .catch(function (error) {
          console.log(error);
      });
  }

  const getStations = () => {

      setLoadingData({grid:true})
      setIsLoading(true)

      api.get('/api/stations/all', {
        withCredentials:true
      })
      .then(function(response){
        console.log("permissions")
        
        setPermission({
          read: response.data.read,
          create: response.data.create,
          fullAccess: response.data.fullAccess
        })
        addStationInList(response.data.listDatas);
        setCountStations(response.data.listDatas.length);
        setLoadingData({grid:false})
        setIsLoading(false)
      })
      .catch(function (error) {
          console.log(error);
      });
  }

  const onDelete = id => {
    console.log(id)
    api.post('/api/stations/details/delete', {
      Id:id,
      withCredentials: true
    })
    .then(function (response) {
      
      displayToast(response.data.message, response.data.isSuccess)
      setLoadingData({button:false})
      addStationInList(response.data.listDatas)
      setCountStations(response.data.listDatas.length);
    })
    .catch(function (error) {
      console.log(error);
      setLoadingData({button:false})
    });
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', //access nested data with dot notation
        header: translated.Name,
      },
      {
        accessorFn: (row) => row.description != null && row.description != '' ? row.description : translated.Empty,
        id: 'description',
        header: translated.Description,
      },
      {
        accessorFn: (row) => row.isDeleted != null 
          ? <Badge bg="success" className="font-size-13">{translated.Active}</Badge> 
          : <Badge bg="danger" className="font-size-13">{translated.Deleted}</Badge>,
        accessorKey: 'isDeleted', //normal accessorKey
        header: translated.Status,
      },
      {
        accessorFn: (row) => row.company.name,
        accessorKey: 'isParent',
        header: translated.Company,
      },
      {
        accessorFn: (row) => 
        <>
          <Button onClick={() => router.push(`/station/${row.id}`)} className="btn btn-light btn-sm">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.125 10H16.875" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11.25 4.375L16.875 10L11.25 15.625" stroke="#000" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
          </Button> 
        </>,
        accessorKey: 'button',
        header: translated.Actions,
      },
    ],
    [],
  );

  return (
    isAuth ?
    <>
    {
      isLoading
      ? <Spinner animation="border" variant="info" />
      :
      <>
      <div className="d-sm-flex align-items-center justify-content-between">
              <h2 className="mb-sm-0 fw-semibold  font-size-18 benzamat-font-bold text-black">
                  {translated.Title}
                  <span className="text-secondary benzamat-font-bold font-size-20"> {countStations}</span>
              </h2>
              {
                permission.create || permission.fullAccess
                ?
                <div className="page-title-right">
                    <Button varian="primary" onClick={handleShow}>
                        <i className="bx bx-plus font-size-16 align-middle me-2 "></i> {translated.Add}
                    </Button>
                </div>
                : ''
              }                   
        </div>
        <div className="row">
        <div className="col-xs-12 col-lg-12 col-md-12 ">         
            <div className="mt-4">
              {
                permission.read || permission.fullAccess
                ?
                <MaterialReactTable 
                  columns={columns} 
                  data={listOfStations}
                  localization={MRT_Localization_EN}
                  enableTopToolbar={false}
                  initialState={{ showColumnFilters: true }}
                  positionToolbarAlertBanner="left"
                  enableRowVirtualization
                  muiTablePaperProps={{
                    elevation: 1,
                  }}
                  muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                      fontWeight: 'bold',
                      color:"#000",
                      backgroundColor:'#F3F3F3',
                      fontFamily: 'BenzamatRegular',
                    },
                  }}
                  muiTableBodyCellProps={{
                    sx: {
                      color:"#000",
                      fontFamily: 'BenzamatRegular',
                    },
                  }}
                  muiTopToolbarProps={{
                    sx: {
                      textAlign:"left"
                    },
                  }}
                />
                :
                <Alert variant="danger">
                  {translated.PermissionDenied}
                </Alert>
              }
            </div>
        </div>
      </div>
      
      <Offcanvas placement="end" show={showFormAdd} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="benzamat-font-bold text-black" style={{fontSize: 22 + 'px', fontWeight:'bold'}}>
              {translated.AddStationTitle}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <div className="dropdown-divider"></div>
          <Offcanvas.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.Name} <sup className="text-danger">*</sup></Form.Label>
                  <Form.Control 
                      {...register("Name", {
                        minLength : {
                          value: 5,
                          message: translatedForm.MinLengthString(5)
                        },
                        maxLength : {
                          value: 100,
                          message: translatedForm.MaxLenghtString(100)
                        },
                        required: translatedForm.Required
                      })}
                      type="text" maxLength="100" isInvalid={errors.Name}  
                      placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                      {errors.Name && 
                      <span className="text-danger font-size-13">{errors.Name.message}</span>
                      }
                  </Form.Group>

                  <Form.Group className="mb-3 mt-3">
                  <Form.Label className="text-black">{translated.Company} <sup className="text-danger">*</sup></Form.Label>
                  
                    <Controller
                        name="Company"                                
                        control={control}
                        rules={{ required: translatedForm.Required }}
                        render={({ field }) => (
                        <Select 
                        {...field}                               
                            isSearchable
                            options={listOfCompanies}                        
                            placeholder={translatedForm.SelectFromList}
                            classNamePrefix="select"
                            error={Boolean(errors.Company)}
                        />
                        )}
                    />
                    {errors.Company && 
                        <span className="text-danger font-size-13">{errors.Company.message}</span>
                    }   

                  </Form.Group>

                  <Form.Group className="mb-3 mt-3">
                    <Form.Label className="text-black">{translated.Timezone} <sup className="text-danger">*</sup></Form.Label>
                    <Form.Control 
                      {...register("Timezone", {
                        minLength : {
                          value: 1,
                          message: translatedForm.MinNumber(1)
                        },
                        maxLength : {
                          value: 2,
                          message: translatedForm.MaxNumber(2)
                        },
                      })}
                      type="number" maxLength="2" isInvalid={errors.Timezone}  
                      placeholder={translatedForm.EnterValue(2)} className="form-control"  />
                      {errors.Timezone && 
                        <span className="text-danger font-size-13">{errors.Timezone.message}</span>
                      }
                  </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="text-black">{translated.Description} </Form.Label>
                  <Form.Control 
                      {...register("Description", {
                        minLength : {
                          value: 5,
                          message: translatedForm.MinLengthString(5)
                        },
                        maxLength : {
                          value: 100,
                          message: translatedForm.MaxLenghtString(100)
                        },
                      })}
                      type="text" maxLength="800" isInvalid={errors.Description}  
                      placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                    {errors.Description && 
                    <span className="text-danger font-size-13">{errors.Description.message}</span>
                    }
                </Form.Group>

                <Form.Group className="mb-3 mt-3">
                <Form.Label className="text-black">{translated.SerialNumber} </Form.Label>
                <Form.Control 
                  {...register("SerialNumber", {
                    minLength : {
                      value: 1,
                      message: translatedForm.MinLengthString(1)
                    },
                    maxLength : {
                      value: 100,
                      message: translatedForm.MaxLenghtString(100)
                    },
                  })}
                  type="number" maxLength="100" isInvalid={errors.SerialNumber}  
                  placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                  {errors.SerialNumber && 
                    <span className="text-danger font-size-13">{errors.SerialNumber.message}</span>
                  }
              </Form.Group>

              <Form.Group className="mb-3 mt-3">
                <Form.Label className="text-black">{translated.OrderNumber} </Form.Label>
                <Form.Control 
                  {...register("OrderNumber", {
                    minLength : {
                      value: 1,
                      message: translatedForm.MinLengthString(1)
                    },
                    maxLength : {
                      value: 100,
                      message: translatedForm.MaxLenghtString(100)
                    },
                  })}
                  type="number" maxLength="100" isInvalid={errors.OrderNumber} 
                   placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                  {errors.OrderNumber && 
                    <span className="text-danger font-size-13">{errors.OrderNumber.message}</span>
                  }
              </Form.Group>

              <Form.Group className="mb-3 mt-3">
                <Form.Label className="text-black">{translated.Longtitude} </Form.Label>
                <Form.Control 
                  {...register("Longtitude", {
                    minLength : {
                      value: 5,
                      message: translatedForm.MinLengthString(5)
                    },
                    maxLength : {
                      value: 100,
                      message: translatedForm.MaxLenghtString(100)
                    },
                  })}
                  type="text" maxLength="100" isInvalid={errors.Longtitude}  
                  placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                  {errors.Longtitude && 
                    <span className="text-danger font-size-13">{errors.Longtitude.message}</span>
                  }
              </Form.Group>

              <Form.Group className="mb-3 mt-3">
                <Form.Label className="text-black">{translated.Langtitude} </Form.Label>
                <Form.Control 
                  {...register("Lantitude", {
                    minLength : {
                      value: 5,
                      message: translatedForm.MinLengthString(5)
                    },
                    maxLength : {
                      value: 100,
                      message: translatedForm.MaxLenghtString(100)
                    },
                  })}
                  type="text" maxLength="100" isInvalid={errors.Lantitude} 
                  placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                  {errors.Lantitude && 
                    <span className="text-danger font-size-13">{errors.Lantitude.message}</span>
                  }
              </Form.Group>
                  
              <div className="form-group mt-3">
              <button type="submit" disabled={loadingData.button} className="btn btn-primary">
                  {loadingData.button && (
                      <Spinner animation="border" variant="light" 
                          as="span"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: "5px" }}
                      />
                  )}
                  {loadingData.button && <span>{translated.Add}</span>}
                  {!loadingData.button && <span>{translated.Add}</span>}
                  </button>
              </div>
              
          </Form>

          </Offcanvas.Body>
      </Offcanvas>
      </>
    }
    </>
    :
    <Redirect to='/login'/>
  );

}
