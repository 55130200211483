import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Spinner, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import './../../boxicons.css';
import api from '../../utils/axiosInterceptions';

export const ControllerValve = ({translated, translatedForm, controllerInfo}) => {

    const router = useHistory()
    const params = useParams();
    const {isAuth} = useContext(AuthContext);
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [selectedValveGate, setSelectedValveGate] = useState({
        label:'',
        value:''
    })
    const [selectedValveDecrease, setSelectedValveDecrease] = useState({
        label:'',
        value:''
    })
    const [loadingBtn, setLoadingBtn] = useState(false)

    const isConnect = [
        {label:translated.Connected, value:1},
        {label:translated.NotConnected, value:0}
    ]

  useEffect(() => {

    api.get(`api/controllers/details/valve`, {
        headers:{
            "content-type" : "application/json",
            "controllerId":params.id
        },
        params:{
        controllerId: params.id,
        },
        withCredentials:true
      })
      .then(function (response) { 
        
        let valveGateType = isConnect.filter((item) => item.value == response.data.dataResult.valveGateControl)
        let valveDecreaseType = isConnect.filter((item) => item.value == response.data.dataResult.valveDecreaseControl)
        setSelectedValveGate({label:valveGateType[0].label, value:valveGateType[0].value})
        setSelectedValveDecrease({label:valveDecreaseType[0].label, value:valveDecreaseType[0].value})

        setValue("ValveGateControl", selectedValveGate)
        setValue("ValveDecreaseControl", selectedValveDecrease)
      })
      .catch(function (error) {
        console.log(error);
    });

  }, [])

  const onSubmit = data => {

    setLoadingBtn(true);
    api.post("/api/controllers/details/valve/update", {
        ValveGateControl: selectedValveGate.value,
        ValveDecreaseControl: selectedValveDecrease.value,
        ControllerId: params.id
    })
    .then(function (response) {
      setLoadingBtn(false)
      displayToast(response.data.message, response.data.isSuccess)
    })
    .catch(function (error) {
      setLoadingBtn(false)
      displayToast(error.response.data.message, error.response.data.isSuccess)
    });
  }

  const displayToast = (message, status) => {

    if(status){
      toast.success(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
  
    } else {
      toast.error(message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme:"dark"
      });
    }
   
  }

    return(
      isAuth ?
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-xs-12 col-lg-5 col-md-5">
                
                    <Form.Group className="mb-3">
                    <Form.Label className="text-black">{translated.ShutOffValve}</Form.Label>
                    
                        <Controller
                            name="ValveGateControl"                                
                            control={control}
                            rules={{ required: translatedForm.Required}}
                            render={({ field: {value} }) => (
                            <Select
                                onChange={(item) => {
                                    setSelectedValveGate({label:item.label, value:item.value})
                                    setValue("ValveGateControl", selectedValveGate)
                                }}
                                value={selectedValveGate}
                                options={isConnect}          
                                placeholder={translatedForm.SelectFromList}
                                classNamePrefix="select"
                            />
                            )}
                        />
                        {errors.ValveGateControl && 
                            <span className="text-danger font-size-13">{errors.ValveGateControl.message}</span>
                        }   

                    </Form.Group>

                    {
                        controllerInfo.type != 3
                        ?
                        <>
                            <Form.Group className="mb-3">
                            <Form.Label className="text-black">{translated.ShutOffControlValve}</Form.Label>
                                <input type="text" disabled={true} className="form-control" value={translated.Relay2} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                            <Form.Label className="text-black">{translated.TurnShutOffValve}</Form.Label>
                                <input type="text" disabled={true} className="form-control" value={translated.Connected} />
                            </Form.Group>
                        </>
                        : null
                    }

                    
                    <div className="form-group mt-3">
                        <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                            {loadingBtn && (
                                <Spinner animation="border" variant="light" 
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                            {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                            {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                        </button>
                    </div>                
                </div>

                <div className="col-xs-12 col-lg-5 col-md-5">
                    
                    <Form.Group className="mb-3 ">
                    <Form.Label className="text-black">{translated.ReducingValve}</Form.Label>
                    
                        <Controller
                            name="ValveDecreaseControl"                                
                            control={control}
                            rules={{ required: translatedForm.Required }}
                            render={({ field: {value} }) => (
                            <Select
                                onChange={(item) => {
                                    setSelectedValveDecrease({label:item.label, value:item.value})
                                    setValue("ValveDecreaseControl", selectedValveDecrease)
                                }}
                                value={selectedValveDecrease}
                                options={isConnect}          
                                placeholder={translatedForm.SelectFromList}
                                classNamePrefix="select"
                            />
                            )}
                        />
                        {errors.ValveDecreaseControl && 
                            <span className="text-danger font-size-13">{errors.ValveDecreaseControl.message}</span>
                        }   

                    </Form.Group>

                    {
                        controllerInfo.type != 3
                        ?
                        <>
                            <Form.Group className="mb-3">
                            <Form.Label className="text-black">{translated.ReducingControlValve}</Form.Label>
                                <input type="text" disabled={true} className="form-control" value={translated.Relay3} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                            <Form.Label className="text-black">{translated.TurnReducingValve}</Form.Label>
                                <input type="text" disabled={true} className="form-control" value={translated.Connected} />
                            </Form.Group>
                        </>
                        : null
                    }

                </div>
            </div>
        </Form>
      </>
    :
    <Redirect to='/login'/>
    );
}