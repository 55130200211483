import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Card, Spinner, Nav} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import './../../../boxicons.css';
import { LivenkaBs2 } from './Bs2/LivenkaBs2';
import { LivenkaBs2v2 } from './Bs2v2/LivenkaB2v2';

export const Livenka = ({translated, translatedForm, smartData, controllerInfo}) => {
    return(
        <>
            {
                controllerInfo.type != 3
                ? <LivenkaBs2 translated={translated} translatedForm={translatedForm} smartData={smartData} controllerInfo={controllerInfo} />
                : <LivenkaBs2v2 translated={translated} translatedForm={translatedForm} smartData={smartData} controllerInfo={controllerInfo} />
            }
        </>
    );
}