import React from "react";
import { useLittera } from "@assembless/react-littera";

export const TranslateLoginPage = {

    Password: {
        en_US: "Password",
        ru_RU: "Пароль"
    },
    EnterPassword: {
        en_US: "Enter password",
        ru_RU: "Введите пароль"
    },
    ForgotPassword: {
        en_US: "Forgot password",
        ru_RU: "Забыли пароль?"
    },
    Login: {
        en_US: "Welcome",
        ru_RU: "Вход в личный кабинет"
    },
    PleaseSignIn: {
        en_US: "To get started, please sign in",
        ru_RU: " "
    },
    Continue: {
        en_US: "Continue",
        ru_RU: "Войти в систему"
    },
    Process: {
        en_US: "Processing",
        ru_RU: "Выполняется вход"
    },
    
};