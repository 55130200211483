import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Spinner, Offcanvas, Dropdown, DropdownButton, Card, Tooltip, OverlayTrigger, Nav} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import api from '../../../utils/axiosInterceptions';

export const FormAddParam = ({translated, translatedForm, id}) => {
    
    const { control, register, handleSubmit, reset, setValue, getValues, formState, formState: { errors }} = useForm()
    const [loadingBtn, setLoadingBtn] = useState(false)

    const listValues = [
        {label:"1024", value:1024},
        {label:"4096", value:4096}
    ]

    const submitPostRequest = (data) => {
        setLoadingBtn(true)
        api.post('/api/controllers/details/level-meters/details/params/update', {
            Id: id,
            Param2: data.Param2,
            Param3: Number(data.Param3),
            Param5: data.Param5.value,
            Param6: Number(data.Param6),
            ChartTableSize: data.ChartTableSize,
            withCredentials:true
        })
        .then(function (response) {
            console.log("critical level")
            
            setLoadingBtn(false)
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingBtn(false)
        });
    }

    const onSubmit= data => {
        console.log(data)
        let success_status = false

        if(data.Param5.value == 4096) {
            if(data.Param6 < 640) {
                displayToast(translated.MessageErrorLengthDut4096, false)
            } else {
                success_status = true
            }
        } else if(data.Param5.value == 1024) {
            if(data.Param6 < 160) {
                displayToast(translated.MessageErrorLengthDut1024, false)
            } else {
                success_status = true
            }
        } else if(data.ChartTableSize < 100 || data.ChartTableSize > 400 || data.ChartTableSize == '' || data.ChartTableSize == 0) {
            displayToast(translated.MessageErroCalibrationSize, false)
        }
        else {
            console.log("submit request")
            success_status = true;
        }

        if(success_status) {
            submitPostRequest(data)
        }
    }
        
    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    
    }

    useEffect(() => {        
        api.get("/api/controllers/details/level-meters/details/params", {
            headers:{
              "content-type" : "application/json",
              "levelMeterId":id
            },
            params:{
                levelMeterId: id,
            },
            withCredentials:true
          })
          .then(function (response) {
            console.log("param data level meter")
            

            let param5 = listValues.filter((item) => item.value === response.data.dataResult.param5)
            setValue("Param5", param5)
            
            setValue("Param2", response.data.dataResult.param2)
            setValue("Param3", response.data.dataResult.param3)
            setValue("ChartTableSize", response.data.dataResult.chartTableSize)
            setValue("Param6", response.data.dataResult.param6)
          })
          .catch(function (error) {
            console.log(error);
          });
    }, [])

    return (        
    <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
            <Form.Label className="text-black">{translated.RelativeUnits}</Form.Label>
            <Form.Control 
                {...register("Param2")}
                type="text" readOnly="true" isInvalid={errors.Param2} className="form-control"  />
            {errors.Param2 && 
                <span className="text-danger font-size-13">{errors.Param2.message}</span>
            }
        </Form.Group>

        <Form.Group className="mb-3 mt-3">
            <Form.Label className="text-black">{translated.OffsetFromBottomSection}</Form.Label>
            <Form.Control 
                {...register("Param3", {
                    required : translatedForm.Required
                })}
                type="text" isInvalid={errors.Param3} className="form-control"  />
            {errors.Param3 && 
                <span className="text-danger font-size-13">{errors.Param3.message}</span>
            }
        </Form.Group>

        <Form.Group className="mb-3 mt-3">
            <Form.Label className="text-black">{translated.CalibrationChartSize}</Form.Label>
            <Form.Control 
                {...register("ChartTableSize")}
                type="text" readOnly="true" isInvalid={errors.Name}  className="form-control"  />
            {errors.ChartTableSize && 
                <span className="text-danger font-size-13">{errors.ChartTableSize.message}</span>
            }
        </Form.Group>

        <Form.Group className="mb-3 mt-3">
            <Form.Label className="text-black">{translated.MeasuringRange}</Form.Label>
            <Controller
                name="Param5"                                
                control={control}
                rules={{ required: translatedForm.Required }}
                render={({ field: {value} }) => (
                <Select
                    value={value}
                    options={listValues}  
                    placeholder={translatedForm.SelectFromList}
                    classNamePrefix="select"
                    onChange={(item) => {
                        setValue("Param5", item)
                        let param6 = getValues("Param6")

                        if(param6 == 0 || param6 == '') {
                            setValue("Param2", 0)
                        } else {
                            setValue("Param2", Math.ceil((item.value / param6) * 10))
                        }
                    }}
                />
                )}
            />
            {errors.Param5 && 
                <span className="text-danger font-size-13">{errors.Param5.message}</span>
            }  
        </Form.Group>

        <Form.Group className="mb-3 mt-3">
            <Form.Label className="text-black">{translated.LengthDUT}</Form.Label>
            <Form.Control 
            {...register("Param6", {required : translatedForm.Required})}
                type="text" maxLength="500" 
                isInvalid={errors.Param6}
                className="form-control"
                onChange={(e) => {
                    console.log(e.target.value)
                    let param5 = getValues("Param5")

                    if(e.target.value == '' || e.target.value == 0) {
                        setValue("Param2", 0)
                    } else {
                        setValue("Param2", Math.ceil((param5.value / e.target.value) * 10))
                    }
                }}
                
                />
            {errors.Param6 && 
                <span className="text-danger font-size-13">{errors.Param6.message}</span>
            }
        </Form.Group>

        <div className="form-group mt-3">
            <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                {loadingBtn && (
                    <Spinner animation="border" variant="light" 
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: "5px" }}
                    />
                )}
                {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
            </button>
        </div>
    </Form>
    )
}