import React, { useContext, useEffect, useState } from 'react';
import CatalogSingle from '../single';

export const TypeAuth = () => {

    const TYPE_AUTH_VALUES_URL = "api/type-auths/all"
    const TYPE_AUTH_VALUES_CREATE = "api/type-auths/create"
    
    const data = {
        all_url: TYPE_AUTH_VALUES_URL,
        create_url: TYPE_AUTH_VALUES_CREATE,
        name: "Типы авторизации"
    }

    return (
        <>
            <CatalogSingle {...data} />
        </>
    )
}