import React, { useState, useMemo, Component } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from './../../components/Layout';
import { Button, Card, Table, Badge, Modal, ListGroup, Accordion, DropdownButton, Dropdown, ProgressBar, Tabs, Tab, Form, Spinner, Nav} from 'react-bootstrap';
import { YMaps, Map, Placemark, GeoObject, TypeSelector, ListBoxItem, ListBox } from '@pbe/react-yandex-maps';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import moment from 'moment/moment';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton'
import tank100 from "../../img/tanks/black/64/barrel-black-green-100.png";
import tank90 from "../../img/tanks/black/64/barrel-black-green-090.png";
import tank80 from "../../img/tanks/black/64/barrel-black-green-080.png";
import tank70 from "../../img/tanks/black/64/barrel-black-green-070.png";
import tank60 from "../../img/tanks/black/64/barrel-black-green-060.png";
import tank50 from "../../img/tanks/black/64/barrel-black-green-050.png";
import tank40 from "../../img/tanks/black/64/barrel-black-yellow-040.png";
import tank30 from "../../img/tanks/black/64/barrel-black-yellow-030.png";
import tank20 from "../../img/tanks/black/64/barrel-black-red-020.png";
import tank10 from "../../img/tanks/black/64/barrel-black-red-010.png";
import tank_black from "../../img/tanks/black/64/barrel-black.png";
import { DashboardTranslate } from '../../lang/dashboard';
import { useLittera, useLitteraMethods } from "@assembless/react-littera";
import { CurrentSelectLanguage } from '../../helpers/language';
import { TranslateSection } from '../../lang/stations/translateSection';
import api from '../../utils/axiosInterceptions';
import { Chart as ChartJS } from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2'

const Dashboard = () => {

    const router = useHistory();
    const [fillings, setFillings] = useState([])
    const [listStations, setListStations] = useState([])
    const [placeMarks, setPlaceMarks] = useState([])
    const translated = useLittera(DashboardTranslate);
    const translatedSection = useLittera(TranslateSection);
    const [centerMap, setCenterMap] = useState([0, 0])
    const methods = useLitteraMethods();
    const [isLoading, setIsLoading] = useState(true)
    const [modalSection, setModalSection] = useState(false)
    const [dashboardData, setDashboardData] = useState({
        countControllers:0,
        countControllerActiveLicense:0,
        countControllerDisableLicense:0,
        countControllerConnect:0,
        countControllerDisconnect:0,
        countUsers:0,
        countTransports:0,
        countActiveCompanies:0,
        countDisableCompanies:0,
        sumDayLitres:0,
        sumMonthLitres:0,
        sumMonthPouringLitres:0,
        sumDayPouringLitres:0
    })
    const [bar, setBar] = useState({
        labels:[],
            datasets: [
            {
                data: [],
                backgroundColor: 'rgba(255, 99, 132, 1)',
            },
        ],
    })
    const [barPouring, setBarPouring] = useState({
        labels:[],
            datasets: [
            {
                data: [],
                backgroundColor: 'rgba(255, 99, 132, 1)',
            },
        ],
    })
    const [selectedSection, setSelectedSection] = useState({
        name:'',
        fuel:null,
        fuelBalance:0,
        allVolume:0,
        currentVolume:0,
    })

    const placeMark = {
        geometry: [56.848217, 53.236675],
        properties:{
            balloonContentBody:"",
        },
        options:{
            iconLayout: 'default#image',
            iconImageHref: tank_black,
            iconImageSize: [64, 64],
        }
    }

    const columns = useMemo(
        () => [
          {
            accessorFn: (row) => row.firstname != "" && row.lastname != "" && row.firstname != null && row.lastname != null
                ? row.firstname + ' ' + row.lastname
                : "Не указано",
            id: 'userName',
            header: translated.User,
          },
          {
            accessorFn: (row) => moment(row.fillingDateTime).format("DD.MM.YYYY HH:mm"),
            id: 'date',
            header: translated.FillingDate,
          },
          {
            accessorFn: (row) => row.fuelName != "" && row.fuelName != null ? row.fuelName : translated.Unknow,
            id: 'fuel',
            header: translated.Fuel,
          },
          {
            accessorFn: (row) => row.countLitre,
            id: 'liters',
            header: translated.Liters,
          },
          {
            accessorFn: (row) => row.controllerName,
            id: 'controllerName',
            header: translated.Controller,
          }
        ],
    [],
    );

    const getProgressBar = (volume, fuel, section) => {
                        
        let success = `<span style="font-size:14px;color:#000;display:block;margin-top:10px"><strong>${section} (${fuel})</strong></span>`+
        `<div style="margin-top:5px;width:100%;background-color:#e0e0e0;padding:1px;border-radius:50px;text-align:center;font-weight:bold;color:#fff">`+ 
            `<span style="width:${volume}%;padding:1px;display:block;background-color:#238B49;border-radius:50px;">${volume}%</span>` +
        `</div>`;

        let danger = `<span style="font-size:14px;color:#000;display:block;margin-top:10px"><strong>${section} (${fuel})</strong></span>`+
        `<div style="margin-top:5px;width:100%;background-color:#e0e0e0;padding:1px;border-radius:50px;text-align:center;font-weight:bold;color:#fff">`+ 
            `<span style="width:${volume}%;padding:1px;display:block;background-color:#BE2F33;border-radius:50px;">${volume}%</span>` +
        `</div>`;

        let warning = `<span style="font-size:14px;color:#000;display:block;margin-top:10px"><strong>${section} (${fuel})</strong></span>`+
        `<div style="margin-top:5px;width:100%;background-color:#e0e0e0;padding:1px;border-radius:50px;text-align:center;font-weight:bold;color:#fff">`+ 
            `<span style="color:#000;width:${volume}%;padding:1px;display:block;background-color:#FF8B00;border-radius:50px;">${volume}%</span>` +
        `</div>`;

        let none = `<span style="font-size:14px;color:#000;display:block;margin-top:10px"><strong>${section} (${fuel})</strong></span>`+
        `<div style="margin-top:5px;width:100%;background-color:#e0e0e0;padding:1px;border-radius:50px;text-align:center;font-weight:bold;color:#fff">`+ 
            `<span style="color:#000;width:${100}%;padding:1px;display:block;background-color:#e0e0e0;border-radius:50px;">0%</span>` +
        `</div>`;

        let html = ''
        if(volume >= 50 && volume <= 100){
            html += success;
        }

        if(volume >= 30 && volume <= 49)
        {
            html += warning;
        }

        if(volume < 30 && volume > 0)
        {
            html += danger;
        }

        if(volume == 0)
        {
            html += none;
        }

        return html;
    }

    const getTankLevelImage = (balance) => {
        
        if(balance == 100) {
            return tank100
        } else if(balance < 100 && balance >= 90) {
            return tank90
        } else if(balance < 90 && balance >= 80) {
            return tank80
        } else if(balance < 80 && balance >= 70) {
            return tank70
        } else if(balance < 70 && balance >= 60) {
            return tank60
        } else if(balance < 60 && balance >= 50) {
            return tank50
        } else if(balance < 50 && balance >= 40) {
            return tank40
        } else if(balance < 40 && balance >= 30) {
            return tank30
        } else if(balance < 30 && balance >= 20) {
            return tank20
        } else {
            return tank10
        } 

    }

    const user = () => {
        let token = localStorage.getItem("accessToken")
        api.get('/api/users/dashboard', {
            withCredentials:true
        })
        .then(function (response) {
            console.log("dashboard")
            console.log(response)

            setDashboardData({
                countControllers:response.data.dataResult.countControllers,
                countControllerActiveLicense:response.data.dataResult.countControllerActiveLicense,
                countControllerDisableLicense:response.data.dataResult.countControllerDisableLicense,
                countControllerConnect:response.data.dataResult.countControllerConnect,
                countControllerDisconnect:response.data.dataResult.countControllerDisconnect,
                countUsers:response.data.dataResult.countUsers,
                countTransports: response.data.dataResult.countTransports,
                countActiveCompanies: response.data.dataResult.countActiveCompanies,
                countDisableCompanies: response.data.dataResult.countDisableCompanies,
                sumDayLitres: response.data.dataResult.sumDayLitres,
                sumMonthLitres: response.data.dataResult.sumMonthLitres,
                sumDayPouringLitres: response.data.dataResult.sumDayPouringLitres,
                sumMonthPouringLitres: response.data.dataResult.sumMonthPouringLitres
            })

            let array_donut_series = []
            let array_bar_labels = []

            if(response.data.dataResult.fillingGraphics.length != 0)
            {
                response.data.dataResult.fillingGraphics.map((item) => {
                    array_donut_series.push(item.sumLitres)
                    array_bar_labels.push(item.date)
                })
                
                setBar(
                    {
                        labels:array_bar_labels,
                        datasets: [
                        {
                            label:"Литры",
                            data: array_donut_series,
                            backgroundColor: 'rgba(0, 70, 128, 1)',
                        },
                        ],
                    }
                )
            }

            let array_donut_pouring_series = []
            let array_bar_pouring_labels = []

            if(response.data.dataResult.pouringGraphics.length != 0)
            {
                response.data.dataResult.pouringGraphics.map((item) => {
                    array_donut_pouring_series.push(item.sumLitres)
                    array_bar_pouring_labels.push(item.date)
                })
                
                setBarPouring(
                    {
                        labels:array_bar_pouring_labels,
                        datasets: [
                        {
                            label:"Литры",
                            data: array_donut_pouring_series,
                            backgroundColor: 'rgba(0, 70, 128, 1)',
                        },
                        ],
                    }
                )
            }

            setListStations(response.data.dataResult.stations)
            setFillings(response.data.dataResult.fillings)
            let stations_with_sections = []
            if(response.data.dataResult.stations.length != 0)
            {                
                response.data.dataResult.stations.map(item => {                

                    let html_baloon = ''

                    if(item.sections != null)
                    {
                        item.sections.map((stat => {  
                            html_baloon += getProgressBar(stat.fuelBalance.toFixed(2), stat.fuel.name, stat.name)                                           
                        }))
                    }

                    let station = {
                        geometry: [item.lantitude, item.longtitude],
                        properties:{
                             // Зададим содержимое заголовка балуна.
                            balloonContentHeader: item.name,
                            // Зададим содержимое основной части балуна.
                            balloonContentBody: html_baloon,

                            // Зададим содержимое нижней части балуна.
                            balloonContentFooter: `<span style="font-size:14px;color:gray;font-weight:bold">${item.description}</span>`,
                            // Зададим содержимое всплывающей подсказки.
                            hintContent: item.description
                        },
                        options:{
                            iconLayout: 'default#image',
                            iconImageHref: getTankLevelImage(item.currentFuelVolume),
                            iconImageSize: [35, 35],
                        }
                    }
                    stations_with_sections.push(station)

                })

                setPlaceMarks(stations_with_sections)
                setCenterMap([response.data.dataResult.stations[0].lantitude, response.data.dataResult.stations[0].longtitude ])
            }

            setIsLoading(false)
        })
        .catch(function (error) {
            console.log(error);
        });  
    }

    React.useEffect(() => {
        methods.setLocale(CurrentSelectLanguage)
        user()
    }, [])

    const optionsTest = {
        responsive: true,
        animation:false,
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true
              },
              mode: 'xy',
            }
          },
        },
      };
     

    return (
        <>
            <div className="row">
            <div className="col-xs-12 col-lg-12 col-md-12">
                <h3 className="benzamat text-black">
                    {translated.StationOnTheMap}
                    <span className="text-secondary benzamat-font-bold font-size-20"> {listStations.length}</span>
                </h3>
            </div>
            
            <div className="col-xs-12 col-md-8 col-lg-8">
            {
                isLoading
                ?
                <Skeleton count={1} height={350}/>
                :
                <YMaps query={{ lang: 'en_RU' }}>
                    <Map width="100%" height="350px" 
                        state={{
                            center: centerMap,
                            zoom: 9,
                            controls: ["zoomControl", "fullscreenControl"],
                        }}
                        modules={["control.ZoomControl", "control.FullscreenControl", "geoObject.addon.hint", "geoObject.addon.balloon"]}
                    >
                    <TypeSelector options={{ float: "right" }} />
                    {
                        placeMarks.length != 0
                        ?
                        placeMarks.map((item, index) => (
                            <Placemark key={index} {...item}/>
                        ))
                        : null
                    }
                    </Map>
                </YMaps>
            }
                
            </div>
            <div className="col-xs-12 col-md-4 col-lg-4" style={{overflowY:"scroll", height:"350px"}}>
            {
                isLoading
                ?
                <Skeleton count={1} height={350}/>
                :
                <ListGroup >
                {
                    listStations.length != 0
                    ?
                    listStations.map((item) => (
                        <ListGroup.Item >
                            <span className="font-size-16">
                                <strong>{item.name}</strong>
                            </span>
                            <p className="font-size-14">{item.description}</p>
                            
                            {                            
                                <Accordion defaultActiveKey={item.sections.map((i) => i.id)} alwaysOpen>
                                    {
                                        item.sections.map((section) => (
                                            <>
                                            <Accordion.Item eventKey={section.id}>
                                                <Accordion.Header style={{"color":"#000","fontSize":"14px"}}>{section.name} ({section.fuel.name})</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="form-group">
                                                            <span className="d-block">{translated.Fuel}: {section.fuel.name}</span>
                                                            <span className="d-block">{translated.Volume}: {section.volume}</span>
                                                            <span className="d-block">{translated.CurrentVolume}: {section.fuelLitreBalance.toFixed(2)}</span>
                                                            <span className="d-block">{translated.Occupancy}: {section.fuelBalance.toFixed(2)}%</span>
                                                        </div>
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                            </>
                                        ))                              
                                    }
                                </Accordion>
                            }
                        
                            <Dropdown className="mt-3  d-grid" style={{width:"100%"}}>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                    {translated.Actions}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => {
                                        setCenterMap([item.lantitude, item.longtitude])
                                    }}>
                                        {translated.OpenTheMap}
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => router.push(`/station/${item.id}`)}>
                                        {translated.More}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ListGroup.Item>
                    ))
                    :                
                    <ListGroup.Item >
                        <span className="font-size-14">
                            {translated.ObjectNotFound}
                        </span>
                    </ListGroup.Item>
                }

            </ListGroup>
            }
            </div>

            <div className="row mt-4">
                <div className="col-xs-12 col-md-4 col-lg-4">
                    {
                        isLoading
                        ? <Skeleton count={1} height={350}/>
                        :
                        <div class="card">
                            <div class="card-body">
                                <h4 class="mb-4 text-black">Контроллеры <span class="text-muted font-16">{dashboardData.countControllers}</span>  </h4>
                                <div class="text-center">
                                    <h6>
                                        <span class="text-muted font-16">Информация по контроллерам о наличии связи и действующей лицензии</span> 
                                    </h6>
                                </div>
                                <div class="row mt-4">

                                    <div class="col-6">
                                        <div class="social-source text-center">
                                            <div class="avatar-xs mx-auto mb-3">
                                                <svg  xmlns="http://www.w3.org/2000/svg"  width="36"  height="36"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-wifi"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 18l.01 0" /><path d="M9.172 15.172a4 4 0 0 1 5.656 0" /><path d="M6.343 12.343a8 8 0 0 1 11.314 0" /><path d="M3.515 9.515c4.686 -4.687 12.284 -4.687 17 0" /></svg>
                                            </div>
                                            <h5 class="font-size-15 text-black">На связи</h5>
                                            <p class="mb-0 text-black h3">{dashboardData.countControllerConnect} </p>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="social-source text-center">
                                            <div class="avatar-xs mx-auto mb-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"  stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-wifi-off">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M12 18l.01 0" />
                                                    <path d="M9.172 15.172a4 4 0 0 1 5.656 0" />
                                                    <path d="M6.343 12.343a7.963 7.963 0 0 1 3.864 -2.14m4.163 .155a7.965 7.965 0 0 1 3.287 2" />
                                                    <path d="M3.515 9.515a12 12 0 0 1 3.544 -2.455m3.101 -.92a12 12 0 0 1 10.325 3.374" />
                                                    <path d="M3 3l18 18" />
                                                </svg>
                                            </div>
                                            <h3 class="font-size-15 text-black">Без связи</h3>
                                            <p class="mb-0 text-black h3">{dashboardData.countControllerDisconnect}</p>
                                        </div>
                                    </div>
                                    <div class="col-6 mt-3">
                                        <div class="social-source text-center">
                                            <div class="avatar-xs mx-auto mb-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount-check">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
                                                    <path d="M9 12l2 2l4 -4" />
                                                </svg>
                                            </div>
                                            <h3 class="font-size-15 text-black">Действует</h3>
                                            <p class="mb-0 text-black h3">{dashboardData.countControllerActiveLicense}</p>
                                        </div>
                                    </div>
                                    <div class="col-6 mt-3">
                                        <div class="social-source text-center">
                                            <div class="avatar-xs mx-auto mb-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount-check-off">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M9 12l2 2l1.5 -1.5m2 -2l.5 -.5" />
                                                    <path d="M8.887 4.89a2.2 2.2 0 0 0 .863 -.53l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.528 .858m-.757 3.248a2.193 2.193 0 0 1 -1.555 .644h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1c0 -.604 .244 -1.152 .638 -1.55" />
                                                    <path d="M3 3l18 18" />
                                                </svg>
                                            </div>
                                            <h3 class="font-size-15 text-black">Отключена</h3>
                                            <p class="mb-0 text-black h3">{dashboardData.countControllerDisableLicense}</p>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <button className="btn btn-light" onClick={() => router.push(`/controllers`)} style={{width:"100%"}}>Подробнее</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                </div>                

                <div className="col-xs-12 col-lg-8 col-md-8">
                    {
                        isLoading
                        ? <Skeleton count={1} height={350}/>
                        :
                        <div class="card-body">
                            <div class="d-flex flex-wrap align-items-start">
                                <h4 class="mb-4 text-black">Отпуск топлива</h4>
                            </div>

                            <div class="row text-center">
                                <div class="col-lg-6">
                                    <div>
                                        <p class="text-muted h5 mb-1">День</p>
                                        <h4 className="text-black">{dashboardData.sumDayLitres}</h4>
                                    </div>
                                </div>
                                
                                <div class="col-lg-6">
                                    <div>
                                        <p class="text-muted h5 mb-1">Месяц</p>
                                        <h4 className="text-black">{dashboardData.sumMonthLitres}</h4>
                                    </div>
                                </div>
                            </div>

                            <Bar options={optionsTest} data={bar} style={{"height":"100px"}} />
                        </div>
                    }
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-4">
                    <div className="col-12">
                        {
                            isLoading
                            ? <Skeleton count={1} height={175}/>
                            :
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="mb-4  text-black">Пользователи и транспорт</h4>
                                    <div class="text-center">
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-6">
                                            <div class="social-source text-center">
                                                <div class="avatar-xs mx-auto mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-users">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                                                        <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                        <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                                        <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                                                    </svg>
                                                </div>
                                                <h5 class="font-size-15 text-black">Пользователи</h5>
                                                <p class="mb-0 text-black h3">{dashboardData.countUsers}</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="social-source text-center">
                                                <div class="avatar-xs mx-auto mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-car">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                                        <path d="M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                                        <path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" />
                                                    </svg> 
                                                </div>                                
                                                <h3 class="font-size-15 text-black">Транспорт</h3>
                                                <p class="mb-0 text-black h3">{dashboardData.countTransports}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>

                    <div className="col-12">
                        {
                            isLoading
                            ? <Skeleton count={1} height={175}/>
                            :
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="mb-4  text-black">Компании </h4>
                                    <div class="text-center">
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-6">
                                            <div class="social-source text-center">
                                                <div class="avatar-xs mx-auto mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount-check">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
                                                        <path d="M9 12l2 2l4 -4" />
                                                    </svg>
                                                </div>
                                                <h5 class="font-size-15 text-black">Активные</h5>
                                                <p class="mb-0 text-black h3">{dashboardData.countActiveCompanies}</p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="social-source text-center">
                                                <div class="avatar-xs mx-auto mb-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount-check-off">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M9 12l2 2l1.5 -1.5m2 -2l.5 -.5" />
                                                        <path d="M8.887 4.89a2.2 2.2 0 0 0 .863 -.53l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.528 .858m-.757 3.248a2.193 2.193 0 0 1 -1.555 .644h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1c0 -.604 .244 -1.152 .638 -1.55" />
                                                        <path d="M3 3l18 18" />
                                                    </svg>
                                                </div>                                
                                                <h3 class="font-size-15 text-black">Заблокированы</h3>
                                                <p class="mb-0 text-black h3">{dashboardData.countDisableCompanies}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>      
                </div>

                <div className="col-8">
                    {
                        isLoading
                        ? <Skeleton count={1} height={350}/>
                        :
                        <div class="card-body">
                            <div class="d-flex flex-wrap align-items-start">
                                <h4 class="mb-4 text-black">Поступление топлива</h4>
                            </div>

                            <div class="row text-center">
                                <div class="col-lg-6">
                                    <div>
                                        <p class="text-muted h5 mb-1">День</p>
                                        <h4 className="text-black">{dashboardData.sumDayPouringLitres}</h4>
                                    </div>
                                </div>
                                
                                <div class="col-lg-6">
                                    <div>
                                        <p class="text-muted h5 mb-1">Месяц</p>
                                        <h4 className="text-black">{dashboardData.sumMonthPouringLitres}</h4>
                                    </div>
                                </div>
                            </div>

                            <Bar options={optionsTest} data={barPouring} style={{"height":"100px"}} />
                        </div>
                    }
                </div>
            </div>
            {/*<div className="row mt-3">
                <div className="col-8">
                    <div className="row">
                    <div className="col-6">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="mb-4 text-black">Controllers <span class="text-muted font-16">125</span>  </h4>
                                <div class="text-center">
                                    <h6>
                                        <span class="text-muted font-16">Information on controllers about the presence of communication and a valid license</span> 
                                    </h6>
                                </div>
                                <div class="row mt-4">

                                    <div class="col-6">
                                        <div class="social-source text-center">
                                            <div class="avatar-xs mx-auto mb-3">
                                                <svg  xmlns="http://www.w3.org/2000/svg"  width="36"  height="36"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-wifi"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 18l.01 0" /><path d="M9.172 15.172a4 4 0 0 1 5.656 0" /><path d="M6.343 12.343a8 8 0 0 1 11.314 0" /><path d="M3.515 9.515c4.686 -4.687 12.284 -4.687 17 0" /></svg>
                                            </div>
                                            <h5 class="font-size-15 text-black">Connect</h5>
                                            <p class="mb-0 text-black h3">10 </p>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="social-source text-center">
                                            <div class="avatar-xs mx-auto mb-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"  stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-wifi-off">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M12 18l.01 0" />
                                                    <path d="M9.172 15.172a4 4 0 0 1 5.656 0" />
                                                    <path d="M6.343 12.343a7.963 7.963 0 0 1 3.864 -2.14m4.163 .155a7.965 7.965 0 0 1 3.287 2" />
                                                    <path d="M3.515 9.515a12 12 0 0 1 3.544 -2.455m3.101 -.92a12 12 0 0 1 10.325 3.374" />
                                                    <path d="M3 3l18 18" />
                                                </svg>
                                            </div>
                                            <h3 class="font-size-15 text-black">Disconnect</h3>
                                            <p class="mb-0 text-black h3">2</p>
                                        </div>
                                    </div>
                                    <div class="col-6 mt-3">
                                        <div class="social-source text-center">
                                            <div class="avatar-xs mx-auto mb-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount-check">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
                                                    <path d="M9 12l2 2l4 -4" />
                                                </svg>
                                            </div>
                                            <h3 class="font-size-15 text-black">Active</h3>
                                            <p class="mb-0 text-black h3">10</p>
                                        </div>
                                    </div>
                                    <div class="col-6 mt-3">
                                        <div class="social-source text-center">
                                            <div class="avatar-xs mx-auto mb-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount-check-off">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M9 12l2 2l1.5 -1.5m2 -2l.5 -.5" />
                                                    <path d="M8.887 4.89a2.2 2.2 0 0 0 .863 -.53l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.528 .858m-.757 3.248a2.193 2.193 0 0 1 -1.555 .644h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1c0 -.604 .244 -1.152 .638 -1.55" />
                                                    <path d="M3 3l18 18" />
                                                </svg>
                                            </div>
                                            <h3 class="font-size-15 text-black">Disable</h3>
                                            <p class="mb-0 text-black h3">5</p>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <button className="btn btn-light" style={{width:"100%"}}>See more</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/*<div className="col-6">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="mb-4 text-black">Notifications</h4>
                                    <div class="d-flex mb-3">
                                        <div class="avatar-xs align-self-center me-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="30" height="30" stroke-width="2"> <path d="M18 8a3 3 0 0 1 0 6"></path> <path d="M10 8v11a1 1 0 0 1 -1 1h-1a1 1 0 0 1 -1 -1v-5"></path> <path d="M12 8h0l4.524 -3.77a.9 .9 0 0 1 1.476 .692v12.156a.9 .9 0 0 1 -1.476 .692l-4.524 -3.77h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h8"></path> </svg> 
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden">
                                            <h5 class="text-truncate text-black font-size-14 mb-1">Update</h5>
                                            <p class="text-truncate mb-0">benzamatPro was updated</p>
                                        </div>
                                        <div class="font-size-11">20.10.24</div>
                                    </div>
                                    <div class="d-flex mb-3">
                                        <div class="avatar-xs align-self-center me-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="30" height="30" stroke-width="2"> <path d="M12 9v4"></path> <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z"></path> <path d="M12 16h.01"></path> </svg> 
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden">
                                            <h5 class="text-truncate text-black font-size-14 mb-1">Controller's connect</h5>
                                            <p class="text-truncate mb-0">Error with controller connect</p>
                                        </div>
                                        <div class="font-size-11">20.10.24</div>
                                    </div>
                                    <div class="d-flex mb-3">
                                        <div class="avatar-xs align-self-center me-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="30" height="30" stroke-width="2"> <path d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"></path> <path d="M12 16v.01"></path> <path d="M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"></path> </svg> 
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden">
                                            <h5 class="text-truncate text-black font-size-14 mb-1">Support</h5>
                                            <p class="text-truncate mb-0">User manual was updated</p>
                                        </div>
                                        <div class="font-size-11">20.10.24</div>
                                    </div>
                                                                 
                            </div>
                            
                            <button className="btn btn-light" style={{width:"100%"}}>See more</button>
                            
                        </div>
                    </div>
                    </div>
                </div>                   
                        
            </div>*/}

            <div className="col-xs-12 col-lg-12 col-md-12 mt-3">
                <h4 className="text-black benzamat">
                    {translated.LastFillings}
                </h4>
            </div>
            <div className="col-xs-12 col-lg-12 col-md-12">
            {
                isLoading
                ?
                <Skeleton count={1} height={150}/>
                :
                <MaterialReactTable 
                    columns={columns} 
                    data={fillings}
                    localization={MRT_Localization_EN}
                    initialState={{ showColumnFilters: true }}
                    enableTopToolbar={false}
                    enableRowVirtualization
                    muiTablePaperProps={{
                    elevation: 1,
                    }}
                    muiTableHeadCellProps={{
                    //simple styling with the `sx` prop, works just like a style prop in this example
                    sx: {
                        fontWeight: 'bold',
                        color:"#000",
                        backgroundColor:'#F3F3F3',
                        fontFamily: 'BenzamatRegular',
                    },
                    }}
                    muiTableBodyCellProps={{
                    sx: {
                        color:"#000",
                        fontFamily: 'BenzamatRegular',
                    },
                    }}
                />
            }          

            </div>
        </div>

        </>
    );
  
}

export default Dashboard;
