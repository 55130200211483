import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, Form, Card, Spinner, Nav} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import './../../../boxicons.css';
import api from '../../../utils/axiosInterceptions';

export const Gilbarco = ({translated, translatedForm, smartData, controllerInfo}) => {

    const router = useHistory()
    const params = useParams();
    const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
    const [showSecondSleeva, setShowSecondSleeve] = useState(false)
    const [fuels, setFuels] = useState([])
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [showSecondParity, setShowSecondParity] = useState(false)
    const [showFuelType, setShowFuelType] = useState({
        fuelType:false,
        fuelType2:false,
        fuelType3:false,
        fuelType4:false,
        fuelType5:false,
        fuelType6:false,
    })

    const listSubtypes = [
        {label:"SK700-2", value:0},
    ]

    const listParity = [
        {label:"1", value:0},
        {label:"2", value:1},
    ]

    const listCountFuel = [
        {label:"1", value:1},
        {label:"2", value:2},
        {label:"3", value:3},
        {label:"4", value:4},
        {label:"5", value:5},
        {label:"6", value:6}
    ]

    const addresses = [
        {label:"1", value:1},
        {label:"2", value:2},
        {label:"3", value:3},
        {label:"4", value:4},
        {label:"5", value:5},
        {label:"6", value:6},
        {label:"7", value:7},
        {label:"8", value:8},
        {label:"9", value:9},
        {label:"10", value:10},
    ]

    const getFuels = () => {
        api.get(`/api/fuels/all`, {
            withCredentials:true
        })
        .then(function (response) { 
            const add = response.data.listDatas.map(item => ({
                ...item,
                value: item.id,
                label: item.name
            }))
            setFuels(add)
            getSmarts(add)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        getSmarts()
        getFuels()
    }, [])

    const getSmarts = () => {
        api.get(`/api/controllers/details/smarts`, {
            headers:{
                "content-type" : "application/json",
                "controllerId":controllerInfo.id
            },
            params:{
                controllerId: controllerInfo.id,
            },
            withCredentials:true
        })
        .then(function (response) 
        { 
            console.log("SMART DATA method")
            console.log(response.data)

            if(response.data.listDatas[0].countParty == 1) {
                setShowSecondParity(true)
            } else {
                setShowSecondParity(false)
            }

            let find_subtype = listSubtypes.filter((item) => item.value == response.data.listDatas[0].subtype)
            setValue("Subtype", find_subtype[0])

            let find_count_party = listParity.filter((item) => item.value == response.data.listDatas[0].countParty)
            setValue("CountParty", find_count_party[0])

            let find_count_fuel = listCountFuel.filter((item) => item.value == response.data.listDatas[0].countTypeFuel)
            setValue("CountFuel", find_count_fuel[0])

            let find_address = addresses.filter((item) => item.value == response.data.listDatas[0].address)
            setValue("Address", find_address[0])

            let find_address2 = addresses.filter((item) => item.value == response.data.listDatas[0].address2)
            setValue("Address2", find_address2[0])

            let fuel2 = response.data.listDatas.filter((item) => item.id === response.data.listDatas[0].fuel_type2)
            setValue("FuelType2", {label:fuel2[0].name, value:fuel2[0].id})

            let fuel3 = response.data.listDatas.filter((item) => item.id === response.data.listDatas[0].fuel_type3)
            setValue("FuelType3", {label:fuel3[0].name, value:fuel3[0].id})

            let fuel4 = response.data.listDatas.filter((item) => item.id === response.data.listDatas[0].fuel_type3)
            setValue("FuelType4", {label:fuel4[0].name, value:fuel4[0].id})

            let fuel5 = response.data.listDatas.filter((item) => item.id === response.data.listDatas[0].fuel_type3)
            setValue("FuelType5", {label:fuel5[0].name, value:fuel5[0].id})

            let fuel6 = response.data.listDatas.filter((item) => item.id === response.data.listDatas[0].fuel_type3)
            setValue("FuelType6", {label:fuel6[0].name, value:fuel6[0].id})
            
            if(response.data.listDatas[0].countTypeFuel == 2) {
                setShowFuelType({
                    fuelType:true,
                    fuelType2:true
                })
            } else if(response.data.listDatas[0].countTypeFuel == 3) {
                setShowFuelType({
                    fuelType:true,
                    fuelType2:true,
                    fuelType3:true
                })
            } else if(response.data.listDatas[0].countTypeFuel == 4) {
                setShowFuelType({
                    fuelType:true,
                    fuelType2:true,
                    fuelType3:true,
                    fuelType4:true
                })
            } else if(response.data.listDatas[0].countTypeFuel == 5) {
                setShowFuelType({
                    fuelType:true,
                    fuelType2:true,
                    fuelType3:true,
                    fuelType4:true,
                    fuelType5:true
                })
            } else if(response.data.listDatas[0].countTypeFuel == 6) {
                setShowFuelType({
                    fuelType:true,
                    fuelType2:true,
                    fuelType3:true,
                    fuelType4:true,
                    fuelType5:true,
                    fuelType6:true
                })
            }

        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const onSubmit = data => {
        setLoadingBtn(true)
        console.log(data)
        api.post('/api/controllers/details/gilbarco/update', {
            ControllerId: params.id,
            CountParty: data.CountParty != undefined ? data.CountParty.value : 1,
            CountTypeFuel: data.CountFuel != undefined ? data.CountFuel.value : 1,
            SubType: data.Subtype.value,
            FuelType: data.FuelType != undefined ? data.FuelType.value : 1,
            FuelType2:  data.FuelType2 != undefined ? data.FuelType2.value : 1,
            FuelType3: data.FuelType3 != undefined ? data.FuelType3.value : 1,
            FuelType4:  data.FuelType4 != undefined ? data.FuelType4.value : 1,
            FuelType5:  data.FuelType5 != undefined ? data.FuelType5.value : 1,
            FuelType6:  data.FuelType6 != undefined ? data.FuelType6.value : 1,
            Address2: data.Address2 != undefined ? data.Address2.value : 1,
            Address: data.Address != undefined ? data.Address.value : 1,
            CountDispenser: 1,
            withCredentials:true
        })
        .then(function (response) {
            setLoadingBtn(false)
            displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
            console.log(error);
            setLoadingBtn(false)
        });
    }

    const displayToast = (message, status) => {

        if(status){
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
    
        } else {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
        });
        }
    
    }

    return(      
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-xs-12 col-lg-12 col-md-12">
                    <h4 className="text-black">Gilbarco</h4>
                </div>

                <div className="col-xs-12 col-lg-12 col-md-12">
                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.ModelDispenser} </Form.Label>
                            <Controller
                                name="Subtype"                                
                                control={control}
                                rules={{ required: translatedForm.Required}}
                                render={({ field:{value} }) => (
                                <Select
                                    value={value}
                                    options={listSubtypes}
                                    placeholder={translatedForm.EnterSingleValue}
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        console.log(item)
                                        setValue("Subtype", item)
                                        if(item.value == 1) {
                                            setShowSecondSleeve(false)
                                        } else {
                                            setShowSecondSleeve(true)
                                        }
                                    }}
                                />
                                )}
                            />
                            {errors.Subtype && 
                                <span className="text-danger font-size-13">{errors.Subtype.message}</span>
                            }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-6 col-md-6">
                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.Sides} </Form.Label>
                            <Controller
                                name="CountParty"                                
                                control={control}
                                rules={{ required: translatedForm.Required}}
                                render={({ field:{value} }) => (
                                <Select
                                    value={value}
                                    options={listParity}
                                    placeholder={translatedForm.EnterSingleValue}
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        console.log(item)
                                        setValue("CountParty", item)
                                        if(item.value === 1) {
                                            setShowSecondParity(true)
                                        } else {
                                            setShowSecondParity(false)
                                        }
                                    }}
                                />
                                )}
                            />
                            {errors.CountParty && 
                                <span className="text-danger font-size-13">{errors.CountParty.message}</span>
                            }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-6 col-md-6">
                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.CountFuels} </Form.Label>
                            <Controller
                                name="CountFuel"                                
                                control={control}
                                rules={{ required: translatedForm.Required}}
                                render={({ field:{value} }) => (
                                <Select
                                    value={value}
                                    options={listCountFuel}
                                    placeholder={translatedForm.EnterSingleValue}
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        console.log(item)
                                        setValue("CountFuel", item)
                                        if(item.value == 1) {
                                            setShowFuelType({
                                                fuelType:true,
                                            })
                                        } else if(item.value == 2) {
                                            setShowFuelType({
                                                fuelType:true,
                                                fuelType2:true
                                            })
                                        } else if(item.value == 3) {
                                            setShowFuelType({
                                                fuelType:true,
                                                fuelType2:true,
                                                fuelType3:true
                                            })
                                        } else if(item.value == 4) {
                                            setShowFuelType({
                                                fuelType:true,
                                                fuelType2:true,
                                                fuelType3:true,
                                                fuelType4:true
                                            })
                                        } else if(item.value == 5) {
                                            setShowFuelType({
                                                fuelType:true,
                                                fuelType2:true,
                                                fuelType3:true,
                                                fuelType4:true,
                                                fuelType5:true
                                            })
                                        } else if(item.value == 6) {
                                            setShowFuelType({
                                                fuelType:true,
                                                fuelType2:true,
                                                fuelType3:true,
                                                fuelType4:true,
                                                fuelType5:true,
                                                fuelType6:true
                                            })
                                        }
                                    }}
                                />
                                )}
                            />
                            {errors.CountFuel && 
                                <span className="text-danger font-size-13">{errors.CountFuel.message}</span>
                            }
                    </Form.Group>
                </div>

                <div className="col-xs-12 col-lg-6 col-md-6">
                    <Form.Group className="mb-3">
                        <Form.Label className="text-black">{translated.AddressParty1}</Form.Label>
                            <Controller
                                name="Address"                                
                                control={control}
                                rules={{ required: translatedForm.Required}}
                                render={({ field:{value} }) => (
                                <Select
                                    value={value}
                                    options={addresses}
                                    placeholder={translatedForm.EnterSingleValue}
                                    classNamePrefix="select"
                                    onChange={(item) => {
                                        console.log(item)
                                        setValue("Address", item)
                                    }}
                                />
                                )}
                            />
                            {errors.Address && 
                                <span className="text-danger font-size-13">{errors.Address.message}</span>
                            }
                    </Form.Group>
                </div>

                {
                    showSecondParity
                    ?
                    <div className="col-xs-12 col-lg-6 col-md-6">
                        <Form.Group className="mb-3">
                            <Form.Label className="text-black">{translated.AddressParty2} </Form.Label>
                                <Controller
                                    name="Address2"                                
                                    control={control}
                                    rules={{ required: translatedForm.Required}}
                                    render={({ field:{value} }) => (
                                    <Select
                                        value={value}
                                        options={addresses}
                                        placeholder={translatedForm.EnterSingleValue}
                                        classNamePrefix="select"
                                        onChange={(item) => {
                                            console.log(item)
                                            setValue("Address2", item)
                                        }}
                                    />
                                    )}
                                />
                                {errors.Address2 && 
                                    <span className="text-danger font-size-13">{errors.Address2.message}</span>
                                }
                        </Form.Group>
                    </div>
                    : null
                }
                
                <div className="col-xs-12 col-lg-12 col-md-12"></div>

                <div className="col-xs-12 col-lg-4 col-md-4">
                    <Card border="light" className="border shadow-none">
                        <Card.Body>
                        <h3 className="text-black benzamat-font-bold">{translated.Sleeve1}</h3>
                        <div>
                            <Form.Group className="mb-3 mt-3">
                                <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                <Controller
                                    name="FuelType"                     
                                    control={control}
                                    render={({ field: {value} }) => (
                                    <Select
                                        options={fuels}
                                        value={value}               
                                        placeholder={translatedForm.EnterSingleValue}
                                        classNamePrefix="select"
                                        onChange={(item) => setValue("FuelType", item)}
                                    />
                                    )}
                                />
                            </Form.Group>
                        </div>
                        </Card.Body>
                    </Card>
                </div>

                {
                    showFuelType.fuelType2
                    ?
                    <div className="col-xs-12 col-lg-4 col-md-4">
                        <Card border="light" className="border shadow-none">
                            <Card.Body>
                            <h3 className="text-black benzamat-font-bold">{translated.Sleeve2}</h3>
                            <div>
                                <Form.Group className="mb-3 mt-3">
                                    <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                    <Controller
                                        name="FuelType2"                                
                                        control={control}
                                        render={({ field: {value} }) => (
                                        <Select
                                            options={fuels}
                                            value={value}               
                                            placeholder={translatedForm.EnterSingleValue}
                                            classNamePrefix="select"
                                            onChange={(item) => setValue("FuelType2", item)}
                                        />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            </Card.Body>
                        </Card>
                    </div>
                    : null
                }

                {
                    showFuelType.fuelType3
                    ?
                    <div className="col-xs-12 col-lg-4 col-md-4">
                        <Card border="light" className="border shadow-none">
                            <Card.Body>
                            <h3 className="text-black benzamat-font-bold">{translated.Sleeve3}</h3>
                            <div>
                                <Form.Group className="mb-3 mt-3">
                                    <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                    <Controller
                                        name="FuelType3"                                
                                        control={control}
                                        render={({ field: {value} }) => (
                                        <Select
                                            options={fuels}
                                            value={value}               
                                            placeholder={translatedForm.EnterSingleValue}
                                            classNamePrefix="select"
                                            onChange={(item) => setValue("FuelType3", item)}
                                        />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            </Card.Body>
                        </Card>
                    </div>
                    : null
                }

                {
                    showFuelType.fuelType4
                    ?
                    <div className="col-xs-12 col-lg-4 col-md-4">
                        <Card border="light" className="border shadow-none">
                            <Card.Body>
                            <h3 className="text-black benzamat-font-bold">{translated.Sleeve4}</h3>
                            <div>
                                <Form.Group className="mb-3 mt-3">
                                    <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                    <Controller
                                        name="FuelType4"                                
                                        control={control}
                                        render={({ field: {value} }) => (
                                        <Select
                                            options={fuels}
                                            value={value}               
                                            placeholder={translatedForm.EnterSingleValue}
                                            classNamePrefix="select"
                                            onChange={(item) => setValue("FuelType4", item)}
                                        />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            </Card.Body>
                        </Card>
                    </div>
                    : null
                }

                {
                    showFuelType.fuelType5
                    ?
                    <div className="col-xs-12 col-lg-4 col-md-4">
                        <Card border="light" className="border shadow-none">
                            <Card.Body>
                            <h3 className="text-black benzamat-font-bold">{translated.Sleeve5}</h3>
                            <div>
                                <Form.Group className="mb-3 mt-3">
                                    <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                    <Controller
                                        name="FuelType5"                                
                                        control={control}
                                        render={({ field: {value} }) => (
                                        <Select
                                            options={fuels}
                                            value={value}               
                                            placeholder={translatedForm.EnterSingleValue}
                                            classNamePrefix="select"
                                            onChange={(item) => setValue("FuelType5", item)}
                                        />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            </Card.Body>
                        </Card>
                    </div>
                    : null
                }
                
                {
                    showFuelType.fuelType6
                    ?
                    <div className="col-xs-12 col-lg-4 col-md-4">
                        <Card border="light" className="border shadow-none">
                            <Card.Body>
                            <h3 className="text-black benzamat-font-bold">{translated.Sleeve6}</h3>
                            <div>
                                <Form.Group className="mb-3 mt-3">
                                    <Form.Label className="text-black">{translated.FuelType}</Form.Label>                                
                                    <Controller
                                        name="FuelType6"                                
                                        control={control}
                                        render={({ field: {value} }) => (
                                        <Select
                                            options={fuels}
                                            value={value}               
                                            placeholder={translatedForm.EnterSingleValue}
                                            classNamePrefix="select"
                                            onChange={(item) => setValue("FuelType6", item)}
                                        />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            </Card.Body>
                        </Card>
                    </div>
                    : null
                }

                <div className="form-group ">
                    <button type="submit" disabled={loadingBtn} className="btn btn-primary">
                        {loadingBtn && (
                            <Spinner animation="border" variant="light" 
                                as="span"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: "5px" }}
                            />
                        )}
                        {loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                        {!loadingBtn && <span>{translatedForm.SaveChanges}</span>}
                    </button>
                </div>
            </div>
        </Form>
    );
}